import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})

export class StatisticsService {

  constructor(
    private restful: RestfulService
  ) {
  }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.log(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 최근 년도 가져오기
  getProdTotalLastYear( type: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'statistics',
        version: '1.0',
        action: 'getProdTotalLastYear'
      }, {
        reportType: type
      }
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 년도별 생산/출하 리스트 가져오기
  getYearProdSaleReport( search: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'statistics',
        version: '1.0',
        action: 'getYearProdSaleReport'
      }, search
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 제품 리스트 가져오기
  getProdList(): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'statistics',
        version: '1.0',
        action: 'getProdList'
      }, {}
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 품목별 생산/출하 리스트 가져오기
  getYearProdSaleList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'statistics',
        version: '1.0',
        action: 'getYearProdSaleList'
      }, search
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 제품 리스트 가져오기( 년도별 수출입통계에서 호출시 YEAR , 월별에서 넘길떄는 MONTH )
  getImpExpProdList( type: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'statistics',
        version: '1.0',
        action: 'getImpExpProdList'
      }, {
        yearType: type
      }
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 연도별 수출입통계 리스트 가져오기
  getYearImpExpList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'statistics',
        version: '1.0',
        action: 'getYearImpExpList'
      }, search
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 월별 수출입통계 리스트 가져오기
  getMonthImpExpList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'statistics',
        version: '1.0',
        action: 'getMonthImpExpList'
      }, search
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 성상 데이터 년도 가져오기
  getYear(): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'statistics',
        version: '1.0',
        action: 'getYear'
      }, {}
    ).then( response => {
      return response || [];
    })
      .catch(this.handleErrorPromise);
  }

  // 성상 데이터 가져오기
  getData(year, month): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'statistics',
        version: '1.0',
        action: 'getData'
      }, {
        year: year,
        month: month
      }
    ).then( response => {
      return response || [];
    })
      .catch(this.handleErrorPromise);
  }

  // 성상 데이터 삭제하기
  deleteData(seq): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'statistics',
        version: '1.0',
        action: 'deleteData'
      }, {
        seq: seq,
      }
    ).then( response => {
      return response || [];
    })
      .catch(this.handleErrorPromise);
  }

  // 성상분석 정보 저장하기
  setData( form ): Promise<any> {
    return this.restful.post({
        program: 'home',
        service: 'statistics',
        version: '1.0',
        action: 'setData'
      }, form.value
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

}
