<app-header [menuList$]="menuList$" [memberInfo$]="memberInfo$"></app-header>

<app-sub-header [currMenu]="currMenu$" [memberInfo]="memberInfo$"></app-sub-header>

<div class="login-page contents-page">
  <div class="inner-wrap">
    <div class="login-txt">한국에너지기기산업진흥회에 오신것 을 환영합니다.</div>
    <form [formGroup]="form">
  
      <div class="login">
        <label for="id">아이디</label>
        <input type="text" id="id" formControlName="id" placeholder="아이디를 입력해주세요." [ngClass]="{formErrors:formErrors['id']}" [autofocus]="true" />
        <div *ngIf="formErrors['id']" class="error-message">{{formErrors['id']}}</div>
        <label for="pw">비밀번호</label>
        <input type="password" id="pw" formControlName="pw" placeholder="비밀번호를 입력해주세요." [ngClass]="{formErrors:formErrors['pw']}"/>
        <div *ngIf="formErrors['pw']" class="error-message">{{formErrors['pw']}}</div>
        
        <button class="login-btn" (click)="setLogin()">로그인</button>
  
        <div class="btn-area">
          <a routerLink="/member/findid" class="idfind-btn">아이디 찾기</a>
          <a routerLink="/member/findpw" class="pwfind-btn">비밀번호 찾기</a>
          <a routerLink="/member/join/step1" class="join-btn">회원가입</a>
        </div>
      </div>
  
    </form>
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>