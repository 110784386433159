<app-sub-header [menu]="menu"></app-sub-header>

<div class="eatl-page contents-page">
  <div class="inner-wrap">
    <ul class="page-tab clearfix">
      <li [ngClass]="{active: tabIndex == '1'}" (click)="tabIndex = '1'">국가공인시험검사기관</li>
      <li [ngClass]="{active: tabIndex == '2'}" (click)="tabIndex = '2'">KS표시인증업체협의회</li>
      <li [ngClass]="{active: tabIndex == '3'}" (click)="tabIndex = '3'">안전확인시험검사기관</li>
      <li [ngClass]="{active: tabIndex == '4'}" (click)="tabIndex = '4'">표준개발협력기관</li>
      <li [ngClass]="{active: tabIndex == '5'}" (click)="tabIndex = '5'">품목별 품질관리기관</li>
      <li [ngClass]="{active: tabIndex == '6'}" (click)="tabIndex = '6'">가스보일러<br/>실환경 실증실험실 구축</li>      
    </ul>

    <app-eatl-business1 *ngIf="tabIndex == '1'"></app-eatl-business1>
    <app-eatl-business2 *ngIf="tabIndex == '2'"></app-eatl-business2>
    <app-eatl-business3 *ngIf="tabIndex == '3'"></app-eatl-business3>
    <app-eatl-business4 *ngIf="tabIndex == '4'"></app-eatl-business4>
    <app-eatl-business5 *ngIf="tabIndex == '5'"></app-eatl-business5>
    <app-eatl-business6 *ngIf="tabIndex == '6'"></app-eatl-business6>

  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>


