<app-sub-header [menu]="menu"></app-sub-header>

<div class="promote-page contents-page">
  <div class="inner-wrap">
    <div class="section">
      <div class="title">홍보자료 다운로드</div>
      <ul class="list clearfix">
        <li>
          <div class="div1">
            <div class="down-title">에너지기기<br/>전문시험 검사기관</div>
            <a href="/assets/download/Binder1.pdf" target="_blank" class="down-btn">PDF 다운로드</a>
          </div>
          <div class="div2"></div>
          <div class="div3"></div>
        </li>
        <li>
          <div class="div1">
            <div class="down-title">에너지기기<br/>실환경 실험실 구축</div>
            <a href="/assets/download/Binder3.pdf" target="_blank" class="down-btn">PDF 다운로드</a>
          </div>
          <div class="div2"></div>
          <div class="div3"></div>          
        </li>
        <li>
          <div class="div1">
            <div class="down-title">한국 에너지기기<br/>산업진흥회</div>
            <a href="/assets/download/Binder2.pdf" target="_blank" class="down-btn">PDF 다운로드</a>
          </div>
          <div class="div2"></div>
          <div class="div3"></div>          
        </li>
      </ul>
    </div>  
  </div>  
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>      