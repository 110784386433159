/*******************************************************************************
  설  명 : 회원가입 - 회사검색 모달창
  작성자 : 
  작성일 : 
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { HMemberService } from '@home/service/member.service';

@Component({
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: ['./company-search.component.scss']
})
export class CompanySearchComponent implements OnInit {

/*******************************************************************************
  설명 : 전역 변수 선언부
*******************************************************************************/
  search = '';
  companyList = [];

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    public toastrService: ToastrService,
    private memberService: HMemberService,

  ) { 
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 회사 검색
  *******************************************************************************/
  getCompany() {
    this.memberService.getCompanySearchList( {searchText: this.search} ).then( response => {
      if ( response.ResultCode ) {
        this.companyList = response.data;
      } else {
        this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 검색 Input에서 엔터 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( $event ) {
    if( $event.key == 'Enter' ) {
      this.getCompany();
    }
  }

}
