import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AgGridModule } from 'ag-grid-angular';
import {MatTabsModule} from '@angular/material/tabs';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { HAuthGuard } from '@home/service/auth.guard';

import { SharedModule } from '@app/shared.module';

import { HomeRoutingModule } from '@home/home-routing.module';
import { HomeComponent } from '@home/home.component';

import { MainComponent } from '@home/page/main/main.component';
import { HeaderComponent } from '@home/page/main/header/header.component';
import { FooterComponent } from '@home/page/main/footer/footer.component';
import { SubHeaderComponent } from '@home/page/main/sub-header/sub-header.component';

import { BoardComponent } from '@home/page/board/board.component';
import { BoardListComponent } from '@home/page/board/board-list/board-list.component';
import { BoardViewComponent } from '@home/page/board/board-view/board-view.component';
import { BoardWriteComponent } from '@home/page/board/board-write/board-write.component';

import { LoginComponent } from '@home/page/member/login/login.component';
import { FindIdComponent } from '@home/page/member/find-id/find-id.component';
import { FindPwComponent } from '@home/page/member/find-pw/find-pw.component';

import { FindIdResultComponent } from '@home/page/member/find-id/find-id-result/find-id-result.component';
import { FindPwResultComponent } from '@home/page/member/find-pw/find-pw-result/find-pw-result.component';

import { AgGridImageComponent } from '@components/ag-grid-image/ag-grid-image.component';
import { AgGridHtmlComponent } from '@components/ag-grid-html/ag-grid-html.component';
import { AgGridSaveComponent } from '@components/ag-grid-save/ag-grid-save.component';
import { AgGridExcelComponent } from '@components/ag-grid-excel/ag-grid-excel.component';
import { AgGridHeaderCheckboxComponent } from '@components/ag-grid-header-checkbox/ag-grid-header-checkbox.component';

import { ModalMonthComponent } from '@components/modal-month/modal-month.component';
import { JoinGuideComponent } from './page/customer/join-guide/join-guide.component';
import { MemberIntroduceComponent } from './page/customer/member-introduce/member-introduce.component';
import { MemberNewsComponent } from './page/customer/member-news/member-news.component';
import { MemberDetailComponent } from './page/customer/member-introduce/member-detail/member-detail.component';
import { EatlBusinessComponent } from './page/EATL/eatl-business/eatl-business.component';
import { EatlBusiness1Component } from './page/EATL/eatl-business/eatl-business1/eatl-business1.component';
import { EatlBusiness2Component } from './page/EATL/eatl-business/eatl-business2/eatl-business2.component';
import { EatlBusiness3Component } from './page/EATL/eatl-business/eatl-business3/eatl-business3.component';
import { EatlBusiness4Component } from './page/EATL/eatl-business/eatl-business4/eatl-business4.component';
import { EatlBusiness5Component } from './page/EATL/eatl-business/eatl-business5/eatl-business5.component';
import { EatlBusiness6Component } from './page/EATL/eatl-business/eatl-business6/eatl-business6.component';
import { EquipmentComponent } from './page/EATL/equipment/equipment.component';
import { PromoteComponent } from './page/EATL/promote/promote.component';
import { Business41Component } from './page/EATL/eatl-business/eatl-business4/business41/business41.component';
import { Business42Component } from './page/EATL/eatl-business/eatl-business4/business42/business42.component';
import { Business43Component } from './page/EATL/eatl-business/eatl-business4/business43/business43.component';
import { ArticlesComponent } from './page/introduce/articles/articles.component';
import { GreetingsComponent } from './page/introduce/greetings/greetings.component';
import { CompanyOverviewComponent } from './page/introduce/company-overview/company-overview.component';
import { BusinessComponent } from './page/introduce/company-overview/business/business.component';
import { HistoryComponent } from './page/introduce/company-overview/history/history.component';
import { OrganizationComponent } from './page/introduce/company-overview/organization/organization.component';
import { MapComponent } from './page/introduce/map/map.component';
import { MapBusComponent } from './page/introduce/map/map-bus/map-bus.component';
import { MapCarComponent } from './page/introduce/map/map-car/map-car.component';
import { MapSubwayComponent } from './page/introduce/map/map-subway/map-subway.component';
import { JoinStep1Component } from './page/member/join/join-step1/join-step1.component';
import { JoinStep2Component } from './page/member/join/join-step2/join-step2.component';
import { JoinStep3Component } from './page/member/join/join-step3/join-step3.component';
import { MypageComponent } from './page/member/mypage/mypage.component';
import { ApplicationResultComponent } from './page/member/mypage/application-result/application-result.component';
import { ComplainResultComponent } from './page/member/mypage/complain-result/complain-result.component';
import { MemberEditComponent } from './page/member/mypage/member-edit/member-edit.component';
import { BoardLockComponent } from './page/board/board-lock/board-lock.component';
import { AccreditedTestComponent } from './page/online-application/accredited-test/accredited-test.component';
import { ApplicationModal1Component } from './page/online-application/accredited-test/application-modal1/application-modal1.component';
import { ApplicationModal2Component } from './page/online-application/accredited-test/application-modal2/application-modal2.component';
import { ApplicationModal3Component } from './page/online-application/accredited-test/application-modal3/application-modal3.component';
import { ComplainModalComponent } from './page/online-application/accredited-test/complain-modal/complain-modal.component';
import { ApplicationModalComponent } from './page/online-application/application-modal/application-modal.component';
import { KeaaJoinComponent } from './page/online-application/keaa-join/keaa-join.component';
import { KeaaJoinApplicationComponent } from './page/online-application/keaa-join/keaa-join-application/keaa-join-application.component';
import { SafetyConfirmationTestComponent } from './page/online-application/safety-confirmation-test/safety-confirmation-test.component';
import { ApplicationModal4Component } from './page/online-application/safety-confirmation-test/application-modal4/application-modal4.component';
import { StandardDevelopmentComponent } from './page/online-application/standard-development/standard-development.component';
import { StandardDevelopmentApplication1Component } from './page/online-application/standard-development/standard-development-application1/standard-development-application1.component';
import { StandardDevelopmentApplication2Component } from './page/online-application/standard-development/standard-development-application2/standard-development-application2.component';
import { AnalysisComponent } from './page/service/analysis/analysis.component';
import { CustomerServiceComponent } from './page/service/customer-service/customer-service.component';
import { OverseasMarketsComponent } from './page/service/overseas-markets/overseas-markets.component';
import { PolicyComponent } from './page/service/policy/policy.component';
import { StatisticsComponent } from './page/service/statistics/statistics.component';
import { OutputStatisticsComponent } from './page/service/statistics/output-statistics/output-statistics.component';
import { TradeStatisticsComponent } from './page/service/statistics/trade-statistics/trade-statistics.component';

import { ContentsComponent } from '@home/page/contents/contents.component';
import { CompanySearchComponent } from './page/member/join/join-step2/company-search/company-search.component';
import { ReportCheckModalComponent } from './page/online-application/report-check-modal/report-check-modal.component';
import { PasswordChangeComponent } from './page/member/mypage/member-edit/password-change/password-change.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    HomeComponent,

    // DaumAddressComponent,
    MainComponent,
    HeaderComponent,
    FooterComponent,
    SubHeaderComponent,
    BoardComponent,
    BoardListComponent,
    BoardViewComponent,
    BoardWriteComponent,
    LoginComponent,
    FindIdComponent,
    FindPwComponent,
    FindIdResultComponent,
    FindPwResultComponent,
    JoinGuideComponent,
    MemberIntroduceComponent,
    MemberNewsComponent,
    MemberDetailComponent,
    EatlBusinessComponent,
    EatlBusiness1Component,
    EatlBusiness2Component,
    EatlBusiness3Component,
    EatlBusiness4Component,
    EatlBusiness5Component,
    EatlBusiness6Component,
    EquipmentComponent,
    PromoteComponent,
    Business41Component,
    Business42Component,
    Business43Component,
    ArticlesComponent,
    GreetingsComponent,
    CompanyOverviewComponent,
    BusinessComponent,
    HistoryComponent,
    OrganizationComponent,
    MapComponent,
    MapBusComponent,
    MapCarComponent,
    MapSubwayComponent,
    JoinStep1Component,
    JoinStep2Component,
    JoinStep3Component,
    MypageComponent,
    ApplicationResultComponent,
    ComplainResultComponent,
    MemberEditComponent,
    BoardLockComponent,
    AccreditedTestComponent,
    ApplicationModal1Component,
    ApplicationModal2Component,
    ApplicationModal3Component,
    ComplainModalComponent,
    ApplicationModalComponent,
    KeaaJoinComponent,
    KeaaJoinApplicationComponent,
    SafetyConfirmationTestComponent,
    ApplicationModal4Component,
    StandardDevelopmentComponent,
    StandardDevelopmentApplication1Component,
    StandardDevelopmentApplication2Component,
    AnalysisComponent,
    CustomerServiceComponent,
    OverseasMarketsComponent,
    PolicyComponent,
    StatisticsComponent,
    OutputStatisticsComponent,
    TradeStatisticsComponent,
    ContentsComponent,
    CompanySearchComponent,
    ReportCheckModalComponent,
    PasswordChangeComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SwiperModule,
    SharedModule,
    SlickCarouselModule,
    AgGridModule.withComponents([
      AgGridImageComponent,
      AgGridHtmlComponent,
      AgGridHeaderCheckboxComponent
    ]),
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ko',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    MatTabsModule
  ],
  providers: [
    HAuthGuard,
    NgbActiveModal,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
  ],
  entryComponents: [
    ModalMonthComponent

  ]
})
export class HomeModule { }
