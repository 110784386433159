/*******************************************************************************
  설  명 : 회원사 소개
  작성자 : 김종현
  작성일 : 2021-12-23
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '@app/service/common.service';
import { HMemberService } from '@app/home/service/member.service';

@Component({
  selector: 'app-member-introduce',
  templateUrl: './member-introduce.component.html',
  styleUrls: ['./member-introduce.component.scss']
})
export class MemberIntroduceComponent extends O2OZCMS implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  isSelectOn : boolean = false;
  isSelectTxt = '전체';
  page = 1;
  public totalCount = '';

  public companyList = [];

  public params: any = {
    no: '1',
    row: '10',
    totalCount: '',
    searchGroup: '업체명',
    searchText: '',
  };
  public maxSize: any = 10;
  
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    router: Router,
    commonService: CommonService,
    activatedRoute: ActivatedRoute,
    toastrService: ToastrService,
    public memberService: HMemberService,
  ) { 
    // 상위 클래스에 router 전달
    super(router, commonService, activatedRoute, toastrService);
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCompanyList();
  }

  /*******************************************************************************
    설  명 : 회원사 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCompanyList() {
    this.memberService.getCompanyList( this.params ).then( response => {
      if ( response.ResultCode ) {
        this.companyList = response.data;
        this.totalCount = response.total;
      }
    });
  }

  /*******************************************************************************
    설  명 : 회원사 상세정보 리스트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goDetail(row) {
    let seq = row.seq

    this.router.navigate(
      ['/member/detail'],
      {
        relativeTo: this.activatedRoute,
        queryParams: {seq: seq},
        queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    // 반드시 체크할 것
    // 페이지 리프리시 할때 권한 문제 발생
    this.authCheck$.subscribe( check => {
      if( check == false ) return false;

      this.params.no = page;

      this.getCompanyList();
    });
  }

  /*******************************************************************************
    설  명 : 검색 Input에서 엔터 처리
    입력값 : $event
    리턴값 : 없음
  *******************************************************************************/
  searchList( $event ) {
    if( $event.key == 'Enter' ) {
      this.getCompanyList();
    }
  }
}
