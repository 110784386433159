<div class="keaa-join-modal">
  <div class="modal-header">
    <div class="modal-title">진흥회 가입신청</div>
    <button type="button" class="close" (click)="activeModal.dismiss()"></button>
  </div>
  
  <form [formGroup]="form">
    <div class="modal-body">
    
      <table>
        <colgroup>
          <col class="col1">
          <col class="col2">
          <col class="col3">
        </colgroup>
      
        <tbody>
        <tr>
          <th colspan="2"><span class="red">*</span>업체명</th>
          <td>
            <input type="text" class="input1" required formControlName="com_kor" [ngClass]="{formErrors:formErrors['com_kor']}" />
            <div *ngIf="formErrors['com_kor']" class="error-message">{{formErrors['com_kor']}}</div>
          </td>
        </tr>
        <tr>
          <th colspan="2"><span class="red">*</span>대표자</th>
          <td>
            <input type="text" required formControlName="name_kor" [ngClass]="{formErrors:formErrors['name_kor']}" />
            <div *ngIf="formErrors['name_kor']" class="error-message">{{formErrors['name_kor']}}</div>
          </td>
        </tr>
        <tr>
          <th colspan="2"><span class="red">*</span>생산품목</th>
          <td>
            <input type="textarea" class="textarea" required formControlName="product_kor" [ngClass]="{formErrors:formErrors['product_kor']}" />
            <div *ngIf="formErrors['product_kor']" class="error-message">{{formErrors['product_kor']}}</div>
          </td>
        </tr>     
        <tr>
          <th colspan="2"><span class="red">*</span>사업자등록번호</th>
          <td>
            <input type="text" required formControlName="com_number" [ngClass]="{formErrors:formErrors['com_number']}" />
            <div *ngIf="formErrors['com_number']" class="error-message">{{formErrors['com_number']}}</div>
          </td>
        </tr>      
        <tr>
          <th rowspan="2"><span class="red">*</span>본사</th>
          <th>소재지</th>
          <td>
            <div class="zipcode">
              <input type="text" readonly formControlName="zipcode" [ngClass]="{formErrors:formErrors['zipcode']}"/>
              <btn-daum-address class="ml10" (result)="setDaumAddress1Api($event)" [options]="daumAddressOptions"></btn-daum-address>
            </div>  
            <input type="text" class="addr mr5" formControlName="address" [ngClass]="{formErrors:formErrors['address']}"/>
            <input type="text" class="addr" formControlName="address_detail" [ngClass]="{formErrors:formErrors['address_detail']}"/>
            <div *ngIf="formErrors['zipcode'] || formErrors['address'] || formErrors['address_detail']" class="error-message">필수 입력(선택)항목입니다.</div>
          </td>  
        </tr>       
        <tr>
          <th>전화</th>
          <td class="tel">
            <input type="text" formControlName="tel1" maxlength="3" [ngClass]="{formErrors:formErrors['tel1']}" />
            <span class="between">-</span>
            <input type="text" formControlName="tel2" maxlength="4" [ngClass]="{formErrors:formErrors['tel2']}" />
            <span class="between">-</span>
            <input type="text" formControlName="tel3" maxlength="4" [ngClass]="{formErrors:formErrors['tel3']}" />
            <div *ngIf="formErrors['tel1'] || formErrors['tel2'] || formErrors['tel3']" class="error-message">필수 입력(선택)항목입니다.</div>
          </td>  
        </tr>
        <tr>
          <th rowspan="2"><span class="red">*</span>공장</th>
          <th>소재지</th>
          <td>
            <div class="zipcode">
              <input type="text" readonly formControlName="zipcode_factory" [ngClass]="{formErrors:formErrors['zipcode_factory']}"/>
              <btn-daum-address class="ml10" (result)="setDaumAddress2Api($event)" [options]="daumAddressOptions"></btn-daum-address>
              <button class="btn2 cp" (click)="setSameAddress()">본사와 동일</button>  
            </div>  
            <input type="text" class="addr mr5" formControlName="address_factory" [ngClass]="{formErrors:formErrors['address_factory']}"/>
            <input type="text" class="addr" formControlName="address_detail_factory" [ngClass]="{formErrors:formErrors['address_detail_factory']}"/>
            <div *ngIf="formErrors['zipcode_factory'] || formErrors['address_factory'] || formErrors['address_detail_factory']" class="error-message">필수 입력(선택)항목입니다.</div>
          </td>  
        </tr>       
        <tr>
          <th>전화</th>
          <td class="tel">
            <input type="text" formControlName="tel_factory1" maxlength="3" [ngClass]="{formErrors:formErrors['tel_factory1']}" />
            <span class="between">-</span>
            <input type="text" formControlName="tel_factory2" maxlength="4" [ngClass]="{formErrors:formErrors['tel_factory2']}" />
            <span class="between">-</span>
            <input type="text" formControlName="tel_factory3" maxlength="4" [ngClass]="{formErrors:formErrors['tel_factory3']}" />
            <div *ngIf="formErrors['tel_factory1'] || formErrors['tel_factory2'] || formErrors['tel_factory3']" class="error-message">필수 입력(선택)항목입니다.</div>
          </td>  
        </tr>      
        </tbody>
      </table>
    </div>
  </form>
  <label class="check"><input type="checkbox" [(ngModel)]="check" [value]="true">위 본인은 한국에너지기기산업진흥회 설립취지에 찬동하면서 귀 진흥회에 가입을 신청합니다.</label>
  
  <div class="modal-footer clearfix">
    <button class="btn1" (click)="activeModal.dismiss()">취소</button>  
    <button class="btn1 blue" (click)="setApplication()">신청</button>  
  </div>
</div>
