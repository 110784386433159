import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { config } from '@app/service/config.service';
import { FileUploader, FileItem, FileLikeObject } from 'ng2-file-upload';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { Globals } from '@app/service/globals.service';
import { CommonCodeService } from '@app/admin/service/common.code.service';
import { InspectionBasicService } from '@app/admin/service/inspectionbasic.service';
import { HMemberService } from '@app/home/service/member.service';
import { HInspectionService } from '@app/home/service/inspection.service';

const URL = config.apiInspectionUploadUrl;

@Component({
  selector: 'app-application-modal1',
  templateUrl: './application-modal1.component.html',
  styleUrls: ['./application-modal1.component.scss']
})
export class ApplicationModal1Component implements OnInit {
  public seq = '';

  public btnName: string = '신청';

  public form: FormGroup;
  public formErrors = {};

  public com_gbn: String;

  // 파일 업로드
  public uploader: FileUploader;
  public uploadProgress: any = 0;

  // 업로드 도메인
  public baseURL = config.baseUrl

  viewMode = false;

  commonList = [];
  commonDetailList = [];
  productList = [];
  inspectionList = [];
  inspectionKSList = [];
  IRGCodeGasArray: any = [];
  requestList: any = [];

  now_item_gbn_code: '';
  now_item_code: '';
  now_item_seq: '';

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private hMemberService: HMemberService,
    private hInspectionService: HInspectionService,
    private commonCodeService: CommonCodeService,
    private InspectionBasicService: InspectionBasicService,
    private toastrService: ToastrService,
    private globals: Globals,
    public utilService: UtilService,
  ) {
    this.buildForm();

    this.getCompanyInfo();
  }

  /*******************************************************************************
    설  명 : 회사 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCompanyInfo(): void {
    this.hMemberService.getCompanyInfoLogin().then((response) => {
      if( response.ResultCode && response.data && response.data.length > 0 ) {
        this.form.patchValue(response.data[0]);
        this.form.patchValue({
          company_name: response.data[0].com_kor,
          com_address: response.data[0].address,
          com_tel: response.data[0].tel,
          com_fax: response.data[0].fax,
          applicant_name: response.data[0].username,
          applicant_hp: response.data[0].mobile,
          applicant_email: response.data[0].email,
        });

        this.com_gbn = response.data[0].com_gbn;

      }

      this.form.patchValue({'application_date': moment().format('YYYY-MM-DD')});
    });
  }

  /*******************************************************************************
    설  명 : 검사신청 첨부파일 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getInspectionApplicationFile(): void {
    this.hInspectionService.getInspectionApplicationFile(this.seq).then( response => {
      let files= [];
      this.form.patchValue( {tab1_files: files} );
      if( response.ResultCode && response.data && response.data.length > 0 ) {
        for( let list of response.data ) {
          files.push({
            seq: list.seq,
            url: list.upload_file,
            origin: list.org_upload_file,
            size: list.file_size,
            thumbnail: list.thumbnail,
            thumbnail_result: '',
          });
        }
        this.form.patchValue( {tab1_files: files} );
      }
    });
  }


  /*******************************************************************************
   설  명 : 시험 검사신청 조회
   입력값 : 없음
   리턴값 : 없음
   *******************************************************************************/
   getInspectionApplicationInfo() {
    this.hInspectionService.getInspectionApplicationInfo(this.seq).then( response => {
      if ( response.ResultCode ) {

        for(let key in this.form.value) {
          if( key.substr(0, 5) === 'tab1_' ) {
            let key1 = key.substr((key.length - 5) * -1);
            this.form.patchValue({[key] : response.data[0][key1]});
          } else {
            this.form.patchValue({[key] : response.data[0][key]});
          }
        }
        this.com_gbn = response.data[0].com_gbn;
        this.form.patchValue({
          application_date: response.data[0].application_date.substr(0, 10),
          tab1_agree: true
        });
        this.getInspectionApplicationFile();

        // selectbox selected 처리
        this.now_item_gbn_code = response.data[0].item_gbn_code;
        this.now_item_code = response.data[0].item_code;
        this.now_item_seq = response.data[0].item_seq;
        this.onClickBtn('', 'gbn_code');
        this.onChange('');
        this.onChangeItem('');
        this.now_item_gbn_code = '';
        this.now_item_code = '';
        this.now_item_seq = '';
      }
    });
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let tmp = {
      application_model_seq: [ '', [] ],
      application_request_seq: [ '', [] ],
      IAT_code: [ '1000', [] ],
      company_seq: [ '', [] ],
      company_name: [ '', [Validators.maxLength(100)] ],
      name_kor: [ '', [Validators.maxLength(50)] ],
      com_number: [ '', [Validators.pattern('^[0-9-]+$'), Validators.minLength(10), Validators.maxLength(12)] ],
      zipcode: [ '', [Validators.maxLength(5)] ],
      com_address: [ '', [Validators.maxLength(200)] ],
      addressDetail: [ '', [] ],
      com_tel: [ '', [Validators.maxLength(20)] ],
      com_fax: [ '', [Validators.maxLength(20)] ],
      job: [ '', [Validators.maxLength(100)] ],
      job1: [ '', [Validators.maxLength(100)] ],
      com_gbn: [ '', [] ],
      application_date: [ '', [Validators.required, Validators.maxLength(100)] ],
      applicant_name: [ '', [Validators.required, Validators.maxLength(50)] ],
      applicant_hp: [ '', [Validators.required, Validators.maxLength(30)] ],
      applicant_email: [ '', [Validators.required, Validators.maxLength(100)] ],

      tab1_EIG_code: [ '', [Validators.required] ],
      tab1_report_purpose: [ '', [Validators.required] ],
      tab1_sample_disposition: [ '', [Validators.required] ],
      tab1_conformity_statement: [ '', [Validators.required] ],
      tab1_item_gbn_code: [ '', [Validators.required] ],
      tab1_item_seq: [ '', [Validators.required] ],
      tab1_item_code: [ '', [Validators.required] ],
      tab1_IRG_code: [ '', [] ],
      tab1_IRG_NAME: [ '', [] ],
      tab1_IRG_name: [ '', [] ],
      tab1_IRG_code_gas: [ '', [] ],
      tab1_model_name: [ '', [Validators.required, Validators.maxLength(250)] ],
      tab1_sample_qty: [ '', [Validators.pattern('^[0-9]+$')] ],
      tab1_sample_spec: [ '', [Validators.maxLength(50)] ],
      tab1_apply_item_type: [ '', [Validators.required] ],
      tab1_apply_standard: [ '', [Validators.maxLength(20)] ],
      tab1_file: [ '', [] ],
      tab1_files: [[], []],
      tab1_request_memo: [ '', [Validators.required] ],
      tab1_agree: [ '', [Validators.required] ],
    }

    this.form = this.formBuilder.group(tmp);

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }


  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getCommonList();
    if (this.seq) {
      this.btnName = '저장';
      this.getInspectionApplicationInfo();
    } else {
      this.getCompanyInfo();
    }

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'txt', 'hwp'];
    //var filetype: string[] = this.boardInfo.upload_file_type.split('|');
    //let imagetype: string[] = this.boardInfo.upload_image_type.split('|');

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.uploadProgress = '0%';
    };

    // 업로드 폼생성시 처리
    this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
      form.append( "token", this.globals.token );
    };

    // 업로드시 프로그레스바 처리
    this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
      this.uploadProgress = progress + '%';
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.globals.indicator.next(false);
      });
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '파일 업로드');

        let files = this.form.controls.tab1_files.value;

        files.push({
          seq:'0',
          url: config.baseUrl + response.url.replace(/\\/gi, '/'),
          origin: response.origin_filename,
          size: response.size,
          thumbnail: response.thumbnail.replace(/\\/gi, '/'),
          thumbnail_result: response.thumbnail_result,
        });

        this.form.patchValue( {tab1_files: files} );

      } else {
        this.toastrService.error( response.message, '파일 업로드');
      }

      // indicator 표시 숨김
      this.globals.indicator.next(false);
    };
  }

  /*******************************************************************************
    설  명 : 공통코드 품목구분 가져오기
    입력값 : code
    리턴값 : 없음
  *******************************************************************************/
  getCommonList() {
    if( this.commonList.length < 1 ) {
      this.commonCodeService.getCommonListCode('ITG').then(response => {
        if ( response.ResultCode ) {
          this.commonList = response.data;
        }
      })
    }
  }

  /*******************************************************************************
    설  명 : 품목 선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChange($event: any) {
    if (this.now_item_code > '') {
      this.form.patchValue({tab1_item_code: this.now_item_code});
    } else {
      this.form.patchValue({tab1_item_code: $event.target.value});
    }
    this.getInspectionProductList();
    this.onChangeItem('');
  }

  onChangeItem($event: any) {
    if ($event) {
      this.form.patchValue({tab1_item_seq: $event.target.value});
    } else {
      this.form.patchValue({tab1_item_seq: this.now_item_seq});
    }
    this.inspectionItemCategoryNew();
  }

  /*******************************************************************************
    설  명 : inspection 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  inspectionItemCategoryNew() {
    this.InspectionBasicService.inspectionItemCategoryNew({
      CRUD: 'R',
      isMaster: true,
      item_gbn_code: this.form.value.tab1_item_gbn_code,
      item_seq: this.form.value.tab1_item_seq
    }).then(res => {
      if(res.ResultCode) {
        const data = res.data.map((item: any) => ({...item, IRG_name: item.IRG_NAME}));

        this.inspectionList = data.filter((item: any) => item.IRG_code.replace(/^(0000|30[3|4].+)/, ''))
        this.inspectionKSList = data.filter((item: any) => !item.IRG_code.replace(/^(30[3|4].+$)/, ''))
        .map((item: any) => ({
          ...item,
          id: item.IRG_code,
          text: item.IRG_name
        }))

        if(this.form.value.tab1_IRG_code_gas !== '') {
          this.form.value.tab1_IRG_code_gas.split('|')
          .forEach((item: any) => this.IRGCodeGasArray.push(
            this.inspectionKSList.find((_item: any) => _item.IRG_code == item)));
        } else {
          this.IRGCodeGasArray = [];
        }
      }
    })
  }

  getParams() {
    const item = this.productList.find((item: any) => item.seq == this.form.value.tab1_item_seq) || {}
    const irg = this.inspectionList.find((item: any) => item.IRG_code == this.form.value.IRG_code) || {}

    return {
      item_code: this.form.value.tab1_item_code,
      item_gbn_code: this.form.value.tab1_item_gbn_code,
      item_seq: this.form.value.tab1_item_seq,
      IRG_code: this.form.value.tab1_IRG_code,
      IRG_NAME: irg.IRG_name,
      IRG_name: irg.IRG_name
    }
  }

  /*******************************************************************************
    설  명 : 품목 리스트 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getInspectionProductList() {
    const data = this.getParams();

    // this.InspectionBasicService.getInspectionProductList({
    //   product: data.item_gbn_code,
    //   productDetail: data.item_code
    this.InspectionBasicService.inspectionApplication({
      ...data,
      CRUD: 'READINSPECTIONPRODUCT'
    }).then(res => {
      if(res.ResultCode) this.productList = res.data.map((item: any) => ({
        ...item,
        label: item.item_full_name
      }))
    })
  }


  /*******************************************************************************
    설  명 : 공통코드 품목 가져오기
    입력값 : code
    리턴값 : 없음
  *******************************************************************************/
  getCommonDetailList() {
    if( this.commonDetailList.length < 1 ) {
      this.commonCodeService.getCommonListCode('ITM').then(response => {
        if ( response.ResultCode ) {
          for( let list of response.data ) {
            if( list.c1 == this.form.value.tab1_item_gbn_code ) {
              this.commonDetailList.push(list);
            }
          }
        }
      })
    }
  }

   /*******************************************************************************
    설  명 : 품목구분 클릭 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onClickBtn($event: any, type: any) {
    if( type == 'gbn_code' ) {
      this.form.patchValue({
        tab1_item_gbn_code: $event.common_code,
        tab1_item_code: '',
        tab1_item_seq: '',
        tab1_IRG_code: '',
        tab1_IRG_code_gas: ''
      });
      if (this.now_item_gbn_code > '') {
        this.form.patchValue({tab1_item_gbn_code : this.now_item_gbn_code});
      }

      this.commonDetailList = []
      this.productList = []
      this.inspectionList = []
      this.inspectionKSList = []
      this.requestList = {}

    } else if( type == 'report_type' ) {
      // this.params.report_type = $event.common_code;

    } else if( type = 'inspection_place' ) {
      // this.params.inspection_place = $event;
      // this.params.inspection_place == null ? this.params.inspection_place_address = '' : this.params.inspection_place_address;
    }

    this.getCommonDetailList()
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증

    let files:File[] = event.target.files;
    let filteredFiles:File[] = [];
    for(var f of files) {
      filteredFiles.push(f);
    }

    let options = null;
    let filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);
    // indicator 표시
    this.globals.indicator.next(true);

    this.uploader.uploadAll()
  }

  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile( index ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.form.controls.tab1_files.value;

      tmp.splice( index, 1 );

      this.form.patchValue({tab1_files: tmp});
    }
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  deleteUploadFile( upload: any, index: any ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      /*
      this.boardService.deleteUploadFile( upload.board_id, upload.board_seq, upload.seq ).then( response => {
        if( response.ResultCode ) {
          this.boardData.upload.splice( index, 1 );

          this.toastrService.success( response.ResultMessage, '게시글 첨부파일 삭제');
        } else {
          this.toastrService.error( response.ResultMessage, '게시글 첨부파일 삭제');
        }
      });
      */
    }
  }

  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
  checkImage( ext: string ) {
    let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];

    if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
    else return true;
  }

  /*******************************************************************************
    설  명 : 공통코드 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    console.log(this.formErrors);

    if(this.form.valid) {
      if( this.form.value.tab1_files.length < 1 ) {
        this.toastrService.error('구비서류 - 사업자 등록증, 기타 설명 문서를 등록해 주시기 바랍니다.');
      }

      let params = {};
      for(let key in this.form.value) {
        if( key.substr(0, 5) === 'tab1_' ) {
          let key1 = key.substr((key.length - 5) * -1);
          params[key1] = this.form.value[key];
        } else {
          params[key] = this.form.value[key];
        }
      }
      params['seq'] = this.seq;

      this.hInspectionService.setApplicationInspection(params).then((response) => {
        if( response.ResultCode ) {
          this.toastrService.success('정상적으로 등록되었습니다.', '시험검사신청');
          this.activeModal.close();

        } else {
          this.toastrService.error( response.ResultMessage, '시험검사신청');
        }
      }, (error) => {
        this.toastrService.error(error, '시험검사신청');
      });
    }
  }

}
