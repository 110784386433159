/*******************************************************************************
  설  명 : 회원사 상세정보
  작성자 : 김종현
  작성일 : 2021-12-24
*******************************************************************************/
import { Component, OnInit} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '@app/service/common.service';
import { HMemberService } from '@app/home/service/member.service';

@Component({
  selector: 'app-member-detail',
  templateUrl: './member-detail.component.html',
  styleUrls: ['./member-detail.component.scss']
})
export class MemberDetailComponent extends O2OZCMS implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  seq: any = '';

  public companyDetailList = [];

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    router: Router,
    commonService: CommonService,
    activatedRoute: ActivatedRoute,
    toastrService: ToastrService,
    public memberService: HMemberService,
  ) { 
    // 상위 클래스에 router 전달
    super(router, commonService, activatedRoute, toastrService);
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe( params => {
      this.seq = params.seq;

      this.getCompanyInfo();
    });
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.router.navigate(
      ['/company/introduce'],
      {
        relativeTo: this.activatedRoute,
        // queryParams: row,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /*******************************************************************************
    설  명 : 기업정보 가져오기
  *******************************************************************************/
  getCompanyInfo() {
    this.memberService.getCompanyInfo( this.seq ).then( response => {
      if ( response.ResultCode ) {
        this.companyDetailList = response.data;
      }
    });
  }

}
