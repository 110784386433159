import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

 /*******************************************************************************
    설  명 : 바로가기
    입력값 : $event
    리턴값 : 
  *******************************************************************************/
  goLink( $event: any ) {
    window.open($event.target.value);
  }  
}
