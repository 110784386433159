<div class="section" *ngIf="!isboard">
  <table class="list-table">
    <colgroup>
      <col style="width:200px;">
      <col class="col2">
      <col style="width:200px;">
    </colgroup>
    <thead>
    <tr>
      <th>IOS/TC</th>
      <th>명칭</th>
      <th>KS표준 합계</th>
    </tr>
    </thead>    
    <tbody>
      <tr>
        <td>21</td>
        <td>소방 및 소화기구(Equipment for fire protection and fire fighting)</td>
        <td>37</td>
      </tr>
      <tr>
        <td>58</td>
        <td>가스실린더(Gas cylinders)</td>
        <td>49</td>
      </tr>  
      <tr>
        <td>86(SC6)</td>
        <td>냉동 및 공조(Refrigeration and air-conditioning)</td>
        <td>15</td>
      </tr>  
      <tr>
        <td>109</td>
        <td>오일 및 가스버너(Oil and Gas burners)</td>
        <td>8</td>
      </tr>  
      <tr>
        <td>142</td>
        <td>공기 및 기타가스정화장치(Cleaning equipment for air and other gases)</td>
        <td>5</td>
      </tr>  
      <tr>
        <td>161</td>
        <td>난방용 제어 및 안전장치(Controls and protective devices for gas and/or oil)</td>
        <td>114</td>
      </tr>  
      <tr>
        <td>185</td>
        <td>과압 안전장치(Safety devices for protection against excessive pressure)</td>
        <td>10</td>
      </tr>  
      <tr>
        <td>192</td>
        <td>가스 터빈(Gas turbines)</td>
        <td>21</td>
      </tr> 
      <tr>
        <td>220</td>
        <td>초저온 용기(Cryogenic vessels)</td>
        <td>8</td>
      </tr>  
      <tr>
        <td>244</td>
        <td>공업로(Industrial furnaces and associated processing equipment)</td>
        <td>6</td>
      </tr>  
      <tr>
        <td>291</td>
        <td>가정용 가스조리기기(Domestic gas cooking appliances)</td>
        <td>7</td>
      </tr>  
      <tr>
        <td>293</td>
        <td>사료기기(Feed Machinery)</td>
        <td>0</td>
      </tr>                                
    </tbody>
    <tfoot>
      <tr>
        <td colspan="2"></td>
        <td>합계 : 280</td>
      </tr>
    </tfoot>
  </table>

  <div class="btn-area">
    <button (click)="isboard = true">민원접수 및 기업애로사항</button>
  </div>
</div>

<app-board-list *ngIf="isboard" [boardInfo]="boardInfo" (back)="isboard=false"></app-board-list>

<div *ngIf="isboard" class="bottom"></div>