<app-header [menuList$]="menuList$" [memberInfo$]="memberInfo$"></app-header>

<app-sub-header [currMenu]="currMenu$" [memberInfo]="memberInfo$"></app-sub-header>

<div class="member-introduce-page contents-page">
  <div class="inner-wrap">
    <div class="board-list">
      <div class="board-header clearfix">
        <div class="rowcnt">등록회원사 <span>{{totalCount | number}}</span>개</div>
        <div class="search-area">
          <div class="select mr10">
            <div class="select-title" (click)="isSelectOn=!isSelectOn">{{params.searchGroup}}</div>
            <ul *ngIf="isSelectOn" (click)="isSelectOn=!isSelectOn">
              <li (click)="params.searchGroup='업체명'">업체명</li>
              <li (click)="params.searchGroup='생산품목'">생산품목</li>
            </ul>
          </div>
          <input type="text" placeholder="검색어를 입력해주세요" class="input-txt mr10" [(ngModel)]="params.searchText" (keypress)="searchList($event)" />
          <button class="search-btn" (click)="getCompanyList()">검색</button>   
        </div>
      </div>
    
      <div class="table-area">
        <table class="list-table">
          <colgroup>
            <col style="width: 10%"/>
            <col style="width: 27%"/>
            <col style="width: 10%"/>
            <col style="width: 33%"/>
            <!-- <col style="width: 10%"/> -->
          </colgroup>
          <thead>
          <tr>
            <th>회원사분류</th>
            <th>업체명</th>
            <th>대표자</th>
            <th>생산품목</th>
            <!-- <th>상세정보</th> -->
          </tr>
          </thead>    
          <tbody>
            <!-- <tr *ngFor="let row of companyList;" [ngClass]="{blue1:row.common_name === 'KS협의회'}" > -->
            <tr *ngFor="let row of companyList;">
              <td>{{row.com_gbn}}</td>
              <td>{{row.companyName}}</td>
              <td>{{row.ceoName}}</td>
              <td>{{row.productKor}}</td>
              <!-- <td><button (click)="goDetail(row)">상세보기</button></td> -->
            </tr>                 
          </tbody>
        </table>
      </div>
      <div class="clearfix">
        <div class="pagination-area">
          <ngb-pagination
            [(page)]="params.no"
            [pageSize]="params.row"
            [maxSize]="maxSize"
            [collectionSize]="totalCount"
            [boundaryLinks]="true"
            rotate="true"
            (pageChange)="loadPage($event)"
            >
            <ng-template ngbPaginationFirst>&lt;&lt;</ng-template>
            <ng-template ngbPaginationLast>&gt;&gt;</ng-template>
            <ng-template ngbPaginationPrevious>&lt;</ng-template>
            <ng-template ngbPaginationNext>&gt;</ng-template>
          </ngb-pagination>
        </div>  
      </div>      
    </div>

  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>


