/*******************************************************************************
  설  명 : 마이페이지 - 불만처리신청 조회
  작성자 :
  작성일 :
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { HInspectionService } from '@app/home/service/inspection.service';

import { ComplainModalComponent } from '@home/page/online-application/accredited-test/complain-modal/complain-modal.component';

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-certification'
};

@Component({
  selector: 'app-complain-result',
  templateUrl: './complain-result.component.html',
  styleUrls: ['./complain-result.component.scss']
})
export class ComplainResultComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  list: any = [];

  term = 2; //기간조회
  viewRow = -1; // 상세보기

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private hInspectionService: HInspectionService,
    private modalService: NgbModal,
  ) {
    this.buildForm();

    this.chageTerm(2);
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getList();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      term: [ '', [] ],
      applicationSdate: [ '', [] ],
      applicationEdate: [ '', [] ],
      status: [ '', [] ],
      pageNo: 1,
      pageRow: 20,
      totalCount: 0
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 상세보기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  viewDetail( row: any ) {
    if( row == this.viewRow ) {
      this.viewRow = -1;
    } else {
      this.viewRow = row;
    }
  }

  /*******************************************************************************
    설  명 : 기간 변경 시 처리
    입력값 : term 기간
    리턴값 : 없음
  *******************************************************************************/
  chageTerm(term: any) {
    switch(term) {
      case 0:
        this.form.patchValue({
          term: term,
          applicationSdate: moment().format('YYYY-MM-DD'),
          applicationEdate: moment().format('YYYY-MM-DD')
        });
        break;

      case 1:
        this.form.patchValue({
          term: term,
          applicationSdate: moment().add(-7, 'day').format('YYYY-MM-DD'),
          applicationEdate: moment().format('YYYY-MM-DD')
        });
        break
      case 2:
        this.form.patchValue({
          term: term,
          applicationSdate: moment().add(-1, 'month').format('YYYY-MM-DD'),
          applicationEdate: moment().format('YYYY-MM-DD')
        });
        break;
      case 3:
        this.form.patchValue({
          term: term,
          applicationSdate: moment().add(-3, 'month').format('YYYY-MM-DD'),
          applicationEdate: moment().format('YYYY-MM-DD')
        });
        break;
      case 4:
        this.form.patchValue({
          term: term,
          applicationSdate: moment().add(-6, 'month').format('YYYY-MM-DD'),
          applicationEdate: moment().format('YYYY-MM-DD')
        });
        break;
    }

    this.getList();
  }

  /*******************************************************************************
    설  명 : 상태 변경 시 처리
    입력값 : event = select event
    리턴값 : 없음
  *******************************************************************************/
  onChange(event) {
    this.form.patchValue({status: event.target.value});
    this.getList();
  }

  /*******************************************************************************
    설  명 : 데이터 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getList() {
    let params = {...this.form.value};

    this.hInspectionService.getApplicationComplain(params).then((response) => {
      if( response.ResultCode ) {
        // this.toastrService.success('정상적으로 등록되었습니다.', '불만처리신청');
        this.list = response.data;

        this.form.patchValue({
          totalCount: response.total
        })

      } else {
        this.toastrService.error( response.ResultMessage, '불만처리신청 조회');
      }
    }, (error) => {
      this.toastrService.error(error, '불만처리신청 조회');
    });
  }

  /*******************************************************************************
    설  명 : 페이징 변경 시 처리
    입력값 : event = 페이지번호
    리턴값 : 없음
  *******************************************************************************/
  loadPage(event) {
    // 로딩 되기 전체 표시 안되게 처리
    this.list = [];

    this.form.patchValue({pageNo: event});

    this.getList();
  }

  /*******************************************************************************
    설  명 : 신청 내역 삭제
    입력값 : seq
    리턴값 : 없음
  *******************************************************************************/
  deleteRow(seq) {
    if(!window.confirm("불만처리신청 내역을 삭제하시겠습니까?")) return;

    let params = {
      seq: seq
    }

    this.hInspectionService.delApplicationComplain(params).then((response) => {
      if( response.ResultCode ) {
        this.toastrService.success(response.ResultMessage, '불만처리신청 삭제');

        this.getList();

      } else {
        this.toastrService.error( response.ResultMessage, '불만처리신청 삭제');
      }
    }, (error) => {
      this.toastrService.error(error, '불만처리신청 삭제');
    });
  }

  /*******************************************************************************
    설  명 : 신청 내역 수정
    입력값 : seq
    리턴값 : 없음
  *******************************************************************************/
  editRow(item) {
    const modalRef = this.modalService.open(ComplainModalComponent, optionsXL);
    modalRef.componentInstance.setInit(item);

    modalRef.result.then((result) => {
      if( result ) {
        this.getList();
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : enter to BR tag
    입력값 : seq
    리턴값 : 없음
  *******************************************************************************/
  getHtml(contents) {
    return contents?.replace(/\n/gi, '<br />');
  }
}
