<div class="map-bus">
  <ul class="bus-list clearfix">
    <li>
      <div class="title">시내버스</div>
      <div class="bus-stop">수암파출소 건너편 하차</div>
      <ul class="busno clearfix">
        <li>30-2 번</li>
        <li>35 번</li>
        <li>314 번</li>
      </ul>
    </li>
    <li>
      <div class="title">좌석버스</div>
      <div class="bus-stop">수암파출소 하차</div>
      <ul class="busno clearfix">
        <li>301 번</li>
        <li>320 번</li>
        <li>350 번</li>
      </ul>
    </li>
    <li>
      <div class="title">광역버스</div>
      <div class="bus-stop">수암파출소 건너편 하차</div>
      <ul class="busno clearfix">
        <li>5601 번</li>
      </ul>
    </li>
    <li>
      <div class="title">마을버스</div>
      <div class="bus-stop">안산동 주민센터 하차</div>
      <ul class="busno clearfix">
        <li>3-1 번</li>
      </ul>
    </li>
    <li>
      <div class="title">시외버스</div>
      <div class="bus-stop">수암파출소 하차</div>
      <ul class="busno clearfix">
        <li>8851 번</li>
      </ul>
    </li>                
  </ul>
</div>
