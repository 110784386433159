import { async } from '@angular/core/testing';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})

export class HInspectionService {
  constructor(
    private restful: RestfulService
  ) {
  }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.log(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 온라인 시험 검사신청
  setApplicationInspection( params: any ): Promise<any> {
    return this.restful.post({
        program: 'home',
        service: 'application',
        version: '1.0',
        action: 'setApplicationInspection'
      }, params
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  setApplicationInspectionLab( params: any ): Promise<any> {
    return this.restful.post({
        program: 'home',
        service: 'application',
        version: '1.0',
        action: 'setApplicationInspectionLab'
      }, params
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  setApplicationComplain( params: any ): Promise<any> {
    return this.restful.post({
        program: 'home',
        service: 'application',
        version: '1.0',
        action: 'setApplicationComplain'
      }, params
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  getApplicationComplain( params: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'application',
        version: '1.0',
        action: 'getApplicationComplain'
      }, params
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  delApplicationComplain( params: any ): Promise<any> {
    return this.restful.post({
        program: 'home',
        service: 'application',
        version: '1.0',
        action: 'delApplicationComplain'
      }, params
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 온라인 시험 검사 신청 조회
  getInspectionApplicationInfo( seq: any ): Promise<any> {
    return this.restful.get({
      program: 'home',
      service: 'application',
      version: '1.0',
      action: 'getInspectionApplicationInfo'
    }, {seq:seq}
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 실험실 사용신청서 정보 조회
  getlabUseApplicationInfo( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'application',
        version: '1.0',
        action: 'getlabUseApplicationInfo'
      }, {seq:seq}
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 검사신청 구비서류 
  getInspectionApplicationFile( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'application',
        version: '1.0',
        action: 'getInspectionApplicationFile'
      }, {seq:seq}
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }
}  

