<app-sub-header [menu]="menu"></app-sub-header>

<div class="online-page contents-page">
  <div class="inner-wrap">
    <ul class="page-tab clearfix">
      <li><a routerLink="/online/accreditedTest">공인시험검사신청</a></li>
      <li><a routerLink="/online/SafetyConfirmationTest">안전확인시험검사신청</a></li>
      <li class="active"><a routerLink="/online/StandardDevelopment">표준개발협력제안</a></li>
      <li><a routerLink="/online/KeaaJoin">진흥회 가입신청</a></li>
    </ul>

    <div class="online1-page">
      <div class="page-title">표준개발협력 제안</div>
      <div class="page-title-bar"></div>
      <div class="guide-txt">
        <span class="blue">온라인 신청 서비스 준비 중입니다.</span><br class="mobile-only"> 서비스 시작 시 까지는 아래 양식을 다운로드 하셔서<br class="mobile-only"> 작성 후 팩스로 접수하여 주십시요. <br class="mobile-only">(팩스 : 031-480 - 2986)
      </div>

      <ul class="application clearfix">
        <li>
          <div>표준개발협력 제안</div>
          <div>
            <a class="cp" (click)="application1()">온라인신청</a>
            <a href="/assets/download/standard_suggest.hwp">양식다운로드</a>
          </div>
        </li> 
        <li>
          <div>표준개정검토요청</div>
          <div>
            <a class="cp" (click)="application2()">온라인신청</a>
            <a href="/assets/download/표준 개정 검토요청서.hwp">양식다운로드</a>
          </div>
        </li> 
        <li>
          <div>민원접수 및 기업애로사항</div>
          <div>
            <a routerLink="#">바로가기</a>
          </div>
        </li> 
        <li>
          <div>KS표준현황</div>
          <div>
            <a routerLink="#">바로가기</a>
          </div>
        </li> 
      </ul> 

      <div class="section">
        <div class="title">개요</div>
        <ul class="txt-area">
          <li>
            <p class="txt"><span></span><span>우리 진흥회는 표준개발협력기관으로서의 업무를 원할히 수행하기 위해 업체에서 필요로 하는 표준의 수요를 조사하고 이를 표준제정 및 개정에 반영코자 합니다. 
            이와 관련하여 우리 업계 종사자 및 에너지기기산업에 관심이 있으신 여러분의 의견을 수렴하고 있습니다.</span></p>
          </li>
        </ul>
      </div>
      <div class="section">
        <div class="title">제안방법</div>
        <ul class="txt-area">
          <li>
            <p class="txt"><span></span><span>우리 진흥회 표준개발실으로 연락을 주시거나, 아래에 첨부된 양식을 다운로드 하시어 내용을 작성하신 다음 팩스나 E-mail 로 발송해 주시면 표준제개정에 적극 반영되도록 노력하겠습니다. 
              많은 참여를 부탁드립니다</span></p>
          </li>
        </ul>
      </div>      
      <div class="section">
        <div class="title">문의 및 접수 <span>(표준개발실)</span></div>
        <ul class="txt-area">
          <li>
            <p class="txt"><span></span><span>전화 : 031) 480 - 2981</span></p>
            <p class="txt"><span></span><span>팩스 : 031) 480 - 2986</span></p>
            <p class="txt"><span></span><span>이메일 : kjc5405@naver.com</span></p>
          </li>
        </ul>
      </div>   
    </div>  
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>
