<app-sub-header [menu]="menu"></app-sub-header>

<div class="online-page contents-page">
  <div class="inner-wrap">
    <ul class="page-tab clearfix">
      <li><a routerLink="/online/accreditedTest">공인시험검사신청</a></li>
      <li class="active"><a routerLink="/online/SafetyConfirmationTest">안전확인시험검사신청</a></li>
      <li><a routerLink="/online/StandardDevelopment">표준개발협력제안</a></li>
      <li><a routerLink="/online/KeaaJoin">진흥회 가입신청</a></li>
    </ul>

    <div class="online1-page">
      <div class="page-title">안전확인시험검사신청</div>
      <div class="page-title-bar"></div>
      <div class="guide-txt">
        <span class="blue">온라인 신청 서비스 준비 중입니다.</span><br class="mobile-only"> 서비스 시작 시 까지는 아래 양식을 다운로드 하셔서<br class="mobile-only"> 작성 후 팩스로 접수하여 주십시요. <br class="mobile-only">(팩스 : 031-480 - 2986)
      </div>

      <ul class="application clearfix">
        <li>
          <div>시험검사신청</div>
          <div>
            <a class="cp" (click)="ApplicationModal(4)">온라인신청</a>
            <a href="/assets/download/SP701_06 안전확시험검사신청서(2021.05.21).hwp">양식다운로드</a>
          </div>
        </li> 
      </ul>  

      <div class="section">
        <div class="title">시험검사의뢰 제출서류</div>
        <div class="table-area">
          <table class="list-table">
            <colgroup>
              <col class="col1">
              <col>
            </colgroup>
            <thead>
            <tr>
              <th>구분</th>
              <th>제출 서류</th>
            </tr>
            </thead>    
            <tbody>
              <tr>
                <th>시험검사 신청시</th>
                <td>
                  - 시험검사신청서(양식)<br/>
                  - 시험검사용 시료(의뢰인)<br/>
                  - 사업자등록증 사본(의뢰인)
                </td>
              </tr>
              <tr>
                <th>안전확인 신고시</th>
                <td>
                  안전인증기관에 신고_한국기계전기전자시험연구원 홈페이지의 절차 및 서식<br/>
                  전기용품 및 생활용품 안전관리법 시행규칙 제26조<br/><br/>
                  - 안전확인신고서(전기용품 및 생할용품 안전관리법 시행규칙 별지 제14호서식)<br/>
                  - 사업자등록증 사본 또는 이에 준하는 서류(동일한 제품을 제조하는 공장이 둘 이상인 경우에는 공장별로 첨부)<br/>
                  - 대리인임을 증명하는 서류(대리인이 신고하는 경우)<br/>
                  - 제품설명서<br/>
                  - 안전확인시험결과서(안전확인시험기관 발행)<br/>
                  - 동일한 제품임을 증명하는 서류(동일한 제품을 제조하는 공장이 둘 이상인 경우)<br/>
                  - 전기용품 및 생할용품 안전관리법 제18조 제1항제1호에 따른 안전확인의 표시 견본
                </td>
              </tr>
              <tr>
                <th>안전확인 신고대행</th>
                <td>
                  안전확인시험의뢰시 안전확인신고 대행을 요청하시면 시험완료후 안전인증기관에 관련서류와 함께 신고하여 드리며<br/> 
                  이 경우 관련서류와 함께 『전기용품및생활용품안전관리법시행령 별표 2』에 정한 안전 확인신고증명서 발급수수료(50000원)를 함께 납부하셔야 합니다.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>  

      <div class="section">
        <div class="clearfix">
          <div class="title fl">안전확인 시험검사수수료<span>(VAT별도)</span></div>
          <div class="title-sub fr">시험검사 수수료는 진흥회 안전확인 시험검사업무규정에 의거하여 산출 / 단위 : 원</div>
        </div>
        <div class="table-area">
          <table class="list-table list-table1">
            <colgroup>
              <col class="col-th">
              <col>
              <col>
              <col>
            </colgroup>
            <thead>
            <tr>
              <th><div class="line"></div><div class="tr">심지종류</div><div class="tl">기름탱크종류</div></th>
              <th>이음매 없는 심지사용 </th>
              <th>이음매 있는 심지사용 </th>
              <th>보통 보강쇠 붙은  심지사용</th>
            </tr>
            </thead>    
            <tbody>
              <tr>
                <th>개방형 기름탱크</th>
                <td>1,150,000</td>
                <td>1,330,000</td>
                <td>1,260,000</td>
              </tr>
              <tr>
                <th>기밀 기름탱크</th>
                <td>1,200,000</td>
                <td>1,380,000</td>
                <td>1,310,000</td>
              </tr>              
            </tbody>
          </table>
        </div>
        <ul class="txt-area">
          <li>
            <p class="txt"><span></span><span>시험 검사 적용 항목에 따라 수수료가 달라질 수 있으므로 정확한 수수료는 담당자에게 문의 하시기 바랍니다.</span></p>
          </li>
        </ul>
      </div>  
      <div class="section">
        <div class="title">수수료 납부</div>
        <ul class="txt-area">
          <li>
            <p class="txt"><span></span><span>입금계좌 : 176-890101-87305(KEB하나은행)</span></p>
            <p class="txt"><span></span><span>예금주 : 한국에너지기기산업진흥회</span></p>
          </li>
        </ul>
      </div>
      <div class="section">
        <div class="title">문의 및 접수 <span>(기술인증실)</span></div>
        <ul class="txt-area">
          <li>
            <p class="txt"><span></span><span>전화 : 031) 480 - 2981</span></p>
            <p class="txt"><span></span><span>팩스 : 031) 480 - 2986</span></p>
          </li>
        </ul>
      </div>        
    </div>  
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>
