<div class="eatl-business5">
  <div class="page-title">품목별 품질관리기관</div>
  <div class="page-title-bar"></div>

  <div class="guide-txt">
    진흥회는 공공의 안전과 KS품질수준유지를 위하여 기술표준원장이 별도로 고시하는 KS표시 가스연소기기에 대하여<br class="pc-only" /> 
    매년 정기적으로 제품심사를 실시하고 있는 한편, 가스연소기기 KS표시인증업체협의회 운영을 통하여 품질지도요원을 회원사 생산현장에 파견하여<br class="pc-only" /> 
    품질 및 기술지도를 하고 있는 등 가스연소기기의 품질향상과 소비자보호를 위한 선진기술의 도입·개발에 전력을 기울이고 있습니다.
  </div>

  <div class="section">
    <div class="title">근거법령</div>
    <ul class="txt-area">
      <li>
        <p class="txt"><span></span><span>산업표준화법 제16조</span></p>
      </li>
    </ul>
  </div>    

  <div class="section">
    <div class="title">업무처리절차</div>
    <ul class="step clearfix">
      <li>
        <div class="no">STEP 1</div>
        <div class="txt">
          <div>
            제품심사<br class="pc-only1" /> 연간계획수립/제출<br/><span>(연도개시 1개월전)</span>
          </div>
        </div>  
        <div class="arrow"></div>
      </li>
      <li>
        <div class="no">STEP 2</div>
        <div class="txt">
          <div>승인<br/><span>(연도개시 1개월전)</span></div>
        </div>  
        <div class="arrow"></div>
      </li>   
      <li>
        <div class="no">STEP 3</div>
        <div class="txt">
          <div>제품심사<br class="pc-only1" /> 일정통보<br/><span>(10일전)</span></div>
        </div>  
        <div class="arrow"></div>
      </li>            
      <li>
        <div class="no">STEP 4</div>
        <div class="txt">
          <div>
            사료채취<br/><span>(해당업체 생산공장)</span>
          </div>
        </div>  
        <div class="arrow"></div>
      </li>
      <li>
        <div class="no">STEP 5</div>
        <div class="txt">
          <div>
            품질시험실시<br/><span>(KS B 8103 등 17개품목)</span>
          </div>
        </div>  
        <div class="arrow"></div>
      </li>            
      <li>
        <div class="no">STEP 6</div>
        <div class="txt">
          <div>심사결과보고</div>
        </div>  
        <div class="arrow"></div>
      </li>   
      <li>
        <div class="no">STEP 7</div>
        <div class="txt">
          <div>
            조치<br/><span>(한국표준협회)</span>
          </div>
        </div>  
      </li>            
    </ul>
  </div>  

  <div class="section">
    <div class="title">기술인증실 문의</div>
    <ul class="txt-area">
      <li>
        <p class="txt"><span></span><span>전화 :  031) 480 - 2981</span></p>
        <p class="txt"><span></span><span>팩스 :  031) 480 - 2986</span></p>
      </li>
    </ul>
  </div>    

</div>  
