<div class="application-modal">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="modal-header">
      <div class="modal-title">온라인 시험 검사 신청</div>
      <button type="button" class="close" (click)="activeModal.dismiss()"></button>
    </div>

    <div class="modal-body">
      <div class="section">
        <div class="section-title">신청업체 정보</div>
        <div class="guide-txt">신청업체 정보는 현재 화면에서 직접 수정 가능합니다.</div>
        <table class="table1">
          <colgroup>
            <col class="col1">
            <col class="col2">
            <col class="col3">
            <col class="col4">
            <col class="col5">
            <col class="col6">
          </colgroup>

          <tbody>
          <tr>
            <th><label for="company_name">업체명</label></th>
            <td>
              <input type="text" id="company_name" formControlName="company_name" [autofocus]="true" [ngClass]="{formErrors:formErrors['company_name']}" />
              <div *ngIf="formErrors['company_name']" class="error-message">{{formErrors['com_kor']}}</div>
            </td>
            <th><label for="name_kor">대표자명</label></th>
            <td>
              <input type="text" id="name_kor" formControlName="name_kor" [ngClass]="{formErrors:formErrors['name_kor']}" />
              <div *ngIf="formErrors['name_kor']" class="error-message">{{formErrors['name_kor']}}</div>
            </td>
            <th><label for="com_number">사업자번호</label></th>
            <td>
              <input type="text" id="com_number" formControlName="com_number" [ngClass]="{formErrors:formErrors['com_number']}" />
              <div *ngIf="formErrors['com_number']" class="error-message">{{formErrors['com_number']}}</div>
            </td>
          </tr>
          <tr>
            <th><label for="zipcode">주소</label></th>
            <td colspan="3">
              <input type="text" style="width:95px" id="zipcode" formControlName="zipcode" [ngClass]="{formErrors:formErrors['zipcode']}" />
              <div *ngIf="formErrors['zipcode']" class="error-message">{{formErrors['zipcode']}}</div>

              <button class="btn1" style="width: 75px">우편번호찾기</button>

              <input type="text" style="width:374px" id="com_address" formControlName="com_address" [ngClass]="{formErrors:formErrors['com_address']}" />
              <div *ngIf="formErrors['com_address']" class="error-message">{{formErrors['com_address']}}</div>
            </td>
            <th><label for="com_tel">전화번호</label></th>
            <td>
              <input type="text" id="com_tel" formControlName="com_tel" [ngClass]="{formErrors:formErrors['com_tel']}" />
              <div *ngIf="formErrors['com_tel']" class="error-message">{{formErrors['com_tel']}}</div>
            </td>
          </tr>
          <tr>
            <th><label for="com_fax">팩스</label></th>
            <td>
              <input type="text" id="com_fax" formControlName="com_fax" [ngClass]="{formErrors:formErrors['com_fax']}" />
              <div *ngIf="formErrors['com_fax']" class="error-message">{{formErrors['com_fax']}}</div>
            </td>
            <th><label for="job">업태</label></th>
            <td>
              <input type="text" id="job" formControlName="job" [ngClass]="{formErrors:formErrors['job']}" />
              <div *ngIf="formErrors['job']" class="error-message">{{formErrors['job']}}</div>
            </td>
            <th><label for="comJob">종목</label></th>
            <td>
              <input type="text" id="job1" formControlName="job1" [ngClass]="{formErrors:formErrors['job1']}" />
              <div *ngIf="formErrors['job1']" class="error-message">{{formErrors['job1']}}</div>
            </td>
          </tr>
          <tr>
            <th><span class="red">*</span>회원사구분</th>
            <td colspan="5">
              <label class="mr30">
                <input type="radio" disabled="true" name="com_gbn" id="com_gbn" [(ngModel)]="com_gbn" [value]="1020" [checked]="com_gbn == '1020'" (change)="form.patchValue({com_gbn: '1020'})" /> 일반회원
              </label>
              <label class="mr30">
                <input type="radio" disabled="true" name="com_gbn" id="com_gbn" [(ngModel)]="com_gbn" [value]="1010" [checked]="com_gbn == '1010'" (change)="form.patchValue({com_gbn: '1010'})" /> 협의회 회원
              </label>
              <label>
                <input type="radio" disabled="true" name="com_gbn" id="com_gbn" [(ngModel)]="com_gbn" [value]="9000" [checked]="com_gbn == '9000'" (change)="form.patchValue({com_gbn: '9000'})" /> 비회원
              </label>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="section">
        <div class="section-title"><span class="red">*</span>필수정보</div>
        <table class="table2">
          <colgroup>
            <col class="col1">
            <col class="col2">
            <col class="col3">
            <col class="col4">
            <col class="col5">
            <col class="col6">
            <col class="col7">
            <col class="col8">
          </colgroup>

          <tbody>
          <tr>
            <th><label for="registration_date"><span class="red">*</span>신청일자</label></th>
            <td>
              <input type="date" id="registration_date" formControlName="registration_date" [ngClass]="{formErrors:formErrors['registration_date']}" />
              <div *ngIf="formErrors['registration_date']" class="error-message">{{formErrors['registration_date']}}</div>
            </td>
            <th><label for="applicant_name"><span class="red">*</span>신청자</label></th>
            <td>
              <input type="text" id="applicant_name" formControlName="applicant_name" [ngClass]="{formErrors:formErrors['applicant_name']}" />
              <div *ngIf="formErrors['applicant_name']" class="error-message">{{formErrors['applicant_name']}}</div>
            </td>
            <th><label for="applicant_hp"><span class="red">*</span>연락처</label></th>
            <td>
              <input type="text" id="applicant_hp" formControlName="applicant_hp" [ngClass]="{formErrors:formErrors['applicant_hp']}" />
              <div *ngIf="formErrors['applicant_hp']" class="error-message">{{formErrors['applicant_hp']}}</div>
            </td>
            <th><span class="red">*</span>이메일</th>
            <td>
              <input type="text" id="applicant_email" formControlName="applicant_email" [ngClass]="{formErrors:formErrors['applicant_email']}" />
              <div *ngIf="formErrors['applicant_email']" class="error-message">{{formErrors['applicant_email']}}</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <ul class="tab">
        <li class="active">실험실임대신청</li>
      </ul>

      <div class="application-modal2">
        <div class="section">
          <table class="table table-bordered table-small">
            <colgroup>
              <col style="width:10%" />
              <col style="width:15%" />
              <col style="width:10%" />
              <col style="width:15%" />
              <col style="width:10%" />
              <col style="width:15%" />
              <col style="width:10%" />
              <col style="width:15%" />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row"><label for="tab2_application_sdate">신청기간</label></th>
                <td colspan="7">
                  <div class="form-inline">
                    <input type="date" id="tab2_application_sdate" style="width:160px;" name="tab2_application_sdate" formControlName="tab2_application_sdate" (change)="form.patchValue({tab2_application_sdate: $event.target.value})" />
                    <span class="pl5 pr5">~</span>
                    <input type="date" id="tab2_application_edate" style="width:160px;" name="tab2_application_edate" formControlName="tab2_application_edate" (change)="form.patchValue({tab2_application_edate: $event.target.value})" />
                    <span class="f12 pl20">* 진흥회 시험 검사일정과 조율하여 신청 기간 중에 가능일자를 통보해 드립니다.</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row"><label for="tab2_lab_seq"><span class="red">*</span>실험실</label></th>
                <td colspan="3">
                  <select
                    class="form-control form-control-small"
                    name="tab2_lab_seq"
                    id="tab2_lab_seq"
                    formControlName="tab2_lab_seq"
                    [(ngModel)]="form.value.tab2_lab_seq"
                    [value]="9"
                    (change)="onChange($event);"
                  >
                    <option value="undefined">실험실 선택</option>
                    <option
                      *ngFor="let item of labList;let i = index"
                      [attr.data-index]="i"
                      value={{item.seq}}
                      [selected]="form.value.tab2_lab_seq == item.seq"
                    >{{item.labName}}</option>
                  </select>
                  <div *ngIf="formErrors['tab2_lab_seq']" class="error-message">{{formErrors['tab2_lab_seq']}}</div>
                </td>
                <th scope="row"><label for="tab2_use_date">사용일자</label></th>
                <td>
                  <input type="date" readonly id="tab2_use_date" name="tab2_use_date" formControlName="tab2_use_date" (change)="form.patchValue({tab2_use_date: $event.target.value})" />
                </td>
                <th scope="row"><label>사용시간</label></th>
                <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default btn-small f12" value="8" [ngClass]="{active: form.value.tab2_LUG_code == 8}" (click)="form.patchValue({tab2_LUG_code: 8})">8시간</button>
                    <button type="button" class="btn btn-default btn-small f12" value="24" [ngClass]="{active: form.value.tab2_LUG_code == 24}" (click)="form.patchValue({tab2_LUG_code: 24})">24시간</button>
                  </div>
                </td>
              </tr>

              <tr>
                <th scope="row"><label for="outgoing_company">사용목적</label></th>
                <td colspan="7">
                  <textarea rows="3" id="tab2_use_purpose" name="tab2_use_purpose" formControlName="tab2_use_purpose" (change)="form.patchValue({tab2_use_purpose: $event.target.value})" [ngClass]="{formErrors:formErrors['tab2_use_purpose']}"></textarea>
                  <div *ngIf="formErrors['tab2_use_purpose']" class="error-message">{{formErrors['tab2_use_purpose']}}</div>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- <table class="table1">
            <colgroup>
              <col class="col1">
              <col class="col2">
              <col class="col3">
              <col class="col4">
              <col class="col5">
              <col class="col6">
            </colgroup>

            <tbody>
            <tr>
              <th><span class="red">*</span>품목구분</th>
              <td>
                <select>
                  <option>선택</option>
                </select>
              </td>
              <th><span class="red">*</span>품목</th>
              <td>
                <select>
                  <option>선택</option>
                </select>
              </td>
              <th>시료수량</th>
              <td><input type="text" /></td>
            </tr>
            </tbody>
          </table> -->
        </div>
        <div class="section">
          <!-- <div class="div1">
            <div class="section-title"><span class="red">*</span>시험항목</div>
            <button class="btn">추가</button>
            <table class="table2">
              <colgroup>
                <col class="col1">
                <col class="col2">
                <col class="col3">
                <col class="col4">
              </colgroup>
              <thead>
              <tr>
                <th>번호</th>
                <th>시험항목</th>
                <th>시험장비</th>
                <th colspan="2">사용횟수</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>1</td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><button class="icon-btn del"></button></td>
              </tr>
              <tr>
                <td>2</td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><button class="icon-btn del"></button></td>
              </tr>
              </tbody>
            </table>
          </div>       -->
          <div class="div2">
            <div class="section-title"><span class="red">*</span>문의·요청사항</div>
            <textarea id="tab2_request_memo" name="tab2_request_memo" formControlName="tab2_request_memo" (change)="form.patchValue({tab2_request_memo: $event.target.value})" [ngClass]="{formErrors:formErrors['tab2_request_memo']}" placeholder="문의 사항 및 요청사항을 간단히 기술해 주십시요."></textarea>
            <div *ngIf="formErrors['tab2_request_memo']" class="error-message">{{formErrors['tab2_request_memo']}}</div>
          </div>
        </div>
        <div class="check">
          <div class="mb20">* 의뢰하신 장비사용과 관련 한 모든 정보는 법률에 의해 요구 받거나, 법률에 의해 금지되지 않는한 고객과 별도의 합의가 있는 경우를 제외 하고는 비밀로 취급 됩니다.</div>
          <label [ngClass]="{formErrors:formErrors['tab2_agree']}">
            <input type="checkbox" id="tab2_agree" name="tab2_agree" formControlName="tab2_agree" (change)="form.patchValue({tab2_agree: true})" />
            상기 내용과 비고사항을 확인하였습니다.
          </label>
          <div *ngIf="formErrors['tab2_agree']" class="error-message">{{formErrors['tab2_agree']}}</div>
        </div>
      </div>
    </div>

    <div class="modal-footer clearfix">
      <button type="button" class="btn1" (click)="activeModal.dismiss()">취소</button>
      <button type="submit" class="btn1 blue">{{btnName}}</button>
    </div>
  </form>
</div>
