<app-header [menuList$]="menuList$" [memberInfo$]="memberInfo$"></app-header>

<app-sub-header [currMenu]="currMenu$" [memberInfo]="memberInfo$"></app-sub-header>

<div class="findidpw-page contents-page">
  <div class="inner-wrap clearfix">
    <form [formGroup]="form">
  
      <div class="find-area find1">
        <div class="find-title">회원정보로 찾기</div>
        <input type="text" id="userId" placeholder="아이디를 입력하세요." formControlName="userId" [ngClass]="{formErrors:formErrors['userId']}" />
        <input type="text" id="userName" placeholder="이름을 입력하세요." formControlName="userName" [ngClass]="{formErrors:formErrors['userName']}"  />
        <input type="text" id="email" class="email" placeholder="이메일 주소를 입력하세요." formControlName="email" [ngClass]="{formErrors:formErrors['email']}" />
        <div class="select" [ngClass]="{on: isSelectOn}" (click)="isSelectOn=!isSelectOn" (mouseleave)="isSelectOn=false">
          <div class="selected-item">{{isSelectTxt}}</div>       
          <ul class="select-item">
            <li (click)="selectChange($event)">직접입력</li>
            <li (click)="selectChange($event)">@naver.com</li>
            <li (click)="selectChange($event)">@hanmail.net</li>
            <li (click)="selectChange($event)">@daum.net</li>
            <li (click)="selectChange($event)">@yahoo.co.kr</li>
            <li (click)="selectChange($event)">@gmail.net</li>
            <li (click)="selectChange($event)">@nate.com</li>
            <li (click)="selectChange($event)">@hotmail.com</li>
            <li (click)="selectChange($event)">@empal.com</li>
            <li (click)="selectChange($event)">@freechal.com</li>
            <li (click)="selectChange($event)">@paran.com</li>
          </ul>
        </div>  
        <a class="find-btn" (click)="setFindPw()">확인</a>
      </div>
  
      <!-- <div class="find-area find2 clearfix">
        <div class="find-title">본인인증으로 찾기</div>
        <button class="certification-btn hp"><span>휴대폰 본인 인증</span></button>
        <button class="certification-btn ipin"><span>아이핀 본인 인증</span></button>
      </div> -->
  
    </form>
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>
