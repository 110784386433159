<app-header [menuList$]="menuList$" [memberInfo$]="memberInfo$"></app-header>

<app-sub-header [currMenu]="currMenu$" [memberInfo]="memberInfo$"></app-sub-header>

<div class="analysis-page contents-page">
  <div class="inner-wrap">

    <div class="section">
      <div class="table-area">
        <div class="title">{{selectedYY}}년 {{selectedMM}}월 분석결과 <span>(분석기간 : {{selectedYY}}/{{sdate.substr(5, 2)}}/{{sdate.substr(8, 2)}}~{{edate.substr(5, 2)}}/{{edate.substr(8, 2)}})</span></div>
        <div class="ym">
          <div class="fl mr10">
            <div class="select-title" (click)="isOpenYY=!isOpenYY">{{selectedYY}}년</div>
            <ul *ngIf="isOpenYY" (click)="isOpenYY=!isOpenYY">
              <li *ngFor="let row of yearList" (click)="selectedYY=row.years;getData()">{{row.years}}년</li>
            </ul>
          </div>
          <div class="fl">
            <div class="select-title" (click)="isOpenMM=!isOpenMM">{{selectedMM}}월</div>
            <ul *ngIf="isOpenMM" (click)="isOpenMM=!isOpenMM">
              <li (click)="selectedMM='01';getData()">1월</li>
              <li (click)="selectedMM='02';getData()">2월</li>
              <li (click)="selectedMM='03';getData()">3월</li>
              <li (click)="selectedMM='04';getData()">4월</li>
              <li (click)="selectedMM='05';getData()">5월</li>
              <li (click)="selectedMM='06';getData()">6월</li>
              <li (click)="selectedMM='07';getData()">7월</li>
              <li (click)="selectedMM='08';getData()">8월</li>
              <li (click)="selectedMM='09';getData()">9월</li>
              <li (click)="selectedMM='10';getData()">10월</li>
              <li (click)="selectedMM='11';getData()">11월</li>
              <li (click)="selectedMM='12';getData()">12월</li>
            </ul>
          </div>  
        </div>

        <table class="list-table">
          <colgroup>
            <col style="width:120px">
            <col span="6" style="width:92.8px">
            <col span="4" style="width:150.8px">
            <!-- <col span="6" style="width:calc((100% - 120px) * 0.48 / 6)">
            <col span="4" style="width:calc((100% - 120px) * 0.52 / 4)"> -->
          </colgroup>
          <thead>
          <tr>
            <th rowspan="2">LNG의 성분</th>
            <th colspan="6">성분(부피 퍼센트)</th>
            <th colspan="4">연소성</th>
          </tr>
          <tr class="white">
            <th>CH₄</th>
            <th>C₂H₆</th>
            <th>C3H8</th>
            <th>n-C₄H₁₀</th>
            <th>i-C₄H₁₀</th>
            <th>N₂</th>
            <th colspan="2">총발열량(MJ/㎥N, ㎉/㎥N)</th>
            <th>비중</th>
            <th>웨버지수(MJ/㎥N)</th>
          </tr>    
          <tr>
            <th>일자</th>
            <th colspan="10">분석치</th>
          </tr>          
          </thead>    
          <tbody>
            <tr *ngFor="let rows of dataList">
              <td>{{rows.analysis_date.substr(8, 2)}}({{rows.weeks}}) {{rows.analysis_date.substr(11, 5)}}</td>
              <td>{{rows.ingredient_CH4}}</td>
              <td>{{rows.ingredient_C2H6}}</td>
              <td>{{rows.ingredient_C3H8}}</td>
              <td>{{rows.ingredient_nC4H10}}</td>
              <td>{{rows.ingredient_iC4H10}}</td>
              <td>{{rows.ingredient_N2}}</td>
              <td>{{rows.heat_amt_kcal1}}</td>
              <td>{{rows.heat_amt_kcal2}}</td>
              <td>{{rows.specific_gravity2}}</td>
              <td>{{rows.webbe_index2}}</td>
            </tr>            
          </tbody>
        </table>
        <div class="txt">※ 분석시 가스의 연소 및 계량 기준조건은 15/15 ℃, 101.325 kPa 에서 분석한 값임.</div>
      </div>
    </div>
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>