import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business41',
  templateUrl: './business41.component.html',
  styleUrls: ['./business41.component.scss']
})
export class Business41Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
