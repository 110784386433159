import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  
  public menu: any = {
    depth1_id: 5,
    depth2_id: 4,
    depth1_nm: '진흥회 소개',
    depth2_nm: '찾아오시는 길',
  }; 
  
  tabIndex = '1'; // tab순번

  constructor() { }

  ngOnInit(): void {
  }

}
