/*******************************************************************************
  설  명 : 회원가입 - 약관동의
  작성자 :
  작성일 :
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { CommonService } from '@app/service/common.service';

import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';

@Component({
  selector: 'app-join-step1',
  templateUrl: './join-step1.component.html',
  styleUrls: ['./join-step1.component.scss']
})
export class JoinStep1Component extends O2OZCMS implements OnInit {

  /*******************************************************************************
    설  명 : 전역 변수 선언부
  *******************************************************************************/
  public agree = false;
  public agree1 = false;

  public policy = '';
  public privacy = '';

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    router: Router,
    commonService: CommonService,
    activatedRoute: ActivatedRoute,
    toastrService: ToastrService,
  ) {
    // 상위 클래스에 router 전달
    super(router, commonService, activatedRoute, toastrService);
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.getTermAndPolicy();

    this.getPrivacy();
  }

  /*******************************************************************************
    설  명 : 이용약관 가져오기
  *******************************************************************************/
  public getTermAndPolicy() {
    this.commonService.getMenu(829).then( response => {
      if( response.ResultCode ) {
        this.policy = response.data.contents;
      }
    })
  }

  /*******************************************************************************
    설  명 : 개인정보처리방침 가져오기
  *******************************************************************************/
  public getPrivacy() {
    this.commonService.getMenu(826).then( response => {
      if( response.ResultCode ) {
        this.privacy = response.data.contents;
      }
    })
  }

  /*******************************************************************************
    설  명 : 본인인증 체크
  *******************************************************************************/
  setAuth( type: any ) {
    if ( this.agree === false ) {
      this.toastrService.error('회원가입을 진행하시려면 이용약관에 동의하셔야 합니다.', '');

    } else if ( this.agree1 === false ) {
      this.toastrService.error('회원가입을 진행하시려면 개인정보처리방침에 동의하셔야 합니다.', '');

    } else {
      if ( type === 'H' ) {
        window.open( config.baseUrl + '/checkplus/checkplus_main.php', 'Parent_window', 'width=500,height=550,scrollbar=yes' );
      } else if ( type === 'I' ) {
        window.open( config.baseUrl + '/ipin/ipin_request.php', 'Parent_window', 'width=500,height=550,scrollbar=yes' );
      } else {
        this.toastrService.error('정상적으로 실행하시기 바랍니다.', '');
      }
    }
  }

  /*******************************************************************************
    설  명 : 회원가입 step2로
  *******************************************************************************/
  goStep2() {
    if ( this.agree === false ) {
      this.toastrService.error('이용약관을 동의하셔야 합니다.');

    } else if ( this.agree1 === false ) {
      this.toastrService.error('개인정보처리방침에 동의하셔야 합니다.');

    } else {
      this.router.navigate(['/member/join/step2']);
    }
  }

}
