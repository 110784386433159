<app-sub-header [menu]="menu"></app-sub-header>

<div class="statistics-page contents-page">
  <div class="inner-wrap">
    <ul class="tab clearfix">
      <li [ngClass]="{active: tabIndex == '1'}" (click)="tabIndex = '1'">무역통계</li>
      <li [ngClass]="{active: tabIndex == '2'}" (click)="tabIndex = '2'">생산통계</li>
    </ul>

    <app-trade-statistics *ngIf="tabIndex == '1'"></app-trade-statistics>
    <app-output-statistics *ngIf="tabIndex == '2'"></app-output-statistics>

  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>


