<div class="standard1-modal">
  <div class="modal-header">
    <div class="modal-title">표준개발협력제안</div>
    <button type="button" class="close" (click)="activeModal.dismiss()"></button>
  </div>
  
  <div class="modal-body">
    <table>
      <colgroup>
        <col class="col1">
        <col class="col2">
      </colgroup>
      
      <tbody>
      <tr>
        <th><span class="red">*</span>과제명</th>
        <td><input type="text" class="input1" required /></td>
      </tr>  
      <tr>
        <th><span class="red">*</span>사업구분</th>
        <td>
          <label><input type="checkbox">단독과제</label>
          <label><input type="checkbox">단체표준</label>
        </td>
      </tr>
      <tr>
        <th><span class="red">*</span>사업분류</th>
        <td>
          <label><input type="checkbox">국제표준화 등록지원</label>
          <label><input type="checkbox">국제표준화 대응 국제협력</label>
          <label><input type="checkbox">표준화연구개발</label>
          <label><input type="checkbox">표준기반조성</label>
          <br/>
          <label><input type="checkbox">표준전문가양성</label>
          <label><input type="checkbox">표준이행확산 및 경제성 분석</label>
        </td>
      </tr>
      <tr>
        <th><span class="red">*</span>제안자 구분</th>
        <td>
          <label><input type="checkbox">대학</label>
          <label><input type="checkbox">연구기관</label>
          <label><input type="checkbox">기업(부설연구소 포함)</label>
          <label><input type="checkbox">협회</label>
          <label><input type="checkbox">조합</label>
          <label><input type="checkbox">기타</label>
        </td>
      </tr>  
      <tr>
        <th><span class="red">*</span>기술구분</th>
        <td>
          <label><input type="checkbox">가스기기</label>
          <label><input type="checkbox">석유기기</label>
          <label><input type="checkbox">가스기기부품</label>
          <label><input type="checkbox">석유기기부품</label>
          <label><input type="checkbox">기타에너지기기</label>
        </td>
      </tr>  
      <tr>
        <th><span class="red">*</span>기술수명주기</th>
        <td>
          <label><input type="checkbox">기술개발이전</label>
          <label><input type="checkbox">기술개발기</label>
          <label><input type="checkbox">기술시험기</label>
          <label><input type="checkbox">기술도입기</label>
          <label><input type="checkbox">기술성장기</label>
          <label><input type="checkbox">기술성숙기</label>
          <br/>
          <label><input type="checkbox">기술쇠퇴기</label>
          <label><input type="checkbox">해당없음</label>
        </td>
      </tr>              
      <tr>
        <th><span class="red">*</span>사업목표</th>
        <td><input type="text" required /></td>
      </tr>  
      <tr>
        <th><span class="red">*</span>사업내용</th>
        <td><textarea></textarea></td>
      </tr>  
      <tr>
        <th><span class="red">*</span>사업기간</th>
        <td><input type="text" required /></td>
      </tr>  
      <tr>
        <th><span class="red">*</span>사업예산<br/><span>(단위:백만원)</span></th>
        <td><input type="text" required /></td>
      </tr>              
      <tr>
        <th><span class="red">*</span>첨부파일</th>
        <td>
          <input type="text"  class="input1" required />
          <button class="btn1">찾아보기</button>
        </td>
      </tr>                 
      </tbody>
    </table>
  </div>
  
  <div class="modal-footer clearfix">
    <button class="btn1" (click)="activeModal.dismiss()">취소</button>  
    <button class="btn1 blue">신청</button>  
  </div>
</div>


