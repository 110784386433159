<app-header [menuList$]="menuList$" [memberInfo$]="memberInfo$"></app-header>

<app-sub-header [currMenu]="currMenu$" [memberInfo]="memberInfo$"></app-sub-header>

<div class="board-page contents-page">

  <ul class="tab1 clearfix" *ngIf="boardInfo.board_id != 'qna' && boardInfo.board_id != 'member_news' && boardInfo.board_id != 'member_movements'">
    <li [ngClass]="{active: qnaTabIndex == '1'}" (click)="setQnaTab('묻고 답하기', '1')">묻고 답하기</li>
    <li [ngClass]="{active: qnaTabIndex == '2'}" (click)="setQnaTab('건의제도', '2')">건의제도</li>
    <li [ngClass]="{active: qnaTabIndex == '3'}" (click)="setQnaTab('회원사애로지원', '3')">회원사애로지원</li>
  </ul>

  <div class="policy-tab" *ngIf="boardInfo.board_id != 'qna' && boardInfo.board_id != 'member_news' && boardInfo.board_id != 'member_movements'">
    <div class="page-title">{{tabQnaName}}</div>
    <div class="page-title-bar"></div>
  </div>

  <form [formGroup]="form">
    <div class="board-write inner-wrap">
      <div class="board-header">
        <div *ngIf="boardInfo.board_id=='qna'" class="guide">평소에 궁금하신 내용이 있으신 경우<br class="mobile-only"> 자유롭게 작성하여 주세요.</div>
        <div *ngIf="boardInfo.board_id=='customer_service_request'" class="guide">
          우리 진흥회를 통하여 시험·검사 및 의뢰시험 접수, 방문, 전화상담, 관련업무 수행시 직원의 불친절, 불편사례가 있었거나<br class="pc-only">
          진흥회 전체적으로 개선할 사안이 있을경우 작성바랍니다.
        </div>
        <div *ngIf="boardInfo.board_id=='customer_service_support'" class="guide">
          기업의 전반적인 운영과정에서 발생하는 각종 애로사항 질의에 대한 솔루션 모색 및 해결방안 제시 합니다.<br/>
          상담내용 : 대정부 정책건의 · 각종 제도 및 정책 개선 대안 · 경영 및 노무 애로사항 · 기타 기업활동과 관련된 문제
        </div>    
        <div class="clearfix">
          <div *ngIf="boardInfo.board_id=='qna'" class="subtitle fl">질문하기</div>
          <div *ngIf="boardInfo.board_id=='suggestion'" class="subtitle fl">건의내용</div>
          <div *ngIf="boardInfo.board_id=='complain'" class="subtitle subtitle1 fl">애로사항 입력 <span>(질의서 작성으로부터 2주이내 회신)</span></div>
          <div class="txt"><span class="red">*</span> 표시는 필수입력사항입니다.</div>
        </div>
      </div>

      <table class="list-table">
        <colgroup>
          <col class="col1">
          <col class="col2">
        </colgroup>    

        <tbody>
        <tr>
          <th><label for="col1"><span class="red">*</span> 제목</label></th>
          <td><input type="text" id="subject" formControlName="subject" [ngClass]="{formErrors:formErrors['subject']}" autofocus="true" class="input1"></td>
        </tr>    
        <tr *ngIf="params.board_seq == ''">
          <th><label for="col2"><span class="red">*</span> 작성자</label></th>
          <td><input type="text" id="writer_name" formControlName="writer_name" [ngClass]="{formErrors:formErrors['writer_name']}"></td>
        </tr>    
        <tr *ngIf="boardInfo.board_id !=='qna'">
          <th><label for="col3"><span class="red">*</span> 연락처</label></th>
          <td>
            <input type="text" class="input2" id="hp1" formControlName="hp1" [ngClass]="{formErrors:formErrors['hp1']}" maxlength="4"><span class="between">-</span>  
            <input type="text" class="input2" id="hp2" formControlName="hp2" [ngClass]="{formErrors:formErrors['hp2']}" maxlength="4"><span class="between">-</span>  
            <input type="text" class="input2" id="hp3" formControlName="hp3" [ngClass]="{formErrors:formErrors['hp3']}" maxlength="4">
          </td>
        </tr>       
        <tr>
          <th><label for="col4"><span class="red">*</span> E-mail</label></th>
          <td>
            <input type="text" id="email1" formControlName="email1" [ngClass]="{formErrors:formErrors['email1']}" class="email1"><span class="between">@</span>  
            <input type="text" class="email2" id="email2" formControlName="email2" [ngClass]="{formErrors:formErrors['email2']}">
            <select id="col4" (change)="setEmail2($event)">
              <option value="">직접 입력</option>
              <option value="gmail.com">gmail.com</option>
              <option value="daum.net">daum.net</option>
              <option value="naver.com">naver.com</option>
              <option value="nate.com">nate.com</option>
            </select>
            <span class="txt">※ 정확히 기재하셔야 답변을 받아보실 수 있습니다.</span>
          </td>
        </tr>    
        <tr>
          <th><label for="col5"><span class="red">*</span> 인증번호</label></th>
          <td>
            <div class="check-img"><img src="/assets/images/check_img.png"></div>
            <div class="check-input">
              <input type="text" id="col5">
              <span class="txt">대소문자 구분하지 않습니다.</span>
            </div>
          </td>
        </tr>
        <tr *ngIf="boardInfo.board_id =='qna'">
          <th><label for="col6">비밀번호</label></th>
          <td>
            <input type="password" id="pw" formControlName="pw" [ngClass]="{formErrors:formErrors['pw']}">
            <span class="txt red">공개를 원하지 않으시면, 비밀번호를 꼭 입력하십시오.</span>            
          </td>
        </tr>   
        <tr *ngIf="boardInfo.board_id !=='qna'">
          <th><label for="col7">첨부파일</label></th>
          <td>
            <input type="file" id="file" class="file-input" (change)="handleUploadFileChanged($event)" >
            <!-- <span *ngIf="formErrors['upload']" class="error-message">{{formErrors['upload']}}</span> -->
            <label for="file" class="file-btn">찾아보기</label>
            <div class="progress mt5 mb5">
              <div class="progress-bar"></div>
            </div>
            <div class="mt5">
            ※ 파일별 50MB 까지, 최대 10개까지 첨부 가능, 전체 첨부용량 50MB 제한, 가로*세로 : 1000*1000픽셀 (필히 등록)
            </div>
            <div class="file-div" *ngIf="imageList.length > 0">
              <ul class="file-ul clearfix">
                <li class="fl" *ngFor="let row of imageList">
                  <div class="upload-item" >
                    <div class="upload-image">
                      <img [src]="row.filepath"/>
                    </div>
                    <div class="upload-filename"><a href="{{row.filepath}}" target="_blank" title="{{row.origin}}">{{row.origin_filename}}</a></div>
                    <div class="upload-size">{{row.size}}KB
                      <a class="cp ml5" (click)="imageDelete(row)">
                        <img src="/assets/images/del_ico.png"/>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <textarea placeholder="내용을 입력하세요." id="contents" formControlName="contents" [ngClass]="{formErrors:formErrors['contents']}"></textarea>
            <div class="agree">
              <label><input type="checkbox" class="mr10" (change)="checkinfo($event)">개인정보 처리방침에 동의합니다. <span class="blue">[개인정보처리방침]</span></label>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      
      <div class="btn-area clearfix">
        <button class="add-btn" (click)="write()">등록</button>  
        <button *ngIf="boardInfo.board_id =='qna'" class="list-btn" (click)="goList()">목록</button>  
      </div>
    </div>
  </form>
</div>

<app-footer></app-footer>
