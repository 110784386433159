import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { config } from './config.service';
import { ApiResponse } from './api-response';


import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private toastrService: ToastrService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private http: HttpClient
  ) { }

  public checkSuccess(response: any): Promise<any> {
    if(response.success) return Promise.resolve(response);
    else return Promise.reject(response);
  }

  public handleApiError(error: any): Promise<any> {
    if(!config.production) console.error('An error occurred', error);
    return Promise.reject(error);
  }

  public updateFormErrors(form: FormGroup, formErrors: any) {
    if (!form) { return; }

    for( const field in form.controls ) {
      const control = form.get( field );

      formErrors[field] = '';

      if (control && control.dirty && !control.valid) {
        for (const key in control.errors) {
          let message = '';

          if( key == 'ngbDate' ) {
          } else {
            switch( key ) {
              case 'required':
                message = '필수항목';
                break;
              case 'email':
                message = '이메일형식';
                break;
              case 'maxlength':
                message = '최대 ' + control.errors[key].requiredLength + '자';
                break;
              case 'minlength':
                message = '최소 ' + control.errors[key].requiredLength + '자';
                break;
              case 'pattern':
                message = '형식오류';
                break;
              case 'numeric':
                message = '숫자만';
                break;
              case 'alpha':
                message = '영문만';
                break;
              case 'alphaUpper':
                message = '영문(대)';
                break;
              case 'alphaLower':
                message = '영문(소)';
                break;
              case 'phone':
                message = '숫자와 -만';
                break;
              case 'ngbDate':
                message = '날짜';
                break;
            }

            formErrors[field] = message;
          }
        }
      }
    }
  }

  public updateFieldErrors(form: FormGroup, formErrors: any, Field: any) {
    if (!form) { return; }

    for( const field in form.controls ) {
      if( Field == field ) {
        const control = form.get( field );

        formErrors[field] = '';

        if (control && control.dirty && !control.valid) {
          for (const key in control.errors) {
            let message = '';

            if( key == 'ngbDate' ) {
            } else {
              switch( key ) {
                case 'required':
                  message = '필수 항목';
                  break;
                case 'email':
                  message = '이메일 형식';
                  break;
                case 'maxlength':
                  message = '최대 ' + control.errors[key].requiredLength + '자';
                  break;
                case 'minlength':
                  message = '최소 ' + control.errors[key].requiredLength + '자';
                  break;
                case 'pattern':
                  message = '형식 오류';
                  break;
                case 'numeric':
                  message = '숫자형';
                  break;
                case 'alpha':
                  message = '영문만';
                  break;
                case 'alphaUpper':
                  message = '영문(대)만';
                  break;
                case 'alphaLower':
                  message = '영문(소)만';
                  break;
                case 'phone':
                  message = '숫자와 -만';
                  break;
              }

              formErrors[field] = message;
            }
          }
        }
      }
    }
  }

  public makeAllFormFieldsDirty(form: FormGroup) {
    if (!form) { return; }

    for (var field in form.controls) {
      const control = form.get(field);
      if(control) control.markAsDirty();
    }
  }

  public makeFieldsDirty(form: FormGroup, Field: any) {
    if (!form) { return; }

    for(var field in form.controls) {
      if( field == Field ) {
        const control = form.get(field);
        if(control) control.markAsDirty();
      }
    }
  }

  public makeFormDirtyAndUpdateErrors(form: FormGroup, formErrors: any) {
    this.makeAllFormFieldsDirty(form);
    this.updateFormErrors(form, formErrors);
  }

  public makeFieldDirtyAndUpdateErrors(form: FormGroup, formErrors: any, field: any) {
    this.makeFieldsDirty(form, field);
    this.updateFieldErrors(form, formErrors, field);
  }

  public handleFormSubmitError(response: ApiResponse, form: FormGroup, formErrors: any): void {
    if(response.errors){
      for (const field in formErrors) {
        const control = form.get(field);
        if (response.errors[field] && response.errors[field].message) {
          formErrors[field] += response.errors[field].message;
        }
      }
      if(response.errors.unhandled){
        response.message += response.errors.unhandled;
      }
    }
  }

  /*******************************************************************************
    설  명 : ngbDateStruct
    입력값 : '' or date string
    리턴값 : ngbDateStruct array
  *******************************************************************************/
  getDate( date: any ): any {
    let tmp: NgbDateStruct;

    if( date == null ) return null;

    if( date == '' ) {
      tmp = {
        year: parseInt( moment().format('YYYY') ),
        month: parseInt( moment().format('MM') ),
        day: parseInt( moment().format('DD') )
      };
    } else {
      tmp = {
        year: parseInt( moment(date).format('YYYY') ),
        month: parseInt( moment(date).format('MM') ),
        day: parseInt( moment(date).format('DD') )
      };
    }

    return tmp;
  }

  /*******************************************************************************
    설  명 : LPAD
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  lpad(str: any, padLen: any, padStr: any) {
    if (padStr.length > padLen) {
      alert("오류 : 채우고자 하는 문자열이 요청 길이보다 큽니다");
      return str;
    }
    str += ""; // 문자로
    padStr += ""; // 문자로
    while (str.length < padLen)
        str = padStr + str;
    str = str.length >= padLen ? str.substring(0, padLen) : str;
    return str;
  }

  /*******************************************************************************
    설  명 : ngbDateStruct
    입력값 : '' or date string
    리턴값 : ngbDateStruct array
  *******************************************************************************/
  getMonthFirstLast( date: any, type ): any {
    let tmp: NgbDateStruct;
    let startOfDate: any;
    let endOfDate: any;

    if( date == '' ) {
      startOfDate = moment().startOf('month').format('YYYY-MM-DD').split('-');
      endOfDate = moment().endOf('month').format('YYYY-MM-DD').split('-');
    } else {
      startOfDate = moment( moment(date).subtract(1, 'months') ).startOf('month').format('YYYY-MM-DD').split('-');
      endOfDate = moment( moment(date).subtract(1, 'months') ).endOf('month').format('YYYY-MM-DD').split('-');
    }

    if( type == 'first' ) {
      tmp = {
        year: parseInt( startOfDate[0] ),
        month: parseInt( startOfDate[1] ),
        day: parseInt( startOfDate[2] )
      };

      return tmp;
    } else {
      tmp = {
        year: parseInt( endOfDate[0] ),
        month: parseInt( endOfDate[1] ),
        day: parseInt( endOfDate[2] )
      };

      return tmp;
    }
  }

  /*******************************************************************************
    설  명 : ngbDateStruct
    입력값 : '' or date string
    리턴값 : ngbDateStruct array
  *******************************************************************************/
  getDateStr( date: NgbDateStruct ): string {
    return this.ngbDateParserFormatter.format( date );
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    if( num == '' || num == null ) return 0;

    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /***************************************************************************************
   * 설명 : 용량 표시('Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB')
  ***************************************************************************************/
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /***************************************************************************************
   * 설명 : 파일을 다운로드 받아서 실제 파일명으로 변경하여 저장
  ***************************************************************************************/
  downloadAndRenameFile(subfolder: string, newFilename: string): void {
    // HTTP 요청을 통해 파일 다운로드
    this.http.get(subfolder, { responseType: 'blob' }).subscribe((data: Blob) => {
    // 파일 다운로드 및 이름 변경
    const file = new Blob([data], { type: 'application/octet-stream' });
       
    const anchor = document.createElement('a');
          anchor.
    href = window.URL.createObjectURL(file);
          anchor.
    download = newFilename;
    document.body.appendChild(anchor);
          anchor.
         
    click();
          
    document.body.removeChild(anchor);

    });
  }

    

}
