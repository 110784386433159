<div class="sub-header">
  <div class="top-visual" [ngClass]="getTopPathClass(currMenu | async)">
    <div class="menu">{{getTopPathName(currMenu | async)}}</div>
    <div class="title">{{getSecondPathName(currMenu | async)}}</div>
  </div>

  <div class="path">
    <div class="inner-wrap">
      <a routerLink="/" class="home">홈</a>
      <div class="depth1">{{getTopPathName(currMenu | async)}}</div>
      <div class="depth2">{{getSecondPathName(currMenu | async)}}
        <ul>
          <li *ngFor="let item of getSecondNode(currMenu | async)['children']">
            <ng-container *ngIf="item.use_yn === '1' && item.viewgroup_slevel <= level && item.viewgroup_elevel >= level">
              <ng-container *ngIf="item.link_open_yn == 'N'">
                <ng-container *ngIf="item.link_url.includes('http'); else route">
                  <a [href]="item.link_url" target="_blank">{{item.page_menu_name}}</a>
                </ng-container>
                <ng-template #route>
                  <a [routerLink]="item.link_url" target="_blank">{{item.page_menu_name}}</a>
                </ng-template>
              </ng-container>
    
              <ng-container *ngIf="item.link_open_yn == 'C'">
                <a class="cp" [ngClass]="{active: active_seq == item.seq}" (click)="goLink(item)">{{item.page_menu_name}}</a>
              </ng-container>
            </ng-container>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- <div class="sub-header">
  <div class="top-visual" [ngClass]="'top-visual' + menu.depth1_id">
    <div class="menu">{{menu.depth1_nm}}</div>
    <div class="title">{{menu.depth2_nm}}</div>
  </div>

  <div class="path">
    <div class="inner-wrap">
      <a routerLink="/" class="home">홈</a>
      <div class="depth1">{{menu.depth1_nm}}</div>
      <div class="depth2">{{menu.depth2_nm}}
        <ul *ngIf="menu.depth1_id==1">
          <li><a routerLink="/eatl/business" [ngClass]="{active: menu.depth2_id==1}">사업안내</a></li>
          <li><a routerLink="/eatl/equipment" [ngClass]="{active: menu.depth2_id==2}">보유설비</a></li>
          <li><a routerLink="/eatl/promote" [ngClass]="{active: menu.depth2_id==3}">홍보자료</a></li>
        </ul> 
        <ul *ngIf="menu.depth1_id==2">
          <li><a routerLink="/online/accreditedTest" [ngClass]="{active: menu.depth2_id==1}">공인시험검사신청</a></li>
          <li><a routerLink="/online/SafetyConfirmationTest" [ngClass]="{active: menu.depth2_id==2}">안전확인시험검사신청</a></li>
          <li><a routerLink="/online/StandardDevelopment" [ngClass]="{active: menu.depth2_id==3}">표준개발협력제안</a></li>
          <li><a routerLink="/online/KeaaJoin" [ngClass]="{active: menu.depth2_id==4}">진흥회 가입신청</a></li>
        </ul>
        <ul *ngIf="menu.depth1_id==3">
          <li><a routerLink="/service/overseasMarkets" [ngClass]="{active: menu.depth2_id==1}">해외시장 조사자료</a></li>
          <li><a routerLink="/service/statistics" [ngClass]="{active: menu.depth2_id==2}">통계자료</a></li>
          <li><a routerLink="/service/analysis" [ngClass]="{active: menu.depth2_id==3}">성상분석 DATA</a></li>
          <li><a routerLink="/board" [queryParams]="{board_id:'notice'}" [ngClass]="{active: menu.depth2_id==4}">공지사항</a></li>
          <li><a routerLink="/service/policy" [ngClass]="{active: menu.depth2_id==5}">정부시책</a></li>
          <li><a routerLink="/service/customer" [ngClass]="{active: menu.depth2_id==6}">고객상담실</a></li>
          <li><a routerLink="/service/overseasMarkets" [ngClass]="{active: menu.depth2_id==7}">양식다운로드</a></li>
        </ul>     
        <ul *ngIf="menu.depth1_id==4">
          <li><a routerLink="/company/joinGuide" [ngClass]="{active: menu.depth2_id==1}">진흥회 가입안내</a></li>
          <li><a routerLink="/company/introduce" [ngClass]="{active: menu.depth2_id==2}">회원사 소개</a></li>
          <li><a routerLink="/board" [queryParams]="{board_id:'member_news'}" [ngClass]="{active: menu.depth2_id==3}">회원사 소식</a></li>
          <li><a routerLink="/board" [queryParams]="{board_id:'member_movements'}" [ngClass]="{active: menu.depth2_id==4}">회원사 인사동정</a></li>
        </ul>              
        <ul *ngIf="menu.depth1_id==5">
          <li><a routerLink="/introduce/greeings" [ngClass]="{active: menu.depth2_id==1}">회장 인사말</a></li>
          <li><a routerLink="/introduce/companyOverview" [ngClass]="{active: menu.depth2_id==2}">진흥회 개요</a></li>
          <li><a routerLink="/introduce/articles" [ngClass]="{active: menu.depth2_id==3}">정관</a></li>
          <li><a routerLink="/introduce/map" [ngClass]="{active: menu.depth2_id==4}">찾아오시는 길</a></li>
        </ul>  
        <ul *ngIf="menu.depth1_id==6">
          <li><a routerLink="/member/login" [ngClass]="{active: menu.depth2_id==1}">로그인</a></li>
          <li><a routerLink="/member/findid" [ngClass]="{active: menu.depth2_id==2}">아이디 찾기</a></li>
          <li><a routerLink="/member/findpw" [ngClass]="{active: menu.depth2_id==3}">비밀번호 찾기</a></li>
          <li><a routerLink="/member/join" [ngClass]="{active: menu.depth2_id==4}">회원가입</a></li>
          <li><a routerLink="/member/mypage" [ngClass]="{active: menu.depth2_id==5}">마이페이지</a></li>
        </ul>               
      </div>
    </div>
  </div>
</div> -->