<app-sub-header [menu]="menu"></app-sub-header>

<div class="board-page contents-page">
  <div class="inner-wrap">
    <!-- <app-board-list [boardInfo]="boardInfo"></app-board-list> -->
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>


