import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eatl-business5',
  templateUrl: './eatl-business5.component.html',
  styleUrls: ['./eatl-business5.component.scss']
})
export class EatlBusiness5Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
