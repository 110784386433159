<app-header [menuList$]="menuList$" [memberInfo$]="memberInfo$"></app-header>

<app-sub-header [currMenu]="currMenu$" [memberInfo]="memberInfo$"></app-sub-header>

<div class="overview-page contents-page">
  <div class="inner-wrap">
    <ul class="tab clearfix">
      <li [ngClass]="{active: tabIndex == '1'}" (click)="tabIndex = '1'">회원정보수정</li>
      <li [ngClass]="{active: tabIndex == '2'}" (click)="tabIndex = '2'" *ngIf="approvalYN == 'Y'">검사신청/결과</li>
      <li [ngClass]="{active: tabIndex == '3'}" (click)="tabIndex = '3'" *ngIf="approvalYN == 'Y'">불만처리신청 조회</li>
    </ul>

    <app-member-edit *ngIf="tabIndex == '1'"></app-member-edit>
    <app-application-result *ngIf="tabIndex == '2'"></app-application-result>
    <app-complain-result *ngIf="tabIndex == '3'"></app-complain-result>
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>
