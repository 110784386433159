import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})

export class MypageService {

constructor(
	private restful: RestfulService,
) { }

  // 시험 검사신청 조회
  getMypageApplicationList(search: any): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'application',
        version: '1.0',
        action: 'getMypageApplicationList'
      }, search
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

  // 신청상태 리스트
  getApplicationStatus(): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'application',
        version: '1.0',
        action: 'getApplicationStatus'
      }, {}
    ).then(this.restful.extractData)
    .catch(this.restful.handleErrorPromise);
  }

}