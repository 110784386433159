import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './page/main/main.component';
import { GreetingsComponent } from './page/introduce/greetings/greetings.component';
import { CompanyOverviewComponent } from './page/introduce/company-overview/company-overview.component';
import { ArticlesComponent } from './page/introduce/articles/articles.component';
import { MapComponent } from './page/introduce/map/map.component';

import { EatlBusinessComponent } from './page/EATL/eatl-business/eatl-business.component';
import { EquipmentComponent } from './page/EATL/equipment/equipment.component';
import { PromoteComponent } from './page/EATL/promote/promote.component';

import { OverseasMarketsComponent } from './page/service/overseas-markets/overseas-markets.component';
import { StatisticsComponent } from './page/service/statistics/statistics.component';
import { AnalysisComponent } from './page/service/analysis/analysis.component';
import { PolicyComponent } from './page/service/policy/policy.component';
import { CustomerServiceComponent } from './page/service/customer-service/customer-service.component';

import { BoardComponent } from './page/board/board.component';
import { BoardWriteComponent } from './page/board/board-write/board-write.component';
import { BoardViewComponent } from './page/board/board-view/board-view.component';

import { JoinGuideComponent } from './page/customer/join-guide/join-guide.component';
import { MemberIntroduceComponent } from './page/customer/member-introduce/member-introduce.component';

import { AccreditedTestComponent } from './page/online-application/accredited-test/accredited-test.component';
import { SafetyConfirmationTestComponent } from './page/online-application/safety-confirmation-test/safety-confirmation-test.component';
import { StandardDevelopmentComponent } from './page/online-application/standard-development/standard-development.component';
import { KeaaJoinComponent } from './page/online-application/keaa-join/keaa-join.component';

import { LoginComponent } from './page/member/login/login.component';
import { FindIdComponent } from './page/member/find-id/find-id.component';
import { FindPwComponent } from './page/member/find-pw/find-pw.component';
import { FindIdResultComponent } from './page/member/find-id/find-id-result/find-id-result.component';
import { FindPwResultComponent } from './page/member/find-pw/find-pw-result/find-pw-result.component';
import { JoinStep1Component } from './page/member/join/join-step1/join-step1.component';
import { JoinStep2Component } from './page/member/join/join-step2/join-step2.component';
import { JoinStep3Component } from './page/member/join/join-step3/join-step3.component';
import { MypageComponent } from './page/member/mypage/mypage.component';
import { MemberDetailComponent } from './page/customer/member-introduce/member-detail/member-detail.component';

import { AuthLoginCheck } from '@home/service/auth.login.check';
import { ContentsComponent } from '@home/page/contents/contents.component';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full', canActivate: [AuthLoginCheck] },
  { path: 'main', component: MainComponent, canActivate: [AuthLoginCheck] },
  { path: 'contents', component: ContentsComponent, canActivate: [AuthLoginCheck] },
  { path: 'board', canActivate: [AuthLoginCheck], canActivateChild: [AuthLoginCheck], runGuardsAndResolvers: 'always',
    children: [
      { path: 'list', component: BoardComponent, canActivate: [AuthLoginCheck] },
      { path: 'view', component: BoardViewComponent, canActivate: [AuthLoginCheck] },
      { path: 'write', component: BoardWriteComponent, canActivate: [AuthLoginCheck] }
    ]
  },
  { path: 'introduce/greeings', component: GreetingsComponent, canActivate: [AuthLoginCheck] },
  { path: 'introduce/companyOverview', component: CompanyOverviewComponent, canActivate: [AuthLoginCheck] },
  { path: 'introduce/articles', component: ArticlesComponent, canActivate: [AuthLoginCheck] },
  { path: 'introduce/map', component: MapComponent, canActivate: [AuthLoginCheck] },

  { path: 'eatl/business', component: EatlBusinessComponent, canActivate: [AuthLoginCheck] },
  { path: 'eatl/equipment', component: EquipmentComponent, canActivate: [AuthLoginCheck] },
  { path: 'eatl/promote', component: PromoteComponent, canActivate: [AuthLoginCheck] },

  { path: 'service/overseasMarkets', component: OverseasMarketsComponent, canActivate: [AuthLoginCheck] },
  { path: 'service/statistics', component: StatisticsComponent, canActivate: [AuthLoginCheck] },
  { path: 'service/analysis', component: AnalysisComponent, canActivate: [AuthLoginCheck] },
  { path: 'service/policy', component: PolicyComponent, canActivate: [AuthLoginCheck] },
  { path: 'service/customer', component: CustomerServiceComponent, canActivate: [AuthLoginCheck] },

  { path: 'company/joinGuide', component: JoinGuideComponent, canActivate: [AuthLoginCheck] },
  { path: 'company/introduce', component: MemberIntroduceComponent, canActivate: [AuthLoginCheck] },

  { path: 'online/accreditedTest', component: AccreditedTestComponent, canActivate: [AuthLoginCheck] },
  { path: 'online/SafetyConfirmationTest', component: SafetyConfirmationTestComponent, canActivate: [AuthLoginCheck] },
  { path: 'online/StandardDevelopment', component: StandardDevelopmentComponent, canActivate: [AuthLoginCheck] },
  { path: 'online/KeaaJoin', component: KeaaJoinComponent, canActivate: [AuthLoginCheck] },

  { path: 'member', runGuardsAndResolvers: 'always',
    children: [
      { path: 'login', component: LoginComponent, canActivate: [AuthLoginCheck] },
      { path: 'findid', component: FindIdComponent, canActivate: [AuthLoginCheck] },
      { path: 'findid/result', component: FindIdResultComponent, canActivate: [AuthLoginCheck] },
      { path: 'findpw', component: FindPwComponent, canActivate: [AuthLoginCheck] },
      { path: 'findpw/result', component: FindPwResultComponent, canActivate: [AuthLoginCheck] },
      { path: 'join/step1', component: JoinStep1Component, canActivate: [AuthLoginCheck] },
      { path: 'join/step2', component: JoinStep2Component, canActivate: [AuthLoginCheck] },
      { path: 'join/step3', component: JoinStep3Component, canActivate: [AuthLoginCheck] },
      { path: 'mypage', component: MypageComponent, canActivate: [AuthLoginCheck] },
      { path: 'detail', component: MemberDetailComponent, canActivate: [AuthLoginCheck] }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
