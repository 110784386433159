import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUploader, FileSelectDirective, FileItem, FileLikeObject } from 'ng2-file-upload';
import * as moment from 'moment';

import { HMemberService } from '@app/home/service/member.service';
import { HInspectionService } from '@app/home/service/inspection.service';
import { UtilService } from '@app/service/util.service';

import { config } from '@app/service/config.service';
import { Globals } from '@app/service/globals.service';

const URL = config.apiInspectionUploadUrl;

@Component({
  selector: 'app-application-modal3',
  templateUrl: './application-modal3.component.html',
  styleUrls: ['./application-modal3.component.scss']
})
export class ApplicationModal3Component implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq = '';
  public btnName: string = '신청';

  public form: FormGroup;
  public formErrors = {};

  public com_gbn: String;

  // 파일 업로드
  public uploader: FileUploader;
  public uploadProgress: any = 0;

  // 업로드 도메인
  public baseURL = config.baseUrl

  isChecked : boolean = false;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private hMemberService: HMemberService,
    private hInspectionService: HInspectionService,
    private globals: Globals,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 회사 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCompanyInfo(): void {
    this.hMemberService.getCompanyInfoLogin().then((response) => {
      if( response.ResultCode && response.data && response.data.length > 0 ) {
        this.form.patchValue(response.data[0]);
        this.form.patchValue({
          company_name: response.data[0].com_kor, 
          com_address: response.data[0].address,
          com_tel: response.data[0].tel,
          com_fax: response.data[0].fax,
          applicant_name: response.data[0].username,
          applicant_hp: response.data[0].mobile,
          applicant_email: response.data[0].email,
        });

        this.com_gbn = response.data[0].com_gbn;

      }

      this.form.patchValue({'application_date': moment().format('YYYY-MM-DD')});
    });
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let tmp = {
      application_model_seq: [ '', [] ],
      application_request_seq: [ '', [] ],
      IAT_code: [ '3000', [] ],
      company_seq: [ '', [] ],
      company_name: [ '', [Validators.maxLength(100)] ],
      name_kor: [ '', [Validators.maxLength(50)] ],
      com_number: [ '', [Validators.pattern('^[0-9-]+$'), Validators.minLength(10), Validators.maxLength(12)] ],
      zipcode: [ '', [Validators.maxLength(5)] ],
      com_address: [ '', [Validators.maxLength(200)] ],
      addressDetail: [ '', [] ],
      com_tel: [ '', [Validators.maxLength(20)] ],
      com_fax: [ '', [Validators.maxLength(20)] ],
      job: [ '', [Validators.maxLength(100)] ],
      job1: [ '', [Validators.maxLength(100)] ],
      com_gbn: [ '', [] ],
      application_date: [ '', [Validators.required, Validators.maxLength(100)] ],
      applicant_name: [ '', [Validators.required, Validators.maxLength(50)] ],
      applicant_hp: [ '', [Validators.required, Validators.maxLength(30)] ],
      applicant_email: [ '', [Validators.required, Validators.maxLength(100)] ],

      tab3_sample_disposition: [ '', [Validators.required] ],
      tab3_model_name: [ '', [Validators.required, Validators.maxLength(250)] ],
      tab3_standard_model_name: [ '', [Validators.required, Validators.maxLength(50)] ],
      tab3_apply_model_name: [ '', [Validators.required, Validators.maxLength(50)] ],
      tab3_kind_grade: [ '', [Validators.maxLength(50)] ],
      tab3_apply_reason: [ '', [Validators.maxLength(150)] ],
      tab3_file: [ '', [] ],
      tab3_files: [[], []],
      tab3_request_memo: [ '', [Validators.required] ],
      tab3_agree: [ '', [Validators.required] ],

      tab3_application_kind1: [ false, [] ],
      tab3_application_kind2: [ false, [] ],
      tab3_application_kind3: [ false, [] ],
      tab3_application_kind4: [ false, [] ],
      tab3_radio: [ '', [] ],
    }

    this.form = this.formBuilder.group(tmp);

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 검사신청 첨부파일 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getInspectionApplicationFile(): void {
    this.hInspectionService.getInspectionApplicationFile(this.seq).then( response => {
      let files= [];
      this.form.patchValue( {tab3_files: files} );
      if( response.ResultCode && response.data && response.data.length > 0 ) {
        for( let list of response.data ) {
          files.push({
            seq: list.seq,
            url: list.upload_file,
            origin: list.org_upload_file,
            size: list.file_size,
            thumbnail: list.thumbnail,
            thumbnail_result: '',
          });
        }  
        this.form.patchValue( {tab3_files: files} );
      }
    });
  }

  /*******************************************************************************
   설  명 : 시험 검사신청 조회
   입력값 : 없음
   리턴값 : 없음
   *******************************************************************************/
   getInspectionApplicationInfo() {
    this.hInspectionService.getInspectionApplicationInfo(this.seq).then( response => {
      if ( response.ResultCode ) {

        for(let key in this.form.value) {
          if( key.substr(0, 5) === 'tab3_' ) {
            let key1 = key.substr((key.length - 5) * -1);
            this.form.patchValue({[key] : response.data[0][key1]});
          } else {
            this.form.patchValue({[key] : response.data[0][key]});
          }
        }  
        this.com_gbn = response.data[0].com_gbn;
        this.form.patchValue({application_date: response.data[0].application_date.substr(0, 10)});
        this.form.patchValue({tab3_agree: true});
        this.getInspectionApplicationFile();
      } 
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    if (this.seq) {
      this.btnName = '저장';
      this.getInspectionApplicationInfo();
    } else {
      this.getCompanyInfo();
    }

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'txt', 'hwp'];
    //var filetype: string[] = this.boardInfo.upload_file_type.split('|');
    //let imagetype: string[] = this.boardInfo.upload_image_type.split('|');

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.uploadProgress = '0%';
    };

    // 업로드 폼생성시 처리
    this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
      form.append( "token", this.globals.token );
    };

    // 업로드시 프로그레스바 처리
    this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
      this.uploadProgress = progress + '%';
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.globals.indicator.next(false);
      });
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if( response.result ) {
        this.toastrService.success( response.message, '파일 업로드');

        let files = this.form.controls.tab3_files.value;

        files.push({
          seq:'0',
          url: config.baseUrl + response.url.replace(/\\/gi, '/'),
          origin: response.origin_filename,
          size: response.size,
          thumbnail: response.thumbnail.replace(/\\/gi, '/'),
          thumbnail_result: response.thumbnail_result,
        });

        this.form.patchValue( {tab3_files: files} );

      } else {
        this.toastrService.error( response.message, '파일 업로드');
      }

      // indicator 표시 숨김
      this.globals.indicator.next(false);
    };

  }

  /*******************************************************************************
    설  명 : 표준개발협력제안
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changeChecked(field, e: any) {
    console.log('tt');
    if( field === 0 ) {
      this.form.patchValue({tab3_application_kind1: e.target.checked});
    } else if( field === 1 ) {
      this.form.patchValue({tab3_application_kind2: e.target.checked});
    } else if( field === 2 ) {
      this.form.patchValue({tab3_application_kind3: e.target.checked});
    } else if( field === 3 ) {
      this.form.patchValue({tab3_application_kind4: e.target.checked});
    } else {
      this.form.patchValue({tab3_radio: e.target.value});
    }

    if( field === 0 ) {
      if (e.target.checked) {
        this.isChecked = true
      } else {
        this.isChecked = false;
      }
    }

    let text = '';
    if( this.form.value.tab3_application_kind1 ) text += '형식승인검사';
    if( this.form.value.tab3_application_kind2 ) text += text !== '' ? ', 효율등급신청 포함' : '효율등급신청 포함';
    if( this.form.value.tab3_application_kind3 ) text += text !== '' ? ', 환경표시인증' : '환경표시인증';
    if( this.form.value.tab3_application_kind4 ) text += text !== '' ? ', 대기권역시험' : '대기권역시험';

    if( this.form.value.tab3_radio !== '' ) text += ' ' + this.form.value.tab3_radio;

    this.form.patchValue({
      tab3_request_memo: text
    });
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {

    this.uploader.clearQueue();
    
    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증

    let files:File[] = event.target.files;
    let filteredFiles:File[] = [];
    for(var f of files) {
      filteredFiles.push(f);
    }

    let options = null;
    let filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.globals.indicator.next(true);

    this.uploader.uploadAll()
  }

  /*******************************************************************************
    설  명 : 확장명 가져오기
    입력값 : filename
    리턴값 : ext
  *******************************************************************************/
  getExt( filename ) {
    if( typeof filename == 'undefined' ) return '';
    else return filename.substr(filename.lastIndexOf('.') + 1);
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 파일
    리턴값 : 없음
  *******************************************************************************/
  deleteFile( index ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      let tmp = this.form.controls.tab3_files.value;

      tmp.splice( index, 1 );

      this.form.patchValue({tab3_files: tmp});
    }
  }

  /*******************************************************************************
    설  명 : 파일 삭제
    입력값 : 업로드 파일 정보
    리턴값 : 없음
  *******************************************************************************/
  deleteUploadFile( upload: any, index: any ) {
    if( confirm("선택하신 파일을 삭제하시겠습니까?") ) {
      /*
      this.boardService.deleteUploadFile( upload.board_id, upload.board_seq, upload.seq ).then( response => {
        if( response.ResultCode ) {
          this.boardData.upload.splice( index, 1 );

          this.toastrService.success( response.ResultMessage, '게시글 첨부파일 삭제');
        } else {
          this.toastrService.error( response.ResultMessage, '게시글 첨부파일 삭제');
        }
      });
      */
    }
  }

  /*******************************************************************************
    설  명 : 업로드 된 파일 명이 이미지 인지 체크
    입력값 : 확장명
    리턴값 : true / false
  *******************************************************************************/
  checkImage( ext: string ) {
    let extArray = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];

    if( extArray.indexOf( ext.toLowerCase() ) == -1 ) return false;
    else return true;
  }

  /*******************************************************************************
    설  명 : 공통코드 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public submit() {
    console.log('1');
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      if( this.form.value.tab3_files.length < 1 ) {
        this.toastrService.error('구비서류 - 사업자 등록증, 기타 설명 문서를 등록해 주시기 바랍니다.');
      }

      let params = {};
      for(let key in this.form.value) {
        if( key.substr(0, 5) === 'tab3_' ) {
          let key1 = key.substr((key.length - 5) * -1);
          params[key1] = this.form.value[key];
        } else {
          params[key] = this.form.value[key];
        }
      }
      params['seq'] = this.seq;
      this.hInspectionService.setApplicationInspection(params).then((response) => {
        if( response.ResultCode ) {
          this.toastrService.success('정상적으로 등록되었습니다.', '시험검사신청');
          this.activeModal.close();

        } else {
          this.toastrService.error( response.ResultMessage, '시험검사신청');
        }
      }, (error) => {
        this.toastrService.error(error, '시험검사신청');
      });
    }
  }

}
