import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-eatl-business',
  templateUrl: './eatl-business.component.html',
  styleUrls: ['./eatl-business.component.scss']
})
export class EatlBusinessComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public menu: any = {
    depth1_id: 1,
    depth2_id: 1,
    depth1_nm: '부설 에너지기기 시험원',
    depth2_nm: '사업안내',
  };   

  tabIndex = '1'; // tab순번  

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.tabIndex) {
        this.tabIndex = params.tabIndex;
      }      
    });  
  }

}
