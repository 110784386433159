import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business42',
  templateUrl: './business42.component.html',
  styleUrls: ['./business42.component.scss']
})
export class Business42Component implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  public boardInfo: any = {
    board_id: 'standard'
  };

  isboard : boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
