import { Component, OnInit, Input} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { BoardService } from '@admin/service/board.service';
import { Router, ActivatedRoute } from '@angular/router';
import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';
import { CommonService } from '@app/service/common.service';
import { Globals } from '@app/service/globals.service';
import { config } from '@app/service/config.service';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-board-view',
  templateUrl: './board-view.component.html',
  styleUrls: ['./board-view.component.scss']
})
export class BoardViewComponent extends O2OZCMS implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  @Input() boardInfo: any;  
  @Input() board_seq: any;  
  @Output() list = new EventEmitter();
  public commentList: any = [];
  data: any = {};
  upload: any = {};
  public params: any = {
    type: 'list',
    board_id: '',
    board_seq: '',
    group_id: '전체',
    row: 10,
    no: 1,
    group: 'subject',
    text: ''
  };
  checkWrite = false;
  public groupTab: any = [];
  public linecnt: any = [];
  public baseURL = config.baseUrl;

  constructor(
    private boardService: BoardService,
    toastrService: ToastrService,
    router: Router,
    commonService: CommonService,
    activatedRoute: ActivatedRoute,
    private globals: Globals,

  ) {
    // 상위 클래스에 router 전달
    super(router, commonService, activatedRoute, toastrService);
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? '' : params.board_id;
      this.params.board_seq = ( typeof params.board_seq == 'undefined' || params.board_seq == '' ) ? '' : params.board_seq;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '' : params.group_id;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? 'list' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? '' : params.row;
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? '1' : params.no;
      this.params.group = ( typeof params.group == 'undefined' || params.group == '' ) ? '' : params.group;
      this.params.text = ( typeof params.text == 'undefined' || params.text == '' ) ? '' : params.text;
      this.params.view_type = ( typeof params.view_type == 'undefined' || params.view_type == '' ) ? '' : params.view_type;
      this.params.totalCount = ( typeof params.totalCount == 'undefined' || params.totalCount == '' ) ? '0' : params.totalCount;
      
      if( this.params.board_id !== '' ) {
        this.getBoardInfo();
      }
    });

    this.getBoardView();    
    this.getCommentList();
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }
  
  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardInfo() {
    this.groupTab = [];

    this.boardService.getBoardInfoId( this.params.board_id ).then( response => {
      if( response.ResultCode ) {
        this.boardInfo = response.data;

        if( this.params.view_type == '' ) {
          switch( this.boardInfo.lvt_default ) {
            case 'L':
              this.params.view_type = 'list';
              break;
            case 'A':
              this.params.view_type = 'album';
              break;
            case 'G':
              this.params.view_type = 'gallery';
              break;
            default:
              this.params.view_type = 'list';
          }
        }

        if( this.params.view_type == 'list' ) this.linecnt = this.boardInfo.linecnt_list.split('/');
        else if( this.params.view_type == 'album' ) this.linecnt = this.boardInfo.linecnt_album.split('/');
        else if( this.params.view_type == 'gallery' ) this.linecnt = this.boardInfo.linecnt_gallery.split('/');
  
        if( this.params.row == '' ) this.params.row = this.linecnt[0];

      } else {
        this.globals.indicator.next(false);

        this.toastrService.error( "존재하지 않는 게시판입니다.", "게시판 에러" );

        this.router.navigate( ['/main'] );
        return false;
      }
    });
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardView() {
    this.boardService.getBoardView(this.params.board_id, this.params.board_seq, null ).then( response => {
      if( response.ResultCode ) {
        this.data = response.data;
        this.params.totalCount = response.total;
        this.upload = response.upload;
      } else {
        this.data = [];
        this.params.totalCount = 0;
      }

      this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : 댓글 리스트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCommentList() {
    this.boardService.getCommentList( this.params ).then( response => {
      if( response.ResultCode ) {
        this.commentList = response.data;
      }

      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 게시글 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  delete() {
    if( confirm("게시글을 삭제하시겠습니까?") ) {
      this.boardService.deleteBoard( this.boardInfo.board_id, this.params.board_seq ).then( response => {
        if( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '게시글 삭제' );

          this.params.type = 'list';
          this.router.navigate([ ('/board/list') ], { queryParams: { board_id: this.params.board_id} });
          
        } else {
          this.toastrService.error( response.ResultMessage, '게시글 삭제' );
        }
      });
    }
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.router.navigate([ ('/board/list') ], { queryParams: this.params });
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  write() {
    this.router.navigate([ ('/board/write') ], { queryParams: { board_id: this.params.board_id, board_seq: this.params.board_seq } });
  }

}
