<app-sub-header [menu]="menu"></app-sub-header>

<div class="overview-page contents-page">
  <div class="inner-wrap">
    <ul class="tab clearfix">
      <li [ngClass]="{active: tabIndex == '1'}" (click)="tabIndex = '1'">설립목적 및 주요사업</li>
      <li [ngClass]="{active: tabIndex == '2'}" (click)="tabIndex = '2'">연혁</li>
      <li [ngClass]="{active: tabIndex == '3'}" (click)="tabIndex = '3'">조직도</li>
    </ul>

    <app-business *ngIf="tabIndex == '1'"></app-business>
    <app-history *ngIf="tabIndex == '2'"></app-history>
    <app-organization *ngIf="tabIndex == '3'"></app-organization>
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>
