import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

import { HMemberService } from '@app/home/service/member.service';
import { HInspectionService } from '@app/home/service/inspection.service';
import { UtilService } from '@app/service/util.service';
import { InspectionBasicService } from '@app/admin/service/inspectionbasic.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-application-modal2',
  templateUrl: './application-modal2.component.html',
  styleUrls: ['./application-modal2.component.scss']
})
export class ApplicationModal2Component implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public seq = '';
  public btnName: string = '신청';

  public form: FormGroup;
  public formErrors = {};

  public com_gbn: String;

  public labList: any = [];

  constructor(
    private InspectionBasicService: InspectionBasicService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private hMemberService: HMemberService,
    private hInspectionService: HInspectionService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getlabList();
    if (this.seq) {
      this.btnName = '저장';
      this.getlabUseApplicationInfo();
    } else {
      this.getCompanyInfo();
    }
  }

  /*******************************************************************************
    설  명 : 품목 선택 시
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChange($event: any) {
    this.form.patchValue({tab2_lab_seq: $event.target.value});
  }

  /*******************************************************************************
    설  명 : 회사 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCompanyInfo(): void {
    this.hMemberService.getCompanyInfoLogin().then((response) => {
      if( response.ResultCode && response.data && response.data.length > 0 ) {
        this.form.patchValue(response.data[0]);
        this.form.patchValue({
          company_name: response.data[0].com_kor, 
          com_address: response.data[0].address,
          com_tel: response.data[0].tel,
          com_fax: response.data[0].fax,
          applicant_name: response.data[0].username,
          applicant_hp: response.data[0].mobile,
          applicant_email: response.data[0].email,
        });

        this.com_gbn = response.data[0].com_gbn;

      }

      this.form.patchValue({'registration_date': moment().format('YYYY-MM-DD')});
    });
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let tmp = {
      IAT_code: [ '1000', [] ],
      company_seq: [ '', [] ],
      company_name: [ '', [Validators.maxLength(100)] ],
      name_kor: [ '', [Validators.maxLength(50)] ],
      com_number: [ '', [Validators.pattern('^[0-9-]+$'), Validators.minLength(10), Validators.maxLength(12)] ],
      zipcode: [ '', [Validators.maxLength(5)] ],
      com_address: [ '', [Validators.maxLength(200)] ],
      addressDetail: [ '', [] ],
      com_tel: [ '', [Validators.maxLength(20)] ],
      com_fax: [ '', [Validators.maxLength(20)] ],
      job: [ '', [Validators.maxLength(100)] ],
      job1: [ '', [Validators.maxLength(100)] ],
      com_gbn: [ '', [] ],
      registration_date: [ '', [Validators.required, Validators.maxLength(100)] ],
      applicant_name: [ '', [Validators.required, Validators.maxLength(50)] ],
      applicant_hp: [ '', [Validators.required, Validators.maxLength(30)] ],
      applicant_email: [ '', [Validators.required, Validators.maxLength(100)] ],

      tab2_application_sdate: [ '', [] ],
      tab2_application_edate: [ '', [] ],
      tab2_lab_seq: [ '', [Validators.required] ],
      tab2_use_date: [ '', [] ],
      tab2_LUG_code: [ '', [] ],
      tab2_use_purpose: [ '', [] ],
      tab2_request_memo: [ '', [Validators.required] ],
      tab2_agree: [ '', [Validators.required] ],
    }

    this.form = this.formBuilder.group(tmp);

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 실험실 리스트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getlabList() {
    this.InspectionBasicService.getLabList().then(res => this.labList = res.data)
  }
  
  /*******************************************************************************
    설  명 : 실험실 신청조회
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getlabUseApplicationInfo() {
    this.hInspectionService.getlabUseApplicationInfo(this.seq).then((response) => {
      if( response.ResultCode && response.data && response.data.length > 0 ) {
        for(let key in this.form.value) {
          if( key.substr(0, 5) === 'tab2_' ) {
            let key1 = key.substr((key.length - 5) * -1);
            this.form.patchValue({[key] : response.data[0][key1]});
          } else {
            this.form.patchValue({[key] : response.data[0][key]});
          }
        }  
        this.com_gbn = response.data[0].com_gbn;
        this.form.patchValue({registration_date: response.data[0].registration_date.substr(0, 10)});
        this.form.patchValue({tab2_agree: true});
      }
    });
  }

  /*******************************************************************************
    설  명 : 실험실 임대신청 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    console.log(this.formErrors);

    if(this.form.valid) {
      let params = {};
      for(let key in this.form.value) {
        if( key.substr(0, 5) === 'tab2_' ) {
          let key1 = key.substr((key.length - 5) * -1);
          params[key1] = this.form.value[key];
        } else {
          params[key] = this.form.value[key];
        }
      }
      params['seq'] = this.seq;

      this.hInspectionService.setApplicationInspectionLab(params).then((response) => {
        if( response.ResultCode ) {
          this.toastrService.success('정상적으로 등록되었습니다.', '실험실임대신청');
          this.activeModal.close();

        } else {
          this.toastrService.error( response.ResultMessage, '실험실임대신청');
        }
      }, (error) => {
        this.toastrService.error(error, '실험실임대신청');
      });
    }
  }
}
