import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { CommonService } from '@app/service/common.service';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() menu: any;
  // @Input() currMenu: BehaviorSubject<{}>;
  // @Input() memberInfo: BehaviorSubject<{}>;
  @Input() currMenu: any;
  @Input() memberInfo: any;

  public active_seq = '';
  public selectedLanguage: any = '';

  isSubmenu = false; // 모바일 메뉴 선택

  /** 멤버 레벨 */
  public level: any;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public router: Router,
    public commonService: CommonService,
    public activatedRoute: ActivatedRoute,
    public toastrService: ToastrService,
    private translate: TranslateService
  ) {
    // 다국어 설정이 파라미터로 넘어온 경우 처리
    this.activatedRoute.queryParams.subscribe(params => {
      if( typeof params.language !== 'undefined' && params.language !== '' ) {
        this.selectedLanguage = params.language;
      }
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.currMenu.subscribe( (data: any) => {
      if ( typeof data.page_cd === 'undefined' ) {
        return false;
      }

      let mainMenu: any = [];
      switch (data.page_cd.length) {
        case 9:
          this.active_seq = data.parentNode.seq;
          mainMenu = data.parentNode.parentNode;
          break;
        case 12:
          this.active_seq = data.parentNode.parentNode.seq;
          mainMenu = data.parentNode.parentNode.parentNode;
          break;
        default:
          this.active_seq = data.seq;
          mainMenu = data.parentNode;
          break;
      }

      this.memberInfo.subscribe( (member: any) => {
        if (member === null) {
          // null 이면 비회원
          this.level = '0';
        } else if (typeof member.level === 'undefined') {
          // 빈 Object 처리
          return false;
        } else {
          this.level = member.level;
        }
      });
    });
  }

  /*******************************************************************************
    설  명 : 서브메뉴 링크
    입력값 : item = 메뉴 array
    리턴값 : 없음
  *******************************************************************************/
  goLink( menu ) {
    let href = menu.link_url;
    let boardId = menu.board_id;
    let menuIndex = menu.seq;

    if (href === '') {
      let result = false;
      const link = function getURL(item) {
        if (item.link_url === '' && item.board_id === '') {
          for (const element of item.children) {
            getURL(element);
            if (result) {
              return false;
            }
          }
        } else {
          href = item.link_url;
          boardId = item.board_id;
          menuIndex = item.seq;
          result = true;
        }
      };
      link(menu);
    }

    if ( boardId === '' ) {
      this.router.navigate([ href ], { queryParams: { menu_seq: menuIndex, language: this.selectedLanguage }});
    } else {
      this.router.navigate([ (href !== '' ? href : '/board/list') ], { queryParams: { board_id: boardId } });
    }
  }

  /*******************************************************************************
    설  명 : 재귀 호출로 패스 리스트 가져오기
    입력값 : item = menu node, html = ''
    리턴값 : string html
  *******************************************************************************/
  getPathName( item, html ) {
    if ( typeof item['parentNode'] == 'undefined' ) {
      return html;
    } else {
      html = '<span style="padding:0px 14px;">></span><span class="depth1">' + item['page_menu_name'] + '</span>' + html;
      return this.getPathName( item['parentNode'], html );
    }
  }

  /*******************************************************************************
    설  명 : 패스 리스트
    입력값 : 없음
    리턴값 : string html
  *******************************************************************************/
  getPath( item ) {
    // 재귀 호출로 패스 리스트 가져오기
    let html = this.getPathName( item, '' );

    return html;
  }

  /*******************************************************************************
    설  명 : 메뉴의 루트 노드를 가져온다.
    입력값 : item
    리턴값 : root node
  *******************************************************************************/
  getRootNode( item, level ) {
    try {
      if ( typeof item.parentNode == 'undefined' ) {
        return item;
      } else if ( item.page_cd.length == (level * 3) ) {
        return item;
      } else {
        return this.getRootNode( item.parentNode, level );
      }
    } catch (ex) {
      console.log('getRootNode Error:', ex);
    }
  }

  /*******************************************************************************
    설  명 : 최상위 노드의 메뉴의 이름
    입력값 : item
    리턴값 : 메뉴 이름 string
  *******************************************************************************/
  getTopPathName( item ) {
    let node: any;

    node = this.getRootNode( item, 1 );

    return node.page_menu_name;
  }

  /*******************************************************************************
    설  명 : 상단 레이어 배경 이미지 스타일 시트 클래스 선언
    입력값 : item
    리턴값 : top-visual + 메뉴 번호
  *******************************************************************************/
  getTopPathClass( item ) {
    let node: any;

    node = this.getRootNode( item, 1 );

    return 'top-visual' + node.page_cd;
  }

  /*******************************************************************************
    설  명 : 두번째 메뉴 이름 가져오기
    입력값 : item
    리턴값 : page_name
  *******************************************************************************/
  getSecondPathName( item ) {
    if (item.length === undefined) {
      // return item.page_name;
    }

    let node: any;
    node = this.getRootNode( item, 2 );
    if (node.length > 1) {
      return '';
    } else {
      return node.page_menu_name;
    }
  }

  /*******************************************************************************
    설  명 : 두번째 메뉴 노드 가져오기
    입력값 : item
    리턴값 : second menu node
  *******************************************************************************/
  getSecondNode( item ) {
    return this.getRootNode( item, 1 );
  }
  
}
