<div class="map-car">
  <div class="car clearfix">
    <div class="line">자가용 이용 시</div>
    <div class="map-area">
      <div class="addr">
        <div class="clearfix"><span class="th">주소</span><span>경기도 안산시 상록구 장상동 535-4<br class="mobile-only"> 한국에너지기기산업진흥회</span></div>
        <div class="clearfix"><span class="th">전화</span><span>031-480-2981</span></div>
      </div>
      <div class="map"></div>
    </div>
  </div>
</div>
