import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-customer-service',
  templateUrl: './customer-service.component.html',
  styleUrls: ['./customer-service.component.scss']
})
export class CustomerServiceComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  public menu: any = {
    depth1_id: 3,
    depth2_id: 6,
    depth1_nm: '고객서비스',
    depth2_nm: '고객상담실',
  }; 

  tabIndex = '1'; // tab순번  

  public boardInfo: any = {
    board_id: 'qna'
  };
  public boardInfo1: any = {
    board_id: 'suggestion'
  };  
  public boardInfo2: any = {
    board_id: 'complain'
  };    

  isboard : boolean = false;  

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.tabIndex) {
        this.tabIndex = params.tabIndex;
      }      
    });  
  }

}
