<div class="eatl-business2">
  <div class="page-title">KS표시인증업체협의회</div>
  <div class="page-title-bar"></div>

  <div class="guide-txt">
    KS표시품에 대한 제품심사와 자율적인 품질관리를<br class="mobile-only"> 통해서 소비자보호와 회원업체의 건전한 발전을<br class="mobile-only"> 도모하고 제품의 안전과 품질수준 향상을 위하여<br/>
    공업진흥청의 승인을 받아 ’86년<br class="mobile-only"> 가스연소기기 KS표시인증업체협의회를 구성·운영
  </div>

  <div class="section">
    <div class="title">주요사업</div>
    <ul>
      <li><span></span><span>산업표준화의 촉진 및 소비자보호에 관한 사업</span></li>
      <li><span></span><span>우수 KS인증제품의 생산 및 보급에 관한 사업</span></li>
      <li><span></span><span>불량 KS인증제품의 생산 및 유통근절을 위한 제품심사 및 시판품조사에 관한 사업</span></li>
      <li><span></span><span>협의회 소속 회원업체의 품질향상 지도사업</span></li>
      <li><span></span><span>기술표준원장으로부터 위임받은 사업</span></li>
      <li><span></span><span>제품 및 부품인증 사업</span></li>
      <li><span></span><span>기타 협의회의 목적을 달성하기 위하여 필요한 사업</span></li>
    </ul>
  </div>

  <div class="section">
    <div class="title">회원우대 사항</div>
    <ul>
      <li><span></span><span>시험검사수수료의 30% 감면(KOLAS품질문서)</span></li>
    </ul>
  </div>

  <div class="section">
    <div class="title">협의회 관장품목 : <span>17개 KS품목</span></div>
    <ul class="four clearfix">
      <li><span></span><span>기름연소온수보일러(KS B 8017)</span></li>
      <li><span></span><span>가스레인지(KS B 8114)</span></li>
      <li><span></span><span>이동형가스난로(KS B 8125)</span></li>
      <li><span></span><span>업용가스부침기(KS B 8130)</span></li>
      <li><span></span><span>가스그릴(KS B 8103)</span></li>
      <li><span></span><span>가스오븐레인지(KS B 8115)</span></li>
      <li><span></span><span>콘덴싱가스온수보일러(KS B 8127)</span></li>
      <li><span></span><span>상업용가스튀김지(KS B 8131)</span></li>
      <li><span></span><span>가스오븐(KS B 8104)</span></li>
      <li><span></span><span>가스순간온수기(KS B 8116)</span></li>
      <li><span></span><span>상업용가스국솥(KS B 8128)</span></li>
      <li><span></span><span>상업용가스밥솥(KS B 8135)</span></li>
      <li><span></span><span>가스온수보일러(KS B 8109)</span></li>
      <li><span></span><span>가스난로(KS B 8117)</span></li>
      <li><span></span><span>상업용가스레인지(KS B 8129)</span></li>
      <li><span></span><span>상업용식기세척기(KS B 8136)</span></li>
      <li><span></span><span>가스의류건조기(KS B 8122)</span></li>
    </ul>
  </div>

  <div class="section">
    <div class="title">협의회 운영규정</div>
    <div class="file-down"><a href="/assets/download/ks02.hwp">파일 다운로드</a>가스연소기기 KS표시인증업체협의회 운영규정.hwp</div>
  </div>

</div>

