<app-sub-header [menu]="menu"></app-sub-header>

<div class="policy-page contents-page">
  <div class="inner-wrap">
    <ul class="tab clearfix">
      <li [ngClass]="{active: tabIndex == '1'}" (click)="tabIndex = '1'">최근정책동향</li>
      <li [ngClass]="{active: tabIndex == '2'}" (click)="tabIndex = '2'">최근공고</li>
    </ul>

    <div class="policy-tab">
      <div class="page-title" *ngIf="tabIndex == '1'">최근정책동향</div>
      <div class="page-title" *ngIf="tabIndex == '2'">최근공고</div>
      <div class="page-title-bar"></div>
    </div>  
    <app-board-list [boardInfo]="boardInfo"></app-board-list>

  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>
