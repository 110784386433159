<app-header [menuList$]="menuList$"></app-header>

<div class="main-page" style="overflow: hidden;">

  <section class="main-visual">
    <div class="txt">
      <div class="txt-logo"></div>
      <div class="txt1">행복한 세상을 향한<br class="mobile-only"> 에너지 기기</div>
      <div class="txt2">한국에너지기기산업진흥회는 에너지기기산업의 발전을 위해<br/>최선을 다하고 있습니다.</div>
    </div>
    <div class="bg1"></div>
    <div class="bg2"></div>
    <div class="bg3"></div>
    <div class="bg4"></div>
    <ngx-slick-carousel class="carousel main-img" 
      #slickModal="slick-carousel" 
      [config]="slideConfig_main"
      (beforeChange)="beforeChange($event)"
      (afterChange)="afterChange($event)"
    >
      <div ngxSlickItem class="slide">
        <div class="img-wrapper">
          <img src="/assets/images/main_visual01.png" alt="main visual" />
        </div>
      </div>
      <div ngxSlickItem class="slide">
        <div class="img-wrapper">
          <img src="/assets/images/main_visual02.png" alt="main visual" />
        </div>
      </div>
      <div ngxSlickItem class="slide">
        <div class="img-wrapper">
          <img src="/assets/images/main_visual03.png" alt="main visual" />
        </div>
      </div>
    </ngx-slick-carousel>    
    <div class="slide-paging">
      <!-- <div class="paging"><span>1</span>/3</div> -->
      <div class="slide-progressbar" [ngClass]="isProgressInit? 'init':''"><span class="slide-progress"></span></div>
      <button class="pause-toggle" [ngClass]="isPlay? 'slide-pause':'slide-play'" (click)="slidePausePlay()"></button>
    </div>
  </section>
  <section class="online-application">
    <div class="section-title">온라인 신청</div>
    <div class="section-guide">에너지기기제품 및 부품에 대한<br class="mobile-only"> 검사 신청 및 인증을 받을 수 있습니다.</div>
    <ul class="clearfix">
      <li>
        <a routerLink="/contents" [queryParams]="{menu_seq:469}">
          <div class="icon"></div>
          <div class="name">공인시험검사</div>
          <button class="btn"><span>신청하기</span></button>
        </a>
      </li>
      <li>
        <a routerLink="/contents" [queryParams]="{menu_seq:570}">
          <div class="icon"></div>
          <div class="name">안전확인시험검사</div>
          <button class="btn"><span>신청하기</span></button>
        </a>
      </li>
      <li>
        <a routerLink="/contents" [queryParams]="{menu_seq:573}">
          <div class="icon"></div>
          <div class="name">표준개발협력제안</div>
          <button class="btn"><span>신청하기</span></button>
        </a>
      </li>
      <li>
        <a routerLink="/contents" [queryParams]="{menu_seq:472}">
          <div class="icon"></div>
          <div class="name">진흥회 가입</div>
          <button class="btn"><span>신청하기</span></button>
        </a>
      </li>
      <li>
        <a routerLink="/contents" [queryParams]="{menu_seq:469}">
          <div class="icon"></div>
          <div class="name">불만처리</div>
          <button class="btn"><span>신청하기</span></button>
        </a>
      </li>
      <li>
        <a routerLink="/contents" [queryParams]="{menu_seq:469}">
          <div class="icon"></div>
          <div class="name">재발급</div>
          <button class="btn"><span>신청하기</span></button>
        </a>
      </li>                              
    </ul>
    <div class="bg1"></div>
    <div class="bg2"></div>
    <div class="bg3"></div>   
  </section>
  <section class="authenticity">
    <div class="txt-area inner-wrap">
      <!-- <a routerLink="/contents" [queryParams]="{menu_seq:469}" class="title">성적서 진위 확인 신청/조회<span class="arrow-btn"></span></a> -->
      <a class="title" style="cursor: pointer;" (click)="authenticityModal()">성적서 진위 확인 신청<span class="arrow-btn"></span></a>
      <div class="txt">우리 진흥회에서 발급한 시험ㆍ검사 성적서의<br class="mobily-only"> 진위 여부를 확인 할 수 있습니다.</div>
    </div>
  </section>

  <section class="business">
    <div class="section-title">주요 사업분야</div>
    <div class="section-guide">한국 에너지기기 산업진흥회의<br class="mobile-only"> 주요사업 및 보유장비를 확인할 수 있습니다.</div>
    <div class="business-list">
      <ngx-slick-carousel class="carousel"
        #slickModal="slick-carousel" 
        [config]="slideConfig_business" 
      >
        <div ngxSlickItem class="slide">
          <div class="img-wrapper img-wrapper1">
            <a routerLink="/contents" [queryParams]="{menu_seq:740}">
              <div class="img"></div>
              <div class="title">표준개발<br/>협력업무</div>
              <span class="more-btn">더보기</span>
            </a>
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="img-wrapper img-wrapper2">
            <a routerLink="/contents" [queryParams]="{menu_seq:736}">
              <div class="img"></div>
              <div class="title">품목별<br/>품질관리기관</div>
              <span class="more-btn">더보기</span>
            </a>          
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="img-wrapper img-wrapper3">
            <a routerLink="/contents" [queryParams]="{menu_seq:729}">
              <div class="img"></div>
              <div class="title">국가공인시험<br/>검사업무</div>
              <span class="more-btn">더보기</span>
            </a>            
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="img-wrapper img-wrapper4">
            <a routerLink="/contents" [queryParams]="{menu_seq:878}">
              <div class="img"></div>
              <div class="title">단체표준<br/>인증업무</div>
              <span class="more-btn">더보기</span>
            </a>            
          </div>
        </div>
        <div ngxSlickItem class="slide">
          <div class="img-wrapper img-wrapper5">
            <a routerLink="/contents" [queryParams]="{menu_seq:733}">
              <div class="img"></div>
              <div class="title">안전확인시험<br/>검사기관</div>
              <span class="more-btn">더보기</span>
            </a>            
          </div>    
        </div>   
      </ngx-slick-carousel>
    </div>
  </section>
  <section class="notice">
    <div class="section-title">공지사항</div>
    <ul class="inner-wrap clearfix">
      <li *ngFor="let row of data">
        <a routerLink="/board/view" [queryParams]="{board_id:'notice', board_seq: row.board_seq}">
          <div class="date">{{row.write_date.substr(0, 10)}}<span class="new" *ngIf="row.new_check_date <= 7">NEW</span></div>  
          <div class="title">{{row.subject}}</div>
          <div class="txt" [innerHTML]="row.contents | safe : 'html'"></div> 
          <div class="more-btn"></div> 
        </a>
      </li>        
    </ul>
    <a routerLink="/board" [queryParams]="{board_id:'board',board_state:'list'}" class="mobile-only notice-more">더보기</a>
    
    <div class="bg1"></div>
    <div class="bg2"></div>
    <div class="bg3"></div>
  </section>
  <section class="qna">
    <div class="inner-wrap clearfix">
      <div class="div1">
        <a routerLink="/board/list" [queryParams]="{board_id: 'qna'}" class="title">Q&A<span class="more-btn"></span></a>
        <div class="txt">한국에너지기기산업진흥회에 관한<br class="mobile-only"> 문의를 남기실 수 있습니다.</div>
      </div>
      <div class="div1">
        <a href="/assets/download/Binder1.pdf" target="_blank" class="title">전자 카탈로그<span class="more-btn"></span></a>
        <div class="txt">한국에너지기기산업진흥회에 관한<br class="mobile-only"> 정보를 볼 수 있습니다.</div>
      </div>      
    </div>  
  </section>
</div>

<!-- 팝업 //-->
<section *ngFor="let row of popupList">
  <div *ngIf="getPopupToday(row.board_seq)">
    <div class="popup-layer" [ngStyle]="{'max-width':'100%', 'max-height':'100%', 'left':changePopupLayerStyle(row, 'screen_x')+'px', 'top':changePopupLayerStyle(row, 'screen_y')+'px'}" [ngClass]="{hidden: row.is_popup_preview == false, 'popup-layer-mobile':isMobile===true}">
      <div class="popup-preview-contents">
        <ng-container *ngIf="row.title_link; else notLink">
          <a [href]="row.title_link" target="_blank">
            <img [src]="row.upload_file" [alt]="row.subject" />
          </a>
        </ng-container>
        <ng-template #notLink>
          <img [src]="row.upload_file" [alt]="row.subject" />
        </ng-template>
      </div>

      <!-- 오늘 더 이상 열지 않기 처리 //-->
      <div class="popup-button-area">
        <div class="fl">
          <label class="popup-not-today">
            <input type="checkbox" name="checkBtn" (click)="setPopupToday(row)" /> 오늘 더 이상 보지 않음
          </label>
        </div>
        <div class="fr">
          <button type="button" class="btn btn-default btn-small" (click)="row.is_popup_preview=false">창닫기</button>
        </div>
        <div class="cb"></div>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>