// const baseURL = 'http://keaa.o2oz.net';
// const baseURL = 'https://www.eaa.or.kr';

const baseURL =
  ( window.location.host.indexOf('localhost') > -1 )
  ? 'http://localhost:8090'
  : ( window.location.host.indexOf('192.168.') > -1 ) ? 'http://192.168.0.16:8090' : window.location.origin;
// const baseURL = window.location.origin;

export const config = {
  production: false,
  baseUrl: baseURL,
  apiBaseUrl: baseURL + "/api",
  apiFileUploadUrl: baseURL + "/api/file/file_upload.php",
  apiInspectionUploadUrl: baseURL + "/api/file/file_upload_inspection.php",
  apiImageUploadUrl: baseURL + "/api/file/image_upload.php",
  cookie_token: 'keaatoken',
  cookie_id: 'keaaid',
};
