<app-header [menuList$]="menuList$" [memberInfo$]="memberInfo$"></app-header>

<app-sub-header [currMenu]="currMenu$" [memberInfo]="memberInfo$"></app-sub-header>

<div class="join-page contents-page">
  <div class="inner-wrap">
    <ul class="tab clearfix">
      <li class="active">01. 약관동의 및 본인인증</li>
      <li>02. 정보입력</li>
      <li>03. 가입완료</li>
    </ul>

    <div class="join-step1">
      <div class="join-info">이용약관에 동의하셔야만<br class="mobile-only"/> 회원가입이 완료됩니다.</div>
      <div class="terms">
        <span [innerHtml]="policy | safe : 'html'"></span>
      </div>
      <label class="check-txt"><input type="checkbox" [(ngModel)]="agree" [value]="true">이용약관을 읽었으며 내용에 동의합니다.</label>

      <div class="join-info mt20">개인정보처리방침에 동의하셔야만<br class="mobile-only"/> 회원가입이 완료됩니다.</div>
      <div class="terms">
        <span [innerHtml]="privacy | safe : 'html'"></span>
      </div>
      <label class="check-txt"><input type="checkbox" [(ngModel)]="agree1" [value]="true">개인정보처리방침을 읽었으며 내용에 동의합니다.</label>


      <div class="btn-area">
        <a class="join-btn cp" (click)="goStep2()">다음페이지</a>
      </div>

      <!-- 인증부분 결정 안됨 -->
      <!-- <div class="certification clearfix">
        <div class="ctitle">본인인증</div>
        <button class="cbtn hp" (click)="setAuth('H')"><span>휴대폰 본인 인증</span></button>
        <button class="cbtn ipin" (click)="setAuth('I')"><span>아이핀 본인 인증</span></button>
      </div> -->
    </div>
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>
