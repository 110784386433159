import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-member-news',
  templateUrl: './member-news.component.html',
  styleUrls: ['./member-news.component.scss']
})
export class MemberNewsComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  
  public menu: any = {
    depth1_id: 4,
    depth2_id: 3,
    depth1_nm: '회원사',
    depth2_nm: '회원사 소식',
  };   

  constructor() { }

  ngOnInit(): void {
  }

}
