import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trade-statistics',
  templateUrl: './trade-statistics.component.html',
  styleUrls: ['./trade-statistics.component.scss']
})
export class TradeStatisticsComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  
  tabIndex1 = '1'; // tab순번  

  // 수출통계  
  list1 = [
    {year:'1988', data1:'531,447', data1_1:'5,446', data2:'127,245', data2_1:'896', data3:'18', data3_1:'5', data4:'8,899', data4_1:'1,387'},
    {year:'1989', data1:'215,751', data1_1:'5,338', data2:'147,564', data2_1:'1,305', data3:'4', data3_1:'3', data4:'6,619', data4_1:'1,202'},
    {year:'1990', data1:'234,775', data1_1:'3,111', data2:'177,228', data2_1:'1,729', data3:'6', data3_1:'1', data4:'7', data4_1:'2,687'}
  ]

  // 수입통계
  list2 = [
    {year:'1988', data1:'10,896', data1_1:'2,220', data2:'2,920', data2_1:'329', data3:'2,582', data3_1:'1,045', data4:'20,941', data4_1:'2,009'},
    {year:'1989', data1:'39,610', data1_1:'8,424', data2:'24,281', data2_1:'2,127', data3:'12,877', data3_1:'4,693', data4:'53,970', data4_1:'4,596'},
    {year:'1990', data1:'49,033', data1_1:'11,224', data2:'42,395', data2_1:'3,483', data3:'22,870', data3_1:'9,955', data4:'52,694', data4_1:'4,757'}
  ]  

  constructor() { }

  ngOnInit(): void {
  }

}
