<div class="application-modal">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="modal-header">
      <div class="modal-title">온라인 시험 검사 신청</div>
      <button type="button" class="close" (click)="activeModal.dismiss()"></button>
    </div>

    <div class="modal-body">
      <div class="section">
        <div class="section-title">신청업체 정보</div>
        <div class="guide-txt">신청업체 정보는 현재 화면에서 직접 수정 가능합니다.</div>
        <table class="table1">
          <colgroup>
            <col class="col1">
            <col class="col2">
            <col class="col3">
            <col class="col4">
            <col class="col5">
            <col class="col6">
          </colgroup>

          <tbody>
          <tr>
            <th><label for="company_name">업체명</label></th>
            <td>
              <input type="text" id="company_name" formControlName="company_name" [autofocus]="true" [ngClass]="{formErrors:formErrors['company_name']}" />
              <div *ngIf="formErrors['company_name']" class="error-message">{{formErrors['company_name']}}</div>
            </td>
            <th><label for="name_kor">대표자명</label></th>
            <td>
              <input type="text" id="name_kor" formControlName="name_kor" [ngClass]="{formErrors:formErrors['name_kor']}" />
              <div *ngIf="formErrors['name_kor']" class="error-message">{{formErrors['name_kor']}}</div>
            </td>
            <th><label for="com_number">사업자번호</label></th>
            <td>
              <input type="text" id="com_number" formControlName="com_number" [ngClass]="{formErrors:formErrors['com_number']}" />
              <div *ngIf="formErrors['com_number']" class="error-message">{{formErrors['com_number']}}</div>
            </td>
          </tr>
          <tr>
            <th><label for="zipcode">주소</label></th>
            <td colspan="3">
              <input type="text" style="width:95px" id="zipcode" formControlName="zipcode" [ngClass]="{formErrors:formErrors['zipcode']}" />
              <div *ngIf="formErrors['zipcode']" class="error-message">{{formErrors['zipcode']}}</div>

              <button class="btn1" style="width: 75px">우편번호찾기</button>

              <input type="text" style="width:374px" id="com_address" formControlName="com_address" [ngClass]="{formErrors:formErrors['com_address']}" />
              <div *ngIf="formErrors['com_address']" class="error-message">{{formErrors['com_address']}}</div>
            </td>
            <th><label for="com_tel">전화번호</label></th>
            <td>
              <input type="text" id="com_tel" formControlName="com_tel" [ngClass]="{formErrors:formErrors['com_tel']}" />
              <div *ngIf="formErrors['com_tel']" class="error-message">{{formErrors['com_tel']}}</div>
            </td>
          </tr>
          <tr>
            <th><label for="com_fax">팩스</label></th>
            <td>
              <input type="text" id="com_fax" formControlName="com_fax" [ngClass]="{formErrors:formErrors['com_fax']}" />
              <div *ngIf="formErrors['com_fax']" class="error-message">{{formErrors['com_fax']}}</div>
            </td>
            <th><label for="job">업태</label></th>
            <td>
              <input type="text" id="job" formControlName="job" [ngClass]="{formErrors:formErrors['job']}" />
              <div *ngIf="formErrors['job']" class="error-message">{{formErrors['job']}}</div>
            </td>
            <th><label for="comJob">종목</label></th>
            <td>
              <input type="text" id="job1" formControlName="job1" [ngClass]="{formErrors:formErrors['job1']}" />
              <div *ngIf="formErrors['job1']" class="error-message">{{formErrors['job1']}}</div>
            </td>
          </tr>
          <tr>
            <th><span class="red">*</span>회원사구분</th>
            <td colspan="5">
              <label class="mr30">
                <input type="radio" disabled="true" name="com_gbn" id="com_gbn" [(ngModel)]="com_gbn" [value]="1020" [checked]="com_gbn == '1020'" (change)="form.patchValue({com_gbn: '1020'})" /> 일반회원
              </label>
              <label class="mr30">
                <input type="radio" disabled="true" name="com_gbn" id="com_gbn" [(ngModel)]="com_gbn" [value]="1010" [checked]="com_gbn == '1010'" (change)="form.patchValue({com_gbn: '1010'})" /> 협의회 회원
              </label>
              <label>
                <input type="radio" disabled="true" name="com_gbn" id="com_gbn" [(ngModel)]="com_gbn" [value]="9000" [checked]="com_gbn == '9000'" (change)="form.patchValue({com_gbn: '9000'})" /> 비회원
              </label>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="section">
        <div class="section-title"><span class="red">*</span>필수정보</div>
        <table class="table2">
          <colgroup>
            <col class="col1">
            <col class="col2">
            <col class="col3">
            <col class="col4">
            <col class="col5">
            <col class="col6">
            <col class="col7">
            <col class="col8">
          </colgroup>
          <tbody>
          <tr>
            <th><label for="application_date"><span class="red">*</span>신청일자</label></th>
            <td>
              <input type="date" id="application_date" formControlName="application_date" [ngClass]="{formErrors:formErrors['application_date']}" />
              <div *ngIf="formErrors['application_date']" class="error-message">{{formErrors['application_date']}}</div>
            </td>
            <th><label for="applicant_name"><span class="red">*</span>신청자</label></th>
            <td>
              <input type="text" id="applicant_name" formControlName="applicant_name" [ngClass]="{formErrors:formErrors['applicant_name']}" />
              <div *ngIf="formErrors['applicant_name']" class="error-message">{{formErrors['applicant_name']}}</div>
            </td>
            <th><label for="applicant_hp"><span class="red">*</span>연락처</label></th>
            <td>
              <input type="text" id="applicant_hp" formControlName="applicant_hp" [ngClass]="{formErrors:formErrors['applicant_hp']}" />
              <div *ngIf="formErrors['applicant_hp']" class="error-message">{{formErrors['applicant_hp']}}</div>
            </td>
            <th><span class="red">*</span>이메일</th>
            <td>
              <input type="text" id="applicant_email" formControlName="applicant_email" [ngClass]="{formErrors:formErrors['applicant_email']}" />
              <div *ngIf="formErrors['applicant_email']" class="error-message">{{formErrors['applicant_email']}}</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <ul class="tab">
        <li class="active">시험·검사 신청</li>
      </ul>

      <div class="application-modal1">
        <div class="section">
          <table class="table1">
            <colgroup>
              <col class="col1">
              <col class="col2">
              <col class="col3">
              <col class="col4">
              <col class="col5">
              <col class="col6">
            </colgroup>

            <tbody>
            <tr>
              <th><span class="red">*</span>신청종류</th>
              <td colspan="5">
                <div [ngClass]="{formErrors:formErrors['tab1_EIG_code']}">
                  <label class="mr30"><input type="radio" name="tab1_EIG_code" [value]="1000" [checked]="form.value.tab1_EIG_code == '1000'" (change)="form.patchValue({tab1_EIG_code: '1000'})" />시험</label>
                  <label class="mr30"><input type="radio" name="tab1_EIG_code" [value]="2000" [checked]="form.value.tab1_EIG_code == '2000'" (change)="form.patchValue({tab1_EIG_code: '2000'})" />검사</label>
                  <label><input type="radio" name="tab1_EIG_code" [value]="3000" [checked]="form.value.tab1_EIG_code == '3000'" (change)="form.patchValue({tab1_EIG_code: '3000'})" />임대</label>
                </div>
                <div *ngIf="formErrors['tab1_EIG_code']" class="error-message">{{formErrors['tab1_EIG_code']}}</div>
              </td>
            </tr>
            <tr>
              <th><label for="tab1_report_purpose"><span class="red">*</span>용도</label></th>
              <td colspan="5">
                <input type="text" id="tab1_report_purpose" name="tab1_report_purpose" [value]="form.value.tab1_report_purpose" (change)="form.patchValue({tab1_report_purpose: $event.target.value})" formControlName="tab1_report_purpose" placeholder="성적서의 용도를 적어주세요." [ngClass]="{formErrors:formErrors['tab1_report_purpose']}"/>
                <div *ngIf="formErrors['tab1_report_purpose']" class="error-message">{{formErrors['tab1_report_purpose']}}</div>
              </td>
            </tr>
            <tr>
              <th><span class="red">*</span>완료 후 시료 처리</th>
              <td>
                <div [ngClass]="{formErrors:formErrors['tab1_sample_disposition']}">
                  <label class="mr30"><input type="radio" id="tab1_sample_disposition" name="tab1_sample_disposition" value="RETURN" [checked]="form.value.tab1_sample_disposition == 'RETURN'" (change)="form.patchValue({tab1_sample_disposition: 'RETURN'})" />반환</label>
                  <label><input type="radio" id="tab1_sample_disposition" name="tab1_sample_disposition" value="DISCARD" [checked]="form.value.tab1_sample_disposition == 'DISCARD'" (change)="form.patchValue({tab1_sample_disposition: 'DISCARD'})" />폐기</label>
                </div>
                <div *ngIf="formErrors['tab1_sample_disposition']" class="error-message">{{formErrors['tab1_sample_disposition']}}</div>
              </td>
              <th><span class="red">*</span>적합성 진술</th>
              <td colspan="3">
                <div [ngClass]="{formErrors:formErrors['tab1_conformity_statement']}">
                  <label class="mr10"><input type="radio" id="tab1_conformity_statement" name="tab1_conformity_statement" [value]="form.value.tab1_conformity_statement" [checked]="form.value.tab1_conformity_statement == '1000'" (change)="form.patchValue({tab1_conformity_statement: '1000'})" />단순채택(이분/이분법적 진술)</label>
                  <label class="mr10"><input type="radio" id="tab1_conformity_statement" name="tab1_conformity_statement" [value]="form.value.tab1_conformity_statement" [checked]="form.value.tab1_conformity_statement == '2000'" (change)="form.patchValue({tab1_conformity_statement: '2000'})" />보호대역(이분/이분법적 진술)</label>
                  <label><input type="radio" id="tab1_conformity_statement" name="tab1_conformity_statement" [value]="form.value.tab1_conformity_statement" [checked]="form.value.tab1_conformity_statement == '3000'" (change)="form.patchValue({tab1_conformity_statement: '3000'})" />단순채택(비이분/비이분법적 진술)</label>
                </div>
                <div *ngIf="formErrors['tab1_conformity_statement']" class="error-message">{{formErrors['tab1_conformity_statement']}}</div>
              </td>
            </tr>
            <tr>
              <th><span class="red">*</span>품목</th>
              <td colspan="5">
                <div class="form-inline">
                  <div class="btn-group mr5">
                    <ng-container *ngFor="let item of commonList;let i = index">
                      <button
                        type="button"
                        [attr.data-index]="i"
                        class="btn btn-default btn-small"
                        [ngClass]="{active: form.value.tab1_item_gbn_code == item.common_code}"
                        (click)="onClickBtn(item, 'gbn_code');"
                        [disabled]="viewMode"
                        [innerText]="item.common_name"
                      >{{item.common_name}}</button>
                    </ng-container>
                  </div>
                  <select
                    class="form-control form-control-small mr5"
                    style="width: 250px;"
                    name="item_code"
                    formControlName="tab1_item_code"
                    [ngModel]="form.value.tab1_item_code"
                    [value]="form.value.tab1_item_code"
                    (change)="onChange($event);"
                    [disabled]="viewMode"
                  >
                    <option value="undefined">품목</option>
                    <option
                      *ngFor="let item of commonDetailList;let i = index"
                      [attr.data-index]="i"
                      [value]="item.common_code"
                    >{{item.common_name}}</option>
                  </select>

                  <select
                    class="form-control form-control-small"
                    style="width: 348px;"
                    name="item_seq"
                    formControlName="tab1_item_seq"
                    [(ngModel)]="form.value.tab1_item_seq"
                    (change)="onChangeItem($event);"
                    [disabled]="viewMode"
                  >
                    <option value="undefined">품목상세</option>
                    <option *ngFor="let item of productList" value="{{item.seq}}">{{item.label}}</option>
                  </select>
                </div>
                <div *ngIf="formErrors['tab1_item_gbn_code']" class="error-message">{{formErrors['tab1_item_gbn_code']}}</div>
              </td>
            </tr>
            <tr>
              <th><label for="tab1_model_name"><span class="red">*</span>모델명</label></th>
              <td>
                <input type="text" id="tab1_model_name" name="tab1_model_name" formControlName="tab1_model_name" (change)="form.patchValue({tab1_model_name: $event.target.value})" [ngClass]="{formErrors:formErrors['tab1_model_name']}" />
                <div *ngIf="formErrors['tab1_model_name']" class="error-message">{{formErrors['tab1_model_name']}}</div>
              </td>
              <th>시료수량</th>
              <td>
                <input type="number" style="width: 100%" id="tab1_sample_qty" name="tab1_sample_qty" (change)="form.patchValue({tab1_sample_qty: $event.target.value})" formControlName="tab1_sample_qty" [ngClass]="{formErrors:formErrors['tab1_sample_qty']}" />
                <div *ngIf="formErrors['tab1_sample_qty']" class="error-message">{{formErrors['tab1_sample_qty']}}</div>
              </td>
              <th>시료사양</th>
              <td>
                <input type="text" id="tab1_sample_spec" name="tab1_sample_spec" formControlName="tab1_sample_spec" (change)="form.patchValue({tab1_sample_spec: $event.target.value})" [ngClass]="{formErrors:formErrors['tab1_sample_spec']}" />
                <div *ngIf="formErrors['tab1_sample_spec']" class="error-message">{{formErrors['tab1_sample_spec']}}</div>
              </td>
            </tr>
            <tr>
              <th><span class="red">*</span>시험검사항목</th>
              <td>
                <div [ngClass]="{formErrors:formErrors['tab1_apply_item_type']}">
                  <label class="mr30"><input type="radio" id="tab1_apply_item_type" name="tab1_apply_item_type" value="ALL" [checked]="form.value.tab1_apply_item_type == 'ALL'" (change)="form.patchValue({tab1_apply_item_type: 'ALL'})"/>전항목</label>
                  <label class="mr30"><input type="radio" id="tab1_apply_item_type" name="tab1_apply_item_type" value="EACH" [checked]="form.value.tab1_apply_item_type == 'EACH'" (change)="form.patchValue({tab1_apply_item_type: 'EACH'})"/>개별항목</label>
                </div>
                <div *ngIf="formErrors['tab1_apply_item_type']" class="error-message">{{formErrors['tab1_apply_item_type']}}</div>
              </td>
              <th>적용규격(년도)</th>
              <td colspan="3">
                <input type="text" id="tab1_apply_standard" name="tab1_apply_standard" formControlName="tab1_apply_standard" (change)="form.patchValue({tab1_apply_standard: $event.target.value})" [ngClass]="{formErrors:formErrors['tab1_apply_standard']}" />
                <div *ngIf="formErrors['tab1_apply_standard']" class="error-message">{{formErrors['tab1_apply_standard']}}</div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="section">
          <div class="div1">
            <header>
              <div class="section-title fl"><span class="red">*</span>구비서류 - 사업자 등록증, 기타 설명 문서</div>
              <div class="fr">
                <input type="file" name="tab1_file" formControlName="tab1_file" ng2FileSelect (change)="handleUploadFileChanged($event)" />
                <button class="btn1 ml5">추가</button>
              </div>
            </header>
            <table class="table3">
              <colgroup>
                <col class="col1">
                <col class="col2">
                <col class="col3">
              </colgroup>

              <thead>
              <tr>
                <th>번호</th>
                <th colspan="2">파일명{{form.value.tab1_files.length}}</th>
              </tr>
              </thead>

              <tbody>
                <ng-container *ngIf="form.value.tab1_files.length > 0">
                  <tr *ngFor="let item of form.value.tab1_files; let i = index">
                    <td>{{(i + 1)}}</td>
                    <td class="left">
                      {{item.origin}}
                      <span class="ml5 f11">({{utilService.formatBytes(item.size)}})</span>
                    </td>
                    <td><button class="icon-btn del" (click)="deleteFile(i)"></button></td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="form.value.tab1_files.length < 1">
                  <tr>
                    <td colspan="3" class="center">구비서류 - 사업자등록증, 기타 설명문서를 추가해주세요.</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="div2">
            <div class="section-title"><span class="red">*</span>문의·요청사항</div>
            <textarea id="tab1_request_memo" name="tab1_request_memo" formControlName="tab1_request_memo" (change)="form.patchValue({tab1_request_memo: $event.target.value})" [ngClass]="{formErrors:formErrors['tab1_request_memo']}" placeholder="문의 사항 및 요청사항을 간단히 기술해 주십시요."></textarea>
            <div *ngIf="formErrors['tab1_request_memo']" class="error-message">{{formErrors['tab1_request_memo']}}</div>
          </div>
        </div>
        <div class="guide-area">
          1. 처리기간은 20일입니다. 다만, 시험·검사 항목에 따라 변동될 수 있습니다.<br/>
          2. 적용규격이 공인된 시방이 아닌 경우 시험·검사방법을 첨부하여 주세요.<br/>
          3. 적용규격 기입란에 년도 표시가 안 된 경우는 해당규격의 최신 본을 적용합니다.<br/>
          4. 시험·검사 후 시료의 특성에 따라 시료의 손상이 있을 수 있습니다.<br/>
          5. 시료의 보관기간은 성적서 발급 일로부터 1개월입니다.<br/>
          6. 시료 보관기간이 만료된 시료는 진흥회에서 임의로 처분합니다.<br/>
          7. 시험·검사의 입회는 접수담당자에게 신청하세요, 다만, 이 경우 다른 고객의 비밀유지를 위하여 시험·검사기간이 늦어질 수 있습니다.<br/>
          8. 출장시험·검사는 특별한 경우에 한하며 접수 담당자와 상의하십시오.<br/>
          9. 변경 신청의 경우 처음 접수번호와 함께 변경되는 사항 만 작성하여 주십시오.
        </div>
        <div class="check">
          <div class="mb20">* 의뢰하신 시험 검사와 관련 한 모든 정보는 법률에 의해 요구 받거나, 법률에 의해 금지되지 않는한 고객과 별도의 합의가 있는 경우를 제외 하고는 비밀로 취급 됩니다.</div>
          <label [ngClass]="{formErrors:formErrors['tab1_agree']}">
            <input type="checkbox" id="tab1_agree" name="tab1_agree" formControlName="tab1_agree" (change)="form.patchValue({tab1_agree: true})" />상기 내용과 비고사항을 확인하였습니다.
          </label>
          <div *ngIf="formErrors['tab1_agree']" class="error-message">{{formErrors['tab1_agree']}}</div>
        </div>
      </div>


    </div>

    <div class="modal-footer clearfix">
      <button type="button" class="btn1" (click)="activeModal.dismiss()">취소</button>
      <button type="submit" class="btn1 blue">{{btnName}}</button>
    </div>
  </form>
</div>




