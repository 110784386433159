import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RestfulService } from '@app/service/restful.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public isSetup: boolean = false;

  constructor(
    private restful: RestfulService,
    private domSanitizer: DomSanitizer,
  ) {
  }

  getInitCMS():  Promise<any> {
    return this.restful.get({
        program: 'o2ozcms',
        service: 'common',
        version: '1.0',
        action: 'getInitCMS'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  getMenu(seq: any):  Promise<any> {
    return this.restful.get({
        program: 'o2ozcms',
        service: 'common',
        version: '1.0',
        action: 'getMenu'
      }, {
        seq: seq
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 메뉴 리스트 가져오기
  getMenuList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'common',
        version: '1.0',
        action: 'getMenuList'
      }, {
      }
    ).then( response => {
      let menuList: any = [];

      if( response['ResultCode'] == true ) {
        for( let key in response.data ) {
          if( response.data.hasOwnProperty(key) ) {
            menuList.push( response.data[key] );
          }
        }
      }

      response.data = menuList;

      return response || [];
    }).catch(this.restful.handleErrorPromise);
  }

  // 게시판 정보 리스트 가져오기
  getBoardInfoList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getBoardInfoList'
      }, {}
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 공통코드 리스트 가져오기
  getCommonListCode( code: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'commoncode',
        version: '1.0',
        action: 'getCommonListCode'
      }, {
        code: code
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 게시판 리스트 가져오기
  getBoardList( params ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'board',
        version: '1.0',
        action: 'getBoardList'
      }, params
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 게시글 정보 가져오기
  getBoardView(board_id, board_seq, view_check ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'board',
        version: '1.0',
        action: 'getBoardView'
      }, {
        board_id: board_id,
        board_seq: board_seq,
        view_check: view_check
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 로그인정보 가져오기
  getHomeMemberInfo(): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'getMember'
      }, {
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }
}
