import { Injectable } from '@angular/core';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class InspectionBasicService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 시험검사신청관리
  inspectionApplication(params: any): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'inspectionApplication'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 모델별 견적서 정보 가져오기
  getEstimateModelInfo(params: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'getEstimateModelInfo'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 모델별 견적서 정보 가져오기
  getEstimateItemBasicFee(params: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'getEstimateItemBasicFee'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }


  // 견적 총합 저장하기
  setEstimateTotal(params: any): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'setEstimateTotal'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 시험검사신청서 데이터 가져오기
  getInspectionApplicationList(search): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'getInspectionApplicationList'
      }, search
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 검사수수료마스터
  inspectionFeeMaster(params: any): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'inspectionFeeMaster'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  inspectionFeeSlave(params: any): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'inspectionFeeSlave'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 품목별시험검사항목 - 시험검사의뢰구분
  inspectionItemCategoryNew(params: any): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'inspectionItemCategoryNew'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 품목별시험검사항목 - 시험검사의뢰구분추가
  inspectionItemCategoryNewCreateDisplay(params: any): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'inspectionItemCategoryNewCreateDisplay'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 단체표준코드관리 - 단체표준코드
  spsCode(params: any): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'spsCode'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 단체표준코드관리 - 적용품목
  spsCodeItem(params: any): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'spsCodeItem'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 시험검사품목관리 가져오기
  getInspectionProductList(search): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getInspectionProductList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시험검사항목관리 가져오기
  getInspectionItemList(search): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getInspectionItemList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 품목별 검사항목 가져오기
  getInspectionItemCategory(search): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getInspectionItemCategory'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시험검사품목관리 추가/수정하기
  setInspectionProduct( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'setInspectionProduct'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시험검사품목관리 삭제하기
  setDeleteProduct(seq: any): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'inspectionbasic',
      version: '1.0',
      action: 'setDeleteProduct'
      }, {
        seq: seq
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 시험검사항목관리 추가/수정하기
  setInspectionCategory( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'setInspectionCategory'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시험검사항목관리 삭제하기
  setDeleteItemCategory(seq: any): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'inspectionbasic',
      version: '1.0',
      action: 'setDeleteItemCategory'
      }, {
        seq: seq
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 시험검사항목관리 순서 저장
  setSortOrderSave( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'setSortOrderSave'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 시험검사항목관리 순서 저장
  setInspectionCategorySave( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'setInspectionCategorySave'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
     .catch(this.restful.handleErrorPromise);
  }

  // 시험검사 수수료관리 마스터 가져오기
  getFeeMaster(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getFeeMaster'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시험검사 수수료관리 마스터 추가/수정
  setFeeMasterSave( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'setFeeMasterSave'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시험검사 전항목수수료 가져오기
  getAllFeeList(search): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getAllFeeList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 시험검사 항목별수수료 가져오기
  getFeeCategory(search): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getFeeCategory'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공장 심사 항목 마스터 가져오기
  getInspectionMasterList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getInspectionMasterList'
      }, {}
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 실험실이용 신청서 리스트 가져오기
  getLabUseApplicationList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'getLabUseApplicationList'
      }, search
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 실험실이용 신청서 정보 가져오기
  getLabUseApplicationInfo( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'getLabUseApplicationInfo'
      }, {
        seq: seq
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }


  // 실험실 이용신청서 삭제하기
  deleteLabUseApplication(seq: any): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'inspectionApplication',
      version: '1.0',
      action: 'setDeleteLabUseApplication'
      }, {
        seq: seq
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 공장 심사 항목 마스터 가져오기
  getInspectionFactoryItemList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getInspectionFactoryItemList'
      }, search
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 공장 심사 항목 마스터 추가/수정
  setInspectionMaster( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'setInspectionMaster'
      }, form.value
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 공장 심사 항목 마스터 삭제
  delInspectionMaster( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'delInspectionMaster'
      }, {
        seq: seq
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 공장 심사 항목 추가/수정
  setInspectionItem( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'setInspectionItem'
      }, form.value
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 공장 심사 항목 마스터 삭제
  delInspectionItem( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'delInspectionItem'
      }, {
        seq: seq
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 공장 심사 항목 마스터 가져오기
  getSpsCheckItemList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getSpsCheckItemList'
      }, {}
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 사이즈 순서 저장
  setSortOrder( array: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'setSortOrder'
      }, {
        params: array
      }
    ).then(this.restful.extractData)
      .catch(this.restful.handleErrorPromise);
  }

  // 구비서류 점검표관리 삭제
  deleteCheckItem( seq: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'deleteCheckItem'
      }, {
        seq: seq
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 구비서류 점검표 추가/수정
  setCheckItem( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'setCheckItem'
      }, form.value
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 회사 정보 가져오기
  getCompanyInfo(seq: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getCompanyInfo'
      }, {seq: seq}
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 회사정보 수정
  setCompany( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'setCompany'
      }, form.value
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 표준 정보 가져오기
  getSpscodeList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getSpscodeList'
      }, {}
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 품목 정보 가져오기
  getInspectionItemCategoryList(seq): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getInspectionItemCategoryList'
      }, {seq: seq}
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 단체표준인증 신청서 가져오기
  getApplicationList( search ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getApplicationList'
      }, search
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 인증신청서 정보 가져오기
  getApplicationInfo(seq: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getApplicationInfo'
      }, {seq: seq}
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 인증신청서 삭제하기
  delApplicationList(seq: any): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'inspectionbasic',
      version: '1.0',
      action: 'delApplicationList'
      }, {
        seq: seq
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 인증서 구비서류 저장
  setAddApplicationFile( form: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'setAddApplicationFile'
      }, form.value
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 인증신청서 구비서류 가져오기
  getApplicationFile(seq: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getApplicationFile'
      }, {seq: seq}
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 인증신청서 구비서류 삭제하기
  delApplicationFile(seq: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'delApplicationFile'
      }, {seq: seq}
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 인증신청서 구비서류 검토리스트 가져오기
  getApplicationCheckList(seq: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getApplicationCheckList'
      }, {seq: seq}
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 구비서류 점검표 검토입력
  setApplicationCheckItem( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'setApplicationCheckItem'
      }, form.value
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 단체표준인증 견적서 등록
  setSpsApplicationEstimate( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'setSpsApplicationEstimate'
      }, form.value
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 견적서 정보 가져오기
  getApplicationEstimate(seq: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getApplicationEstimate'
      }, {seq: seq}
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

   // 시험검사신청서관리
   getInspectionNumbering(number_year: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'getInspectionNumbering'
      }, {number_year: number_year}
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

   // 시험검사 성정서 정보 수정
   setInspectionReport(form): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'setInspectionReport'
      }, form.value
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

   // 시험검사 성정서 정보 가져오기
   getInspectionReport(seq): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'getInspectionReport'
      }, {seq: seq}
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

   // 시험검사 성정서 정보 가져오기
   getInspectionPreview(seq): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'getInspectionPreview'
      }, {seq: seq}
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

   // 변경신청내역 정보 추가/수정
   setApplicationHistory(form): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'setApplicationHistory'
      }, form.value
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 변경신청내역 정보 가져오기
  getApplicationHistory(seq): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'getApplicationHistory'
      }, {seq: seq}
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 시험검사신청서 삭제하기 - 신청서에 포함된 모든 데이터 삭제
  deleteInspectionApplicationAllData(seq: any): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'inspectionApplication',
      version: '1.0',
      action: 'deleteInspectionApplicationAllData'
      }, {
        seq: seq
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }  

  // 시험검사품목관리 삭제하기
  setDeleteHistory(seq: any): Promise<any> {
    return this.restful.get({
      program: 'admin',
      service: 'inspectionApplication',
      version: '1.0',
      action: 'setDeleteHistory'
      }, {
        seq: seq
      }
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 모델별 견적 저장하기
  setEstimateCalc(params): Promise<any> {
  return this.restful.post({
      program: 'admin',
      service: 'inspectionApplication',
      version: '1.0',
      action: 'setEstimateCalc'
    }, params
  ).then(this.extractData)
  .catch(this.handleErrorPromise);
  }

  // 모델별 항목 재설정
  setEstimateModel(params): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'setEstimateModel'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
    }

  // 실별시험장비사용비용 정보 가져오기
  getInspectionDeviceList(search): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'getLabUseFeeList'
      }, search
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 실별시험장비사용비용 정보 추가/수정
  setLabUseFee( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'setLabUseFee'
      }, form.value
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  // 인증신청서 구비서류 삭제하기
  deleteLabUseFee(seq: any): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionbasic',
        version: '1.0',
        action: 'deleteLabUseFee'
      }, {seq: seq}
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

    // 실험실 리스트 가져오기
   getLabList(): Promise<any> {
      return this.restful.get({
          program: 'admin',
          service: 'inspectionbasic',
          version: '1.0',
          action: 'getLabList'
        }, {}
      ).then(this.extractData)
        .catch(this.handleErrorPromise);
    }

   // 실험실 사용신청서 등록 수정
   setLabUseApplication(params): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'setLabUseApplication'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 실험실 사용신청서 등록 수정
  setLabUseEstimate(params): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'setLabUseEstimate'
      }, params
    ).then(this.extractData)
    .catch(this.handleErrorPromise);
  }

  // 시험비용 견적서 출력
  getApplicationEstimateInfo(application_seq): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'getApplicationEstimateInfo'
      }, {application_seq}
    ).then(this.extractData)
      .catch(this.handleErrorPromise);
  }

  getApplicationComplain( params: any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'inspectionApplication',
        version: '1.0',
        action: 'getApplicationComplain'
      }, params
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }


}
