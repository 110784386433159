<section class="application-modal">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <header class="modal-header">
      <div class="modal-title">불만처리 (건의사항) 신청</div>
      <button type="button" class="close" (click)="activeModal.dismiss()"></button>
    </header>

    <section class="modal-body">
      <div class="section">
        <div class="section-title">신청업체 정보</div>
        <div class="guide-txt">신청업체 정보는 현재 화면에서 직접 수정 가능합니다.</div>
        <table class="table1">
          <colgroup>
            <col class="col1">
            <col class="col2">
            <col class="col3">
            <col class="col4">
            <col class="col5">
            <col class="col6">
          </colgroup>

          <tbody>
          <tr>
            <th><label for="companyName"><span class="red">*</span>업체명</label></th>
            <td>
              <input type="text" id="companyName" name="companyName" formControlName="companyName"[autofocus]="true" [ngClass]="{formErrors:formErrors['companyName']}" />
              <div *ngIf="formErrors['companyName']" class="error-message">{{formErrors['companyName']}}</div>
            </td>
            <th><label for="ceoName">대표자명</label></th>
            <td>
              <input type="text" id="ceoName" name="ceoName" formControlName="ceoName" [ngClass]="{formErrors:formErrors['ceoName']}" />
              <div *ngIf="formErrors['ceoName']" class="error-message">{{formErrors['ceoName']}}</div>
            </td>
            <th><label for="businessNo"><span class="red">*</span>사업자번호</label></th>
            <td>
              <input type="text" id="businessNo" name="businessNo" formControlName="businessNo" [ngClass]="{formErrors:formErrors['businessNo']}" />
              <div *ngIf="formErrors['businessNo']" class="error-message">{{formErrors['businessNo']}}</div>
            </td>
          </tr>
          <tr>
            <th><label for="zipCode">주소</label></th>
            <td colspan="3">
              <input type="text" id="zipCode" name="zipCode" formControlName="businessNo" style="width:95px" [ngClass]="{formErrors:formErrors['zipCode']}" />
              <div *ngIf="formErrors['zipcode']" class="error-message">{{formErrors['zipcode']}}</div>

              <button class="btn1" style="width: 75px">우편번호찾기</button>

              <input type="text" style="width:374px" id="address" formControlName="address" [ngClass]="{formErrors:formErrors['address']}" />
              <div *ngIf="formErrors['address']" class="error-message">{{formErrors['address']}}</div>
            </td>
            <th><label for="tel">전화번호</label></th>
            <td>
              <input type="text" id="tel" formControlName="tel" [ngClass]="{formErrors:formErrors['tel']}" />
              <div *ngIf="formErrors['tel']" class="error-message">{{formErrors['tel']}}</div>
            </td>
          </tr>
          <tr>
            <th><label for="fax">팩스</label></th>
            <td>
              <input type="text" id="fax" formControlName="fax" [ngClass]="{formErrors:formErrors['fax']}" />
              <div *ngIf="formErrors['fax']" class="error-message">{{formErrors['fax']}}</div>
            </td>
            <th><label for="businessType">업태</label></th>
            <td>
              <input type="text" id="businessType" formControlName="businessType" [ngClass]="{formErrors:formErrors['businessType']}" />
              <div *ngIf="formErrors['businessType']" class="error-message">{{formErrors['businessType']}}</div>
            </td>
            <th><label for="businessItem">종목</label></th>
            <td>
              <input type="text" id="businessItem" formControlName="businessItem" [ngClass]="{formErrors:formErrors['businessItem']}" />
              <div *ngIf="formErrors['businessItem']" class="error-message">{{formErrors['businessItem']}}</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="section">
        <div class="section-title"><span class="red">*</span>필수정보</div>
        <table class="table2">
          <colgroup>
            <col class="col1">
            <col class="col2">
            <col class="col3">
            <col class="col4">
            <col class="col5">
            <col class="col6">
            <col class="col7">
            <col class="col8">
          </colgroup>

          <tbody>
          <tr>
            <th><label for="applicationDate"><span class="red">*</span>신청일자</label></th>
            <td>
              <input type="date" id="applicationDate" formControlName="applicationDate" [ngClass]="{formErrors:formErrors['applicationDate']}" />
              <div *ngIf="formErrors['applicationDate']" class="error-message">{{formErrors['applicationDate']}}</div>
            </td>
            <th><label for="applicationName"><span class="red">*</span>신청자</label></th>
            <td>
              <input type="text" id="applicationName" formControlName="applicationName" [ngClass]="{formErrors:formErrors['applicationName']}" />
              <div *ngIf="formErrors['applicationName']" class="error-message">{{formErrors['applicationName']}}</div>
            </td>
            <th><label for="hp"><span class="red">*</span>연락처</label></th>
            <td>
              <input type="text" id="hp" formControlName="hp" [ngClass]="{formErrors:formErrors['hp']}" />
              <div *ngIf="formErrors['hp']" class="error-message">{{formErrors['hp']}}</div>
            </td>
            <th><label for="email">이메일</label></th>
            <td>
              <input type="text" id="email" formControlName="email" [ngClass]="{formErrors:formErrors['email']}" />
              <div *ngIf="formErrors['email']" class="error-message">{{formErrors['email']}}</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="section">
        <div class="div1">
          <div class="section-title"><span class="red">*</span>불만내용(건의사항)</div>
          <textarea id="contents" name="contents" formControlName="contents" [value]="form.value.contents" (change)="form.patchValue({contents: $event.target.value})" [ngClass]="{formErrors:formErrors['contents']}" placeholder="내용을 작성해 주세요."></textarea>
          <div *ngIf="formErrors['contents']" class="error-message">{{formErrors['contents']}}</div>
        </div>
        <div class="div1">
          <div class="section-title">시정요구사항</div>
          <textarea id="correctionRequest" name="correctionRequest" formControlName="correctionRequest" [value]="form.value.correctionRequest" (change)="form.patchValue({correctionRequest: $event.target.value})" [ngClass]="{formErrors:formErrors['correctionRequest']}" placeholder="내용을 작성해 주세요."></textarea>
          <div *ngIf="formErrors['correctionRequest']" class="error-message">{{formErrors['correctionRequest']}}</div>
        </div>
        <div class="div2">
          <div class="section-title">비고(논의사항)</div>
          <textarea id="memo" name="memo" formControlName="memo" [value]="form.value.memo" (change)="form.patchValue({memo: $event.target.value})" [ngClass]="{formErrors:formErrors['memo']}" placeholder="논의사항을 간단히 기술해 주십시요."></textarea>
          <div *ngIf="formErrors['memo']" class="error-message">{{formErrors['memo']}}</div>
        </div>
      </div>
      <div class="check">
        <label [ngClass]="{formErrors:formErrors['agree']}">
          <input type="checkbox" id="agree" name="agree" formControlName="agree" (change)="form.patchValue({agree: true})" />위의 내용에 대하여 시정을 요구(건의)하오니 조치하여 주시기 바랍니다.
        </label>
        <div *ngIf="formErrors['tab3_agree']" class="error-message">{{formErrors['tab3_agree']}}</div>
      </div>
    </section>

    <footer class="modal-footer clearfix">
      <button type="button" class="btn1" (click)="activeModal.dismiss()">취소</button>
      <button type="submit" class="btn1 blue">신청</button>
    </footer>
  </form>
</section>


