<div class="trade-statistics">
  <div class="page-title">무역통계</div>
  <div class="page-title-bar"></div>

  <ul class="tab clearfix">
    <li [ngClass]="{active: tabIndex1 == '1'}" (click)="tabIndex1 = '1'">수출통계</li>
    <li [ngClass]="{active: tabIndex1 == '2'}" (click)="tabIndex1 = '2'">수입통계</li>
  </ul>

  <div class="section">
    <div class="section-title">
      <div *ngIf="tabIndex1 == '1'" class="title">수출통계 <span>(연도별)</span></div>
      <div *ngIf="tabIndex1 == '2'" class="title">수입통계 <span>(연도별)</span></div>
      <div class="unit">(단위 : 대/천불)</div>
    </div>
    <table class="list-table">
      <colgroup>
        <col style="width:16%;">
        <col span="4" style="width:21%;">
      </colgroup>
      <thead>
      <tr>
        <th>품목/년도</th>
        <th>가스조리기기<br/><span>(7321110000)</span></th>
        <th>가스난방기기<br/><span>(7321810000)</span></th>
        <th>가스보일러<br/><span>(8403103000)</span></th>
        <th>가스순간온수기<br/><span>(8419110000)</span></th>
      </tr>
      </thead>    
      <tbody *ngIf="tabIndex1 == '1'">
        <tr *ngFor="let list of list1">
          <td>{{list.year}}</td>
          <td>{{list.data1}} <span>({{list.data1_1}})</span></td>
          <td>{{list.data2}} <span>({{list.data2_1}})</span></td>
          <td>{{list.data3}} <span>({{list.data3_1}})</span></td>
          <td>{{list.data4}} <span>({{list.data4_1}})</span></td>
        </tr>
      </tbody>
      <tbody *ngIf="tabIndex1 == '2'">
        <tr *ngFor="let list of list2">
          <td>{{list.year}}</td>
          <td>{{list.data1}} <span>({{list.data1_1}})</span></td>
          <td>{{list.data2}} <span>({{list.data2_1}})</span></td>
          <td>{{list.data3}} <span>({{list.data3_1}})</span></td>
          <td>{{list.data4}} <span>({{list.data4_1}})</span></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>