/*******************************************************************************
  설  명 : 진흥회 가입신청
  작성자 : 
  작성일 : 
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { KeaaJoinApplicationComponent } from './keaa-join-application/keaa-join-application.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg', 
  centered: true,
  windowClass:'modal-900'
};

@Component({
  selector: 'app-keaa-join',
  templateUrl: './keaa-join.component.html',
  styleUrls: ['./keaa-join.component.scss']
})
export class KeaaJoinComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  public menu: any = {
    depth1_id: 2,
    depth2_id: 4,
    depth1_nm: '온라인 신청',
    depth2_nm: '진흥회 가입신청',
  };   

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  constructor(
    private modalService: NgbModal,
  ) { }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 진흥회 가입신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  keaaJoin() {
    const modalRef = this.modalService.open(KeaaJoinApplicationComponent, options);

    modalRef.result.then((result) => {
      if( result ) {
      }
    }, (reason) => {
    });
  }  

}
  