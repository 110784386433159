<div class="eatl-business6">
  <div class="page-title">가스보일러 실환경 실증실험실 구축</div>
  <div class="page-title-bar"></div>

  <div class="guide-txt">
    전용면적 25평규모의 실제 공동주택 사용환경 조성하여 실제사용상태와 유사한 환경조건을 제어할 수 있는 실증시험실에서<br class="pc-only" />
    실제 사용효율에 대한 다양한 인자(가스소비량, 압력, 유량, 전기소비량 등) 들에 대한 기술적 데이터 검증
  </div>

  <div class="section">
    <div class="title">실험실 구축 배경 및 필요성</div>
    <ul class="txt-area">
      <li>
        <p class="txt"><span></span><span>한국에너지기기산업진흥회는 최근 에너지자원기술개발사업 중 ‘초고효율 그린 콘덴싱가스보일러 개발’ 과제 일환으로 가스보일러의 사용패턴과 외부 환경조건에 따른 실제 사용환경과 유사한 조건에서 시험이 
          가능한 ‘가스보일러 실환경 실증시험실’을 구축</span></p>
      </li>
      <li>
        <p class="txt"><span></span><span>가스보일러의 사용패턴과 외부 환경조건에 따른 실제사용환경과 유사한 조건의 시험실을 통해 연간효율 및 초고효율보일러 개발과 성능평가를 위한 시험실 구축은 필수사항임</span></p>
        <p class="txt1">-  국내의 경우 아직까지 시험주택 개념은 물론 효율 개념도 시험실 효율이 통용되고 있어 한계가 있음</p>
      </li>
      <li>
        <p class="txt"><span></span><span>‘실환경 실증시험실’은 LH공사의 온돌난방에 관한 기준 및 설계지령 등 표준주택설계시방서를 따라 전용면적 25평 규모로 공동주택 실제 사용환경으로 구축됐으며 외부에서 실외온도를 조절할 수 있는 2중구조
          의 주택으로 기존 주택내부에 구축되었으며 일반적인 가정의 사용공간인 거실 1개, 방 3개, 욕실 등으로 구성</span></p>
        <p class="txt1">-  난방공간은 개별난방과 지역난방을 가정해 시험하는 것으로 했으며 바닥 난방은 기존에 많이 사용되고 있는 복사난방방식(습식)을 채택</p>          
        <p class="txt1">-  시험을 위해 온습도 등을 제어할 수 있는 환경조절시스템, 보일러 효율측정을 위한 제어 및 기록시스템 설치</p>          
      </li>            
    </ul>
  </div>    

  <div class="section">
    <div class="title">활용분야</div>
    <ul class="txt-area">
      <li>
        <p class="txt"><span></span><span>실제사용상태와 유사한 환경조건을 제어할 수 있는 실증시험실에서 실제 사용효율에 대한 다양한 인자(가스소비량, 압력, 유량, 전기소비량 등) 들에 대한 기술적 데이터 검증</span></p>
        <p class="txt"><span></span><span>보일러의 효율개선 및 CO2 배출지수 기준설정에 활용</span></p>
        <p class="txt"><span></span><span>유럽에서 가스보일러의 성능기술 등에 대한 국내 도입시 시행중인 건축물 성능지침(EPBD)을 기준으로 한 각종 기술기준 및 표준제정 등에 적용</span></p>
      </li>            
    </ul>
  </div>   

  <div class="section">
    <div class="title">주요시설</div>
    <ul class="txt-area">
      <li>
        <p class="txt"><span></span><span>전용면적 25평 규모의 시험실(3층)</span></p>
        <p class="txt"><span></span><span>온, 습도 등을 제어할 수 있는 환경조절시스템(1set)</span></p>
        <p class="txt"><span></span><span>보일러 효율측정을 위한 제어 및 기록 시스템(1set)</span></p>
      </li>            
    </ul>
    <div class="img clearfix">
      <div class="left-area">
        <div><img src="/assets/images/eatl_business601.png" alt="콘트롤 룸"><p>콘트롤 룸</p></div>
        <div><img src="/assets/images/eatl_business602.png" alt="환경제어 공조기"><p>환경제어 공조기</p></div>
        <div><img src="/assets/images/eatl_business603.png" alt="시스템 제어기기"><p>시스템 제어기기</p></div>
        <div><img src="/assets/images/eatl_business604.png" alt="내부전경"><p>내부전경</p></div>
        <div><img src="/assets/images/eatl_business605.png" alt="데이터 측정 모니터"><p>데이터 측정 모니터</p></div>
        <div><img src="/assets/images/eatl_business606.png" alt="각종 센서 및 감지기"><p>각종 센서 및 감지기</p></div>
      </div>
      <div class="right-area">
        <div><img src="/assets/images/eatl_business607.png" alt="테스트전경 "><p>테스트전경 </p></div>
      </div>
    </div>
  </div>    

</div>  
