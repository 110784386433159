import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-company-overview',
  templateUrl: './company-overview.component.html',
  styleUrls: ['./company-overview.component.scss']
})
export class CompanyOverviewComponent implements OnInit {
  
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  
  public menu: any = {
    depth1_id: 5,
    depth2_id: 2,
    depth1_nm: '진흥회 소개',
    depth2_nm: '진흥회 개요',
  }; 

  tabIndex = '1'; // tab순번
  
  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.tabIndex) {
        this.tabIndex = params.tabIndex;
      }      
    });  
  }

}
