<app-header [menuList$]="menuList$" [memberInfo$]="memberInfo$"></app-header>

<app-sub-header [currMenu]="currMenu$" [memberInfo]="memberInfo$"></app-sub-header>

<div class="member-introduce-page contents-page">
  <div class="inner-wrap">
    <div class="member-detail">
      <div class="title">상세정보</div>
      <table class="list-table">
        <colgroup>
          <col class="col1">
          <col class="col2">
          <col class="col1">
          <col class="col2">
        </colgroup>    
        <tbody>
        <tr>
          <td colspan="4">
            {{companyDetailList[0].com_kor}}
          </td>
        </tr>
        <tr>
          <th>회원사분류</th>
          <td>{{companyDetailList[0].common_name}}</td>
          <th>구분</th>
          <td></td>
        </tr>
        <tr>
          <th>대표자</th>
          <td colspan="3">{{companyDetailList[0].name_kor}}</td>
        </tr>
        <tr>
          <th rowspan="2">소재지1</th>
          <td rowspan="2">{{companyDetailList[0].address}}</td>
          <th>전화</th>
          <td>{{companyDetailList[0].tel}}</td>
        </tr>
        <tr>
          <th>팩스</th>
          <td>{{companyDetailList[0].fax}}</td>
        </tr>    
        <tr>
          <th rowspan="2">소재지2</th>
          <td rowspan="2">{{companyDetailList[0].address_factory}}</td>
          <th>전화</th>
          <td>{{companyDetailList[0].tel_factory}}</td>
        </tr>
        <tr>
          <th>팩스</th>
          <td>{{companyDetailList[0].fax_factory}}</td>
        </tr>
        <tr>
          <th>생산품목</th>
          <td colspan="3">{{companyDetailList[0].product_kor}}</td>
        </tr>
        <tr>
          <th>홈페이지</th>
          <td colspan="3">{{companyDetailList[0].web}}</td>
        </tr>        
        <tr>
          <th>A/S 안내</th>
          <td></td>
          <th>A/S센터<br class="mobile-only"> 홈페이지</th>
          <td></td>
        </tr>                    
        </tbody>
      </table>
      
      <div class="btn-area">
        <button class="list-btn" (click)="goList()">목록</button>  
      </div>
    </div>
  </div>
</div>

