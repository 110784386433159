<app-header [menuList$]="menuList$" [memberInfo$]="memberInfo$"></app-header>

<app-sub-header [currMenu]="currMenu$" [memberInfo]="memberInfo$"></app-sub-header>

<div class="board-page contents-page">

  <div class="board-view inner-wrap">
    <table class="list-table" *ngIf="!checkWrite">
      <colgroup>
        <col class="col1">
        <col/>
      </colgroup>    
      <tbody>
      <tr>
        <td colspan="2">
          <div class="title">{{data.subject}}<span *ngIf="boardInfo.board_id=='qna' && data.is_pw == '1'" class="lock"></span></div>
          <div *ngIf="boardInfo.board_id !=='qna'" class="date">{{data.write_date.substr(0, 10)}}</div>
          <div *ngIf="boardInfo.board_id=='qna'" class="date">{{data.write_date.substr(0, 10)}}<span class="bar"></span><span class="pc-only">작성자 : </span>{{data.writer_name}}<span class="bar"></span><span class="pc-only">이메일 : </span>{{data.email}}</div>
        </td>
      </tr>
      <tr>
        <td *ngIf="data.contents" class="contents" colspan="2" [innerHTML]="data.contents | safe : 'html'"></td>
      </tr>
      <tr *ngIf="boardInfo.upload_image_down=='1'">
        <th>첨부파일</th>
        <td class="file">
          <div *ngFor="let row of upload">
            <a [href]="baseURL + '/api/file/file_download.php?seq=' + row.seq" target="_blank">{{row.origin}}</a>
          </div>
        </td>
      </tr>
      <ng-container *ngIf="boardInfo.board_id=='qna'">
        <tr *ngFor="let row of commentList;">
          <th>답변</th>
          <td class="answer">
            {{row.contents}}
          </td>
        </tr>  
      </ng-container>  
      </tbody>
    </table>
    
    <div class="btn-area" *ngIf="!checkWrite">
      <div *ngIf="boardInfo.board_id=='qna' || boardInfo.board_id=='member_news' || boardInfo.board_id=='member_movements'" class="fr">
        <button class="edit-btn" (click)="write()">수정</button>  
        <button class="del-btn" (click)="delete()">삭제</button>  
      </div>    
      <button class="list-btn" (click)="goList()">목록</button>
    </div>
  </div>
</div>

<app-footer></app-footer>
