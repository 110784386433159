/*******************************************************************************
  설  명 : 아이디 찾기 결과
  작성자 : 
  작성일 : 
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { CommonService } from '@app/service/common.service';
import { HMemberService } from '@home/service/member.service';

import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';

@Component({
  selector: 'app-find-id-result',
  templateUrl: './find-id-result.component.html',
  styleUrls: ['./find-id-result.component.scss']
})
export class FindIdResultComponent extends O2OZCMS implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  params = {
    memNo: ''
  }

  userId = '';

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    router: Router,
    commonService: CommonService,
    activatedRoute: ActivatedRoute,
    toastrService: ToastrService,
    private memberService: HMemberService,

  ) {
    // 상위 클래스에 router 전달
    super(router, commonService, activatedRoute, toastrService);
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.memNo = ( typeof params.memNo == 'undefined' || params.memNo == '' ) ? 'notice' : params.memNo;

      this.getId();
    });
  }

  /*******************************************************************************
    설  명 : 아이디 가져오기
  *******************************************************************************/
  getId() {
    this.memberService.getId( this.params.memNo ).then( response => {
      if ( response.ResultCode ) {
        this.userId = response.data.userId;
      }
    });
  }
  
}
