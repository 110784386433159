<app-sub-header [menu]="menu"></app-sub-header>

<div class="equipment-page contents-page">
  <div class="inner-wrap">
    <div class="section">
      <div class="title">시험실 및 검사장비 규모</div>
      <ul class="txt-area">
        <li><span></span><span>보일러, 온수기 시험실 등 12개 시험실 보유</span></li>
        <li><span></span><span>낙뢰시험기 외 150종 578점 시험 및 검사장비 보유</span></li>
      </ul>

      <ul class="list clearfix">
        <li>
          <img src="/assets/images/equipment01.png" alt="보일러/온수기 시험실" class="pc-only">
          <div class="right-area">
            <div class="h2">보일러/온수기 시험실</div>
            <div class="txt">
              <p><span></span><span>보일러, 온수기 시험실 등 12개 시험실보유</span></p>
              <p><span></span><span>낙뢰시험기 외 150종 578점 시험 및 검사장비보유</span></p>
            </div>
          </div>
          <img src="/assets/images/equipment01m.png" alt="보일러/온수기 시험실" class="mobile-only">
        </li>
        <li>
          <img src="/assets/images/equipment02.png" alt="난방/조리기기 시험실" class="pc-only">
          <div class="right-area">
            <div class="h2">난방/조리기기 시험실 </div>
            <div class="txt">
              <p><span></span><span>가스레인지, 가스오븐레인지, 가스난로 등 가스난방 및 조리기기에 대한 열효율,가스소비량 등 성능시험</span></p>
              <p><span></span><span>방사시험기, 온도상승시험기, 가스소비량시험기 외 39종 141점</span></p>
            </div>
          </div>
          <img src="/assets/images/equipment02m.png" alt="난방/조리기기 시험실" class="mobile-only">
        </li>
        <li>
          <img src="/assets/images/equipment03.png" alt="부품/재료 시험실" class="pc-only">
          <div class="right-area">
            <div class="h2">부품/재료 시험실</div>
            <div class="txt">
              <p><span></span><span>가스기기의 부품 및 소재에 대한 내가스성, 내영성 등 재료시험실시</span></p>
              <p><span></span><span>항온항습조, 용량계수시험기, 인장시험기 외 38종 65점 </span></p>
            </div>
          </div>
          <img src="/assets/images/equipment03m.png" alt="부품/재료 시험실" class="mobile-only">
        </li>
        <li>
          <img src="/assets/images/equipment04.png" alt="내구력 시험실" class="pc-only">
          <div class="right-area">
            <div class="h2">내구력 시험실</div>
            <div class="txt">
              <p><span></span><span>가스기기의 제품 및 부품에 대한 내구력 시험</span></p>
              <p><span></span><span>소음측정실, 산소결핍시험기, 열충격시험기 외 17종 39점</span></p>
            </div>
          </div>
          <img src="/assets/images/equipment04m.png" alt="내구력 시험실" class="mobile-only">
        </li>
        <li>
          <img src="/assets/images/equipment05.png" alt="내식성 시험실" class="pc-only">
          <div class="right-area">
            <div class="h2">내식성 시험실</div>
            <div class="txt">
              <p><span></span><span>가스기기 소재 및 부품에대한내식성시험</span></p>
              <p><span></span><span>염수분무시험기</span></p>
            </div>
          </div>
          <img src="/assets/images/equipment05m.png" alt="내식성 시험실" class="mobile-only">
        </li>
        <li>
          <img src="/assets/images/equipment06.png" alt="진동 시험실" class="pc-only">
          <div class="right-area">
            <div class="h2">진동 시험실</div>
            <div class="txt">
              <p><span></span><span>가스연소기를 포장상태로 진동시험하여 가스통로의 기밀상태및  제품의 이상유무 확인 </span></p>
              <p><span></span><span>진동시험기, 진동측정기</span></p>
            </div>
          </div>
          <img src="/assets/images/equipment06m.png" alt="진동 시험실" class="mobile-only">
        </li>
        <li>
          <img src="/assets/images/equipment07.png" alt="유풍 시험실" class="pc-only">
          <div class="right-area">
            <div class="h2">유풍 시험실</div>
            <div class="txt">
              <p><span></span><span>가스기보일러 배기통에 다양한 풍속을 가하여 연소상태 및 량을 확인</span></p>
              <p><span></span><span>유풍시험기 외 4종 4점</span></p>
            </div>
          </div>
          <img src="/assets/images/equipment07m.png" alt="유풍 시험실" class="mobile-only">
        </li>
        <li>
          <img src="/assets/images/equipment08.png" alt="멀티 성능 시험실" class="pc-only">
          <div class="right-area">
            <div class="h2">멀티 성능 시험실</div>
            <div class="txt">
              <p><span></span><span>기름난로, 온풍기 등 연료소비량, 열출력, 연소상태 등 성능시험</span></p>
              <p><span></span><span>연소기구 배기가스 측정기, 방사계 센서, 발열량계 외 30종 80점</span></p>
            </div>
          </div>
          <img src="/assets/images/equipment08m.png" alt="멀티 성능 시험실" class="mobile-only">
        </li>
        <li>
          <img src="/assets/images/equipment09.png" alt="난방시스템 성능 시험실" class="pc-only">
          <div class="right-area">
            <div class="h2">난방시스템 성능 시험실</div>
            <div class="txt">
              <p><span></span><span>온수난방패널, 밸브 등 성능시험</span></p>
              <p><span></span><span>시스템 기록장치, 유량계, 온도센서 외 9종 64점</span></p>
            </div>
          </div>
          <img src="/assets/images/equipment09m.png" alt="난방시스템 성능 시험실" class="mobile-only">
        </li>
        <li>
          <img src="/assets/images/equipment10.png" alt="바이오매스연소기 시험실" class="pc-only">
          <div class="right-area">
            <div class="h2">바이오매스연소기 시험실</div>
            <div class="txt">
              <p><span></span><span>펠릿보일러, 펠릿난방기 등 성능시험</span></p>
              <p><span></span><span>Dust 측정기, THC 측정기, 전자저울외 19종 43점</span></p>
            </div>
          </div>
          <img src="/assets/images/equipment10m.png" alt="바이오매스연소기 시험실" class="mobile-only">
        </li>
        <li>
          <img src="/assets/images/equipment11.png" alt="열병합발전 성능 시험실" class="pc-only">
          <div class="right-area">
            <div class="h2">열병합발전 성능 시험실</div>
            <div class="txt">
              <p><span></span><span>삼중열병합시스템(CCHP) 성능시험</span></p>
              <p><span></span><span>CCHP 성능시험장치</span></p>
            </div>
          </div>
          <img src="/assets/images/equipment11m.png" alt="열병합발전 성능 시험실" class="mobile-only">
        </li>
        <li>
          <img src="/assets/images/equipment12.png" alt="실환경 시험실" class="pc-only">
          <div class="right-area">
            <div class="h2">실환경 시험실</div>
            <div class="txt">
              <p><span></span><span>보일러, 온수난방패널등 실주거환경에서 제품 성능평가</span></p>
              <p><span></span><span>실환경시험실 외 4종 6점</span></p>
            </div>
          </div>
          <img src="/assets/images/equipment12m.png" alt="실환경 시험실" class="mobile-only">
        </li>                                                                                        
      </ul>
    </div>
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>

