<app-header [menuList$]="menuList$" [memberInfo$]="memberInfo$"></app-header>

<app-sub-header [currMenu]="currMenu$" [memberInfo]="memberInfo$"></app-sub-header>

<form [formGroup]="form">
  <div class="join-page contents-page">
    <div class="inner-wrap">
      <ul class="tab clearfix">
        <li>01. 약관동의 및 본인인증</li>
        <li class="active">02. 정보입력</li>
        <li>03. 가입완료</li>
      </ul>

      <div class="join-step2">
        <div>
          <div class="table-title">가입자 정보</div>
          <div class="info"><span class="red">*</span> 표시는 필수입력사항입니다.</div>

          <table>
          <colgroup>
            <col class="col1">
            <col class="col2">
          </colgroup>

          <tbody>
          <tr>
            <th><span class="red">*</span>이름</th>
            <td>
              <input type="text" required formControlName="userName" [ngClass]="{formErrors:formErrors['userName']}" />
              <div *ngIf="formErrors['userName']" class="error-message">{{formErrors['userName']}}</div>
            </td>
          </tr>
          <tr>
            <th><span class="red">*</span>아이디</th>
            <td>
              <input type="text" class="input1" required formControlName="userId" [ngClass]="[formErrors['userId'] ? 'formErrors' : '', formErrors['idCheck'] ? 'formErrors' : '']" />
              <button class="btn1" (click)="checkIdDuplicate()">중복검색</button>
              <div *ngIf="formErrors['userId']" class="error-message">{{formErrors['userId']}}</div>
              <div *ngIf="formErrors['idCheck']" class="error-message" style="position: relative; top: -7px;">아이디 중복체크를 하시기 바랍니다.</div>
              <input type="hidden" formControlName="idCheck" pattern="{{checkTrue}}" />
            </td>
          </tr>
          <tr>
            <th><span class="red">*</span>비밀번호</th>
            <td>
              <input type="password" required formControlName="password" [ngClass]="{formErrors:formErrors['password']}" />
              <span class="guide-txt">( 6~20자의 영문 대소문자 및 숫자 조합 )</span>
              <div *ngIf="formErrors['password']" class="error-message">{{formErrors['password']}}</div>
            </td>
          </tr>
          <tr>
            <th><span class="red" >*</span>비밀번호 확인</th>
            <td>
              <input type="password" required formControlName="passwordConfirm" [ngClass]="{formErrors:formErrors['passwordConfirm']}" pattern="{{ this.form.get('password').value }}" />
              <div *ngIf="formErrors['passwordConfirm']" class="error-message">{{formErrors['passwordConfirm']}}</div>
            </td>
          </tr>
          <tr>
            <th><span class="red">*</span>연락처</th>
            <td>
              <input type="text" formControlName="mobile" maxlength="13" [ngClass]="{formErrors:formErrors['mobile']}" />
              <div *ngIf="formErrors['mobile']" class="error-message">필수 입력(선택)항목입니다.</div>
            </td>
          </tr>
          <tr>
            <th><span class="red">*</span>E-mail</th>
            <td class="email">
              <input type="text" formControlName="email1" [ngClass]="{formErrors:formErrors['email1']}" />
              <span class="between">@</span>
              <input type="text" formControlName="email2" [ngClass]="{'read-input': this.form.value.email3 != '', formErrors:formErrors['email2']}" [readonly]="( this.form.value.email3 != '' ) ? true : false" />
              <select class="ml5" (change)="setEmail($event)" formControlName="email3">
                <option value="">직접 입력</option>
                <option value="naver.com">naver.com</option>
                <option value="hanmail.net">hanmail.net</option>
                <option value="daum.net">daum.net</option>
              </select>
              <span class="guide-txt">※ 정확히 기재하셔야 답변을 받아보실 수 있습니다.</span>
              <div *ngIf="formErrors['email1'] || formErrors['email2']" class="error-message">필수 입력(선택)항목입니다.</div>
            </td>
          </tr>
          </tbody>
          </table>
          <div class="term-title">SMS 수신 동의 안내 (무료 서비스)</div>
          <div class="terms">
            <span [innerHtml]="smsAgree | safe : 'html'"></span>
          </div>
          <label class="check-txt"><input type="checkbox">SMS 수신에 동의합니다.</label>
        </div>

        <div class="company-info">
          <div class="table-title">회사 정보</div>
          <div class="info"><span class="red">*</span> 표시는 필수입력사항입니다.</div>

          <table>
          <colgroup>
            <col class="col1">
            <col class="col2">
          </colgroup>

          <tbody>
          <tr>
            <th><span class="red">*</span>회사명</th>
            <td>
              <input type="text" class="input1" required formControlName="companyName" [ngClass]="{formErrors:formErrors['companyName']}" />
              <button class="btn1" (click)="searchCompany()">회사검색</button>
              <div *ngIf="formErrors['companyName']" class="error-message">{{formErrors['companyName']}}</div>
            </td>
          </tr>
          <tr>
            <th><span class="red">*</span>대표자명</th>
            <td>
              <input type="text" required formControlName="ceoName" [ngClass]="{formErrors:formErrors['ceoName']}"/>
              <div *ngIf="formErrors['ceoName']" class="error-message">{{formErrors['ceoName']}}</div>
            </td>
          </tr>
          <tr>
            <th><span class="red">*</span>사업자번호</th>
            <td>
              <input type="text" required formControlName="comNumber" [ngClass]="{formErrors:formErrors['comNumber']}" />
              <!-- <span class="guide-txt">( - 없이 입력 )</span> -->
              <div *ngIf="formErrors['comNumber']" class="error-message">{{formErrors['comNumber']}}</div>
            </td>
          </tr>
          <tr>
            <th><span class="red">*</span>회사전화번호</th>
            <td>
              <input type="text" formControlName="tel" maxlength="13" [ngClass]="{formErrors:formErrors['tel']}" />
              <div *ngIf="formErrors['tel']" class="error-message">필수 입력(선택)항목입니다.</div>
            </td>
          </tr>
          <tr>
            <th><span class="red">*</span>E-mail</th>
            <td class="email">
              <input type="text" formControlName="comEmail1" [ngClass]="{formErrors:formErrors['comEmail1']}" />
              <span class="between">@</span>
              <input type="text" formControlName="comEmail2" [ngClass]="{'read-input': this.form.value.comEmail3 != '', formErrors:formErrors['comEmail2']}" [readonly]="( this.form.value.comEmail3 != '' ) ? true : false" />
              <select class="ml5" (change)="setEmail1($event)" formControlName="comEmail3">
                <option value="">직접 입력</option>
                <option value="naver.com">naver.com</option>
                <option value="hanmail.net">hanmail.net</option>
                <option value="daum.net">daum.net</option>
              </select>
              <span class="guide-txt">※ 정확히 기재하셔야 답변을 받아보실 수 있습니다.</span>
              <div *ngIf="formErrors['comEmail1'] || formErrors['comEmail2']" class="error-message">필수 입력(선택)항목입니다.</div>
            </td>
          </tr>
          <tr>
            <th>업태</th>
            <td>
              <input type="text" required formControlName="comDept" />
            </td>
          </tr>
          <tr>
            <th>업종</th>
            <td>
              <input type="text" required formControlName="comJob" />
            </td>
          </tr>
          <tr>
            <th><span class="red">*</span>회사주소</th>
            <td>
              <div class="zipcode">
                <input type="text" readonly formControlName="zipcode" [ngClass]="{formErrors:formErrors['zipcode']}"/>
                <btn-daum-address class="ml5" (result)="setDaumAddress1Api($event)" [options]="daumAddressOptions"></btn-daum-address>
              </div>
              <input type="text" class="addr mr5" formControlName="address" [ngClass]="{formErrors:formErrors['address']}"/>
              <input type="text" class="addr" formControlName="addressDetail" [ngClass]="{formErrors:formErrors['addressDetail']}"/>
              <div *ngIf="formErrors['zipcode'] || formErrors['address'] || formErrors['addressDetail']" class="error-message">필수 입력(선택)항목입니다.</div>
            </td>
          </tr>
          </tbody>
          </table>

          <div class="btn-area">
            <a class="join-btn cp" (click)="setMemberSave()">가입하기</a>
          </div>
        </div>
      </div>
    </div>
    <div class="bg1"></div>
    <div class="bg2"></div>
    <div class="bg3"></div>
  </div>
</form>

<app-footer></app-footer>
