<app-sub-header [menu]="menu"></app-sub-header>

<div class="policy-page contents-page">
  <div class="inner-wrap">
    <ul class="tab clearfix">
      <li [ngClass]="{active: tabIndex == '1'}" (click)="tabIndex = '1'">묻고 답하기</li>
      <li [ngClass]="{active: tabIndex == '2'}" (click)="tabIndex = '2'">건의제도</li>
      <li [ngClass]="{active: tabIndex == '3'}" (click)="tabIndex = '3'">회원사애로지원</li>
    </ul>

    <div class="policy-tab">
      <div class="page-title" *ngIf="tabIndex == '1'">묻고 답하기</div>
      <div class="page-title" *ngIf="tabIndex == '2'">건의제도</div>
      <div class="page-title" *ngIf="tabIndex == '3'">회원사애로지원</div>
      <div class="page-title-bar"></div>
    </div>  

    <app-board-list *ngIf="tabIndex == '1'" [boardInfo]="boardInfo"></app-board-list>
    <app-board-write *ngIf="tabIndex == '2'" [boardInfo]="boardInfo1"></app-board-write>
    <app-board-write *ngIf="tabIndex == '3'" [boardInfo]="boardInfo2"></app-board-write>

  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>

