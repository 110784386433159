import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  
  public menu: any = {
    depth1_id: 5,
    depth2_id: 3,
    depth1_nm: '진흥회 소개',
    depth2_nm: '정관',
  }; 

  constructor() { }

  ngOnInit(): void {
  }

}
