import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class BoardService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 게시판 정보 리스트 가져오기
  getBoardInfoList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getBoardInfoList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시판 정보 리스트 가져오기
  getBoardInfo(seq): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getBoardInfo'
      }, {
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시판 정보 리스트 가져오기
  getBoardInfoId(board_id): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getBoardInfoId'
      }, {
        board_id: board_id
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 정보 가져오기
  getBoardView(board_id, board_seq, view_check ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getBoardView'
      }, {
        board_id: board_id,
        board_seq: board_seq,
        view_check: view_check
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 댓글 리스트 가져오기
  getCommentList( params ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getCommentList'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시판 테이블 이름 리스트 가져오기
  getTableNameList( type: string ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getTableNameList'
      }, {
        type: type
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 캐리어 정보 저장하기
  setBoardInfo( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setBoardInfo'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 거래처 정보 삭제하기
  deleteBoardInfo( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'deleteBoardInfo'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시판 테이블 생성
  setBoardTableName( form ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setBoardTableName'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 저장
  setWrite( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setWrite'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 답변
  setReply( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setReply'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시판 리스트 가져오기
  getBoardList( params ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getBoardList'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 신고게시판 리스트 가져오기
  getReportList( params ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getReportList'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 삭제
  deleteBoard( board_id, board_seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'deleteBoard'
      }, {
        board_id: board_id,
        board_seq: board_seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 첨부파일 삭제
  deleteUploadFile( board_id, board_seq, seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'deleteUploadFile'
      }, {
        board_id: board_id,
        board_seq: board_seq,
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 추천
  setRecommend( board_id, board_seq, recommend_seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setRecommend'
      }, {
        board_id: board_id,
        board_seq: board_seq,
				recommend_seq: recommend_seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 반대
  setOpposite( board_id, board_seq, opposite_seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setOpposite'
      }, {
        board_id: board_id,
        board_seq: board_seq,
				opposite_seq: opposite_seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 코멘트 저장
  setWriteComment( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setWriteComment'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 코멘트 수정
  setEditComment( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setEditComment'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 코멘트 삭제
  deleteComment( board_id, seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'deleteComment'
      }, {
				board_id: board_id,
        seq: seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 댓글 추천
  setRecommendComment( board_id, board_seq, comment_seq, recommend_seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setRecommendComment'
      }, {
        board_id: board_id,
        board_seq: board_seq,
				comment_seq: comment_seq,
				recommend_seq: recommend_seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 댓글 반대
  setOppositeComment( board_id, board_seq, comment_seq, opposite_seq ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setOppositeComment'
      }, {
        board_id: board_id,
        board_seq: board_seq,
				comment_seq: comment_seq,
				opposite_seq: opposite_seq
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 신고하기
  setReport( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setReport'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 게시글 이동하기
  setMove( form ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'setMove'
      }, form.value
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // e카탈로그 카테고리 가져오기
  getECatalogCategory( step : any ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getECatalogCategory'
      }, {
        step: step
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // e카탈로그 회사 리스트 가져오기
  getCompanyList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getCompanyList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 매거진 회차 리스트 가져오기
  getMagazineList(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getMagazineList'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 공지사항 리스트 가져오기
  getBoardNotice(): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'board',
        version: '1.0',
        action: 'getBoardNotice'
      }, {}
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
}
