import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss']
})
export class EquipmentComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  
  public menu: any = {
    depth1_id: 1,
    depth2_id: 2,
    depth1_nm: '부설 에너지기기 시험원',
    depth2_nm: '보유설비',
  };  

  constructor() { }

  ngOnInit(): void {
  }

}
