<div class="organization">
  <div class="page-title">조직도</div>
  <div class="page-title-bar"></div>
  <div class="img">
  </div>
  <div class="mobile-only">
    <div class="dept dept1"><div>총회</div></div>
    <div class="dept dept2">이사회</div>
    <div class="dept dept2">회장</div>
    <div class="dept dept3">상근부회장</div>
    <div class="dept dept3">기획실</div>
    <div class="team">
      <div>기획관리팀</div>
      <div>
        사업계획 및 예산<br/>
        회의개최<br/>
        회원사관리<br/>
        KS 협의회 운영
      </div>
    </div>
    <div class="dept dept4-1"><p>부설기관</p>에너지기기시험원</div>
    <div class="dept dept4">기술인증실</div>
    <div class="team">
      <div>기술인증1팀 / 기술인증2팀</div>
      <div>
        KOLAS 시험검사<br/>
        형식 및 인증시험<br/>
        의뢰시험<br/>
        품질관리<br/>
        CE등 해외인증
      </div>
    </div>  
    <div class="dept dept4">표준개발실</div>
    <div class="team">
      <div>표준개발팀</div>
      <div>
        표준개발협력기관<br/>
        단체 표준 개발·등록<br/>
        가스관계법 제·개정<br/>
        기술행정지원<br/>
        연구용역
      </div>
    </div>   
    <div class="dept dept4">품질경영실</div>
    <div class="team">
      <div>품질경영팀</div>
      <div>
        KS인증 및 단체인증<br/>
        산업표준화법 제·개정<br/>
        기술관련 대정부<br/>
        신규사업 개발
      </div>
    </div>            
  </div>
</div>>

