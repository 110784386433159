import { Component, OnInit, Input} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

import { Globals } from '@app/service/globals.service';
import { config } from '@app/service/config.service';

import { BoardLockComponent } from '../board-lock/board-lock.component';
import { BoardService } from '@app/home/service/board.service';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg', 
  centered: true,
  windowClass:'modal-lock'
};

@Component({
  selector: 'app-board-list',
  templateUrl: './board-list.component.html',
  styleUrls: ['./board-list.component.scss']
})
export class BoardListComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  
  /*  board_id : board(공지사항), policy(정부시책), standard(국제표준화활동), 
      complain(민원접수 및 기업애로사항), qna(묻고 답하기)  */

  @Input() boardInfo: any;
  @Output() back = new EventEmitter();
  @Input() data: any = [];
  @Input() params: any = {};
  @Input() totalCount = 0;
  @Input() authCheck$: BehaviorSubject<boolean>;

  public isSelectOn: boolean = false;
  public isSelectTxt = '전체';

  public maxSize: any = 10;
  public baseURL = config.baseUrl;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private boardService: BoardService,
    private globals: Globals,
  ) { }

  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }
  
  /*******************************************************************************
    설  명 : 상세페이지로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goView(item) {
    if (this.boardInfo.board_id == 'qna' && item.is_pw == '1' ) {
      const modalRef = this.modalService.open(BoardLockComponent, options);

      modalRef.componentInstance.board_seq = item.board_seq;

      modalRef.result.then((result) => {
        if( result ) {
        }
      }, (reason) => {
        if (reason == 'OK') {
          this.params.board_seq = item.board_seq;
          this.router.navigate([ ('/board/view') ], { queryParams: { board_id: 'qna', board_seq: this.params.board_seq} });
          
        } else {
        }
      });
    } else {
      this.params.board_seq = item.board_seq;
      // this.router.navigate([ ('/board/view') ], { queryParams: { board_id: item.board_id, board_seq: this.board_seq} });
      this.router.navigate([ ('/board/view') ], { queryParams: this.params });
    }
  }

  /*******************************************************************************
    설  명 : 글쓰기페이지로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  write() {
    this.router.navigate([ ('/board/write') ], { queryParams: { board_id: this.params.board_id } });
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardList() {
    this.boardService.getBoardList( this.params ).then( response => {
      if( response.ResultCode ) {
        this.data = response.data;
        this.totalCount = response.total;
      } else {
        this.data = [];
        this.totalCount = 0;
      }

      this.globals.indicator.next(false);
      this.deleteParams();
    });
  }
  
  /*******************************************************************************
    설  명 : 페이지 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.params.no = page;

    // this.router.navigate(
    // [location.pathname],
    // {
    //   relativeTo: this.activatedRoute,
    //   queryParams: this.params,
    //   queryParamsHandling: '', // remove to replace all query params by provided
    // });
    this.getBoardList();
  }

  /*******************************************************************************
    설  명 : 검색 조건 클릭
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectSearchGroup( group ) {
    if ( group == '' ) {
      this.isSelectTxt = '전체'
    } else if ( group == 'subject' ) {
      this.isSelectTxt = '제목'
    } else if ( group == 'contents' ) {
      this.isSelectTxt = '내용'
    }

    this.params.searchGroup = group;
  }

}
