<app-sub-header [menu]="menu"></app-sub-header>

<div class="overview-page contents-page">
  <div class="inner-wrap">
    <ul class="tab clearfix">
      <li [ngClass]="{active: tabIndex == '1'}" (click)="tabIndex = '1'">버스 이용</li>
      <li [ngClass]="{active: tabIndex == '2'}" (click)="tabIndex = '2'">지하철 이용</li>
      <li [ngClass]="{active: tabIndex == '3'}" (click)="tabIndex = '3'">자가용 이용</li>
    </ul>

    <app-map-bus *ngIf="tabIndex == '1'"></app-map-bus>
    <app-map-subway *ngIf="tabIndex == '2'"></app-map-subway>
    <app-map-car *ngIf="tabIndex == '3'"></app-map-car>

  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>

