<div class="company-search">
  <div class="modal-header">
    <div class="modal-title">회사검색</div>
    <button type="button" class="close" (click)="activeModal.dismiss()"></button>
  </div>
  
    <div class="modal-body">
    
      <div class="search">
        <input type="text" [(ngModel)]="search" placeholder="회사명 또는 사업자번호" (keypress)="searchList($event)"/>
        <button class="btn1" (click)='getCompany()'>검색</button>
      </div>
      
      <table class="list-table">
      <colgroup>
        <col style="width:50%;">
        <col style="width:20%;">
        <col style="width:30%;">
      </colgroup>
      <thead>
        <tr>
          <th>회사명</th>
          <th>대표자</th>
          <th>사업자번호</th>
        </tr>
      </thead>
      <tbody>
      <tr *ngFor="let row of companyList;" (click)="activeModal.close(row)">
        <td>{{row.companyName}}</td>
        <td>{{row.ceoName}}</td>
        <td>{{row.comNumber}}</td>
      </tr>
      </tbody>
      </table>
    </div>
  
  <div class="modal-footer clearfix">
    <button class="btn1" (click)="activeModal.dismiss()">취소</button>  
  </div>
</div>
