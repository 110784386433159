import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-greetings',
  templateUrl: './greetings.component.html',
  styleUrls: ['./greetings.component.scss']
})
export class GreetingsComponent implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  
  public menu: any = {
    depth1_id: 5,
    depth2_id: 1,
    depth1_nm: '진흥회 소개',
    depth2_nm: '회장 인사말',
  }; 

  constructor() { }

  ngOnInit(): void {
  }

}
