<div class="standard2-modal">
  <div class="modal-header">
    <div class="modal-title">표준개정검토 요청서</div>
    <button type="button" class="close" (click)="activeModal.dismiss()"></button>
  </div>
  
  <div class="modal-body">
    <table>
      <colgroup>
        <col class="col1">
        <col class="col2">
        <col class="col3">
        <col class="col4">
        <col class="col5">        
      </colgroup>
      
      <tbody>
      <tr>
        <th colspan="2"><span class="red">*</span>표준의 구분</th>
        <td colspan="3">
          <label><input type="checkbox">KS 표준</label>
          <label><input type="checkbox">단체 표준</label>
        </td>
      </tr>
      <tr>
        <th rowspan="5">신청인</th>
        <th><span class="red">*</span>대표자명</th>
        <td><input type="text" required /></td>
        <th><span class="red">*</span>e-mail</th>
        <td><input type="text" required /></td>        
      </tr>
      <tr>
        <th><span class="red">*</span>제조자/기관명</th>
        <td colspan="3"><input type="text" class="input1" required /></td>
      </tr>    
      <tr>
        <th><span class="red">*</span>주소</th>
        <td colspan="4"><input type="text" class="input1" required /></td>
      </tr>  
      <tr>
        <th><span class="red">*</span>전화번호</th>
        <td colspan="3" class="tel">
          <input type="text" />
          <span class="between">-</span>
          <input type="text" />
          <span class="between">-</span>
          <input type="text" />
        </td>
      </tr>                
      <tr>
        <th><span class="red">*</span>휴대폰</th>
        <td colspan="3" class="tel">
          <input type="text" />
          <span class="between">-</span>
          <input type="text" />
          <span class="between">-</span>
          <input type="text" />
        </td>
      </tr>     
      <tr>
        <th colspan="2"><span class="red">*</span>표준명 (표준번호)</th>
        <td colspan="3"><input type="text" required /></td>
      </tr>  
      <tr>
        <th colspan="2"><span class="red">*</span>개정 요청사유</th>
        <td colspan="3"><textarea></textarea></td>
      </tr>  
      <tr>
        <th colspan="2"><span class="red">*</span>첨부파일</th>
        <td colspan="3">
          <input type="text" required />
          <button class="btn1">찾아보기</button>
        </td>
      </tr>                 
      </tbody>
    </table>
  </div>
  
  <div class="modal-footer clearfix">
    <button class="btn1" (click)="activeModal.dismiss()">취소</button>  
    <button class="btn1 blue">신청</button>  
  </div>
</div>

