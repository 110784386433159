import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-eatl-business4',
  templateUrl: './eatl-business4.component.html',
  styleUrls: ['./eatl-business4.component.scss']
})
export class EatlBusiness4Component implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  
  tabIndex1 = '1'; // tab순번  

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.tabIndex1) {
        this.tabIndex1 = params.tabIndex1;
      }      
    });      
  }

}
