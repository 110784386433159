<form [formGroup]="form" class="h100" (ngSubmit)="submit()">
  <div class="modal-header">
    <h4 class="modal-title bold" id="modal-common-code">비밀번호 변경</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">&times;</button>
  </div>

  <div class="modal-body">
    <table>
      <colgroup>
        <col class="col1">
        <col class="col2">
      </colgroup>

      <tbody>
      <tr>
        <th><span class="red">*</span>비밀번호 변경</th>
        <td>
          <input type="password" required formControlName="password" [ngClass]="{formErrors:formErrors['password']}" autofocus="true" />
          <span class="guide-txt">( 6~20자의 영문 대소문자 및 숫자 조합 )</span>
          <div *ngIf="formErrors['password']" class="cb error-message">{{formErrors['password']}}</div>
        </td>
      </tr>
      <tr>
        <th><span class="red">*</span>비밀번호 확인</th>
        <td>
          <input type="password" required formControlName="passwordConfirm" [ngClass]="{formErrors:formErrors['passwordConfirm']}" pattern="{{ this.form.get('password').value }}" />
          <div *ngIf="formErrors['passwordConfirm']" class="error-message">{{formErrors['passwordConfirm']}}</div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <div class="fl w50p">

    </div>
    <div class="fr w50p right ml0 mr0">
      <button type="button" class="btn btn-default btn-small f15" aria-label="Close" (click)="activeModal.dismiss()">창닫기</button>
      <button type="submit" class="btn btn-primary btn-small f15 ml10">비밀번호 변경</button>
    </div>
    <div class="cb"></div>
  </div>
</form>
