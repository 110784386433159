<div class="history">
  <div class="page-title">연혁</div>
  <div class="page-title-bar"></div>
  <div class="guide-txt">
    한국에너지기기산업진흥회의 실험과 도전정신, 한해 한해 그 성장의 역사를 한눈에 보실 수 있습니다.<br/>
    기술력을 바탕으로 내수시장 확대뿐만아니라 국제경쟁력 확보를 통한 해외시장개척으로 국민경제발전에 기여하도록 노력하겠습니다.
  </div>
  <ul class="term clearfix" id="2010">
    <li><a href="/introduce/companyOverview#2010">현재 ~ 2010’s</a></li>
    <li><a href="/introduce/companyOverview#2000">2000’s</a></li>
    <li><a href="/introduce/companyOverview#1990">1990’s</a></li>
    <li><a href="/introduce/companyOverview#1980">1980’s</a></li>
  </ul>

  <ul class="history-list clearfix">
    <li>
      <div class="year">2017 ~</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">04</div>
          <div class="txt">
            <p><span></span><span>표준기술규제산업계 전문인력 양성</span></p>
            <p><span></span><span>민·관 협력체제 구축운영 업무협약</span></p>
          </div>
        </li>
      </ul>
    </li>
    <li>
      <div class="year">2016</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">01</div>
          <div class="txt">
            <p><span></span><span>자율안전확인대상 공산품 시험기관 지정 (국가기술표준원)</span></p>
          </div>
        </li>
      </ul>
    </li>  
    <li>
      <div class="year">2014</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">11</div>
          <div class="txt">
            <p><span></span><span>ISO/TC 291 간사기관 지정</span></p>
          </div>
        </li>
        <li>
          <div class="mon">09</div>
          <div class="txt">
            <p><span></span><span>한국도시가스협회 업무제휴 협약</span></p>
          </div>
        </li>
        <li>
          <div class="mon">02</div>
          <div class="txt">
            <p><span></span><span>일본가스석유기기공업회 MOU 체결</span></p>
          </div>
        </li>
      </ul>
    </li>  
    <li>
      <div class="year">2012</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">05</div>
          <div class="txt">
            <p><span></span><span>한국임업진흥원 MOU 체결</span></p>
          </div>
        </li>
        <li>
          <div class="mon">04</div>
          <div class="txt">
            <p><span></span><span>SZU CE인증(시험업무 대행)협약체결</span></p>
          </div>
        </li>
        <li>
          <div class="mon">01</div>
          <div class="txt">
            <p><span></span><span>신/재생에너지 설비 성능검사기관 지정</span></p>
          </div>
        </li>
      </ul>
    </li>   
    <li>
      <div class="year" id="2000">2010</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">04</div>
          <div class="txt">
            <p><span></span><span>한국건설생활환경시험연구원과 MOU체결</span></p>
          </div>
        </li>
      </ul>
    </li> 
    <li>
      <div class="year">2009</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">12</div>
          <div class="txt">
            <p><span></span><span>ISO/TC 244 간사기관 지정</span></p>
          </div>
        </li>
      </ul>
    </li> 
    <li>
      <div class="year">2008</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">03</div>
          <div class="txt">
            <p><span></span><span>한국에너지기기산업진흥회 명칭 변경</span></p>
            <p><span></span><span>부설 에너지기기시험원 설립</span></p>
          </div>
        </li>
      </ul>
    </li>   
    <li>
      <div class="year">2007</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">07</div>
          <div class="txt">
            <p><span></span><span>표준개발 협력기관 지정(기술표준원)</span></p>
          </div>
        </li>
      </ul>
    </li> 
    <li>
      <div class="year">2003</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">01</div>
          <div class="txt">
            <p><span></span><span>ISO/TC 161 간사기관 지정(기술표준원)</span></p>
          </div>
        </li>
      </ul>
    </li> 
    <li>
      <div class="year">2001</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">08</div>
          <div class="txt">
            <p><span></span><span>공인검사기관 인정(산업자원부)</span></p>
          </div>
        </li>
      </ul>
    </li>  
    <li>
      <div class="year" id="1990">2000</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">11</div>
          <div class="txt">
            <p><span></span><span>회관중공(가스석유기기회관)</span></p>
          </div>
        </li>
        <li>
          <div class="mon">09</div>
          <div class="txt">
            <p><span></span><span>고효율기자재 성능시험기관 지정(산업자원부)</span></p>
            <p><span></span><span>효율관리기자재 지정시험기관 지정(산업자원부)</span></p>
          </div>
        </li>        
      </ul>
    </li>     
    <li>
      <div class="year">1999</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">11</div>
          <div class="txt">
            <p><span></span><span>직업능력개발훈련과정 지정 (서울남부지방노동사무소)</span></p>
          </div>
        </li>
        <li>
          <div class="mon">05</div>
          <div class="txt">
            <p><span></span><span>화관기공(대지 420평, 건평 700평) - 경기도 안산시 장상동</span></p>
          </div>
        </li>        
      </ul>
    </li>
    <li>
      <div class="year">1998</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">09</div>
          <div class="txt">
            <p><span></span><span>가스기기산업 기술인력양성사업 계약체결(산업자원부)</span></p>
          </div>
        </li>
        <li>
          <div class="mon">07</div>
          <div class="txt">
            <p><span></span><span>산업표준화법 개정에 따라 KS지정심사기관 지정(국립기술품질원)(종전 *표준화능력평가기관* 및 *사후관리시험기관* 기능수행)</span></p>
            <p><span></span><span>KS인증 및 정기검사시 공장심사, 제품심사, 매년 KS품질시험</span></p>
          </div>
        </li>        
      </ul>
    </li>  
    <li>
      <div class="year">1995</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">06</div>
          <div class="txt">
            <p><span></span><span>계량 및 측정에 관한 법률에 의한 공인시험기관 지정(공업진흥청) / 가스기구 및 부품에 대한 성능시험 (국가공인 시험성적서 발급)</span></p>
          </div>
        </li>
        <li>
          <div class="mon">04</div>
          <div class="txt">
            <p><span></span><span>일본가스석유기기공업회(JGKA)와 업무협약 체결</span></p>
          </div>
        </li>        
      </ul>
    </li>  
    <li>
      <div class="year">1990</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">06</div>
          <div class="txt">
            <p><span></span><span>산업표준화법에 의한 표준화능력평가기관 지정 (공업진흥청) / 가스연소기기 LS표시허가 신청업체에 대한 공장심사 및 제품심사</span></p>
          </div>
        </li>
      </ul>
    </li> 
    <li>
      <div class="year" id="1980">1988</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">02</div>
          <div class="txt">
            <p><span></span><span>KS표시 가스연소기기 사후관리기관 지정 (공업진흥청)</span></p>
          </div>
        </li>
      </ul>
    </li> 
    <li>
      <div class="year">1986</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">09</div>
          <div class="txt">
            <p><span></span><span>공업발전법에 의한 사업자단체로 조직 변경 <특수법인></span></p>
          </div>
        </li>
        <li>
          <div class="mon">06</div>
          <div class="txt">
            <p><span></span><span>산업표준화법에 의한 가스연소기기 KS표시허가 업체협의회 구성 /  KS표시 가스연소기기 자율적 품질관리</span></p>
          </div>
        </li>        
      </ul>
    </li> 
    <li>
      <div class="year">1983</div>
      <ul class="list clearfix">
        <li>
          <div class="mon">11</div>
          <div class="txt">
            <p><span></span><span><사단법인> 한국가스석유기기협회 설립허가(상공부)</span></p>
          </div>
        </li>
        <li>
          <div class="mon">06</div>
          <div class="txt">
            <p><span></span><span>협회 창립</span></p>
          </div>
        </li>        
      </ul>
    </li>                                                          
  </ul>  
</div>