/*******************************************************************************
  설  명 : o2oz CMS 클래스
  작성자 : 송영석
  작성일 : 2020-04-24
*******************************************************************************/
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from '@app/service/common.service';
import { ToastrService } from 'ngx-toastr';

export class O2OZCMS {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public isAdminURI: boolean = false;
  public isAdminSeq: any = '0';
  public isAdminIndex: any = 0;

  // 사이트정보 리스트
  public siteList$: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  // array 구조의 원본 메뉴 리스트 - 트리 구성이 되어 있지 않음
  public menuArrayList$: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  // 트리 구조의 메뉴 리스트
  public menuList$: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  // 현재 URI 로 분석 한 페이지 정보
  public currMenu$: BehaviorSubject<{}> = new BehaviorSubject({});

  // 회원 로그인 정보
  public memberInfo$: BehaviorSubject<{}> = new BehaviorSubject({});

  // 관리자 로그인 정보
  public adminInfo$: BehaviorSubject<{}> = new BehaviorSubject({});

  // 권한 그룹 정보
  public authgroupList$: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

  // 해당 메뉴에 접속할 권한이 있는지 체크
  public authCheck$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public router: Router,
    public commonService: CommonService,
    public activatedRoute: ActivatedRoute,
    public toastrService: ToastrService
  ) {
    // 관리자 페이지 인지 분석
    if( location.pathname.indexOf('/admin') > -1 ) this.isAdminURI = true;
    else this.isAdminURI = false;

    // CMS 초기화
    this.initCMS();

    // 상단으로 스크롤
    router.events.subscribe((y: NavigationEnd) => {
      window.scrollTo(0, 0);
    })
  }

  /*******************************************************************************
    설  명 : 메인으로 이동
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  mainLink() {
    // 관리자 여부 분리
    let baseURL = ( this.isAdminURI ) ? '/admin' : '/';

    this.router.navigate( [ baseURL ], { relativeTo: this.activatedRoute, queryParams: {}, queryParamsHandling: '' });
  }

  /*******************************************************************************
    설  명 : CMS 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  async initCMS() {

    // 서버에서 데이터 로딩
    await this.commonService.getInitCMS().then( async response => {

      // 데이터 로딩 성공 시
      if( response.ResultCode ) {

        // 회원 로그인 정보
        await this.memberInfo$.next( response.member );

        // 관리자 로그인 정보
        await this.adminInfo$.next ( response.admin );

        // 권한 그룹 정보
        await this.authgroupList$.next( response.authgroup );

        // 사이트 구성
        await this.initSite( response.site );

        // 메뉴 트리 구성
        await this.initMenuList( response.menu );

      // 데이터 전송 실패 시 메인 페이지로 이동
      } else {
        this.mainLink();
      }

    // CMS 초기화 오류 발생 시 메인 페이지로 이동
    }).catch( error => {
      this.mainLink();

      console.error( error );
    });

  }

  /*******************************************************************************
    설  명 : URI 분석해서 현재 메뉴를 가져온다.
    입력값 : menuArrayList = 원본 메뉴 구조
    리턴값 : 없음
  *******************************************************************************/
  private checkCurrMenu( menu, path, queryString, menu_seq, board_id, group_id ) {
    for( let item of menu ) {
      // 파라미터가 없다면
      if( menu_seq == '' && board_id == '' && group_id == '' ) {
        // 링크 주소가 같을 경우
        if(
          ( item['link_url'] !== '/' && item['link_url'] !== '' && path.indexOf( item['link_url'] ) > -1 ) ||
          ( item['link_url'] == '/' && item['link_url'] == path )
        ) {
          // 쿼리가 없을 경우 리턴
          if( item['query_string'] == '' )
            return item;

          // 쿼리가 있을 경우 포함되어 있다면
          else if( queryString.indexOf( item['query_string'] ) > -1 )
            return item;
        }

      // 메뉴 번호가 넘어온 경우
      } else if( menu_seq !== '' ) {
        //메뉴 번호가 같다면
        if( item.seq == menu_seq ) return item;

      // 게시판인 경우
      } else if( board_id !== '' ) {
        // 게시판인 경우
        if( board_id == item['board_id'] ) {
          // if( group_id == '' ) return item;
          // else if( group_id == item['group_id'] ) return item;
          return item;
        }
      }
    }

  }

  private getCurrMenu( menuArrayList ) {
    let path = location.pathname;
    let queryString = location.search;

    this.activatedRoute.queryParams.subscribe( params => {
      let menu_seq = ( params.menu_seq ) ? params.menu_seq : '';
      let board_id = ( params.board_id ) ? params.board_id : '';
      let group_id = ( params.group_id ) ? params.group_id : '';
      let menu_item: any;

      for( let menu of menuArrayList ) {
        menu_item = this.checkCurrMenu( menu, path, queryString, menu_seq, board_id, group_id );

        if ( typeof menu_item !== 'undefined' ) break;
      }

      if ( typeof menu_item !== 'undefined' ) {
        let level: number = 0;
        let url;

        if( this.isAdminURI ) {
          this.adminInfo$.subscribe( member => {
            if( member !== null ) {
              level = parseInt(member['level'] || 0);
            }
          });

          url = (level === 0) ? '/admin/login' : '/admin/menu/list';
        } else {
          this.memberInfo$.subscribe( member => {
            if( member !== null ) {
              level = parseInt(member['level'] || 0);
            }
          });

          url = (level === 0) ? '/member/login' : '/main';
        }

        // 회원 레벨 검증해서 접속 가능한지 판별
        let msg = (level === 0) ? '회원 로그인이 필요합니다.' : '해당 페이지에 접속하기 위해서는 ' + menu_item.authgroup_name + ' 권한이 필요합니다.';

        if( ! ( parseInt(menu_item.authgroup_slevel) <= level && level <= parseInt(menu_item.authgroup_elevel) ) ) {
          this.toastrService.error( msg, '권한안내' );

          this.authCheck$.next( false );

          this.router.navigate(
          [url],
          {
            relativeTo: this.activatedRoute,
            queryParams: {},
            queryParamsHandling: '', // remove to replace all query params by provided
          });

        } else {
          this.currMenu$.next( menu_item );
        }
      }
    });
  }

  /*******************************************************************************
    설  명 : 메뉴 트리 노드를 만든다.
    입력값 : menuList = 사이트별 메뉴 구성 리스트
    리턴값 : treeNode
  *******************************************************************************/
  private treeNode( menuList ) {
    var node = [];
    var rootNodes = node;
    var parentNode = rootNodes;
    var prevLevel = 1;

    for (let item of menuList) {
      const depth = item.page_cd.length / 3;

      if (depth === 1) {
        item.parentNode = parentNode;
        item.children = [];
        node.push( item );
      } else {
        let check = false;
        const findNode = function findParents(list, page_cd) {
          for (const menu of list) {
            if (menu.page_cd === page_cd) {
              item.parentNode = menu;
              item.children = [];
              menu.children.push(item);
              check = true;
              return false;
            }
          }
          if (!check) {
            for (const raw of list) {
              if (raw.children.length > 0) {
                findParents(raw.children, page_cd);
              }
            }
          }
        };

        findNode(node, item.page_cd.substring(0, item.page_cd.length - 3));
      }
    }
    return node;
  }

  /*******************************************************************************
    설  명 : 메뉴 리스트 트리노드로 생성
    입력값 : menu = response.menu
    리턴값 : 없음
  *******************************************************************************/
  private initMenuList( menu ) {
    // 메뉴 초기화
    let menuList = [];
    for( let siteMenu of menu ) {
      let tmp = this.treeNode( siteMenu );

      menuList.push( tmp );
    }

    this.menuList$.next( menuList );
    this.menuArrayList$.next( menu );

    // 현재 페이지 정보 가져오기
    this.getCurrMenu( menu );
  }

  /*******************************************************************************
    설  명 : 서버에서 받은 site 리스트를 초기화 한다.
    입력값 : site = response.site
    리턴값 : 없음
  *******************************************************************************/
  private initSite( site ) {
    // 관리자 사이트 분석
    let i = 0;
    for( let item of site ) {
      if( item.site_code == '9999' ) {
        this.isAdminIndex = i;
        this.isAdminSeq = item.seq;
        break;
      }
      i++;
    }

    // 사이트 종류 담기
    this.siteList$.next( site );
  }
}
