<div class="application-result">
  <div class="header">
    <div class="title">시험 검사신청 조회</div>
    <div class="search-area clearfix">
      <div class="term">
        <div class="label">기간</div>
        <ul class="clearfix">
          <li *ngFor="let item of searchdate_group" [ngClass]="{active:search.searchdate_group==item.value}" (click)="onSearchDateClick(item.value)">{{item.title}}</li>
        </ul>
      </div>
      <div class="date">
        <input type="date" [(ngModel)]="search.application_date_start">
        <span class="between">~</span>
        <input type="date" [(ngModel)]="search.application_date_end">
      </div>
      <div class="state">
        <div class="label">신청상태</div>
        <select (change)="selectedStatus($event)">
          <option value="">전체</option>
          <option  *ngFor="let row of applicationStatusList;let i = index" [value]="row.common_code">{{row.common_name}}</option>
        </select>
      </div>
      <button class="search-btn" (click)="getMypageApplicationList()">검색</button>
    </div>
  </div>

  <div class="table-area">
    <table class="list-table">
      <colgroup>
        <col style="width: 8%"/>
        <col style="width: 10%"/>
        <col style="width: 6%"/>
        <col style="width: 7%"/>
        <col style="width: 12%"/>
        <col style="width: 12%"/>
        <col style="width: 13%"/>
        <col style="width: 6%"/>
        <col style="width: 16%"/>
        <col style="width: 8%"/>
      </colgroup>
      <thead>
      <tr>
        <th>신청일자</th>
        <th>신청종류</th>
        <th>접수번호</th>
        <th>인증구분</th>
        <th>품목</th>
        <th>모델</th>
        <th>의뢰구분</th>
        <th>신청상태</th>
        <th>확인</th>
        <th>수정 / 삭제</th>
      </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of applicationList;let i = index">
          <tr>
            <td>{{row.application_date}}</td>
            <td>{{row.common_name}}</td>
            <td>{{row.reception_number}}</td>
            <td>{{row.ACC_name}}</td>
            <td>{{row.item_name}}</td>
            <td>{{row.model_name}}</td>
            <td>{{row.IRG_name}}</td>
            <td>{{row.IAS_name}}</td>
            <td>
              <button *ngIf="row.isEastimate === '0'" class="btn1" (click)="printEstimate(row.seq)">견적서</button>
              <button *ngIf="row.isIssuance === '0'" class="btn1" (click)="getInspectionPreview(row.application_request_seq, row.report_type)">성적서</button>
            </td>
            <td>
              <button class="btn edit" (click)="applicationModal(row.IAT_code, row.seq)"></button>
              <button *ngIf="row.canUpdate === '1'" class="btn del"></button>
            </td>
          </tr>
        </ng-container>
        <tr *ngIf="applicationList.length < 1">
          <td colspan="4">내용이 없습니다</td>
        </tr>         
      </tbody>
    </table>
  </div>
  <div class="clearfix">
    <div class="pagination-area">
      <ngb-pagination
        [(page)]="search.pageNo"
        [pageSize]="search.pageRow"
        [maxSize]="10"
        [collectionSize]="totalCount"
        [boundaryLinks]="true"
        rotate="true"
        (pageChange)="loadPage($event)" 
        >
        <ng-template ngbPaginationFirst>&lt;&lt;</ng-template>
        <ng-template ngbPaginationLast>&gt;&gt;</ng-template>
        <ng-template ngbPaginationPrevious>&lt;</ng-template>
        <ng-template ngbPaginationNext>&gt;</ng-template>
      </ngb-pagination>
    </div>      

  </div>
</div>




