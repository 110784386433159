<div class="application-modal">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="modal-header">
      <div class="modal-title">온라인 시험 검사 신청</div>
      <button type="button" class="close" (click)="activeModal.dismiss()"></button>
    </div>

    <div class="modal-body">
      <div class="section">
        <div class="section-title">신청업체 정보</div>
        <div class="guide-txt">신청업체 정보는 현재 화면에서 직접 수정 가능합니다.</div>
        <table class="table1">
          <colgroup>
            <col class="col1">
            <col class="col2">
            <col class="col3">
            <col class="col4">
            <col class="col5">
            <col class="col6">
          </colgroup>

          <tbody>
          <tr>
            <th><label for="com_kor">업체명</label></th>
            <td>
              <input type="text" id="com_kor" formControlName="com_kor" [autofocus]="true" [ngClass]="{formErrors:formErrors['com_kor']}" />
              <div *ngIf="formErrors['com_kor']" class="error-message">{{formErrors['com_kor']}}</div>
            </td>
            <th><label for="name_kor">대표자명</label></th>
            <td>
              <input type="text" id="name_kor" formControlName="name_kor" [ngClass]="{formErrors:formErrors['name_kor']}" />
              <div *ngIf="formErrors['name_kor']" class="error-message">{{formErrors['name_kor']}}</div>
            </td>
            <th><label for="com_number">사업자번호</label></th>
            <td>
              <input type="text" id="com_number" formControlName="com_number" [ngClass]="{formErrors:formErrors['com_number']}" />
              <div *ngIf="formErrors['com_number']" class="error-message">{{formErrors['com_number']}}</div>
            </td>
          </tr>
          <tr>
            <th><label for="zipcode">주소</label></th>
            <td colspan="3">
              <input type="text" style="width:95px" id="zipcode" formControlName="zipcode" [ngClass]="{formErrors:formErrors['zipcode']}" />
              <div *ngIf="formErrors['zipcode']" class="error-message">{{formErrors['zipcode']}}</div>

              <button class="btn1" style="width: 75px">우편번호찾기</button>

              <input type="text" style="width:374px" id="com_address" formControlName="com_address" [ngClass]="{formErrors:formErrors['com_address']}" />
              <div *ngIf="formErrors['com_address']" class="error-message">{{formErrors['com_address']}}</div>
            </td>
            <th><label for="com_tel">전화번호</label></th>
            <td>
              <input type="text" id="com_tel" formControlName="com_tel" [ngClass]="{formErrors:formErrors['com_tel']}" />
              <div *ngIf="formErrors['com_tel']" class="error-message">{{formErrors['com_tel']}}</div>
            </td>
          </tr>
          <tr>
            <th><label for="com_fax">팩스</label></th>
            <td>
              <input type="text" id="com_fax" formControlName="com_fax" [ngClass]="{formErrors:formErrors['com_fax']}" />
              <div *ngIf="formErrors['com_fax']" class="error-message">{{formErrors['com_fax']}}</div>
            </td>
            <th><label for="job">업태</label></th>
            <td>
              <input type="text" id="job" formControlName="job" [ngClass]="{formErrors:formErrors['job']}" />
              <div *ngIf="formErrors['job']" class="error-message">{{formErrors['job']}}</div>
            </td>
            <th><label for="comJob">종목</label></th>
            <td>
              <input type="text" id="job1" formControlName="job1" [ngClass]="{formErrors:formErrors['job1']}" />
              <div *ngIf="formErrors['job1']" class="error-message">{{formErrors['job1']}}</div>
            </td>
          </tr>
          <tr>
            <th><span class="red">*</span>회원사구분</th>
            <td colspan="5">
              <label class="mr30">
                <input type="radio" disabled="true" name="com_gbn" id="com_gbn" [(ngModel)]="com_gbn" [value]="1020" [checked]="com_gbn == '1020'" (change)="form.patchValue({com_gbn: '1020'})" /> 일반회원
              </label>
              <label class="mr30">
                <input type="radio" disabled="true" name="com_gbn" id="com_gbn" [(ngModel)]="com_gbn" [value]="1010" [checked]="com_gbn == '1010'" (change)="form.patchValue({com_gbn: '1010'})" /> 협의회 회원
              </label>
              <label>
                <input type="radio" disabled="true" name="com_gbn" id="com_gbn" [(ngModel)]="com_gbn" [value]="9000" [checked]="com_gbn == '9000'" (change)="form.patchValue({com_gbn: '9000'})" /> 비회원
              </label>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="section">
        <div class="section-title"><span class="red">*</span>필수정보</div>
        <table class="table2">
          <colgroup>
            <col class="col1">
            <col class="col2">
            <col class="col3">
            <col class="col4">
            <col class="col5">
            <col class="col6">
            <col class="col7">
            <col class="col8">
          </colgroup>

          <tbody>
          <tr>
            <th><label for="application_date"><span class="red">*</span>신청일자</label></th>
            <td>
              <input type="date" id="application_date" formControlName="application_date" [ngClass]="{formErrors:formErrors['application_date']}" />
              <div *ngIf="formErrors['application_date']" class="error-message">{{formErrors['application_date']}}</div>
            </td>
            <th><label for="applicant_name"><span class="red">*</span>신청자</label></th>
            <td>
              <input type="text" id="applicant_name" formControlName="applicant_name" [ngClass]="{formErrors:formErrors['applicant_name']}" />
              <div *ngIf="formErrors['applicant_name']" class="error-message">{{formErrors['applicant_name']}}</div>
            </td>
            <th><label for="applicant_hp"><span class="red">*</span>연락처</label></th>
            <td>
              <input type="text" id="applicant_hp" formControlName="applicant_hp" [ngClass]="{formErrors:formErrors['applicant_hp']}" />
              <div *ngIf="formErrors['applicant_hp']" class="error-message">{{formErrors['applicant_hp']}}</div>
            </td>
            <th><span class="red">*</span>이메일</th>
            <td>
              <input type="text" id="applicant_email" formControlName="applicant_email" [ngClass]="{formErrors:formErrors['applicant_email']}" />
              <div *ngIf="formErrors['applicant_email']" class="error-message">{{formErrors['applicant_email']}}</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <ul class="tab">
        <li [ngClass]="{active: tabIndex==1}" (click)="tabIndex=1">시험·검사 신청</li>
        <!-- <li [ngClass]="{active: tabIndex==2}" (click)="tabIndex=2">실험실임대신청</li>
        <li [ngClass]="{active: tabIndex==3}" (click)="tabIndex=3">형식승인검사 신청</li>
        <li [ngClass]="{active: tabIndex==4}" (click)="tabIndex=4">안전확인시험검사 신청</li>-->>
      </ul>

      <!--
        <app-application-modal1 [(form)]="form" [formErrors]="formErrors"></app-application-modal1>
      -->

      <!--
      <mat-tab-group class="tab" [selectedIndex]="(tabIndex - 1)" (selectedIndexChange)="tabChange($event)">
        <mat-tab label="시험·검사 신청">
          <div class="pt10"></div>
          <app-application-modal1 [(form)]="form" [formErrors]="formErrors"></app-application-modal1>
        </mat-tab>
        <mat-tab label="실험실임대신청">
          <div class="pt10"></div>
          <app-application-modal2 [(form)]="form" [formErrors]="formErrors"></app-application-modal2>
        </mat-tab>
        <mat-tab label="형식승인검사 신청">
          <div class="pt10"></div>
          <app-application-modal3 [(form)]="form" [formErrors]="formErrors"></app-application-modal3>
        </mat-tab>
        <mat-tab label="안전확인시험검사 신청">
          <div class="pt10"></div>
          <app-application-modal4 [(form)]="form" [formErrors]="formErrors"></app-application-modal4>
        </mat-tab>
      </mat-tab-group>
      -->
    </div>

    <div class="modal-footer clearfix">
      <button class="btn1" (click)="activeModal.dismiss()">취소</button>
      <button type="submit" class="btn1 blue">신청</button>
    </div>
  </form>
</div>


