import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})
export class OnlineApplyService {

  constructor(
    private restful: RestfulService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorObservable (error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }

  private handleErrorPromise (error: Response | any) {
    console.error(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 온라인 성적서진위확인신청
  postReportCheck(params: any): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'onlineApply',
        version: '1.0',
        action: 'postReportCheck'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  getReportCheck(params: any = {}): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'onlineApply',
        version: '1.0',
        action: 'getReportCheck'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
  
  putReportCheck(params: any): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'onlineApply',
        version: '1.0',
        action: 'putReportCheck'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
  
  deleteReportCheck(params: any): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'onlineApply',
        version: '1.0',
        action: 'deleteReportCheck'
      }, params
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

}
