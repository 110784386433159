<div class="objective-page">
  <div class="page-title">설립목적</div>
  <div class="page-title-bar"></div>
  <div class="objective">
    <div class="txt-area">
      <div class="img"></div>
      <div class="txt">
        <span>정부의 에너지기기 발전시책에 부응</span>하여<br class="mobile-only"> 에너지기기산업의 발전기반 조성 및 관련기술의<br class="mobile-only"> 
        표준화에 관한 업무를 수행함으로서<br class="mobile-only"> <span>에너지기기 산업의 발전을 촉진</span>하고<br class="mobile-only"> 회원 상호간의 
        <span>복리증진</span>을 도모하기 위하여<br class="mobile-only"> 설립되었습니다.
      </div>
    </div>
  </div>
</div>

<div class="business-page clearfix">
  <div class="page-title1">주요사업</div>
  <div class="page-title-bar mobile-only"></div>
  <ul class="business-list">
    <li><span></span><span>에너지기기의 품질 및 생산기술 향상 등 경쟁력 제고를 위한 사업</span></li>
    <li><span></span><span>에너지기기 산업의 발전방향에 관한 조사 연구사업</span></li>
    <li><span></span><span>에너지기기의 안정성 확보를 위한 검사실시 및 계몽지도에 관한 사업</span></li>
    <li><span></span><span>공인시험기관(가스기구 및 부품에 대한 성능시험) 운영에 관한 사업</span></li>
    <li><span></span><span>KS표시인증 업체협의회 운영에 관한 사업</span></li>
    <li><span></span><span>에너지기기 기술인력양성교육에 관한 사업</span></li>
    <li><span></span><span>정부 및 관계기관으로부터 위탁받은 사업</span></li>
    <li><span></span><span>회원 상호간의 제반 이익증진에 관한 사업</span></li>
    <li><span></span><span>기타 필요한 부대사업</span></li>
  </ul>
</div>