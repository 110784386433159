<div class="trade-statistics">
  <div class="page-title">생산통계</div>
  <div class="page-title-bar"></div>

  <div class="section">
    <div class="section-title">
      <div class="title">가스연소기 주요품목 년도별 생산통계</div>
      <div class="unit">(단위 : 대)</div>
    </div>
    <table class="list-table">
      <colgroup>
        <col class="col1">
        <col span="15" style="width:calc((100% - 65px)/ 15)">
      </colgroup>
      <thead>
      <tr>
        <th rowspan="2">년도/품목</th>
        <th colspan="5">가스조리기기</th>
        <th colspan="5">가스레인지</th>
        <th colspan="5">가스오븐 레인지</th>
      </tr>
      <tr class="f12">
        <th>생산량</th>
        <th>출하량</th>
        <th>재고량</th>
        <th>내수량</th>
        <th>수출량</th>
        <th>생산량</th>
        <th>출하량</th>
        <th>재고량</th>
        <th>내수량</th>
        <th>수출량</th>
        <th>생산량</th>
        <th>출하량</th>
        <th>재고량</th>
        <th>내수량</th>
        <th>수출량</th>        
      </tr>      
      </thead>    
      <tbody>
        <tr>
          <td>1995</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>2,307,658</td>
          <td>2,440,103</td>
          <td>85,430</td>
          <td>2,418,581</td>
          <td>21,522</td>
          <td>142,045</td>
          <td>141,108</td>
          <td>15,263</td>
          <td>141,048</td>
          <td>60</td>          									
        </tr>
        <tr>
          <td>1996</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>2,284,115</td>
          <td>2,316,461</td>
          <td>141,909</td>
          <td>2,294,082</td>
          <td>22,379</td>
          <td>141,072</td>
          <td>1149,094</td>
          <td>10,235</td>
          <td>149,086</td>
          <td>8</td>          									
        </tr>     
      </tbody>
    </table>
    <div class="txt">출처: 통계청</div>
  </div>
</div>