import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  public menu: any = {
    depth1_id: 3,
    depth2_id: 5,
    depth1_nm: '고객서비스',
    depth2_nm: '정부시책',
  }; 

  public boardInfo: any = {
    board_id: 'policy'
  }; 

  tabIndex = '1'; // tab순번  

  constructor(
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.tabIndex) {
        this.tabIndex = params.tabIndex;
      }      
    });     
  }

}
