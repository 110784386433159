/*******************************************************************************
  설  명 : 마이페이지 - 검사신청/결과
  작성자 : 
  작성일 : 
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbCalendar, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MypageService } from '@app/home/service/mypage.service';
import { InspectionBasicService } from '@app/admin/service/inspectionbasic.service';
import * as moment from 'moment';

import { ApplicationModal1Component } from '@home/page/online-application/accredited-test/application-modal1/application-modal1.component';
import { ApplicationModal2Component } from '@home/page/online-application/accredited-test/application-modal2/application-modal2.component';
import { ApplicationModal3Component } from '@home/page/online-application/accredited-test/application-modal3/application-modal3.component';
import { ApplicationModal4Component } from '@home/page/online-application/safety-confirmation-test/application-modal4/application-modal4.component';

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-certification'
};

@Component({
  selector: 'app-application-result',
  templateUrl: './application-result.component.html',
  styleUrls: ['./application-result.component.scss']
})
export class ApplicationResultComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
    term = '0'; //기간조회

    public search: any = {
      pageNo: 1,
      pageRow: 10,      
      company_seq: '',
      application_date_start: '',
      application_date_end: '',
      application_status: '',
    };    

    public applicationList: any = [];
    public applicationStatusList: any = [];

    public totalCount: number = 0;

    public gradeList: any = [];

    searchdate_group: any = [
      {title: '오늘', value: 0},
      {title: '1주일', value: -7},
      {title: '1개월', value: -30},
      {title: '3개월', value: -90},
      {title: '6개월', value: -180},
      {title: '1년', value: -365}
    ];      
    
    constructor(
      toastrService: ToastrService,
      private calendar: NgbCalendar,
      private mypageService: MypageService,
      private modalService: NgbModal,
      private InspectionBasicService: InspectionBasicService
    ) { 

    }
  
    ngOnInit(): void {
      this.onSearchDateClick(-180);
      //this.search.application_date_end  = this.calendar.getToday();
      this.getApplicationStatus()
      this.getMypageApplicationList();
    }

  /*******************************************************************************
   설  명 : 시험 검사신청 조회
   입력값 : 없음
   리턴값 : 없음
   *******************************************************************************/
   getMypageApplicationList() {
    this.mypageService.getMypageApplicationList( this.search ).then( response => {
      if ( response.ResultCode ) {
        this.applicationList = response.data;
        this.totalCount = response.totalCnt;      
      } else {
        //this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
   설  명 : 신청상태리스트 
   입력값 : 없음
   리턴값 : 없음
   *******************************************************************************/
   getApplicationStatus() {
    this.mypageService.getApplicationStatus().then( response => {
      if ( response.ResultCode ) {
        this.applicationStatusList = response.data;
      } else {
        //this.toastrService.error(response.ResultMessage);
      }
    });
  }

  /*******************************************************************************
    설  명 : 신청상태 선택 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectedStatus(event) {
    this.search.application_status = event.target.value;
  }  

  /*******************************************************************************
  설  명 : 페이지 선택 처리
  입력값 : 없음
  리턴값 : 없음
  *******************************************************************************/
  loadPage( page ) {
    this.search.pageNo = page;

    this.getMypageApplicationList();
  }

  /*******************************************************************************
    설  명 : 검색 기간 버튼 클릭
    입력값 : 값
    리턴값 : 없음
  *******************************************************************************/
  onSearchDateClick( val ) {
    this.search.searchdate_group = val;
    
    this.search.application_date_end = moment().format("YYYY-MM-DD");
    this.search.application_date_start = moment().add(val, 'day').format("YYYY-MM-DD");
  }

  /*******************************************************************************
    설  명 : 견적서 출력
    입력값 :
    리턴값 :
  *******************************************************************************/
  printEstimate(application_seq) {

    const url = '/admin/inspection/application/estimate/print?seq=' + application_seq;

    window.open(url, '_blank');
  }

  /*******************************************************************************
    설  명 : 성적서 정보 불러오기
    입력값 :
    리턴값 : 
  *******************************************************************************/
  getInspectionPreview(seq, type) {
    this.InspectionBasicService.getInspectionPreview(seq).then( response => {
      if( response.ResultCode ) {
        this.gradeList = response.data[0];

        this.preview(type);
      }
    });
   }


  /*******************************************************************************
    설  명 : 견적서 출력
    입력값 :
    리턴값 :
  *******************************************************************************/
 
  preview(type) {
    
    if (!type) type = '1000';

    switch(type) {
      case '1000': this.printInpReportKor(this.gradeList); break;
      case '1010': this.printInpReportEng(this.gradeList); break;
      case '1030': this.printKeaaInpReportEng(this.gradeList); break;
      case '2000': this.printTestReportKor(this.gradeList); break;
      case '2010': this.printTestReportEng(this.gradeList); break;
      case '2020': this.printKeaaTestReportKor(this.gradeList); break;
      case '2030': this.printKeaaTestReportEng(this.gradeList); break;
      case '3000': this.printAppReportKor(this.gradeList); break;
      case '4000': this.printTestReportbKor(this.gradeList); break;
    }
  }
  /*******************************************************************************
    설  명 : 출력 - 검사성적서(한글)
    입력값 : 
    리턴값 : 
  *******************************************************************************/
  printInpReportKor(data) {
    const queryString = new URLSearchParams(data).toString();

    const url = '/admin/inspection/print/InpReportKor?home="Y"&' + queryString;

    window.open(url, '_blank');
  }

  /*******************************************************************************
    설  명 : 출력 - 검사성적서(영문)
    입력값 : 
    리턴값 : 
  *******************************************************************************/
  printInpReportEng(data) {
    const queryString = new URLSearchParams(data).toString();

    const url = '/admin/inspection/print/InpReportEng?home="Y"&' + queryString;

    window.open(url, '_blank');
  }

  /*******************************************************************************
    설  명 : 출력 - 형식승인성적서(한글)
    입력값 : 
    리턴값 : 
  *******************************************************************************/
  printAppReportKor(data) {
    const queryString = new URLSearchParams(data).toString();

    const url = '/admin/inspection/print/AppReportKor?home="Y"&' + queryString;
    
    window.open(url, '_blank');
  }

  /*******************************************************************************
    설  명 : 출력 - 시험성적서(한글)
    입력값 : 
    리턴값 : 
  *******************************************************************************/
  printTestReportKor(data) {
    const queryString = new URLSearchParams(data).toString();

    const url = '/admin/inspection/print/TestReportKor?home="Y"&' + queryString;

    window.open(url, '_blank');
  }

  /*******************************************************************************
    설  명 : 출력 - 시험성적서(영문)
    입력값 : 
    리턴값 : 
  *******************************************************************************/
  printTestReportEng(data) {
    const queryString = new URLSearchParams(data).toString();

    const url = '/admin/inspection/print/TestReportEng?home="Y"&' + queryString;

    window.open(url, '_blank');
  }
  /*******************************************************************************
    설  명 : 출력 - 시험성적서B(한글)
    입력값 : 
    리턴값 : 
  *******************************************************************************/
  printTestReportbKor(data) {
    const queryString = new URLSearchParams(data).toString();

    const url = '/admin/inspection/print/TestReportbKor?home="Y"&' + queryString;

    window.open(url, '_blank');
  }                            

  /*******************************************************************************
    설  명 : 출력 - Keaa 검사성적서(한글)
    입력값 : 
    리턴값 : 
  *******************************************************************************/
  printKeaaInpReportKor(data) {
    const queryString = new URLSearchParams(data).toString();

    const url = '/admin/inspection/print/KeaaInpReportKor?home="Y"&' + queryString;

    window.open(url, '_blank');
  }
  /*******************************************************************************
    설  명 : 출력 - Keaa 검사성적서(영문)
    입력값 : 
    리턴값 : 
  *******************************************************************************/
  printKeaaInpReportEng(data) {
    const queryString = new URLSearchParams(data).toString();

    const url = '/admin/inspection/print/KeaaInpReportEng?home="Y"&' + queryString;

    window.open(url, '_blank');
  }
  /*******************************************************************************
    설  명 : 출력 - Keaa 시험성적서(한글)
    입력값 : 
    리턴값 : 
  *******************************************************************************/
  printKeaaTestReportKor(data) {
    const queryString = new URLSearchParams(data).toString();

    const url = '/admin/inspection/print/KeaaTestReportKor?home="Y"&' + queryString;

    window.open(url, '_blank');
  }
  /*******************************************************************************
    설  명 : 출력 - Keaa 시험성적서(영문)
    입력값 : 
    리턴값 : 
  *******************************************************************************/
  printKeaaTestReportEng(data) {
    const queryString = new URLSearchParams(data).toString();

    const url = '/admin/inspection/print/KeaaTestReportEng?home="Y"&' + queryString;

    window.open(url, '_blank');
  }     

  /*******************************************************************************
    설  명 : 온라인 시험 검사신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  applicationModal(IAT_code: any, seq: any) {
    let modalRef = null;
    if( IAT_code === '1000' ) {
      modalRef = this.modalService.open(ApplicationModal1Component, optionsXL);
    } else if( IAT_code === '2000' ) {
      modalRef = this.modalService.open(ApplicationModal2Component, optionsXL);
    } else if( IAT_code === '3000' ) {
      modalRef = this.modalService.open(ApplicationModal3Component, optionsXL);
    } else if( IAT_code === '4000' ) {
      modalRef = this.modalService.open(ApplicationModal4Component, optionsXL);
    }

    // const modalRef = this.modalService.open(ApplicationModalComponent, optionsXL);
    
    modalRef.componentInstance.seq = seq;
    modalRef.componentInstance.IAT_code = IAT_code;

    modalRef.result.then((result) => {
      if( result ) {
      }
    }, (reason) => {
    });

  }

}