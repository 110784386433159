import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'site'
})
export class SitePipe implements PipeTransform {

  constructor() {}

  public transform(value: any, site_seq: any): any {

    for( let item of value ) {
      if( item[0].site_seq == site_seq ) {
        return item;
      }
    }

    return value;
  }
}