<footer class="footer">
  <div class="footer1">
    <div class="footer-inner clearfix">
      <ul class="clearfix">
        <li><a routerLink="/contents" [queryParams]="{menu_seq:826}">개인정보처리방침</a></li>
        <li><a routerLink="/contents" [queryParams]="{menu_seq:829}">이용약관</a></li>
        <li><a routerLink="/contents" [queryParams]="{menu_seq:871}">이메일무단수집거부</a></li>
      </ul>
      <div class="shortcut">
        <select (change)="goLink($event)">
          <option>정부기관 바로가기</option>
          <option value="http://www.motie.go.kr/www/main.do">산업통상자원부</option>
          <option value="http://www.smba.go.kr/">중소벤처기업부</option>
          <option value="http://www.kats.go.kr/">국가기술표준원</option>
          <option value="http://www.kipo.go.kr/">특허청</option>
          <option value="http://www.kostat.go.kr/">통계청</option>
        </select>   
        <select (change)="goLink($event)">
          <option>기관 바로가기</option>
          <option value="http://www.kemco.or.kr/">한국에너지공단</option>
          <option value="http://www.kgs.or.kr/">한국가스안전공사</option>
          <option value="http://www.ksa.or.kr/">한국표준협회</option>
          <option value="http://www.keei.re.kr/">에너지경제연구원</option>
          <option value="http://www.kier.re.kr/">한국에너지기술연구원</option>
        </select>
        <select onchange="if(this.selectedIndex) { this.blur(); window.open(options[selectedIndex].value); }">
          <option>단체 바로가기</option>
          <option value="http://www.citygas.or.kr/">한국도시가스협회</option>
          <option value="http://www.boiler.or.kr/">한국열관리시공협회</option>
          <option value="http://www.nanbang.or.kr/">전국보일러설비협회</option>
          <option value="http://www.ref.or.kr/">한국냉동공조산업협회</option>
          <option value="http://www.kccmic.or.kr/">한국조리기계공업협동조합</option>
        </select>      
      </div>
    </div>
  </div>
  <div class="footer2">
    <div class="footer-inner clearfix">
      <div class="logo">
        <a routerLink="/main">한국에너지기기산업진흥회</a>
      </div>    
      <div class="com-info">
        <div class="address">
          경기도 안산시 상록구 장하로 300 (장상동535-4)<span class="pc-only"></span>TEL :  031.480.2981~5<span></span>FAX :  031.480.2980,2986
        </div>
        <div class="copyright">Copyright ⓒ 2021  Korea Engergy Applications Industry Assiciation.  <br class="mobile-only" />All Rights Reserved.</div>
      </div>
    </div>
  </div> 
</footer>

