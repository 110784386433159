<div class="application-modal">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="modal-header">
      <div class="modal-title">온라인 시험 검사 신청</div>
      <button type="button" class="close" (click)="activeModal.dismiss()"></button>
    </div>

    <div class="modal-body">
      <div class="section">
        <div class="section-title">신청업체 정보</div>
        <div class="guide-txt">신청업체 정보는 현재 화면에서 직접 수정 가능합니다.</div>
        <table class="table1">
          <colgroup>
            <col class="col1">
            <col class="col2">
            <col class="col3">
            <col class="col4">
            <col class="col5">
            <col class="col6">
          </colgroup>

          <tbody>
          <tr>
            <th><label for="company_name">업체명</label></th>
            <td>
              <input type="text" id="company_name" formControlName="company_name" [autofocus]="true" [ngClass]="{formErrors:formErrors['company_name']}" />
              <div *ngIf="formErrors['company_name']" class="error-message">{{formErrors['company_name']}}</div>
            </td>
            <th><label for="name_kor">대표자명</label></th>
            <td>
              <input type="text" id="name_kor" formControlName="name_kor" [ngClass]="{formErrors:formErrors['name_kor']}" />
              <div *ngIf="formErrors['name_kor']" class="error-message">{{formErrors['name_kor']}}</div>
            </td>
            <th><label for="com_number">사업자번호</label></th>
            <td>
              <input type="text" id="com_number" formControlName="com_number" [ngClass]="{formErrors:formErrors['com_number']}" />
              <div *ngIf="formErrors['com_number']" class="error-message">{{formErrors['com_number']}}</div>
            </td>
          </tr>
          <tr>
            <th><label for="zipcode">주소</label></th>
            <td colspan="3">
              <input type="text" style="width:95px" id="zipcode" formControlName="zipcode" [ngClass]="{formErrors:formErrors['zipcode']}" />
              <div *ngIf="formErrors['zipcode']" class="error-message">{{formErrors['zipcode']}}</div>

              <button class="btn1" style="width: 75px">우편번호찾기</button>

              <input type="text" style="width:374px" id="com_address" formControlName="com_address" [ngClass]="{formErrors:formErrors['com_address']}" />
              <div *ngIf="formErrors['com_address']" class="error-message">{{formErrors['com_address']}}</div>
            </td>
            <th><label for="com_tel">전화번호</label></th>
            <td>
              <input type="text" id="com_tel" formControlName="com_tel" [ngClass]="{formErrors:formErrors['com_tel']}" />
              <div *ngIf="formErrors['com_tel']" class="error-message">{{formErrors['com_tel']}}</div>
            </td>
          </tr>
          <tr>
            <th><label for="com_fax">팩스</label></th>
            <td>
              <input type="text" id="com_fax" formControlName="com_fax" [ngClass]="{formErrors:formErrors['com_fax']}" />
              <div *ngIf="formErrors['com_fax']" class="error-message">{{formErrors['com_fax']}}</div>
            </td>
            <th><label for="job">업태</label></th>
            <td>
              <input type="text" id="job" formControlName="job" [ngClass]="{formErrors:formErrors['job']}" />
              <div *ngIf="formErrors['job']" class="error-message">{{formErrors['job']}}</div>
            </td>
            <th><label for="comJob">종목</label></th>
            <td>
              <input type="text" id="job1" formControlName="job1" [ngClass]="{formErrors:formErrors['job1']}" />
              <div *ngIf="formErrors['job1']" class="error-message">{{formErrors['job1']}}</div>
            </td>
          </tr>
          <tr>
            <th><span class="red">*</span>회원사구분</th>
            <td colspan="5">
              <label class="mr30">
                <input type="radio" disabled="true" name="com_gbn" id="com_gbn" [(ngModel)]="com_gbn" [value]="1020" [checked]="com_gbn == '1020'" (change)="form.patchValue({com_gbn: '1020'})" /> 일반회원
              </label>
              <label class="mr30">
                <input type="radio" disabled="true" name="com_gbn" id="com_gbn" [(ngModel)]="com_gbn" [value]="1010" [checked]="com_gbn == '1010'" (change)="form.patchValue({com_gbn: '1010'})" /> 협의회 회원
              </label>
              <label>
                <input type="radio" disabled="true" name="com_gbn" id="com_gbn" [(ngModel)]="com_gbn" [value]="9000" [checked]="com_gbn == '9000'" (change)="form.patchValue({com_gbn: '9000'})" /> 비회원
              </label>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="section">
        <div class="section-title"><span class="red">*</span>필수정보</div>
        <table class="table2">
          <colgroup>
            <col class="col1">
            <col class="col2">
            <col class="col3">
            <col class="col4">
            <col class="col5">
            <col class="col6">
            <col class="col7">
            <col class="col8">
          </colgroup>

          <tbody>
          <tr>
            <th><label for="application_date"><span class="red">*</span>신청일자</label></th>
            <td>
              <input type="date" id="application_date" formControlName="application_date" [ngClass]="{formErrors:formErrors['application_date']}" />
              <div *ngIf="formErrors['application_date']" class="error-message">{{formErrors['application_date']}}</div>
            </td>
            <th><label for="applicant_name"><span class="red">*</span>신청자</label></th>
            <td>
              <input type="text" id="applicant_name" formControlName="applicant_name" [ngClass]="{formErrors:formErrors['applicant_name']}" />
              <div *ngIf="formErrors['applicant_name']" class="error-message">{{formErrors['applicant_name']}}</div>
            </td>
            <th><label for="applicant_hp"><span class="red">*</span>연락처</label></th>
            <td>
              <input type="text" id="applicant_hp" formControlName="applicant_hp" [ngClass]="{formErrors:formErrors['applicant_hp']}" />
              <div *ngIf="formErrors['applicant_hp']" class="error-message">{{formErrors['applicant_hp']}}</div>
            </td>
            <th><span class="red">*</span>이메일</th>
            <td>
              <input type="text" id="applicant_email" formControlName="applicant_email" [ngClass]="{formErrors:formErrors['applicant_email']}" />
              <div *ngIf="formErrors['applicant_email']" class="error-message">{{formErrors['applicant_email']}}</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <ul class="tab">
        <li class="active">형식승인검사 신청</li>
      </ul>

      <div class="application-modal3">
        <div class="section">
          <table class="table1">
            <colgroup>
              <col class="col1">
              <col class="col2">
              <col class="col1">
              <col class="col2">
              <col class="col1">
              <col class="col2">
            </colgroup>
            <tbody>
            <tr>
              <th><span class="red">*</span>신청종류</th>
              <td colspan="5">
                <label class="mr40"><input type="checkbox" name="tab3_application_kind1" id="tab3_application_kind1" formControlName="tab3_application_kind1" [value]="form.value.tab3_application_kind1" (change)="changeChecked(0, $event)" />형식승인검사</label>
                <label class="mr40"><input type="checkbox" name="tab3_application_kind2" id="tab3_application_kind2" formControlName="tab3_application_kind2" [value]="form.value.tab3_application_kind2"  (change)="changeChecked(1, $event)"/>효율등급신청 포함</label>
                <label class="mr40"><input type="checkbox" name="tab3_application_kind3" id="tab3_application_kind3" formControlName="tab3_application_kind3" [value]="form.value.tab3_application_kind3"  (change)="changeChecked(2, $event)"/>환경표시인증</label>
                <label><input type="checkbox" name="tab3_application_kind4" id="tab3_application_kind4" formControlName="tab3_application_kind4" [value]="form.value.tab3_application_kind4"  (change)="changeChecked(3, $event)"/>대기권역시험</label>

                <div class="check-radio" [ngClass]="{active: isChecked}">
                  <label class="mr20"><input type="radio" name="tab3_radio" id="tab3_radio" value="전항목검사" (checked)="changeChecked(5, $event)" (change)="changeChecked(5, $event)" [disabled]="!isChecked"/>전항목검사</label>
                  <label class="mr20"><input type="radio" name="tab3_radio" id="tab3_radio" value="유사형식검사" (checked)="changeChecked(5, $event)" (change)="changeChecked(5, $event)" [disabled]="!isChecked"/>유사형식검사</label>
                  <label class="mr20"><input type="radio" name="tab3_radio" id="tab3_radio" value="설계변경" (checked)="changeChecked(5, $event)" (change)="changeChecked(5, $event)" [disabled]="!isChecked"/>설계변경</label>
                  <label><input type="radio" name="tab3_radio" id="tab3_radio" value="형식승인검사 면제(신고)" (checked)="changeChecked(5, $event)" (change)="changeChecked(5, $event)" [disabled]="!isChecked"/>형식승인검사 면제(신고)</label>
                </div>
              </td>
            </tr>
            <tr>
              <th><span class="red">*</span>완료 후 시료 처리</th>
              <td colspan="5">
                <div [ngClass]="{formErrors:formErrors['tab3_sample_disposition']}">
                  <label class="mr30"><input type="radio" id="tab3_sample_disposition" name="tab3_sample_disposition" value="RETURN" [checked]="form.value.tab3_sample_disposition == 'RETURN'" (change)="form.patchValue({tab3_sample_disposition: 'RETURN'})" />반환</label>
                  <label><input type="radio" id="tab3_sample_disposition" name="tab3_sample_disposition" value="DISCARD" [checked]="form.value.tab3_sample_disposition == 'DISCARD'" (change)="form.patchValue({tab3_sample_disposition: 'DISCARD'})" />폐기</label>
                </div>
                <div *ngIf="formErrors['tab3_sample_disposition']" class="error-message">{{formErrors['tab3_sample_disposition']}}</div>
              </td>
            </tr>
            <tr>
              <th><span class="red">*</span>신청모델명</th>
              <td>
                <input type="text" id="tab3_model_name" name="tab3_model_name" formControlName="tab3_model_name" (change)="form.patchValue({tab3_model_name: $event.target.value})" [ngClass]="{formErrors:formErrors['tab3_model_name']}" />
                <div *ngIf="formErrors['tab3_model_name']" class="error-message">{{formErrors['tab3_model_name']}}</div>
              </td>
              <th><span class="red">*</span>기준모델명</th>
              <td>
                <input type="text" id="tab3_standard_model_name" name="tab3_standard_model_name" formControlName="tab3_standard_model_name" (change)="form.patchValue({tab3_standard_model_name: $event.target.value})" [ngClass]="{formErrors:formErrors['tab3_standard_model_name']}" />
                <div *ngIf="formErrors['tab3_standard_model_name']" class="error-message">{{formErrors['tab3_standard_model_name']}}</div>
              </td>
              <th><span class="red">*</span>기본모델명</th>
              <td>
                <input type="text" id="tab3_apply_model_name" name="tab3_apply_model_name" formControlName="tab3_apply_model_name" (change)="form.patchValue({tab3_apply_model_name: $event.target.value})" [ngClass]="{formErrors:formErrors['tab3_apply_model_name']}" />
                <div *ngIf="formErrors['tab3_apply_model_name']" class="error-message">{{formErrors['tab3_apply_model_name']}}</div>
              </td>
            </tr>
            <tr>
              <th>종류및등급</th>
              <td colspan="5">
                <input type="text" id="tab3_kind_grade" name="tab3_kind_grade" formControlName="tab3_kind_grade" (change)="form.patchValue({tab3_kind_grade: $event.target.value})" [ngClass]="{formErrors:formErrors['tab3_kind_grade']}" />
                <div *ngIf="formErrors['tab3_kind_grade']" class="error-message">{{formErrors['tab3_kind_grade']}}</div>
              </td>
            </tr>
            <tr>
              <th>신청사유, 변경내용</th>
              <td colspan="5">
                <input type="text" id="tab3_apply_reason" name="tab3_apply_reason" formControlName="tab3_apply_reason" (change)="form.patchValue({tab3_apply_reason: $event.target.value})" [ngClass]="{formErrors:formErrors['tab3_apply_reason']}" />
                <div *ngIf="formErrors['tab3_apply_reason']" class="error-message">{{formErrors['tab3_apply_reason']}}</div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="section">
          <!-- <div class="div1">
            <div class="section-title"><span class="red">*</span>검사항목</div>
            <button class="btn">추가</button>
            <table class="table2">
              <colgroup>
                <col class="col1">
                <col class="col2">
                <col class="col3">
                <col class="col4">
                <col class="col5">
                <col class="col6">
              </colgroup>
              <thead>
              <tr>
                <th>번호</th>
                <th>기본모델명</th>
                <th>기준모델명</th>
                <th>종류 및 등급</th>
                <th colspan="2">신청사유, 변경내용 등</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>1</td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><button class="icon-btn del"></button></td>
              </tr>
              <tr>
                <td>2</td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><input type="text" /></td>
                <td><button class="icon-btn del"></button></td>
              </tr>
              </tbody>
            </table>
          </div> -->
          <div class="guide-area1">
            <div class="section-title">구비서류 안내</div>
            <div class="box clearfix">
              <div class="boxa">
                1. 제품 사진(전, 후, 좌, 우, 내부, 상부 등) (변경인 경우 기본 모델과 유사형식과의 비교표)<br/>
                2.부품목록표<br/>
                3.구조도에 관한서류<br/>
                4.주요부품의 설계도면에 관한 서류<br/>
                5.배선도에 관한 서류<br/>
                6. 자체검사성적서(승인본에 한함)<br/>
                7. 제품 취급설명서
              </div>
              <div class="boxb">
                <div>※ 변경일 경우</div>
                ① 기준모델의 서류가 등록이 안 된 경우 전체 제출<br/>
                ② 기준모델의 서류가 등록된 경우 신청 모델은 1, 2, 7 항의 서류만 첨부<br/>
                ③ 설계변경의 경우 변경된 도면 첨부
              </div>
            </div>
          </div>
          <div class="div1">
            <header>
              <div class="section-title fl"><span class="red">*</span>구비서류 - 사업자 등록증, 기타 설명 문서</div>
              <div class="fr">
                <input type="file" name="tab3_file" formControlName="tab3_file" ng2FileSelect (change)="handleUploadFileChanged($event)" />
                <button class="btn1 ml5">추가</button>
              </div>
            </header>
            
            <table class="table3">
              <colgroup>
                <col class="col1">
                <col class="col2">
                <col class="col3">
              </colgroup>

              <thead>
              <tr>
                <th>번호</th>
                <th colspan="2">파일명</th>
              </tr>
              </thead>

              <tbody>
                <ng-container *ngIf="form.value.tab3_files.length > 0">
                  <tr *ngFor="let item of form.value.tab3_files; let i = index">
                    <td>{{(i + 1)}}</td>
                    <td class="left">
                      {{item.origin}}
                      <span class="ml5 f11">({{utilService.formatBytes(item.size)}})</span>
                    </td>
                    <td><button class="icon-btn del" (click)="deleteFile(i)"></button></td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="form.value.tab3_files.length < 1">
                  <tr>
                    <td colspan="3" class="center">구비서류 - 사업자등록증, 기타 설명문서를 추가해주세요.</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

          <div class="div2">
            <div class="section-title"><span class="red">*</span>문의·요청사항</div>
            <textarea id="tab3_request_memo" name="tab3_request_memo" formControlName="tab3_request_memo" [value]="form.value.tab3_request_memo" (change)="form.patchValue({tab3_request_memo: $event.target.value})" [ngClass]="{formErrors:formErrors['tab3_request_memo']}" placeholder="문의 사항 및 요청사항을 간단히 기술해 주십시요."></textarea>
            <div *ngIf="formErrors['tab3_request_memo']" class="error-message">{{formErrors['tab3_request_memo']}}</div>
          </div>
        </div>
        <div class="guide-area">
          1. 처리기간은 20일입니다. 다만, 검사 항목에 따라 변동될 수 있습니다.<br/>
          2. 검사 완료 후 연소기기의 특성상 시료의 손상이 있을 수 있습니다.<br/>
          3. 시료의 보관기간은 성적서 발급 일로부터 1개월입니다.<br/>
          4. 시료 보관기간이 만료된 시료는 진흥회에서 임의로 처분합니다.<br/>
          5. 시험·검사의 입회는 접수담당자에게 신청하세요, 다만, 이 경우 다른 고객의 비밀유지를 위하여 검사기간이 늦어질 수 있습니다.<br/>
          6. 출장 검사는 특별한 경우에 한하며 접수 담당자와 상의하십시오.<br/>
          7. 변경 신청의 경우 처음 접수번호와 함께 변경되는 사항만 작성하여 주십시오.
        </div>
        <div class="check">
          <div class="mb20">* 의뢰하신 검사와 관련한 모든 정보는 법률에 의해 요구 받거나, 법률에 의해 금지되지 않는한 고객과 별도의 합의가 있는 경우를 제외 하고는 비밀로 취급 됩니다.</div>
          <label [ngClass]="{formErrors:formErrors['tab3_agree']}">
            <input type="checkbox" id="tab3_agree" name="tab3_agree" formControlName="tab3_agree" (change)="form.patchValue({tab3_agree: true})" />상기 내용과 비고사항을 확인하였으며, 가스연소기 관련 한국산업표준㉿의 형식승인 검사규정에 의하여 형식승인 검사를 받고자 신청합니다.
          </label>
          <div *ngIf="formErrors['tab3_agree']" class="error-message">{{formErrors['tab3_agree']}}</div>
        </div>
      </div>


    </div>

    <div class="modal-footer clearfix">
      <button type="button" class="btn1" (click)="activeModal.dismiss()">취소</button>
      <button type="submit" class="btn1 blue">{{btnName}}</button>
    </div>
  </form>
</div>
