<div class="map-subway">
  <div class="subway clearfix">
    <div class="line"><span>4호선</span>상록수역 하차 시</div>
    <div class="map-area">
      <div class="txt">
        <div><span>4호선 상록수역</span> 하차 1, 2번 출구  >  길 건너 버스정류장에서 301번 버스 이용(15분 소요)</div>
        <div>택시이용시 약 6,000원 (8km)</div>
      </div>
      <div class="map"></div>
    </div>
  </div>

  <div class="subway clearfix">
    <div class="line"><span>4호선</span>중앙역 하차 시</div>
    <div class="map-area">
      <div class="txt">
        <div><span>4호선 중앙역</span>  하차 1번 출구 앞  >   일반버스 314번, 마을버스 3-1 번 이용 안산동주민센터 앞 하차 (7.5km 20분 소요)<br/></div>
        <div>택시이용시 약 6,000원 (8km)</div>
      </div>
      <div class="map"></div>
    </div>
  </div>  
</div>