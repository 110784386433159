<div class="complain-result">
  <header class="header">
    <form [formGroup]="form" (ngSubmit)="getList()">
      <div class="title">불만처리신청 조회</div>
      <div class="search-area clearfix">
        <div class="term">
          <div class="label">기간</div>
          <ul class="clearfix">
            <li [ngClass]="{active:form.value.term == 0}" (click)="chageTerm(0)">오늘</li>
            <li [ngClass]="{active:form.value.term == 1}" (click)="chageTerm(1)">1주일</li>
            <li [ngClass]="{active:form.value.term == 2}" (click)="chageTerm(2)">1개월</li>
            <li [ngClass]="{active:form.value.term == 3}" (click)="chageTerm(3)">3개월</li>
            <li [ngClass]="{active:form.value.term == 4}" (click)="chageTerm(4)">6개월</li>
          </ul>
        </div>
        <div class="date">
          <input type="date" id="applicationSdate" name="applicationSdate" formControlName="applicationSdate" placeholder="시작일" />
          <span class="between">~</span>
          <input type="date" id="applicationEdate" name="applicationEdate" formControlName="applicationEdate" placeholder="종료일">
        </div>
        <div class="state">
          <div class="label">신청상태</div>
          <select
            class="form-control form-control-small mr5"
            style="width: 100px;"
            name="status"
            [(ngModel)]="form.value.status"
            [value]="form.value.status"
            (change)="onChange($event);"
          >
            <option value="">전체</option>
            <option value="0">확인중</option>
            <option value="1">답변완료</option>
          </select>
        </div>

        <button type="submit" class="search-btn">검색</button>
      </div>
    </form>
  </header>

  <div class="table-area">
    <table class="list-table">
      <colgroup>
        <col class="col1" />
        <col class="col2" />
        <col class="col3" />
        <col class="col4" />
        <col class="col5" />
        <col class="col6" />
      </colgroup>
      <thead>
      <tr>
        <th scope="col">번호</th>
        <th scope="col">신청일자</th>
        <th scope="col">불만내용</th>
        <th scope="col">시정 요구사항</th>
        <th scope="col">상태</th>
        <th scope="col">수정 / 삭제</th>
      </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="list.length < 1">
          <tr>
            <td colspan="6">검색된 내역이 없습니다.</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="list.length > 0">
          <ng-container *ngFor="let item of list;let i = index;">
            <tr>
              <td>
                {{form.value.totalCount - ( ( ( form.value.pageNo - 1 ) * form.value.pageRow ) + i )}}
              </td>
              <td>{{item.applicationDate}}</td>
              <td (click)="viewDetail(i)">
                {{item.contents}}
                <div class="date mobile-only"><span>신청일자</span>{{item.applicationDate}}</div>
              </td>
              <td (click)="viewDetail(i)">{{item.correctionRequest}}</td>
              <td>
                <span *ngIf="item.status==0" class="state state1">확인중</span>
                <span *ngIf="item.status==1" class="state state2">답변완료</span>
              </td>
              <td>
                <ng-container *ngIf="item.status == 0">
                  <button class="btn edit" (click)="editRow(item)"></button>
                  <button class="btn del" (click)="deleteRow(item.seq)"></button>
                </ng-container>
              </td>
            </tr>
            <tr class="detail" [ngClass]="{view:viewRow == i}">
              <td colspan="6">
                <div class="complain">
                  <div class="title">불만내용</div>
                  <div>
                    {{getHtml(item.contents)}}
                  </div>
                  <div class="divide"></div>
                  <div class="title">시정 요구사항</div>
                  <div>
                    {{getHtml(item.correctionRequest)}}
                  </div>
                </div>
                <div *ngIf="item.status == 1" class="answer">
                  <div class="title">관리자 답변</div>
                  <div [innerHtml]="getHtml(item.reply) | safe : 'html'">
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="clearfix">
    <div class="pagination-area">
      <ngb-pagination
        [(page)]="form.value.pageNo"
        [pageSize]="form.value.pageRow"
        [collectionSize]="form.value.totalCount"
        [boundaryLinks]="true"
        [maxSize]="'10'"
        rotate="true"
        (pageChange)="loadPage($event)"
      >
        <ng-template ngbPaginationFirst>&lt;&lt;</ng-template>
        <ng-template ngbPaginationLast>&gt;&gt;</ng-template>
        <ng-template ngbPaginationPrevious>&lt;</ng-template>
        <ng-template ngbPaginationNext>&gt;</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>




