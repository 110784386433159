<div class="section">
  <div class="title">표준개발협력기관의 정의</div>
  <ul class="txt-area">
    <li>
      <p class="txt"><span></span><span>산업표준화법 제5조. 동법 시행령 제18조 및 동법 시행규칙 제2조 규정에 따라 산업표준 개발을 효율적으로 추진하기 위하여 전문분야별로 자발적인 합의를 통해서 KS안을 개발할 수 있는 능력을 인정받은 
        법인이나 단체로서 지정분야의 국가표준 개발, 5년도래 표준 검토, 해당 분야별 작업반(WG)을 신설운영</span></p>
      <p class="txt1"><span class="blue">*</span> 표준개발협력기관 (COSD ; Co-operating Organization for Standards Development)</p>
    </li>
  </ul>
</div>  

<div class="section">
  <div class="title">지정분야</div>
  <ul class="txt-area">
    <li>
      <p class="txt"><span></span><span>ISO/TC 21, ISO/TC 58, ISO/TC 86/SC 6, ISO/TC 109, ISO/TC 142, ISO/TC 161, ISO/TC 185, ISO/TC 192, ISO/TC 220, ISO/TC 224, ISO/TC 291, ISO/TC 293 관련 국가표준</span></p>
    </li>
  </ul>
</div>  

<div class="section">
  <div class="title">표준제정절차</div>
  <div class="img"></div>
</div>  

<div class="section">
  <div class="title">표준개발협력기관의 역할</div>
  <ul class="txt-area">
    <li>
      <p class="txt"><span></span><span>국가표준(안) 작성 및 5년도래 표준 검토(개정 및 확인의무)</span></p>
      <p class="txt"><span></span><span>산업표준화법에 근거한 전문위원회 역할 중 국가표준화업무 위탁</span></p>
      <p class="txt"><span></span><span>지정분야의 전문위원회, 작업반 신설ㆍ운영 및 사무국 역할 수행</span></p>
      <p class="txt"><span></span><span>지정분야 국제문서 검토 및 대응 등 국제표준화 활동</span></p>
      <p class="txt"><span></span><span>지정분야 단체표준 개발/유지관리 및 사실상 국제표준 대응</span></p>
      <p class="txt"><span></span><span>기업애로사항 파악, KS 개선 활동, 제도 개선 및 정부와 협의</span></p>
      <p class="txt"><span></span><span>표준개발 성과확산(홍보 및 보급)</span></p>
    </li>
  </ul>
</div>  