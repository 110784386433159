/*******************************************************************************
  설  명 : 회원가입 - 정보입력
  작성자 :
  작성일 :
*******************************************************************************/
import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { HMemberService } from '@home/service/member.service';

import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';
import { CompanySearchComponent } from './company-search/company-search.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-company'
};

declare global {
  interface Window {
    fn: any;
  }
}

@Component({
  selector: 'app-join-step2',
  templateUrl: './join-step2.component.html',
  styleUrls: ['./join-step2.component.scss']
})
export class JoinStep2Component extends O2OZCMS implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public errorResponse: ApiResponse;

  public form: FormGroup;
  public formErrors = {};

  public authCheck = false;
  public checkTrue = true;

  public authInfo: any = {};

  public smsAgree: any = '';

  public daumAddressOptions =  {
    class: ['btn', 'zip-btn']
  };

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    router: Router,
    commonService: CommonService,
    activatedRoute: ActivatedRoute,
    toastrService: ToastrService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private ngZone: NgZone,
    private memberService: HMemberService,

  ) {
    // 상위 클래스에 router 전달
    super(router, commonService, activatedRoute, toastrService);

    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      userName: [ '', [Validators.required, Validators.pattern('^[가-힣a-zA-Z]*$')] ],
      userId: [ '', [Validators.required, Validators.pattern('^[a-z0-9]{5,20}$')] ],
      idCheck:[ false, [Validators.required] ],
      password: [ '', [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{5,19}$')] ],
      passwordConfirm: [ '', [Validators.required] ],
      userGubun: [ '1000', [Validators.required] ],
      mobile: [ '', [Validators.required] ],
      // mobile1: [ '', [Validators.required, Validators.pattern('^[0-9]{2,3}$')] ],
      // mobile2: [ '', [Validators.required, Validators.pattern('^[0-9]{3,4}$')] ],
      // mobile3: [ '', [Validators.required, Validators.pattern('^[0-9]{4}$')] ],
      email: [ '', [] ],
      email1: [ '', [Validators.required, Validators.pattern('^[a-z0-9._-]{1,30}$')] ],
      email2: [ '', [Validators.required, Validators.pattern('^[a-z0-9.-]+\\.[a-z]{2,4}$')] ],
      email3: [ '', [] ],
      smsCheck: [ true, [] ],

      companySeq: [ '', [] ],
      companyName: [ '', [Validators.required] ],
      ceoName: [ '', [Validators.required] ],
      comNumber: [ '', [Validators.pattern('^[0-9-]+$'), Validators.minLength(10), Validators.maxLength(12)] ],
      memberStatus: [ '1000', [Validators.required] ],
      tel: [ '', [Validators.required] ],
      // telephone1: [ '', [Validators.required] ],
      // telephone2: [ '', [Validators.required] ],
      // telephone3: [ '', [Validators.required] ],
      comEail: [ '', [] ],
      comEmail1: [ '', [Validators.required, Validators.pattern('^[a-z0-9._-]{1,30}$')] ],
      comEmail2: [ '', [Validators.required, Validators.pattern('^[a-z0-9.-]+\\.[a-z]{2,4}$')] ],
      comEmail3: [ '', [] ],
      comDept: [ '', [] ],
      comJob: [ '', [] ],
      zipcode: [ '', [Validators.required] ],
      address: [ '', [Validators.required] ],
      addressDetail: [ '', [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    window.fn  = window.fn || {};
    window.fn.namespace = window.fn.namespace || {};
    window.fn.namespace.hpAuthFunc = this.hpAuthFunc.bind(this);
    window.fn.namespace.ipinAuthFunc = this.ipinAuthFunc.bind(this);

    this.getSmsAgree();
  }

  /*******************************************************************************
    설  명 : 인증 실행 후 ngZone 실행
  *******************************************************************************/
  hpAuthFunc( response: any ) {
    this.ngZone.run(() => this.hpAuthCallback( response ));
  }

  ipinAuthFunc( response: any ) {
    this.ngZone.run(() => this.ipinAuthCallback( response ));
  }

  /*******************************************************************************
    설  명 : 휴대폰 인증 콜백
  *******************************************************************************/
  hpAuthCallback( response: any ) {
    this.authInfo = response;

    if ( this.authInfo !== null ) {
      this.form.patchValue({
        userName: this.authInfo.name,
        birth: this.authInfo.birthdate,
        gender: this.authInfo.gender,
        dupinfo: this.authInfo.dupinfo
      });
    }

    this.authCheck = true;
  }

  /*******************************************************************************
    설  명 : 아이핀 인증 콜백
  *******************************************************************************/
  ipinAuthCallback( response: any ) {
    this.authInfo = response;

    if ( this.authInfo !== null ) {
      this.form.patchValue({
        userName: this.authInfo.name,
        birth: this.authInfo.birthdate,
        gender: this.authInfo.gender,
        dupinfo: this.authInfo.dupinfo
      });
    }

    this.authCheck = true;
  }

  /*******************************************************************************
    설  명 : SMS 수신 동의 가져오기
  *******************************************************************************/
  public getSmsAgree() {
    this.commonService.getMenu(931).then( response => {
      if( response.ResultCode ) {
        this.smsAgree = response.data.contents;
      }
    })
  }

  /*******************************************************************************
    설  명 : 회사검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchCompany() {
    const modalRef = this.modalService.open(CompanySearchComponent, options);

    modalRef.result.then((result) => {
      let telTmp1 = '';
      let telTmp2 = '';
      let telTmp3 = '';
      let emailTmp1 = '';
      let emailTmp2 = '';

      // 전화번호 - 기준으로 분할
      let tmp = result.tel.split('-');
      telTmp1 = tmp[0];
      telTmp2 = tmp[1];
      telTmp3 = tmp[2];

      // 이메일 @ 기준으로 분할
      let tmp1 = result.email.split('@');
      emailTmp1 = tmp1[0];
      emailTmp2 = tmp1[1];


      this.form.patchValue({
        companySeq: result.seq,
        companyName: result.companyName,
        ceoName: result.ceoName,
        comNumber: result.comNumber.replace(/\-/g,''),
        tel: result.tel,
        telephone1: telTmp1,
        telephone2: telTmp2,
        telephone3: telTmp3,
        comEmail1: emailTmp1,
        comEmail2: emailTmp2,
        comEmail3: emailTmp2,
        comDept: result.job1,
        comJob: result.job,
        zipcode: result.zipcode,
        address: result.address,
        addressDetail: result.addressDetail,
      });
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 회원가입 처리
  *******************************************************************************/
  public setMemberSave() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if ( this.form.valid ) {
      this.memberService.setMemberSave( this.form ).then(response => {
        if ( response.ResultCode == true ) {
          this.toastrService.success(response.ResultMessage, '회원가입');

          this.router.navigate(['/member/join/step3']);
        } else {
          this.toastrService.error(response.ResultMessage, '회원가입');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
      });
    } else {
      this.toastrService.error('필수 입력값을 확인하시기 바랍니다.', '회원가입');
    }
  }

  /*******************************************************************************
    설  명 : 아이디 중복 검사
  *******************************************************************************/
  checkIdDuplicate() {
    this.utilService.makeFieldDirtyAndUpdateErrors(this.form, this.formErrors, 'userId');

    if( this.form.get('userId').valid ) {
      this.memberService.checkIdDuplicate( this.form.get( 'userId' ).value ).then( response => {
        if( response.ResultCode ) {
          this.form.get( 'idCheck' ).setValue( true );
          this.toastrService.success( response.ResultMessage, '아이디 중복체크');
        } else {
          this.form.get( 'idCheck' ).setValue( false );
          this.toastrService.error( response.ResultMessage, '아이디 중복체크');
        }
      });
    } else {
      this.toastrService.error( '필수 입력값을 다시 한번 확인하시기 바랍니다.', '아이디 중복체크');
    }
  }

  /*******************************************************************************
    설  명 : 이메일 선택 입력 이벤트
  *******************************************************************************/
  setEmail(event) {
    if ( event.target.value == '직접 입력' ) {
      this.form.patchValue({email2: ''});
    } else {
      this.form.patchValue({email2: event.target.value});
    }
  }

  setEmail1(event) {
    if ( event.target.value == '직접 입력' ) {
      this.form.patchValue({comEmail2: ''});
    } else {
      this.form.patchValue({comEmail2: event.target.value});
    }
  }

  /*******************************************************************************
    설  명 : 다음주소창에서 클릭시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddress1Api(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr,
      address_detail: data.addrEng
    });
  }

}
