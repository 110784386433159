import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eatl-business6',
  templateUrl: './eatl-business6.component.html',
  styleUrls: ['./eatl-business6.component.scss']
})
export class EatlBusiness6Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
