/*******************************************************************************
  설  명 : 로그인
  작성자 : 
  작성일 : 
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { ApiResponse } from '@app/service/api-response';
import { UtilService } from '@app/service/util.service';
import { CommonService } from '@app/service/common.service';
import { HMemberService } from '@home/service/member.service';
import { HAuthService } from '@home/service/auth.service';

import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends O2OZCMS implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public errorResponse: ApiResponse;

  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/    
  constructor(
    router: Router,
    commonService: CommonService,
    activatedRoute: ActivatedRoute,
    toastrService: ToastrService,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private memberService: HMemberService,
    private authService: HAuthService,
  ) {
    // 상위 클래스에 router 전달
    super(router, commonService, activatedRoute, toastrService);

    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      id: [ '', [Validators.required] ],
      //id: [ '', [Validators.required, Validators.pattern('^[a-z0-9]{5,20}$')] ],
      pw: [ '', [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }
  
  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 회원 로그인 처리
  *******************************************************************************/
  public setLogin() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if ( this.form.valid ) {
      this.memberService.setLogin( this.form ).then(response => {
        if ( response.ResultCode == true ) {
          this.authService.login(response);
          this.toastrService.success(response.ResultMessage, '회원 로그인');

          // 휴면 회원처리
          if ( response.dormant ) {
            this.router.navigate(['/member/dormant']);
            return;
          }
          this.router.navigate(['/main']);
        } else {
          this.toastrService.error(response.ResultMessage, '회원 로그인');
        }
      })
      .catch(response => {
        this.errorResponse = response;
        this.utilService.handleFormSubmitError(this.errorResponse, this.form, this.formErrors);
      });
    }
  }
  
}
