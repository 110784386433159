import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

import { UtilService } from '@app/service/util.service';
import { HMemberService } from '@app/home/service/member.service';
import { HInspectionService } from '@app/home/service/inspection.service';

@Component({
  selector: 'app-complain-modal',
  templateUrl: './complain-modal.component.html',
  styleUrls: ['./complain-modal.component.scss']
})
export class ComplainModalComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  public isAdd: boolean = true;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private hMemberService: HMemberService,
    private utilService: UtilService,
    private hInspectionService: HInspectionService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: [ '', [] ],
      status: [ 0, [] ],
      companyName: [ '', [Validators.required, Validators.maxLength(100)] ],
      ceoName: [ '', [Validators.maxLength(50)] ],
      businessNo: [ '', [Validators.required, Validators.pattern('^[0-9-]+$'), Validators.minLength(10), Validators.maxLength(12)] ],
      address: [ '', [Validators.maxLength(100)] ],
      zipCode: [ '', [Validators.minLength(5), Validators.maxLength(5)] ],
      tel: [ '', [Validators.maxLength(20)] ],
      fax: [ '', [Validators.maxLength(20)] ],
      businessType: [ '', [Validators.maxLength(100)] ],
      businessItem: [ '', [Validators.maxLength(100)] ],
      applicationDate: [ '', [Validators.required, Validators.maxLength(10)] ],
      applicationName: [ '', [Validators.required, Validators.maxLength(20)] ],
      hp: [ '', [Validators.required, Validators.maxLength(20)] ],
      email: [ '', [Validators.maxLength(100)] ],
      contents: [ '', [Validators.required] ],
      correctionRequest: [ '', [] ],
      memo: [ '', [] ],
      agree: [ '', [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    if( this.isAdd )
      this.getCompanyInfo();
  }

  /*******************************************************************************
    설  명 : 수정시 데이터 변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setInit(item) {
    this.isAdd = false;
    this.form.patchValue({
      ...item,
      agree: true
    });
  }

  /*******************************************************************************
    설  명 : 회사 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCompanyInfo(): void {
    this.hMemberService.getCompanyInfoLogin().then((response) => {
      if( response.ResultCode && response.data && response.data.length > 0 ) {
        this.form.patchValue(response.data[0]);
        this.form.patchValue({
          companyName: response.data[0].com_kor,
          ceoName: response.data[0].name_kor,
          businessNo: response.data[0].com_number,
          zipCode: response.data[0].zipcode,
          businessType: response.data[0].job,
          businessItem: response.data[0].job1,
          applicationDate: moment().format('YYYY-MM-DD'),
          applicationName: response.data[0].username,
          hp: response.data[0].mobile,
        });

        console.log(1);
      }

      this.form.patchValue({'application_date': moment().format('YYYY-MM-DD')});
    });
  }

  /*******************************************************************************
    설  명 : 저장하기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if(this.form.valid) {
      let params = {...this.form.value};

      this.hInspectionService.setApplicationComplain(params).then((response) => {
        if( response.ResultCode ) {
          this.toastrService.success('정상적으로 등록되었습니다.', '불만처리신청');
          this.activeModal.close(true);

        } else {
          this.toastrService.error( response.ResultMessage, '불만처리신청');
        }
      }, (error) => {
        this.toastrService.error(error, '불만처리신청');
      });
    }
  }

}
