<app-sub-header [menu]="menu"></app-sub-header>

<div class="overseas-page contents-page">
  <div class="inner-wrap">
    <div class="section">
      <div class="title">조사품목 <span>(국가별 선택적용)</span></div>
      <ul class="goods clearfix">
        <li>가스레인지</li>
        <li>가스오븐레인지</li>
        <li>가스온수보일러</li>
        <li>가스난로</li>
        <li>가스순간온수기</li>
      </ul>
    </div>  

    <div class="section">
      <div class="title">년도별 조사국가</div>
      <table class="list-table list-table1">
        <colgroup>
          <col style="width:20%;">
          <col style="width:80%;">
        </colgroup>
        <thead>
        <tr>
          <th>년도</th>
          <th>국가명</th>
        </tr>
        </thead>    
        <tbody>
          <tr>
            <td>‘95</td>
            <td>일본, 미국, 영국, 독일, 프랑스</td>
          </tr>
          <tr>
            <td>‘96</td>
            <td>중국</td>
          </tr>
          <tr>
            <td>‘00</td>
            <td>중국, 영국, 우크라이나, 이탈리아</td>
          </tr>
          <tr>
            <td>‘01</td>
            <td>러시아, 브라질, 칠레, 아르헨티나</td>
          </tr>
          <tr>
            <td>‘02</td>
            <td>폴란드, 헝가리, 체코, 루마니아</td>
          </tr>
          <tr>
            <td>‘03</td>
            <td>호주, 베트남</td>
          </tr>
          <tr>
            <td>‘05</td>
            <td>러시아, 이란</td>
          </tr>
          <tr>
            <td>‘06</td>
            <td>중국, 영국, 이집트</td>
          </tr>
          <tr>
            <td>‘07</td>
            <td>미국, 폴란드, 카자흐스탄</td>
          </tr>
          <tr>
            <td>‘08</td>
            <td>중국, 러시아, 프랑스, 불가리아, 대만, 남아공, 뉴질랜드</td>
          </tr>
          <tr>
            <td>‘10</td>
            <td>우크라이나, 인도, 칠레, 베트남, 그리스, 파키스탄, 두바이</td>
          </tr>
          <tr>
            <td>‘11</td>
            <td>체코, 헝가리, 캐나다, 호주, 인도네시아, 카자흐스탄, 중국, 이집트</td>
          </tr>
          <tr>
            <td>‘12</td>
            <td>중국, 대만, 필리핀, 폴란드, 이탈리아</td>
          </tr>
          <tr>
            <td>‘13</td>
            <td>미국, 일본, 폴란드, 아르헨티나, 러시아, 브라질</td>
          </tr>
          <tr>
            <td>‘14</td>
            <td>영국, 베트남, 이집트, 터키, 이란</td>
          </tr>
          <tr>
            <td>‘16</td>
            <td>중국, 러시아, 인도, 칠레, 그리스</td>
          </tr>
          <tr>
            <td>‘17</td>
            <td>우크라이나, 루마니아, 우즈베키스탄</td>
          </tr>
          <tr>
            <td>‘18</td>
            <td>러시아, 중국, 미국</td>
          </tr>                                                            
        </tbody>
      </table>
    </div>      
  </div>

  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>


