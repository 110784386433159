import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router'

import { Globals } from '@app/service/globals.service';

declare let gtag:Function;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    public globals: Globals
  ) {
    // google analytics ����
    router.events.subscribe((y: NavigationEnd) => {
      if(y instanceof NavigationEnd){
        gtag('config','UA-167858793-1',{'page_path' : y.url});
      }
    })
  }

  ngOnInit(): void {
  }

}
