import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promote',
  templateUrl: './promote.component.html',
  styleUrls: ['./promote.component.scss']
})
export class PromoteComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  
  public menu: any = {
    depth1_id: 1,
    depth2_id: 3,
    depth1_nm: '부설 에너지기기 시험원',
    depth2_nm: '홍보자료',
  }; 

  constructor() { }

  ngOnInit(): void {
  }

}
