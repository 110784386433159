import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BoardService } from '@app/home/service/board.service';

@Component({
  selector: 'app-board-lock',
  templateUrl: './board-lock.component.html',
  styleUrls: ['./board-lock.component.scss']
})
export class BoardLockComponent implements OnInit {

  @Input() board_seq: any;
  pw: any = '';

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private boardService: BoardService,

  ) { }  
  
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 비밀번호 확인
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  confirmPw() {
    if ( this.pw != '' ) {
      this.boardService.confirmPw( this.pw, this.board_seq ).then( response => {
        if ( response.ResultCode ) {
          this.activeModal.dismiss('OK'); 
          this.toastrService.success(response.ResultMessage);
        } else {
          this.activeModal.dismiss('NO'); 
          this.toastrService.error(response.ResultMessage);
        }
      });
    } else {
      this.activeModal.dismiss(); 
    }

  }

  /*******************************************************************************
    설  명 : 비밀번호 입력이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setPw(event) {
    this.pw = event.target.value;
  }

}
