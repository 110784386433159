<div class="eatl-business1">
  <div class="page-title">국가공인시험검사기관</div>
  <div class="page-title-bar"></div>
  <div class="business1">
    <div class="txt">
      국가표준기본법에 의거 한국 인정기구<br class="mobile-only"><span>(KOLAS : Korea Laboratory Accreditation Scheme)</span><br class="mobile-only">가 <br class="pc-only">
      시험 검사결과에 대한 신뢰성 확보와<br class="mobile-only"> 국내외 시험 검사 기관간의 상호 인정제도를<br class="mobile-only"> 확립하기 위하여<br class="pc-only">
      일정수준 이상의 능력이 있다고<br class="mobile-only"> 국가가 공인한 시험검사기관입니다.
    </div>
    <div class="txt-area">
      <div>
        진흥회는 국가공인시험 검사기관으로서<br class="mobile-only"> 에너지기기제품 및 부품에 대한<br class="pc-only">
        시험 검사 의뢰시<br class="mobile-only"> 엄격한 <span>시험검사를 거쳐</span><br class="mobile-only"> <span>국가공인 성적서를 발급</span>하고 있습니다.<br/>
        진흥회가 발급한 성적서는 관련법률의 규제에 따라<br class="mobile-only"> 제품의 신뢰성을 보장받을수 있기 때문에<br/>
        국내에서는 물론 앞으로 외국의 인정기관과<br class="mobile-only"> 상호 인증협약을 체결하게 되면<br class="pc-only">
        상대국의<br class="mobile-only"> 까다로운 검사를 받지 않고도 수출할 수 있습니다.
      </div>
    </div>
  </div>
</div>
