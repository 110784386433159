<app-sub-header [menu]="menu"></app-sub-header>

<div class="online-page contents-page">
  <div class="inner-wrap">
    <ul class="page-tab clearfix">
      <li class="active"><a routerLink="/online/accreditedTest">공인시험검사신청</a></li>
      <li><a routerLink="/online/SafetyConfirmationTest">안전확인시험검사신청</a></li>
      <li><a routerLink="/online/StandardDevelopment">표준개발협력제안</a></li>
      <li><a routerLink="/online/KeaaJoin">진흥회 가입신청</a></li>
    </ul>

    <div class="online1-page">
      <div class="page-title">공인시험검사신청</div>
      <div class="page-title-bar"></div>
      <div class="guide-txt">
        <span class="blue">온라인 신청 서비스 준비 중입니다.</span><br class="mobile-only"> 서비스 시작 시 까지는 아래 양식을 다운로드 하셔서<br class="mobile-only"> 작성 후 팩스로 접수하여 주십시요. <br class="mobile-only">(팩스 : 031-480 - 2986)
      </div>

      <ul class="application clearfix">
        <li>
          <div>시험검사신청</div>
          <div>
            <a class="cp" (click)="ApplicationModal(1)">온라인신청</a>
            <a href="/assets/download/SP701_01 시험.검사신청서(2021.04.12) (1).hwp">양식다운로드</a>
          </div>
        </li> 
        <li>
          <div>장비사용신청</div>
          <div>
            <a class="cp" (click)="ApplicationModal(2)">온라인신청</a>
            <a href="/assets/download/SP701-05_00장비사용신청서(2019.08.26).hwp">양식다운로드</a>
          </div>
        </li> 
        <li>
          <div>형식승인검사신청</div>
          <div>
            <a class="cp" (click)="ApplicationModal(3)">온라인신청</a>
            <a href="/assets/download/SP701-04_00형식승인검사신청서(2019.08.26).hwp">양식다운로드</a>
          </div>
        </li> 
        <li>
          <div>부품인증신청</div>
          <div>
            <a routerLink="#">온라인신청</a>
            <a href="/assets/download/SP701-06_00안전인증시험검사신청서(2019.08.26).hwp">양식다운로드</a>
          </div>
        </li> 
        <li>
          <div>불만처리신청</div>
          <div>
            <a class="cp" (click)="ComplainModal()">온라인신청</a>
            <a href="/assets/download/SP712-01_00불만처리신청서(2019.08.26).hwp">양식다운로드</a>
          </div>
        </li> 
        <li>
          <div>재발급신청</div>
          <div>
            <a routerLink="#">온라인신청</a>
            <a href="/assets/download/SP711-09_00재발급신청서(2019.08.26).hwp">양식다운로드</a>
          </div>
        </li> 
        <li>
          <div>성적서진위확인신청</div>
          <div>
            <a routerLink="#">온라인신청</a>
          </div>
        </li>                                                 
      </ul>  

      <div class="section">
        <div class="title">근거법령</div>
        <ul class="txt-area">
          <li><p class="txt"><span></span><span>국가표준기본법 제 23조</span></p></li>
        </ul>
      </div>
      <div class="section">
        <div class="title">업무 처리절차<span>(처리기간 : <span class="blue">25</span>일)</span></div>
        <div class="img"></div>
      </div>        
      <div class="section">
        <div class="title">시험분야</div>
        <ul class="txt-area">
          <li>
            <p class="txt"><span></span><span>연구 및 신제품 개발을 위한 시험</span></p>
            <p class="txt"><span></span><span>품질관리 및 공정개선을 위한 시험</span></p>
            <p class="txt"><span></span><span>국가공인성적서 발급을 위한 시험</span></p>
            <p class="txt"><span></span><span>CE인증을 위한 제품시험</span></p>
            <p class="txt"><span></span><span>효율관리기자재 운용규정에 의한 효율등급시험(가정용가스보일러, 가스온수기, 전기 밥솥, 선풍기, 전기스토브, 전기레인지)</span></p>
            <p class="txt"><span></span><span>고효율에너지기자재보급촉진에 관한 규정에 의한 시험(산업건물용가스보일러)</span></p>
            <p class="txt"><span></span><span>환경기술 및 환경산업 지원법」제17조(환경표지의 인증)에 따른 환경인증 시험(가스보일러, 가스레인지, 가스캐비닛히터, 난방용자동온도조절장치, 조립식바닥난방시스템)</span></p>
          </li>
        </ul>
      </div>  
      <div class="section">
        <div class="title">검사분야</div>
        <ul class="txt-area">
          <li>
            <p class="txt"><span></span><span>산업표준화법 제17조 ①항 및 시행규칙 제14조의 규정에 의한 제품심사</span></p>
            <p class="txt"><span></span><span>도시가스 안전관리기준 통합고시 제 4 장(산업자원부 고시 제 1999-100호) 및 배기통검사기준 및 검사방법에 의한 부품검사 기타 업체 및 단체 등에서 의뢰하는 검사</span></p>
            <p class="txt"><span></span><span>가스연소기 관련 한국산업표준(KS)의 형식승인검사 규정에 의한 형식승인검사</span></p>
            <p class="txt"><span></span><span>전기용품 및 생활용품 안전관리법에 의한 안전확인대상제품의 검사(기름난로)</span></p>
          </li>
        </ul>
      </div>  
      <div class="section">
        <div class="title">시험검사 대상품목</div>
        <ul class="txt-area">
          <li>
            <p class="txt"><span></span><span>산업표준화법 제17조 ①항 및 시행규칙 제14조의 규정에 의한 제품심사</span></p>
            <p class="txt1"><span>-</span><span>가스온수보일러, 가스레인지, 가스오븐레인지, 가스난로, 가스순간온수기, 가스의류건조, 상업용가스 연소기기 및 기타 가스기기</span></p>
          </li>
          <li>
            <p class="txt"><span></span><span>가스연소기기에 사용하는 부품</span></p>
            <p class="txt1"><span>-</span><span>가스보일러용 배기통, 가스배관용자동안전밸브</span></p>
          </li>      
          <li>
            <p class="txt"><span></span><span>기름연소기기</span></p>
            <p class="txt1"><span>-</span><span> 기름온수보일러, 기름난로, 기름튜브히터 등 기름연소기기 및 부품</span></p>
          </li>
          <li>
            <p class="txt"><span></span><span>펠릿연소기기</span></p>
            <p class="txt1"><span>-</span><span>목재펠릿보일러, 목재펠릿난로, 비목재펠릿보일러, 화목보일러 등</span></p>
          </li>
          <li>  
            <p class="txt"><span></span><span>전기기기</span></p>
            <p class="txt1"><span>-</span><span>전기 밥솥, 선풍기, 전기스토브, 전기레인지</span></p>
          </li>
          <li>
            <p class="txt"><span></span><span>기타 제품</span></p>
            <p class="txt1"><span>-</span><span>난방용자동온도조절시스템, 온수분배기, 온수난방용 바닥패널, 지역중앙난방식세대용 난방·급탕통합시스템, 상업용냉난방통합콘트롤러, 래칭솔레노이드벨브</span></p>
          </li>
        </ul>
      </div>       
      <div class="section">
        <div class="title">시험검사항목</div>
        <div class="table-area">
          <table class="list-table">
            <colgroup>
              <col class="col1">
              <col class="col1">
              <col>
            </colgroup>
            <thead>
            <tr>
              <th colspan="2">구분</th>
              <th>시험검사항목</th>
            </tr>
            </thead>    
            <tbody>
              <tr>
                <th rowspan="7">가스 및 기름<br/>연소기기</th>
                <th>성능</th>
                <td>가스소비량, 연료소비량, 난방성능, 온수성능, 열효율, 방사효율, 열탕의 비산, 굽기 성능, 수격음, 가습량, 취사성능, 보온성능, 전기점화성능시험, 온도상승시험, 온도 분포, 가열속도 내부온도, 인장강도, 누설량, 투과저항등</td>
              </tr>
              <tr>
                <th>연소상태</th>
                <td>불옮김, 리프팅, 소화, 불꽃의 균일성, 역화, 연속소음, 파일럿 버너의 불꽃의 안전성 버너불꽃의 안전성, 배기구 이외에서의 연소가스 유출, 과부하연소, 심지조절기 최대 연소, 자연통기 연소시험, 경사시험 등</td>
              </tr>
              <tr>
                <th>안전장치</th>
                <td>소화안전장치, 용기내 압력, 압력감지 안전장치의 작동성능, 배기폐쇄 안전장치, 과대 풍압 안전장치, 재통전시 안전장치, 과열방지 안전장치, 헛불시의 안전장치, 과압방지용 안전장치, 
                  온도조절기, 저온동결 방지장치, 온도제어장치, 불완전연소 방지장치, 산소결핍시의 가스차단장치, 전도시의 가스차단장치, 저온차단장치, 전도시험, 실온조절장치 작동시험, 
                  내단연소성시험, 물흐름검지장치, 연소용 송풍안전장치 등</td>
              </tr>
              <tr>
                <th>전기부</th>
                <td>절연성능시험, 내전압시험, 내충격전압시험(낙뢰시험), 시동시험, 전압변동시험, 소비 전력 시험, 권선의 온도상승, 시험지 시험, 교류전원 이상시험, 직류전원이상시험, 전원 잡음 시험, 
                  정전기시험, 전자회로의 단락 단선시험 등</td>
              </tr>
              <tr>
                <th>반복사용</th>
                <td>기구밸브, 전기점화장치, 기구거버너, 소화안전장치, 전자밸브, 타이머, 서모스탯, 신속이음쇠, 호스접속구, 압력감지 안전장치, 용기와 기기의 접합부, 그릴부, 
                  오븐부 및 건조용기의 문 반복시험 등</td>
              </tr>  
              <tr>
                <th>구조, 재료 및 치수</th>
                <td>진동시험, 기밀시험, 경사전도시험, 신속이음쇠, 내열성, 내식성, 내가스성, 강구충격 시험, 보온재, 단열재등의 난연성 시험, 하중시험, 치수, 재료일반, 각부의 구조</td>
              </tr>  
              <tr>
                <th>표시사항</th>
                <td>제품표시, 취급표시, 취급주의표시, 사용설명서 등</td>
              </tr>   
              <tr>
                <th colspan="2">목재펠릿연소기 (목재 및 비목재)</th>
                <td>연료소비량, 열출력, 난방효율, 소음, 배기가스분석, 점화시간, 온도상승, 배기가스온도, 안전장치, 전기부, 내열성, 물배관의 내압, 장기안전성</td>
              </tr>                                                                                             
              <tr>
                <th colspan="2">화목보일러</th>
                <td>열출력, 온도상승, 배기가스분석, 내압, 연소시간, 온도조절기, 안전장치, 전기부</td>
              </tr>                                                                                             
              <tr>
                <th colspan="2">부품성능인증</th>
                <td>내식성시험, 접속강도시험, 기밀성시험, 배기통탑시험, 구조 및 치수, 내한.내열시험</td>
              </tr>                                                                                             
              <tr>
                <th colspan="2">난방용 자동 온도 조절 시스템</th>
                <td>구조일반, 용량계수시험, 유량통과시험, 온도변화시험, 보호등급시험, 내구성시험</td>
              </tr>                                                                                             
              <tr>
                <th colspan="2">온수분배기</th>
                <td>조작시험, 내압시험, 기밀시험, 나사시험, 표면처리두께 측정시험, 염수분무시험</td>
              </tr>                                                                                             
            <tr>
              <th colspan="2">온수난방용 바닥패널</th>
              <td>수두손실시험, 온도상승시험, 표면온도분포시험, 방열능력시험, 가열반복에 의한 변형 시험, 평탄도시험, 적재하중시험, 내압시험, 내충격시험, 국부압축시험</td>
            </tr>               
            <tr>
              <th colspan="2">지역 또는 중앙 난방식세대용<br/>난방 · 급탕통합시스템</th>
              <td>수두손실시험, 온도상승시험, 표면온도분포시험, 방열능력시험, 가열반복에 의한 변형 시험, 평탄도시험, 적재하중시험, 내압시험, 내충격시험, 국부압축시험</td>
            </tr>                                                                                             
            <tr>
              <th colspan="2">상업용 냉난방 통합 콘트롤러</th>
              <td>온도설정정밀도, 온/오프온도차, 대기시소비전력, 내한성, 내열성, 내습성, 온도변화, 전기자기적합성, 전기적빠른과도현상, 서지내성, 순시정전 및 전압강하, 정전기방전 내성, 하드웨어인터페이스성능</td>
            </tr>                                                                                             
            <tr>
              <th colspan="2">전기레인지 등 전기 용품</th>
              <td>소비전력량 시럼(효율관리기자재운영규정 별표1)</td>
            </tr>              
          </tbody>
        </table>    
      </div>
      </div>  
      <div class="section">
        <div class="title">시험검사의뢰 제출서류</div>
        <div class="table-area">
          <table class="list-table">
            <colgroup>
              <col class="col2">
              <col>
            </colgroup>
            <thead>
            <tr>
              <th>구분</th>
              <th>제출 서류</th>
            </tr>
            </thead>    
            <tbody>
              <tr>
                <th>시험검사 신청시</th>
                <td>
                  - 시험검사신청서(양식)<br/>
                  - 시험검사용 시료(의뢰인)<br/>
                  - 사업자등록증 사본(의뢰인)
                </td>
              </tr>
              <tr>
                <th>장비 사용 신청시</th>
                <td>- 장비사용신청서(양식)</td>
              </tr>
              <tr>
                <th>부품성능인증 신청시</th>
                <td>
                  - 가스연소기기의 부품인증신청서(양식)<br/>
                  - 사업자등록증<br/>
                  - 공장등록증 및 허가증<br/>
                  - 제품검사 규격 및 제조공정도<br/>
                  - 제품도면(상세 부품도 포함)<br/>
                  - 자체시험성적서<br/>
                  - MILL SHEET(주재료, 패킹류)<br/>
                  - 제품시료(3점)<br/>
                  - 재료시편(3점)(가로3cm X 세로3cm)
                </td>
              </tr>
              <tr>
                <th>형식승인검사 신청시</th>
                <td>
                  - 형식승인검사 신청서(양식)<br/>
                  - 제출서류(주요부품등의 변경일 경우 변경 전·후 서류 각각 제출)<br/>
                  - 구조도에 관한 서류<br/>
                  - 주요부품의 설계도면에 관한 서류<br/>
                  - 배선도에 관한 서류<br/>
                  - 제품사진(전,후,좌,우)<br/>
                  - 부품목록표<br/>
                  - 자체검사성적서(승인본에 한함)<br/>
                  - 제품 취급설명서<br/>
                  - 기타 관련서류<br/>
                  - 시료수 : 형식승인검사 - 3대 / 주요부품의 변경 등 - 2대
                </td>
              </tr>            
            </tbody>
          </table>
        </div>
      </div>  
      <div class="section">
        <div class="title">시험검사의뢰시 확인사항</div>
        <ul class="txt-area">
          <li>
            <p class="txt"><span></span><span>시험, 검사 및 장비사용에 대한 구분</span></p>
            <p class="txt"><span></span><span>시험검사규격 및 시험검사방법</span></p>
            <p class="txt"><span></span><span>영문성적서 발급 여부</span></p>
            <p class="txt red">※ 출장시험 검사 의뢰시는 담당자와 협의</p>
          </li>
        </ul>
      </div>  
      <div class="section">
        <div class="clearfix">
          <div class="title fl">시험검사 수수료</div>
          <div class="title-sub fr">시험검사 수수료는 진흥회 관련규정에 의거하여 산출</div>
        </div>
        <div class="table-area">
          <table class="list-table list-table1">
            <colgroup>
              <col style="width:18%">
              <col style="width:29%">
              <col style="width:14%;">
              <col style="width:14%;">
              <col style="width:12%;">
              <col>
            </colgroup>
            <thead>
            <tr>
              <th>표준명</th>
              <th>표준번호</th>
              <th colspan="2">구분</th>
              <th>시험검사비용(원)</th>
              <th>비고</th>
            </tr>
            </thead>    
            <tbody>
              <tr>
                <th>가스그릴</th>
                <th>KS B 8103</th>
                <td colspan="2">일반의뢰</td>
                <td class="tr">2,090,000</td>
                <td>재료비 별도</td>
              </tr>
              <tr>
                <th rowspan="8">가스레인지</th>
                <th rowspan="8">KS B 8114</th>
                <td rowspan="4">일반의뢰</td>
                <td>1회구</td>
                <td class="tr">2,440,000</td>
                <td rowspan="8">재료비 별도</td>
              </tr>
              <tr>
                <td>2회구</td>
                <td class="tr">2,770,000</td>
              </tr>
              <tr>
                <td>3회구</td>
                <td class="tr">3,080,000</td>
              </tr>
              <tr>
                <td>4회구</td>
                <td class="tr">3,390,000</td>
              </tr>
              <tr>
                <td rowspan="4">환경표지 인증</td>
                <td>1회구</td>
                <td class="tr">300,000</td>
              </tr>
              <tr>
                <td>2회구</td>
                <td class="tr">430,000</td>
              </tr>
              <tr>
                <td>3회구</td>
                <td class="tr">560,000</td>
              </tr>
              <tr>
                <td>4회구</td>
                <td class="tr">690,000</td>
              </tr>
              <tr>
                <th rowspan="2">가스오븐 레인지</th>
                <th rowspan="2">KS B 8115</th>
                <td rowspan="2">일반의뢰</td>
                <td>그릴무</td>
                <td class="tr">4,960,000</td>
                <td rowspan="2">재료비 별도</td>
              </tr>
              <tr>
                <td>그릴유</td>
                <td class="tr">5,230,000</td>
              </tr>
              <tr>
                <th rowspan="4">가스온수 보일러</th>
                <th rowspan="4">KS B 8109</th>
                <td rowspan="2">일반의뢰</td>
                <td>FE</td>
                <td class="tr">6,110,000</td>
                <td rowspan="2">외부시험기관 이용항목 별도</td>
              </tr>
              <tr>
                <td>FF</td>
                <td class="tr">6,900,000</td>
              </tr>
              <tr>
                <td colspan="2">효율등급(2대)</td>
                <td class="tr">912,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td colspan="2">대기권역(2대)</td>
                <td class="tr">1,740,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th rowspan="5">콘덴싱 가스온수 보일러</th>
                <th rowspan="5">KS B 8127</th>
                <td rowspan="2">일반의뢰</td>
                <td>FE</td>
                <td class="tr">6,180,000</td>
                <td rowspan="2">외부시험기관 이용항목 별도</td>
              </tr>
              <tr>
                <td>FF</td>
                <td class="tr">7,180,000</td>
              </tr>
              <tr>
                <td colspan="2">효율등급</td>
                <td class="tr">912,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td colspan="2">환경표지인증</td>
                <td class="tr">1,090,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td colspan="2">대기권역(2대)</td>
                <td class="tr">1,740,000 </td>
                <td>-</td>
              </tr>
              <tr>
                <th rowspan="4">가스순간 온수기</th>
                <th rowspan="4">KS B 8116</th>
                <td rowspan="3">일반의뢰</td>
                <td>FE</td>
                <td class="tr">4,530,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td>FF</td>
                <td class="tr">5,300,000 </td>
                <td>-</td>
              </tr>
              <tr>
                <td>콘덴싱</td>
                <td class="tr">5,450,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td colspan="2">효율등급(2대)</td>
                <td class="tr">537,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th rowspan="3">가스난로</th>
                <th rowspan="3">KS B 8127</th>
                <td rowspan="3">일반의뢰</td>
                <td>방사식</td>
                <td class="tr">2,390,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td>FE</td>
                <td class="tr">3,430,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td>FF</td>
                <td class="tr">4,280,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th>용기내장형 이동식가스난로</th>
                <th>KS B 8125</th>
                <td colspan="2">일반의뢰</td>
                <td class="tr">2,200,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th>가스의류 건조기</th>
                <th>KS B 8122 </th>
                <td colspan="2">일반의뢰</td>
                <td class="tr">5,250,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th>상업용 가스국솥</th>
                <th>KS B 8128</th>
                <td colspan="2">일반의뢰</td>
                <td class="tr">1,200,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th rowspan="3">상업용 가스레인지</th>
                <th rowspan="3">KS B 8129</th>
                <td rowspan="3">일반의뢰</td>
                <td>1회구</td>
                <td class="tr">1,210,000 </td>
                <td rowspan="3">버너추가당 160,000원 추가</td>
              </tr>
              <tr>
                <td>2회구</td>
                <td class="tr">1,420,000 </td>
              </tr>
              <tr>
                <td>3회구</td>
                <td class="tr">1,580,000</td>
              </tr>
              <tr>
                <th rowspan="2">상업용 가스튀김기</th>
                <th rowspan="2">KS B 8131</th>
                <td rowspan="2">일반의뢰</td>
                <td>1조</td>
                <td class="tr">3,370,000</td>
                <td rowspan="2">재료비 별도</td>
              </tr>
              <tr>
                <td>2조</td>
                <td class="tr">3,950,000</td>
              </tr>
              <tr>
                <th rowspan="3">상업용 가스밥솥</th>
                <th rowspan="3">KS B 8135</th>
                <td rowspan="3">일반의뢰</td>
                <td>1단</td>
                <td class="tr">3,510,000 </td>
                <td rowspan="3">재료비 별도, 1단 추가시 440,000원 추가</td>
              </tr>
              <tr>
                <td>2단</td>
                <td class="tr">3,980,000</td>
              </tr>
              <tr>
                <td>3단</td>
                <td class="tr">4,420,000</td>
              </tr>
              <tr>
                <th>상업용 가스식기세척기</th>
                <th>KS B 8136</th>
                <td colspan="2">일반의뢰</td>
                <td class="tr">3,760,000</td>
                <td>재료비 별도</td>
              </tr>
              <tr>
                <th rowspan="2">기름온수보일러</th>
                <th rowspan="2">KS B 8017</th>
                <td colspan="2">일반의뢰</td>
                <td class="tr">4,720,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td colspan="2">대기권역(2대)</td>
                <td class="tr">1,530,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th>기름난로</th>
                <th>KS B 8004</th>
                <td>일반의뢰</td>
                <td>개방식</td>
                <td class="tr">3,070,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th rowspan="2">기름튜브히터</th>
                <th rowspan="2">KS B 8128</th>
                <td>일반의뢰</td>
                <td>개방식</td>
                <td class="tr">5,350,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td>조달 납품</td>
                <td>개방식</td>
                <td class="tr">1,040,000 </td>
                <td>-</td>
              </tr>
              <tr>
                <th rowspan="2">전기 밥솥</th>
                <th rowspan="2">효율관리기자재운용규정별표1의10</th>
                <td>일반의뢰</td>
                <td>-</td>
                <td class="tr">530,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td>효율등급(2대)</td>
                <td>-</td>
                <td class="tr">930,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th rowspan="2">선풍기</th>
                <th rowspan="2">효율관리기자재운용규정별표1의12</th>
                <td>일반의뢰</td>
                <td>-</td>
                <td class="tr">430,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td>효율등급</td>
                <td>-</td>
                <td class="tr">750,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th rowspan="2">전기스토브</th>
                <th rowspan="2">효율관리기자재운용규정별표1의28</th>
                <td>일반의뢰</td>
                <td>-</td>
                <td class="tr">470,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td>효율등급</td>
                <td>-</td>
                <td class="tr">820,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th rowspan="8">전기레인지</th>
                <th rowspan="8">효율관리기자재운용규정별표1의36</th>
                <td rowspan="4">일반의뢰</td>
                <td>1구</td>
                <td class="tr">510,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td>2구</td>
                <td class="tr">918,000 </td>
                <td>-</td>
              </tr>
              <tr>
                <td>3구</td>
                <td class="tr">1,326,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td>4구</td>
                <td class="tr">1,734,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td rowspan="4">효율등급(2대)</td>
                <td>1구</td>
                <td class="tr">900,000 </td>
                <td>-</td>
              </tr>
              <tr>
                <td>2구</td>
                <td class="tr">1,600,000 </td>
                <td>-</td>
              </tr>
              <tr>
                <td>3구</td>
                <td class="tr">2,300,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td>4구</td>
                <td class="tr">3,000,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th rowspan="2">난방용자동온도조절시스템</th>
                <th rowspan="2">KS B 6612</th>
                <td colspan="2">일반의뢰</td>
                <td class="tr">6,100,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td colspan="2">KS제품시험(n=5)</td>
                <td class="tr">9,800,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th rowspan="4">난방온수통합배관시스템</th>
                <th rowspan="4">SPS-B KEAA 0015-6633</th>
                <td rowspan="4">일반의뢰</td>
                <td>세대외 시스템</td>
                <td class="tr">4,100,000 </td>
                <td>-</td>
              </tr>
              <tr>
                <td>세대외 유닛</td>
                <td class="tr">3,600,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td>세대내 시스템</td>
                <td class="tr">3,600,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td>세대내 유닛</td>
                <td class="tr">3,200,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th>상업용냉난방 통합콘트롤러</th>
                <th>SPS-B KEAA 0015-6633</th>
                <td colspan="2">일반의뢰</td>
                <td class="tr">3,730,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th rowspan="2">온수난방용 바닥패널</th>
                <th rowspan="2">KS B 8025</th>
                <td colspan="2">일반의뢰</td>
                <td class="tr">6,760,000</td>
                <td>-</td>
              </tr>
              <tr>
                <td colspan="2">조달납품</td>
                <td class="tr">6,670,000</td>
                <td>-</td>
              </tr>
              <tr>
                <th>공동주택용 건식난방패널</th>
                <th>SPS-B KEAA 0033-7274</th>
                <td colspan="2">일반의뢰</td>
                <td class="tr">6,760,000</td>
                <td>바닥충격음 차단성능별도</td>
              </tr>
              <tr>
                <th>목재펠릿 보일러</th>
                <th>KS B 8901</th>
                <td colspan="2">일반의뢰</td>
                <td class="tr">7,440,000</td>
                <td rowspan="3">연료는의뢰인 제공</td>
              </tr>
              <tr>
                <th rowspan="2">목재펠릿 난로</th>
                <th rowspan="2">SPC-KEIC-A-001</th>
                <td>일반의뢰</td>
                <td>-</td>
                <td class="tr">4,720,000</td>
              </tr>
              <tr>
                <td>단체인증</td>
                <td>-</td>
                <td class="tr">3,770,000 </td>
              </tr>
              <tr>
                <th rowspan="8">비목재펠릿 보일러</th>
                <th rowspan="8">SPS-B KEAA 0034-7275</th>
                <td rowspan="4">일반의뢰 (일반형)</td>
                <td>58 kW 이하</td>
                <td class="tr">6,190,000 </td>
                <td rowspan="4">300kW초과시 별도협의</td>
              </tr>
              <tr>
                <td>58 kW 초과<br/>100 kW 이하</td>
                <td class="tr">8,040,000 </td>
              </tr>
              <tr>
                <td>100 kW 초과<br/>200 kW 이하</td>
                <td class="tr">9,280,000 </td>
              </tr>
              <tr>
                <td>200 kW 초과<br/>300 kW 이하</td>
                <td class="tr">10,520,000 </td>
              </tr>
              <tr>
                <td rowspan="4">일반의뢰 (콘덴싱형)</td>
                <td>58 kW 이하</td>
                <td class="tr">6,790,000  </td>
                <td>-</td>
              </tr>
              <tr>
                <td>58 kW 초과<br/>100 kW 이하</td>
                <td class="tr">8,820,000  </td>
                <td>-</td>
              </tr>
              <tr>
                <td>100 kW 초과<br/>200 kW 이하</td>
                <td class="tr">10,180,000 </td>
                <td>-</td>
              </tr>
              <tr>
                <td>200 kW 초과<br/>300 kW 이하</td>
                <td class="tr">11,540,000 </td>
                <td>-</td>
              </tr>
              <tr>
                <th rowspan="2">화목보일러</th>
                <th rowspan="2">SPS-KEAA 23-7211</th>
                <td rowspan="2">일반의뢰</td>
                <td>40 kW 이하</td>
                <td class="tr">5,640,000 </td>
                <td>-</td>
              </tr>
              <tr>
                <td>40 kW 초과<br/>70 kW 이하</td>
                <td class="tr">7,330,000 </td>
                <td>-</td>
              </tr>
              <tr>
                <th>호스접속용 자동가스밸브</th>
                <th>KS B 8133</th>
                <td colspan="2">일반의뢰</td>
                <td class="tr">3,232,000 </td>
                <td>-</td>
              </tr>
              <tr>
                <th>물배관용래칭 솔레노이드밸브</th>
                <th>SPS-KEAA B25-7235</th>
                <td colspan="2">일반의뢰</td>
                <td class="tr">2,060,000 </td>
                <td>-</td>
              </tr>
              <tr>
                <th>산업건물용 가스보일러</th>
                <th>고효율에너지기자재 보급촉진에관한 규정별표2의(1)</th>
                <td colspan="2">고효율인증</td>
                <td class="tr">1,000,000 </td>
                <td>-</td>
              </tr>
            </tbody>
          </table>
        </div>
        <ul class="txt-area">
          <li>
            <p class="txt"><span></span><span>재료비용은 실사용 금액을 별도로 산정한다.</span></p>
            <p class="txt"><span></span><span>품목별 1대 시험기준임(단, 난방용 자동온도조절시스템의 KS제품심사는 n=5를 적용한 기준임)</span></p>
            <p class="txt"><span></span><span>일반의뢰 시험의 경우 시료 추가 시 80%만 적용하고 5대 초과 시는 초과분에 대하여 70 % 적용.</span></p>
            <p class="txt"><span></span><span>VAT는 별도 적용한다.</span></p>
            <p class="txt"><span></span><span>단체표준 인증, 고효율시험 및 목재펠릿보일러 KS인증시험은 회원사 할인율을 적용하지 않는다.</span></p>
            <p class="txt"><span></span><span>기름연소기의 성능시험용 기름 구입 및 연료분석 비용은 의뢰인이 부담한다.</span></p>
            <p class="txt"><span></span><span>시험 검사 항목별 수수료는 품목에 따라 상이하므로 담당자에게 문의하시기 바랍니다.</span></p>
          </li>
        </ul>
        
      </div>  
      
      <div class="section">
        <div class="title">수수료 납부</div>
        <ul class="txt-area">
          <li>
            <p class="txt"><span></span><span>입금계좌 : 176-890101-87305(KEB하나은행)</span></p>
            <p class="txt"><span></span><span>예금주 : 한국에너지기기산업진흥회</span></p>
          </li>
        </ul>
      </div>
      <div class="section">
        <div class="title">불만 처리 신청</div>
        <ul class="txt-area">
          <li>
            <p class="txt"><span></span><span>신청사유 : 시험검사 업무 및 서비스와 관련하여 불만사항(건의사항)이 있을 경우</span></p>
            <p class="txt"><span></span><span>신청방법 : 불만처리신청서를 작성하여 직접 E-mail, Fax 또는 우편으로 신청하거나, 홈페이지 게시판 이용</span></p>
          </li>
        </ul>
      </div>
      <div class="section">
        <div class="title">문의 및 접수 <span>(기술인증실)</span></div>
        <ul class="txt-area">
          <li>
            <p class="txt"><span></span><span>전화 : 031) 480 - 2981</span></p>
            <p class="txt"><span></span><span>팩스 : 031) 480 - 2986</span></p>
          </li>
        </ul>
      </div>            
    </div>  
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>



