<app-header [menuList$]="menuList$" [memberInfo$]="memberInfo$"></app-header>

<app-sub-header [currMenu]="currMenu$" [memberInfo]="memberInfo$"></app-sub-header>

<div class="join-page contents-page">
  <div class="inner-wrap">
    <ul class="tab clearfix">
      <li>01. 약관동의 및 본인인증</li>
      <li>02. 정보입력</li>
      <li class="active">03. 가입완료</li>
    </ul>

    <div class="join-step3">
      <div class="join-info">회원가입이 완료되었습니다.</div>
    
      <div class="btn-area">
        <button class="btn1" routerLink="/">홈으로</button>
        <button class="btn1 blue" routerLink="/member/login">로그인</button>
      </div>
    </div>
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>

