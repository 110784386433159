/*******************************************************************************
  설  명 : 마이페이지 - 회원 비밀번호 변경
  작성자 :
  작성일 :
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '@app/service/auth.service';
import { CommonService } from '@app/service/common.service';
import { UtilService } from '@app/service/util.service';
import { HMemberService } from '@app/home/service/member.service';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public memberInfo: any;

  public form: FormGroup;

  public formErrors = {};

  /*******************************************************************************
    설  명 : 폼 빌드
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      memNo: ['', []],
      password: [ '', [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{5,19}$')] ],
      passwordConfirm: [ '', [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    commonService: CommonService,
    private modalService: NgbModal,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private memberService: HMemberService,
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 로딩 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.form.patchValue({
      memNo: this.memberInfo.memNo
    });
  }

  /*******************************************************************************
    설  명 : 비밀번호 변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public submit() {
    if( this.form.value.password !== this.form.value.passwordConfirm ) {
      this.toastrService.error("비밀번호와 비밀번호 확인의 값이 일치하지 않습니다.");
      return;
    }

    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if( this.form.valid ) {
      this.memberService.setChangePassword( this.form ).then(response => {
        if( response['ResultCode'] == true) {
          this.toastrService.success(response['ResultMessage'], '회원정보수정 성공');

          this.activeModal.close();

        } else {
          this.toastrService.error(response['ResultMessage'], '회원정보수정 실패');
        }
      })
    }
  }
}
