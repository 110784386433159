/*******************************************************************************
  설  명 : 진흥회 가입신청 모달
  작성일 : 2020-12-22
  작성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '@app/service/util.service';
import { ToastrService } from 'ngx-toastr';
import { HAuthService } from '@home/service/auth.service';
import { HMemberService } from '@home/service/member.service';

@Component({
  selector: 'app-keaa-join-application',
  templateUrl: './keaa-join-application.component.html',
  styleUrls: ['./keaa-join-application.component.scss']
})
export class KeaaJoinApplicationComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  daumAddressOptions =  {
    class: ['btn', 'btn-default', 'btn-small', 'f12']
  };

  public memberInfo: any = {};

  public form: FormGroup;
  public formErrors = {};

  public check = false;

  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      seq: ['', [Validators.required]],
      com_kor: ["", [Validators.required] ],
      name_kor: ["", [Validators.required] ],
      product_kor: ["", [Validators.required] ],
      com_number: ["", [Validators.required] ],
      zipcode: ["", [Validators.required]],
      address: ["", [Validators.required] ],
      address_detail: ["", [Validators.required] ],
      tel1: ["", [Validators.required] ],
      tel2: ["", [Validators.required] ],
      tel3: ["", [Validators.required] ],
      zipcode_factory: ["", [Validators.required] ],
      address_factory: ["", [Validators.required] ],
      address_detail_factory: ["", [Validators.required] ],
      tel_factory1: ["", [Validators.required] ],
      tel_factory2: ["", [Validators.required] ],
      tel_factory3: ["", [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private authService: HAuthService,
    private utilService: UtilService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private memberService: HMemberService,
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.authService.getHomeLoginData.subscribe(data => {
      this.memberInfo = data;
      this.getCompanyInfo();
    });
  }

  /*******************************************************************************
    설  명 : 기업정보 가져오기
  *******************************************************************************/
  getCompanyInfo() {
    this.memberService.getCompanyInfo( this.memberInfo.companySeq ).then( response => {
      if ( response.ResultCode ) {
        let tmp1 = '';
        let tmp2 = '';
        let tmp3 = '';
        let tmp4 = '';
        let tmp5 = '';
        let tmp6 = '';

        let teltmp1 = response.data[0].tel.split('-');
        tmp1 = teltmp1[0];
        tmp2 = teltmp1[1];
        tmp3 = teltmp1[2];
        let teltmp2 = response.data[0].tel_factory.split('-');
        tmp4 = teltmp2[0];
        tmp5 = teltmp2[1];
        tmp6 = teltmp2[2];

        this.form.patchValue({
          seq: response.data[0].seq,
          com_kor: response.data[0].com_kor,
          name_kor: response.data[0].name_kor,
          product_kor: response.data[0].product_kor,
          com_number: response.data[0].com_number,
          zipcode: response.data[0].zipcode,
          address: response.data[0].address,
          address_detail: response.data[0].address_detail,
          tel1: tmp1,
          tel2: tmp2,
          tel3: tmp3,
          zipcode_factory: response.data[0].zipcode_factory,
          address_factory: response.data[0].address_factory,
          address_detail_factory: response.data[0].address_detail_factory,
          tel_factory1: tmp4,
          tel_factory2: tmp5,
          tel_factory3: tmp6,
        });
      }
    });
  }

  /*******************************************************************************
    설  명 : 다음주소창에서 클릭시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setDaumAddress1Api(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode: data.zip,
      address: data.addr,
    });
  }

  setDaumAddress2Api(data) {
    // 여기로 주소값이 반환
    this.form.patchValue({
      zipcode_factory: data.zip,
      address_factory: data.addr,
    });
  }

  /*******************************************************************************
    설  명 : 진흥회 가입신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setApplication() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if ( !this.check ) {
      this.toastrService.error('가입신청 체크박스를 클릭해주세요.');
      return;
    }

    if ( this.form.valid ) {
      this.memberService.setApplication( this.form ).then(response => {
        if ( response.ResultCode == true ) {
          this.toastrService.success(response.ResultMessage, '가입신청');

          this.activeModal.close();
        } else {
          this.toastrService.error(response.ResultMessage, '가입신청');
        }
      });
    } else {
      this.toastrService.error('필수 입력값을 확인하시기 바랍니다.', '가입신청');
    }
  }

  /*******************************************************************************
    설  명 : 본사와 동일 버튼 이벤트 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setSameAddress() {
    this.form.patchValue({
      zipcode_factory: this.form.controls.zipcode.value,
      address_factory: this.form.controls.address.value,
      address_detail_factory: this.form.controls.address_detail.value,
    });
  }

}
