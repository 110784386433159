<div class="application-modal">
  <div class="modal-header">
    <div class="modal-title">온라인 성적서진위확인 신청</div>
    <button type="button" class="close" (click)="activeModal.dismiss()"></button>
  </div>
  
  <div class="modal-body">
    <div class="section">
      <div class="section-title">신청자 정보</div>
      <div class="guide-txt"></div>
      <table class="table1">
        <colgroup>
          <col class="col1">
          <col class="col2">
          <col class="col3">
          <col class="col4">
          <col class="col5">
          <col class="col6">
        </colgroup>
        
        <tbody>
        <tr>
          <th>소속</th>
          <td><input type="text" [(ngModel)]="applyInfo.company" /></td>
          <th>부서</th>
          <td><input type="text" [(ngModel)]="applyInfo.depart" /></td>
          <th>성명</th>
          <td><input type="text" [(ngModel)]="applyInfo.name" /></td>
        </tr>
        <tr>
          <th>연락처</th>
          <td><input type="text" [(ngModel)]="applyInfo.hp" /></td>
          <th>이메일</th>
          <td colspan="3">
            <input type="text" style="width: 150px;" [(ngModel)]="applyInfo.email_id"/>
            <span style="margin: 0 5px;">@</span>
            <input type="text" style="width: 150px" [(ngModel)]="applyInfo.email_domain" />
            <select style="margin-left: 5px; height: 30px; width: 100px;" (change)="setEmailDomain($event)">
              <option value="">직접입력</option>
              <option>gmail.com</option>
              <option>daum.net</option>
              <option>naver.com</option>
            </select>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="section">
      <div class="section-title">신청 정보</div>
      <table class="table2">
        <colgroup>
          <col class="col1">
          <col class="col2">
          <col class="col3">
          <col class="col4">
          <col class="col5">
          <col class="col6">
          <col class="col7">
          <col class="col8">
        </colgroup>
        
        <tbody>
        <tr>
          <th>파일업로드</th>
          <td>
            <!-- <input type="file" style="line-height: 21px;" (change)="fileUpload($event)" accept=".pdf" /> -->
            <input type="file" name="file" id="file" formControlName="file" ng2FileSelect (change)="handleUploadFileChanged($event)" style="width: 100%;"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
  
  <div class="modal-footer clearfix">
    <button class="btn1" (click)="activeModal.dismiss()">취소</button>
    <button class="btn1 blue" (click)="submit()">신청</button>
  </div>
</div>


