/*******************************************************************************
  설  명 : 마이페이지 - 회원정보수정
  작성자 :
  작성일 :
*******************************************************************************/
import { Component, OnInit } from '@angular/core';

import { NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CompanySearchComponent } from '../../join/join-step2/company-search/company-search.component';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '@app/service/util.service';
import { AuthService } from '@app/service/auth.service';
import { HMemberService } from '@app/home/service/member.service';

import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';
import { CommonService } from '@app/service/common.service';
import { ActivatedRoute, Router } from '@angular/router';

import { PasswordChangeComponent } from "./password-change/password-change.component";

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-company'
};

@Component({
  selector: 'app-member-edit',
  templateUrl: './member-edit.component.html',
  styleUrls: ['./member-edit.component.scss']
})
export class MemberEditComponent extends O2OZCMS implements OnInit {

  passwordConfirm: boolean = false; // 비밀번호 확인
  isGender = 'M'; // 성별
  isSms = 'Y'; // SMS 수신
  isKakao = 'Y';
  isBike = 'Y'; // bike 유무
  today: string;

  public memberInfo: any = {};

  public form: FormGroup;

  private dateModel: NgbDateStruct;

  public formErrors = {};

  public email_array = [
    'naver.com',
    'gmail.com',
    'daum.net',
    'nate.com',
    'dreamwiz.comn',
    'yahoo.co.kr',
    'outlook.com',
    'hotmail.com',
  ];

  public daumAddressOptions =  {
    class: ['btn', 'zip-btn']
  };

  constructor(
    private modalService: NgbModal,
    private utilService: UtilService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    toastrService: ToastrService,
    private memberService: HMemberService,
    activatedRoute: ActivatedRoute,
    router: Router,
    commonService: CommonService,
  ) {
    super(router, commonService, activatedRoute, toastrService);
    this.buildEditForm();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
  *******************************************************************************/
  buildEditForm(): void {
    this.form = this.formBuilder.group({
      memNo: ['', [] ],
      userName: [ '', [Validators.required, Validators.pattern('^[가-힣a-zA-Z]*$')] ],
      userId: [ '', [Validators.required, Validators.pattern('^[a-z0-9]{5,20}$')] ],
      idCheck:[ false, [Validators.required] ],
      /*
      password: [ '', [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{5,19}$')] ],
      passwordConfirm: [ '', [Validators.required] ],
      */
      userGubun: [ '1000', [Validators.required] ],
      mobile: [ '', [] ],
      mobile1: [ '', [Validators.required, Validators.pattern('^[0-9]{2,3}$')] ],
      mobile2: [ '', [Validators.required, Validators.pattern('^[0-9]{3,4}$')] ],
      mobile3: [ '', [Validators.required, Validators.pattern('^[0-9]{4}$')] ],
      email: [ '', [] ],
      email1: [ '', [Validators.required, Validators.pattern('^[a-z0-9._-]{1,30}$')] ],
      email2: [ '', [Validators.required, Validators.pattern('^[a-z0-9.-]+\\.[a-z]{2,4}$')] ],
      email3: [ '', [] ],
      smsCheck: [ true, [] ],

      companySeq: [ '', [] ],
      companyName: [ '', [Validators.required] ],
      ceoName: [ '', [Validators.required] ],
      comNumber: [ '', [Validators.pattern('^[0-9-]+$'), Validators.minLength(10), Validators.maxLength(12)] ],
      memberStatus: [ '1000', [Validators.required] ],
      tel: [ '', [] ],
      telephone: [ '', [] ],
      comEail: [ '', [] ],
      comEmail1: [ '', [Validators.required, Validators.pattern('^[a-z0-9._-]{1,30}$')] ],
      comEmail2: [ '', [Validators.required, Validators.pattern('^[a-z0-9.-]+\\.[a-z]{2,4}$')] ],
      comEmail3: [ '', [] ],
      comDept: [ '', [] ],
      comJob: [ '', [] ],
      zipcode: [ '', [Validators.required] ],
      address: [ '', [Validators.required] ],
      addressDetail: [ '', [Validators.required] ],
      job: [ '', [] ],
      job1: [ '', [] ],
      company_approval: [ '', [] ], 
      company_approval_nm: [ '', [] ], 
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    })
  }

  ngOnInit(): void {
    this.getMemberInfo();

    this.form.patchValue({
      userId: this.memberInfo.userId
    });
  }

   /*******************************************************************************
    설  명 : 회사검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  searchCompany() {
    const modalRef = this.modalService.open(CompanySearchComponent, options);

    modalRef.result.then((result) => {
      let telTmp1 = '';
      let telTmp2 = '';
      let telTmp3 = '';
      let emailTmp1 = '';
      let emailTmp2 = '';

      // 전화번호 - 기준으로 분할
      let tmp = result.tel.split('-');
      telTmp1 = tmp[0];
      telTmp2 = tmp[1];
      telTmp3 = tmp[2];

      // 이메일 @ 기준으로 분할
      let tmp1 = result.email.split('@');
      emailTmp1 = tmp1[0];
      emailTmp2 = tmp1[1];


      this.form.patchValue({
        companySeq: result.seq,
        companyName: result.companyName,
        ceoName: result.ceoName,
        comNumber: result.comNumber.replace(/\-/g,''),
        telephone1: telTmp1,
        telephone2: telTmp2,
        telephone3: telTmp3,
        comEmail1: emailTmp1,
        comEmail2: emailTmp2,
        comEmail3: emailTmp2,
        comDept: result.job,
        comJob: result.job1,
        zipcode: result.zipcode,
        address: result.address,
        addressDetail: result.addressDetail,
      });
    }, (reason) => {
    });
  }


  /*******************************************************************************
    설  명 : 사용자 정보 가져오기
  *******************************************************************************/
  getMemberInfo() {
    this.memberService.getMemberInfo().then(response => {
      if( response['ResultCode'] == true ) {
        let data = response.data;

        let hp = [];

        data.telephone = data.telephone??'';
        if( data.telephone !== '' ) {
          hp = data.telephone.split('-');
          data.hp1 = hp[0];
          data.hp2 = hp[1];
          data.hp3 = hp[2];
        }

        if( data.mobile !== '' ) {
          let mobile = data.mobile.split('-');
          data.mobile1 = mobile[0];
          data.mobile2 = mobile[1];
          data.mobile3 = mobile[2];
        }
        
        if( data.tel !== '' ) {
          let tel = data.tel.split('-');
          data.telephone1 = tel[0];
          data.telephone2 = tel[1];
          data.telephone3 = tel[2];
        }

        if( data.email !== '' ) {
            let emailArr = data.email.split('@');
            data.email1 = emailArr[0];
            data.email2 = emailArr[1];
            data.email3 = "";

          this.email_array.forEach( item => {
            if( item == emailArr[1] ) {
              data.email3 = emailArr[1];
              return;
            }
          });
        }

        if( data.companyEmail !== '' ) {
          let emailArr = data.companyEmail.split('@');
          data.comEmail1 = emailArr[0];
          data.comEmail2 = emailArr[1];
          data.comEmail3 = "";

          this.email_array.forEach( item => {
            if( item == emailArr[1] ) {
              data.comEmail3 = emailArr[1];
              return;
            }
          });
        }

        if( data.company_approval == '1' ) {
          data.company_approval_nm = '승인';
        } else {
          data.company_approval_nm = '미승인';
        }

        this.form.patchValue( data );
      }
    })
  }

  /*******************************************************************************
    설  명 : 이메일 선택 입력 이벤트
  *******************************************************************************/
    setEmail(event) {
      if ( event.target.value == '직접 입력' ) {
        this.form.patchValue({email2: ''});
      } else {
        this.form.patchValue({email2: event.target.value});
      }
    }

    setEmail1(event) {
      if ( event.target.value == '직접 입력' ) {
        this.form.patchValue({comEmail2: ''});
      } else {
        this.form.patchValue({comEmail2: event.target.value});
      }
    }

    /*******************************************************************************
      설  명 : 다음주소창에서 클릭시 처리
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/
    setDaumAddress1Api(data) {
      // 여기로 주소값이 반환
      this.form.patchValue({
        zipcode: data.zip,
        address: data.addr,
        address_detail: data.addrEng
      });
    }


  /*******************************************************************************
    설  명 : 회원정보수정 처리
  *******************************************************************************/
  public submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
    if( this.form.valid) {
      this.memberService.setMemberSave( this.form ).then(response => {
        if( response['ResultCode'] == true) {
          this.toastrService.success(response['ResultMessage'], '회원정보수정 성공');
          // this. router.navigate(['/mypage/dashboard']);
        } else {
          this.toastrService.error(response['ResultMessage'], '회원정보수정 실패');
        }
      })
    }
  }

  /*******************************************************************************
    설  명 : 비밀번호 변경
  *******************************************************************************/
  public changePassword() {
    const modalRef = this.modalService.open(PasswordChangeComponent, options);

    modalRef.componentInstance.memberInfo = this.form.value;

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

}
