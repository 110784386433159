<app-header [menuList$]="menuList$" [memberInfo$]="memberInfo$"></app-header>

<app-sub-header [currMenu]="currMenu$" [memberInfo]="memberInfo$"></app-sub-header>

<div class="contents-page">
  <!-- 서브메뉴 탭 구성 //-->
  <ng-container *ngIf="(currMenu$ | async)['page_cd'].length > 6 && (currMenu$ | async)['parentNode']['children'].length > 0">
    <div class="inner-wrap">
      <ul class="sub-tab">
        <!-- <li class="nav-item" *ngFor="let item of (currMenu$ | async)['parentNode']['children']; index as i">
          <a class="cp nav-link" [ngClass]="{active: (currMenu$ | async)['seq'] == item.seq}"  (click)="goLink(item)">{{item.page_menu_name}}</a>
        </li> -->

        <li class="nav-item" *ngFor="let item of menu['parentNode']['children']; index as i">
          <a class="cp nav-link" [ngClass]="{active: this.active_seq == item.seq}"  (click)="goLink(item)">
            {{item.page_menu_name}}
          </a>
        </li>
      </ul>
    </div>
  </ng-container>
  <!-- 서브메뉴 탭 구성 //-->

  <!-- 서브에 서브일 경우 //-->
  <ng-container *ngIf="checkForceLevel(currMenu$ | async)">
  <div class="submenu-tab inner-wrap">
    <div class="page-title">
      {{(currMenu$ | async)['parentNode']['page_name']}}
    </div>
    <div class="page-title-bar"></div>

    <div *ngIf="(currMenu$ | async)['parentNode']['contents']" [innerHTML]="(currMenu$ | async)['parentNode']['contents'] | safe: 'html'"></div>

    <div class="submenu-tab-ul">
      <a class="cp nav-item" *ngFor="let item of (currMenu$ | async)['parentNode']['children']" (click)="goLink(item)">
        <span class="nav-link" [ngClass]="{active: item.seq == (currMenu$ | async)['seq']}">
          {{item['page_name']}}
        </span>
      </a>
    </div>
  </div>
  </ng-container>
  <!-- 서브에 서브일 경우 //-->

  <div *ngIf="(currMenu$ | async)['contents']" [innerHTML]="(currMenu$ | async)['contents'] | safe: 'html'"></div>

  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>
<app-footer></app-footer>
