/*******************************************************************************
  설  명 : 홈페이지 > 컨텐츠 표시
  작성자 : 송영석
  작성일 : 2020-06-05
*******************************************************************************/
import { Component, OnInit, Renderer2, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { CommonService } from '@app/service/common.service';
import { AuthService } from '@app/service/auth.service';
import { HMemberService } from '@app/home/service/member.service';
import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';

//import { ApplicationModalComponent } from '@home/page/online-application/application-modal/application-modal.component';
import { ApplicationModal1Component } from '@home/page/online-application/accredited-test/application-modal1/application-modal1.component';
import { ApplicationModal2Component } from '@home/page/online-application/accredited-test/application-modal2/application-modal2.component';
import { ApplicationModal3Component } from '@home/page/online-application/accredited-test/application-modal3/application-modal3.component';
import { ApplicationModal4Component } from '@home/page/online-application/safety-confirmation-test/application-modal4/application-modal4.component';

import { ComplainModalComponent } from '@home/page/online-application/accredited-test/complain-modal/complain-modal.component';
import { StandardDevelopmentApplication1Component } from '@home/page/online-application/standard-development/standard-development-application1/standard-development-application1.component';
import { StandardDevelopmentApplication2Component } from '@home/page/online-application/standard-development/standard-development-application2/standard-development-application2.component';
import { KeaaJoinApplicationComponent } from '@home/page/online-application/keaa-join/keaa-join-application/keaa-join-application.component';
import { ReportCheckModalComponent } from '../online-application/report-check-modal/report-check-modal.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-certification'
};

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-certification'
};

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass:'modal-certification'
};

declare var daum: any;

@Component({
  selector: 'app-contents',
  templateUrl: './contents.component.html',
  styleUrls: ['./contents.component.scss']
})
export class ContentsComponent extends O2OZCMS implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public menu: any;
  public active_seq = '';
  public tabIndex: any = 0;
  private fragment: string;
  public memberInfo: any = {};
  public loginData: any = {};
  public loginId = '';
  public userLevel = '';

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    router: Router,
    commonService: CommonService,
    public authService: AuthService,
    public memberService: HMemberService,
    activatedRoute: ActivatedRoute,
    toastrService: ToastrService,
    private modalService: NgbModal,
    public renderer: Renderer2,
    private ngZone: NgZone,
    private route: ActivatedRoute,
  ) {
    // 상위 클래스에 router 전달
    super(router, commonService, activatedRoute, toastrService);
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    this.authService.getLoginInfo.subscribe(data => {
      this.memberInfo = data;
      this.getMemberInfo();
    });


    // javascript function call 모달 창 호출
    window['angularComponentReference'] = {
      component: this,
      zone: this.ngZone,
      applicationModal: (seq) => this.applicationModal(seq),
      authenticityModal: () => this.authenticityModal(),
      reissuanceModal: () => this.reissuanceModal(),
      complainModal: () => this.complainModal(),
      proposalModal: () => this.proposalModal(),
      requestModal: () => this.requestModal(),
      keaaJoin: () => this.keaaJoin(),
    };

    try {
      this.currMenu$.subscribe( ( menu: any ) => {
        if( typeof menu['seq'] == 'undefined' ) return false;

        // 다음맵 로딩
        if( menu.seq == '764' ) {
          setTimeout( () => {
            new daum.roughmap.Lander({
              "timestamp" : "1640172957849",
              "key" : "28isw",
              "mapWidth" : "968",
              "mapHeight" : "358"
            }).render();
          }, 1000);
          setTimeout( () => {
            new daum.roughmap.Lander({
              "timestamp" : "1640173720814",
              "key" : "28isy",
              "mapWidth" : "968",
              "mapHeight" : "358"
            }).render();
          }, 1000);
        }
        if( menu.seq == '766' ) {
          setTimeout( () => {
            new daum.roughmap.Lander({
              "timestamp" : "1640173847117",
              "key" : "28it2",
              "mapWidth" : "968",
              "mapHeight" : "358"
            }).render();
          }, 1000);
        }

        // 회원사 소개
        if( menu.seq == '690' ) {
          this.router.navigate(
            ['/company/introduce'],
            {
              relativeTo: this.activatedRoute,
              queryParamsHandling: '', // remove to replace all query params by provided
            }
          );
        }

        // 성상분석 DATA
        if( menu.seq == '487' ) {
          this.router.navigate(
            ['/service/analysis'],
            {
              relativeTo: this.activatedRoute,
              queryParamsHandling: '', // remove to replace all query params by provided
            }
          );
        }

        const page = ( menu.page_cd ) ? menu.page_cd : '0';

        switch (page.length) {
          case 12:
            this.active_seq = menu.parentNode.seq;
            this.menu = menu.parentNode;
            break;
          default:
            this.active_seq = menu.seq;
            this.menu = menu;
            break;
        }
      });

      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }

  /*******************************************************************************
    설  명 : 사용자 정보 가져오기
  *******************************************************************************/
    getMemberInfo() {
      this.memberService.getMemberInfo().then(response => {
        if( response['ResultCode'] == true ) {
          let data = response['data'];
          console.log('data');
  //        console.log(data);
          console.log(data);
          this.loginId = data.userId;
          this.userLevel = data.level;
        }
      })

      .catch(response => {
        this.toastrService.error('사용자 정보를 가져오는데 실패하였습니다.', '');
      });

    }

  /*******************************************************************************
    설  명 : 화면 렌더링 후 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {
  }

  /*******************************************************************************
    설  명 : 서브메뉴 링크
    입력값 : item = 메뉴 array
    리턴값 : 없음
  *******************************************************************************/
  goLink( menu ) {

    let href = menu.link_url;
    let boardId = menu.board_id;
    let menuIndex = menu.seq;

    if( href === '' ) {
      let result = false;
      const link = function getURL(item) {
        if (item.link_url === '' && item.board_id === '') {
          for (const element of item.children) {
            getURL(element);
            if (result) {
              return false;
            }
          }
        } else {
          href = item.link_url;
          boardId = item.board_id;
          menuIndex = item.seq;
          result = true;
        }
      };
      link(menu);
    } else {
      if( menu.link_open_yn == 'C' )
        this.router.navigate([ href ]);
      else {
        if( href.indexOf('http') > -1 ) {
          window.open("about:blank").location.href = href;
        } else {
          window.open("about:blank").location.href = location.origin + href;
        }
      }
    }

    if ( boardId === '' ) {
      this.router.navigate([ href ], { queryParams: { menu_seq: menuIndex }});
    } else {
      this.router.navigate([ (href !== '' ? href : '/board/list') ], { queryParams: { board_id: boardId } });
    }
  }

  /*******************************************************************************
    설  명 : 패스 리스트
    입력값 : 없음
    리턴값 : string html
  *******************************************************************************/
  getPathName( item, html ) {
    if( typeof item['parentNode'] == 'undefined' ) return html;
    else {
      html = '<span style="padding:0px 14px;">></span><span class="depth1">' + item['page_menu_name'] + '</span>' + html;
      return this.getPathName( item['parentNode'], html );
    }
  }

  getPath( item ) {
    let html = this.getPathName( item, '' );

    return html;
  }

  /*******************************************************************************
    설  명 : 현재 단계가 4단계 이고, 3단계에 여러 메뉴가 있을 경우
    입력값 : item
    리턴값 : true / false
  *******************************************************************************/
  checkForceLevel( item: any ) {
    if( item.page_cd.length == 12 && item.parentNode.children.length > 1 ) {
      return true;
    } else {
      return false;
    }
  }

  /*******************************************************************************
    설  명 : 온라인 시험 검사신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  applicationModal(tabIndex: any) {
    if ( this.userLevel?.trim() == '' || parseInt(this.userLevel) < 1 ) {
      this.toastrService.warning( '회원 로그인이 필요합니다.', '' );

      this.router.navigate(
        ['/member/login'],
        {
          relativeTo: this.activatedRoute,
          queryParamsHandling: '', // remove to replace all query params by provided
        }
      );

      return;

    } else {

      // 기업승인여부가 1 인 경우에만 검사신청 가능
      this.memberService.getMemberInfo().then(response => {
        if( response['ResultCode'] == true ) {
          if (response.data.company_approval == '1') {
            let modalRef = null;

            if( tabIndex === 1 ) {
              modalRef = this.modalService.open(ApplicationModal1Component, optionsXL);
            } else if( tabIndex === 2 ) {
              modalRef = this.modalService.open(ApplicationModal2Component, optionsXL);
            } else if( tabIndex === 3 ) {
              modalRef = this.modalService.open(ApplicationModal3Component, optionsXL);
            } else if( tabIndex === 4 ) {
              modalRef = this.modalService.open(ApplicationModal4Component, optionsXL);
            }
      
            // const modalRef = this.modalService.open(ApplicationModalComponent, optionsXL);
      
            modalRef.componentInstance.tabIndex = tabIndex;
            //modalRef.componentInstance.tabChange(tabIndex - 1);
      
            modalRef.result.then((result) => {
              if( result ) {
              }
            }, (reason) => {
            });
          } else {
            this.toastrService.error('기업 승인처리가 되지 않은 회원입니다.', '');
          }             
        }
      })
      
    }
  }

  /*******************************************************************************
    설  명 : 불만처리신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  complainModal() {
    if ( this.userLevel?.trim() == '' || parseInt(this.userLevel) < 1 ) {
      this.toastrService.warning( '회원 로그인이 필요합니다.', '' );

      this.router.navigate(
        ['/member/login'],
        {
          relativeTo: this.activatedRoute,
          queryParamsHandling: '', // remove to replace all query params by provided
        }
      );

      return;
    } else {

      // 기업승인여부가 1 인 경우에만 검사신청 가능
      this.memberService.getMemberInfo().then(response => {
        if( response['ResultCode'] == true ) {
          if (response.data.company_approval == '1') {
            const modalRef = this.modalService.open(ComplainModalComponent, optionsXL);

            modalRef.result.then((result) => {
              if( result ) {
              }
            }, (reason) => {
            });
          } else {
            this.toastrService.error('아직 승인 처리가 되지 않은 아이디입니다.', '');
          }             
        }
      })  
    }

  }

  /*******************************************************************************
    설  명 : 재발급신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  reissuanceModal() {
    if ( this.userLevel?.trim() == '' || parseInt(this.userLevel) < 1 ) {
      this.toastrService.warning( '회원 로그인이 필요합니다.', '' );

      this.router.navigate(
        ['/member/login'],
        {
          relativeTo: this.activatedRoute,
          queryParamsHandling: '', // remove to replace all query params by provided
        }
      );

      return;
    }  else  {
      const modalRef = this.modalService.open(ComplainModalComponent, optionsXL);

      modalRef.result.then((result) => {
        if( result ) {
        }
      }, (reason) => {
      });

    }

  }

  /*******************************************************************************
    설  명 : 성적서진위확인신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  authenticityModal() {
    const modalRef = this.modalService.open(ReportCheckModalComponent, optionsXL);

    modalRef.result.then((result) => {
      if( result ) {
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 표준개발협력제안
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  proposalModal() {
    if (this.userLevel !='99') {
      this.toastrService.warning( '서비스 준비중입니다.', '' );
      return;
    } else {
      const modalRef = this.modalService.open(StandardDevelopmentApplication1Component, optionsLG);

      modalRef.result.then((result) => {
        if( result ) {
        }
      }, (reason) => {
      });
    }

  }

  /*******************************************************************************
    설  명 : 표준개정검토 요청서
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  requestModal() {
    if (this.userLevel !='99') {
      this.toastrService.warning( '서비스 준비중입니다.', '' );
      return;
    } else {
      const modalRef = this.modalService.open(StandardDevelopmentApplication2Component, optionsLG);

      modalRef.result.then((result) => {
        if( result ) {
        }
      }, (reason) => {
      });
    }

  }

  /*******************************************************************************
    설  명 : 진흥회 가입신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  keaaJoin() {
    const modalRef = this.modalService.open(KeaaJoinApplicationComponent, optionsLG);

    modalRef.result.then((result) => {
      if( result ) {
      }
    }, (reason) => {
    });
  }


}
