<header class="header" [ngClass]="{open: isOpen, fullpage:isFullpage}" (mouseleave)="closeMenu()">
  <div class="header-inner clearfix">
    <div class="top-logo">
      <a routerLink="/main">한국에너지기기산업진흥회</a>

    </div>
    <div class="gnb" [ngClass]="{mopen: isMobileOn}">
      <a class="close-menu" *ngIf="isMobileOn || isFullpage" (click)="closeMenu1();">닫기</a>
      <ul class="main-menu" (mouseenter)="expandMenu()">
        <li *ngFor="let menu of menuList; let i = index">
          <a class="cp" [ngClass]="{on: menu_on == i.toString()}" (click)="goLink($event, [menu], i)">{{menu.page_name}}
            <span class="dot"></span>
          </a>

          <ul class="sub-menu" [ngClass]="{'sub-menu-even': (i % 2)==1, 'on': menu_on == i.toString()}">
            <ng-container *ngFor="let item of menu['children']">
              <ng-container *ngIf="item.mainmenu_view_yn == '1'">
                <ng-container *ngIf="item.link_open_yn == 'N'">
                  <ng-container *ngIf="item.link_url.includes('http'); else route">
                    <li (click)="goLink($event, [item], -1)">
                      <a class="cp" (click)="goLink($event, [item], -1)">
                        {{item.page_name}}<span class="dot"></span>
                      </a>
                    </li>
                  </ng-container>
                  <ng-template #route>
                    <li (click)="goLink($event, [item], -1)">
                      <a class="cp" (click)="goLink($event, [item], -1)">
                        {{item.page_name}}<span class="dot"></span>
                      </a>
                    </li>
                  </ng-template>
                </ng-container>

                <ng-container *ngIf="item.link_open_yn == 'C'">
                  <li (click)="goLink($event, [item], -1)">
                    <a class="cp" (click)="goLink($event, [item], -1)">
                      {{item.page_name}}<span class="dot"></span>
                    </a>
                  </li>
                </ng-container>
              </ng-container>
            </ng-container>
          </ul>
        </li>
        <!-- <li>
          <a class="cp" [ngClass]="{on: menu_on == '1'}" (click)="goLink($event, '/eatl/business', '1')">부설 에너지기기 시험원</a>
          <ul class="sub-menu" [ngClass]="{on: menu_on == '1'}">
            <li>
              <a routerLink="/eatl/business" (click)="closeMenu1();">사업안내</a>
              <ul class="sub-menu-depth1">
                <li><a routerLink="/eatl/business" [queryParams]="{tabIndex:'1'}" (click)="closeMenu1();">국가공인시험검사기관</a></li>
                <li><a routerLink="/eatl/business" [queryParams]="{tabIndex:'2'}" (click)="closeMenu1();">KS표시인증업체협의회</a></li>
                <li><a routerLink="/eatl/business" [queryParams]="{tabIndex:'3'}" (click)="closeMenu1();">안전확인시험검사기관</a></li>
                <li>
                  <a routerLink="/eatl/business" [queryParams]="{tabIndex:'4'}" (click)="closeMenu1();">표준개발협력기관</a>
                  <ul class="sub-menu-depth2">
                    <li><a routerLink="/eatl/business" [queryParams]="{tabIndex:'4'}" (click)="closeMenu1();">- COSD</a></li>
                    <li><a routerLink="/eatl/business" [queryParams]="{tabIndex:'4'}" (click)="closeMenu1();">- 국제표준화활동</a></li>
                    <li><a routerLink="/eatl/business" [queryParams]="{tabIndex:'4'}" (click)="closeMenu1();">- 정책대응</a></li>
                    <li><a routerLink="/eatl/business" [queryParams]="{tabIndex:'4'}" (click)="closeMenu1();">- 표준화활동 KS고시</a></li>
                  </ul>
                </li>
                <li><a routerLink="/eatl/business" [queryParams]="{tabIndex:'5'}" (click)="closeMenu1();">품목별 품질관리기관</a></li>
                <li><a routerLink="/eatl/business" [queryParams]="{tabIndex:'6'}" (click)="closeMenu1();">가스보일러실환경실증실험실</a></li>
              </ul>
            </li>
            <li><a routerLink="/eatl/equipment" (click)="closeMenu1();">보유설비</a></li>
            <li><a routerLink="/eatl/promote" (click)="closeMenu1();">홍보자료</a></li>
          </ul>
        </li>
        <li>
          <a class="cp" [ngClass]="{on: menu_on == '2'}" (click)="goLink($event, '/online/accreditedTest', '2')">온라인 신청</a>
          <ul class="sub-menu" [ngClass]="{on: menu_on == '2'}">
            <li>
              <a routerLink="/online/accreditedTest" (click)="closeMenu1();">공인시험검사신청</a>
              <ul class="sub-menu-depth1">
                <li><a routerLink="/online/accreditedTest" (click)="closeMenu1();">시험검사신청</a></li>
                <li><a routerLink="/online/accreditedTest" (click)="closeMenu1();">장비사용신청</a></li>
                <li><a routerLink="/online/accreditedTest" (click)="closeMenu1();">형식승인검사신청</a></li>
                <li><a routerLink="/online/accreditedTest" (click)="closeMenu1();">부품인증신청</a></li>
                <li><a routerLink="/online/accreditedTest" (click)="closeMenu1();">불만처리신청</a></li>
                <li><a routerLink="/online/accreditedTest" (click)="closeMenu1();">재발급신청</a></li>
                <li><a routerLink="/online/accreditedTest" (click)="closeMenu1();">성적서진위확인신청</a></li>
              </ul>
            </li>
            <li>
              <a routerLink="/online/SafetyConfirmationTest" (click)="closeMenu1();">안전확인시험검사신청</a>
              <ul class="sub-menu-depth1">
                <li><a routerLink="/online/SafetyConfirmationTest" (click)="closeMenu1();">시험검사신청</a></li>
              </ul>              
            </li>
            <li>
              <a routerLink="/online/StandardDevelopment" (click)="closeMenu1();">표준개발협력제안</a>
              <ul class="sub-menu-depth1">
                <li><a routerLink="/online/StandardDevelopment" (click)="closeMenu1();">표준개발협력 제안</a></li>
                <li><a routerLink="/online/StandardDevelopment" (click)="closeMenu1();">표준개정검토요청</a></li>
                <li><a routerLink="/online/StandardDevelopment" (click)="closeMenu1();">민원접수 및 기업애로사항</a></li>
                <li><a routerLink="/online/StandardDevelopment" (click)="closeMenu1();">KS표준현황</a></li>
              </ul>
            </li>
            <li>
              <a routerLink="/online/KeaaJoin" (click)="closeMenu1();">진흥회 가입신청</a>
              <ul class="sub-menu-depth1">
                <li><a routerLink="/online/KeaaJoin" (click)="closeMenu1();">진흥회 가입신청</a></li>
              </ul>  
            </li>
          </ul>
        </li>
        <li>
          <a class="cp" [ngClass]="{on: menu_on == '3'}" (click)="goLink($event, '/service/overseasMarkets', '3')">고객서비스</a>
          <ul class="sub-menu" [ngClass]="{on: menu_on == '3'}">
            <li><a routerLink="/service/overseasMarkets" (click)="closeMenu1();">해외시장 조사자료</a></li>
            <li>
              <a routerLink="/service/statistics" (click)="closeMenu1();">통계자료</a>
              <ul class="sub-menu-depth1">
                <li>
                  <a routerLink="/service/statistics" [queryParams]="{tabIndex:'1'}" (click)="closeMenu1();">무역통계</a>
                  <ul class="sub-menu-depth2">
                    <li><a routerLink="/service/statistics" [queryParams]="{tabIndex:'1'}" (click)="closeMenu1();">- 수출통계</a></li>
                    <li><a routerLink="/service/statistics" [queryParams]="{tabIndex:'2'}" (click)="closeMenu1();">- 수입통계</a></li>
                  </ul>  
                </li>
                <li><a routerLink="/service/statistics" [queryParams]="{tabIndex:'2'}" (click)="closeMenu1();">생산통계</a></li>
              </ul>
            </li>
            <li><a routerLink="/service/analysis" (click)="closeMenu1();">성상분석 DATA</a></li>
            <li><a routerLink="/board" [queryParams]="{board_id:'board'}" (click)="closeMenu1();">공지사항</a></li>
            <li>
              <a routerLink="/service/policy" [queryParams]="{tabIndex:'1'}" (click)="closeMenu1();">정부시책</a>
              <ul class="sub-menu-depth1">
                <li><a routerLink="/service/policy" [queryParams]="{tabIndex:'1'}" (click)="closeMenu1();">최근정책동향</a></li>
                <li><a routerLink="/service/policy" [queryParams]="{tabIndex:'2'}" (click)="closeMenu1();">최근공고</a></li>
              </ul>
            </li>
            <li>
              <a routerLink="/service/customer" [queryParams]="{tabIndex:'1'}" (click)="closeMenu1();">고객상담실</a>
              <ul class="sub-menu-depth1">
                <li><a routerLink="/service/customer" [queryParams]="{tabIndex:'1'}" (click)="closeMenu1();">Q&A</a></li>
                <li><a routerLink="/service/customer" [queryParams]="{tabIndex:'2'}" (click)="closeMenu1();">건의제도</a></li>
                <li><a routerLink="/service/customer" [queryParams]="{tabIndex:'3'}" (click)="closeMenu1();">회원사 애로지원</a></li>
              </ul>
            </li>
            <li><a routerLink="/service/overseasMarkets" (click)="closeMenu1();">양식다운로드</a></li>
          </ul>   
        </li>
        <li>
          <a class="cp" [ngClass]="{on: menu_on == '4'}" (click)="goLink($event, '/company/joinGuide', '4')">회원사</a>
          <ul class="sub-menu" [ngClass]="{on: menu_on == '4'}">
            <li><a routerLink="/company/joinGuide" (click)="closeMenu1();">진흥회 가입안내</a></li>
            <li><a routerLink="/company/introduce" (click)="closeMenu1();">회원사 소개</a></li>
            <li><a routerLink="/board" [queryParams]="{board_id:'member_news'}" (click)="closeMenu1();">회원사 소식</a></li>
            <li><a routerLink="/board" [queryParams]="{board_id:'member_movements'}" (click)="closeMenu1();">회원사 인사동정</a></li>
          </ul> 
        </li>
        <li>
          <a class="cp" [ngClass]="{on: menu_on == '5'}" (click)="goLink($event, '/introduce/greeings', '5')">진흥회 소개</a>
          <ul class="sub-menu" [ngClass]="{on: menu_on == '5'}"> 
            <li><a routerLink="/introduce/greeings" (click)="closeMenu1();">회장 인사말</a></li>
            <li>
              <a routerLink="/introduce/companyOverview" [queryParams]="{tabIndex:'1'}" (click)="closeMenu1();">진흥회 개요</a>
              <ul class="sub-menu-depth1">
                <li><a routerLink="/introduce/companyOverview" [queryParams]="{tabIndex:'1'}" (click)="closeMenu1();">설립목적 및 주요사업</a></li>
                <li><a routerLink="/introduce/companyOverview" [queryParams]="{tabIndex:'2'}" (click)="closeMenu1();">연혁</a></li>
                <li><a routerLink="/introduce/companyOverview" [queryParams]="{tabIndex:'3'}" (click)="closeMenu1();">조직도</a></li>
              </ul>
            </li>
            <li><a routerLink="/introduce/articles" (click)="closeMenu1();">정관</a></li>
            <li><a routerLink="/introduce/map" (click)="closeMenu1();">찾아오시는 길</a></li>
          </ul> 
        </li>         -->
      </ul>  
    </div>
    <div *ngIf="isLogin$ | async as isLogin; else notLogin" class="right-menu">
      <a class="login-btn cp" [ngClass]="{mopen: isMobileOn}" (click)="logout()">로그아웃</a>
      <a routerLink="/member/mypage"class="join-btn" >마이페이지</a>
      <a class="tot-menu cp" (click)="totMenu()">전체메뉴</a>
    </div>
    <ng-template #notLogin>
      <div class="right-menu">
        <a routerLink="/member/login" class="login-btn" [ngClass]="{mopen: isMobileOn}">로그인</a>
        <a routerLink="/member/join/step1"class="join-btn" >회원가입</a>
        <a class="tot-menu cp" (click)="totMenu()">전체메뉴</a>
      </div>
    </ng-template>
    <div class="bg1"></div>
    <div class="bg2"></div>
    <div class="bg3"></div>
  </div>  

  <div class="panel-overlay" *ngIf="isMobileOn" (click)="isMobileOn = false"></div>
</header>



