import { Component, OnInit, Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { UtilService } from '@app/service/util.service';
import { RestfulService } from '@app/service/restful.service';
import { CustomValidator } from '@app/service/custom.validators';
import { CommonService } from '@app/service/common.service';
import { Globals } from '@app/service/globals.service';
import { BoardService } from '@app/home/service/board.service';

import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';

const URL = config.apiFileUploadUrl;

@Component({
  selector: 'app-board-write',
  templateUrl: './board-write.component.html',
  styleUrls: ['./board-write.component.scss']
})
export class BoardWriteComponent extends O2OZCMS implements OnInit {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  @Input() boardInfo: any;  
  @Input() selectedData: any;

  public data: any = {};
  public fileName = '';
  public form: FormGroup;
  public formErrors = {};
  public checkIndividual = false;
  public qnaTabIndex = '2'; // tab순번 - 고객상담실의 경우  
  public tabQnaName = '건의제도'; // tab순번 - 고객상담실의 경우
  public groupTab: any = [];
  public linecnt: any = [];
  public imageList: any = [];

  public uploader: FileUploader;
  public uploadProgress: any = 0;

  public baseURL = config.baseUrl;

  public params: any = {
    no: '',
    row: '',
    totalCount: '',
    board_id: '',
    group_id: '',
    searchGroup: '',
    searchText: '',
    type: '',   // 리스트, 내용보기, 쓰기, 수정 : list, view, write, modify
    view_type: '', //  리스트, 앨범, 갤러리,
  };

  constructor(
    private boardService: BoardService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    toastrService: ToastrService,
    private restful: RestfulService,
    router: Router,
    commonService: CommonService,
    activatedRoute: ActivatedRoute,
    private globals: Globals,

  ) {
    // 상위 클래스에 router 전달
    super(router, commonService, activatedRoute, toastrService);

    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 폼생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      board_seq: ['', []],
      board_id: ['', [Validators.required, Validators.maxLength(50)] ],
      group_id: ['', [Validators.maxLength(255)] ],
      subject: ['', [Validators.required, Validators.maxLength(255)] ],
      contents: ['', [] ],
      is_pw: [false, [Validators.maxLength(255)]],
      pw: ['', [Validators.maxLength(50)] ],
      email: ['', [Validators.email, Validators.maxLength(255)] ],
      file: ["", [] ],
      upload: [ [], [] ],
      files: [ [], [] ],
      hp: ['', [] ],
      hp1: ['', [Validators.required, Validators.maxLength(4), CustomValidator.numeric] ],
      hp2: ['', [Validators.required, Validators.maxLength(4), CustomValidator.numeric] ],
      hp3: ['', [Validators.required, Validators.maxLength(4), CustomValidator.numeric] ],
      email1: ['', [Validators.required] ],
      email2: ['', [Validators.required] ],
      writer_name: ['', [] ]
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? '' : params.board_id;
      this.params.board_seq = ( typeof params.board_seq == 'undefined' || params.board_seq == '' ) ? '' : params.board_seq;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '' : params.group_id;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? 'list' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? '' : params.row;
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? '1' : params.no;
      this.params.group = ( typeof params.group == 'undefined' || params.group == '' ) ? '' : params.group;
      this.params.text = ( typeof params.text == 'undefined' || params.text == '' ) ? '' : params.text;
      this.params.view_type = ( typeof params.view_type == 'undefined' || params.view_type == '' ) ? '' : params.view_type;
      this.params.totalCount = ( typeof params.totalCount == 'undefined' || params.totalCount == '' ) ? '0' : params.totalCount;

      if( this.params.board_id !== '' ) {
        this.getBoardInfo();
      }

      if ( params.board_id == 'customer_service_request' ) {
        this.qnaTabIndex = '2';
        this.tabQnaName = '건의제도';
      } else if ( params.board_id == 'customer_service_support' ) {
        this.qnaTabIndex = '3';
        this.tabQnaName = '회원사애로지원';
      }

      this.form.patchValue({board_id: this.params.board_id, group_id: this.params.group_id});
    });

    if ( this.params.board_seq != '' ) {
      this.getBoardView();
    }

    this.form.patchValue({board_id: this.boardInfo.board_id});

  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardView() {
    this.boardService.getBoardView(this.params.board_id, this.params.board_seq, null ).then( response => {
      if( response.ResultCode ) {
        this.data = response.data;
        this.params.totalCount = response.total;

        let tmp = this.data.email.split('@');
        let tmp1 = '';
        if ( this.data.hp != undefined ) {
          tmp1 = this.data.hp.split('-');  
        }

        this.form.patchValue({
          board_id: this.data.board_id,
          board_seq: this.data.board_seq,
          group_id: this.data.group_id,
          subject: this.data.subject,
          contents: this.data.contents,
          is_pw: this.data.is_pw,
          pw: this.data.pw,
          email: this.data.email,
          email1: tmp[0],
          email2: tmp[1],
          hp1: tmp1[0],
          hp2: tmp1[1],
          hp3: tmp1[2],
        });
      } else {
        this.data = [];
        this.params.totalCount = 0;
      }

      this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    // 파일 없을 경우 return false;
    if( event.target.files.length < 1 ) return false;

    // 전체 허용 용량 검증
    let files:File[] = event.target.files;
    let filteredFiles:File[] = [];
    for(var f of files) {
      filteredFiles.push(f);
    }

    let options = null;
    let filters = null;

    this.uploader.addToQueue(filteredFiles, options, filters);

    // indicator 표시
    this.restful.indicator.next(true);

    this.uploader.uploadAll();
  }

  /*******************************************************************************
    설  명 : 게시판 등록 / 수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  write() {
    this.makeData();
    if ( this.checkIndividual == false ) {
      this.toastrService.error('개인정보처리방침을 동의해주세요.');
      return;
    }

    if ( this.form.controls.pw.value != '' ) {
      this.form.patchValue({is_pw: true});
    }

    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if ( this.form.valid ) {
      this.boardService.setWrite( this.form ).then( response => {
        if ( response.ResultCode ) {
          this.toastrService.success( response.ResultMessage, '문의하기');
          this.params.view_type = 'list';

          if ( this.params.board_id == 'customer_service_request' || this.params.board_id == 'customer_service_support' ) {
            this.router.navigate([ ('/board/list') ], { queryParams: { board_id: 'qna'} });
          } else {
            this.router.navigate([ ('/board/list') ], { queryParams: { board_id: this.params.board_id} });
          }

        } else {
          this.toastrService.error( response.ResultMessage, '문의하기');
        }
      });
    } else {
      this.toastrService.error('필수 입력항목을 확인하시기 바랍니다.');
    }
  }

  /*******************************************************************************
    설  명 : 전화번호 조합
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  makeData() {
    this.form.patchValue( {hp: [this.form.value.hp1, this.form.value.hp2, this.form.value.hp3].join('-')} );
    this.form.patchValue( {email: [this.form.value.email1, this.form.value.email2].join('@')} );
  }

  /*******************************************************************************
    설  명 : file Input 값 받아오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  onChangeEvent(event: any){
    this.fileName = event.target.value;
  }

  /*******************************************************************************
    설  명 : email 직접입력 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setEmail2(event) {
    this.form.patchValue({email2: event.target.value});
  }

  /*******************************************************************************
    설  명 : 개인정보처리방침 체크 이벤트
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  checkinfo(event) {
    if ( event.target.checked == true ) {
      this.checkIndividual = true;
    } else {
      this.checkIndividual = false;
    }
  }

  setQnaTab(item, i) {

    this.qnaTabIndex = i;

    this.tabQnaName = item;

    if ( i == '2' ) {
      this.router.navigate([ ('/board/write') ], { queryParams: { board_id: 'customer_service_request' } });
    } else if ( i == '3' ) {
      this.router.navigate([ ('/board/write') ], { queryParams: { board_id: 'customer_service_support' } });
    } else {
      this.router.navigate([ ('/board/list') ], { queryParams: { board_id: 'qna'} });
    }
    
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardInfo() {
    this.groupTab = [];

    this.boardService.getBoardInfoId( this.params.board_id ).then( response => {
      if( response.ResultCode ) {
        this.boardInfo = response.data;

        if( this.params.view_type == '' ) {
          switch( this.boardInfo.lvt_default ) {
            case 'L':
              this.params.view_type = 'list';
              break;
            case 'A':
              this.params.view_type = 'album';
              break;
            case 'G':
              this.params.view_type = 'gallery';
              break;
            default:
              this.params.view_type = 'list';
          }
        }

        if( this.params.view_type == 'list' ) this.linecnt = this.boardInfo.linecnt_list.split('/');
        else if( this.params.view_type == 'album' ) this.linecnt = this.boardInfo.linecnt_album.split('/');
        else if( this.params.view_type == 'gallery' ) this.linecnt = this.boardInfo.linecnt_gallery.split('/');
  
        if( this.params.row == '' ) this.params.row = this.linecnt[0];

      } else {
        this.globals.indicator.next(false);

        this.toastrService.error( "존재하지 않는 게시판입니다.", "게시판 에러" );

        this.router.navigate( ['/main'] );
        return false;
      }

    // 업로드 허용 파일 설정
    var filetype: string[] = ['image', 'jpg', 'gif', 'png', 'bmp'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 50 * (1024 * 1024), // 최대 업로드 허용 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }

      // indicator 표시 숨김
      setTimeout (() => {
        this.restful.indicator.next(false);
      });
    };

      // 파일업로드 완료시 처리
      this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        response = $.parseJSON( response );
        if( response.result ) {
          
          this.imageList.push(
            {
              filename: response.filename,
              origin_filename: response.origin_filename,
              size: response.size,
              filepath: response.url,
              thumbnail_org: response.thumbnail_org,
              thumbnail_path: response.thumbnail_path,
              url: this.baseURL + response.url
            }
          );
            
          this.form.patchValue({
            files: this.imageList
          });
        
          this.toastrService.success( response.message, '');
        } else {
          this.toastrService.error( response.message, '');
        }
          
        // indicator 표시 숨김
        this.restful.indicator.next(false);
      };
    });
  }

  /*******************************************************************************
    설  명 : 선택된 이미지 삭제
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  imageDelete(row) {
    this.imageList = this.imageList.filter( rows => rows.filename != row.filename );

    this.form.patchValue({
      files: this.imageList
    });
  }

  /*******************************************************************************
    설  명 : 목록으로
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  goList() {
    this.router.navigate([ ('/board/list') ], { queryParams: { board_id: 'qna'} });
  }
}
