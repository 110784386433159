import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ApplicationModalComponent } from '../application-modal/application-modal.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg', 
  centered: true,
  windowClass:'modal-1100'
};

@Component({
  selector: 'app-safety-confirmation-test',
  templateUrl: './safety-confirmation-test.component.html',
  styleUrls: ['./safety-confirmation-test.component.scss']
})
export class SafetyConfirmationTestComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

    public menu: any = {
      depth1_id: 2,
      depth2_id: 2,
      depth1_nm: '온라인 신청',
      depth2_nm: '안전확인시험검사신청',
    };   
  
    /*******************************************************************************
      설  명 : 생성자
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/  
    constructor(
      private modalService: NgbModal,
    ) { }
  
    /*******************************************************************************
      설  명 : 데이터 초기화
      입력값 : 없음
      리턴값 : 없음
    *******************************************************************************/  
    ngOnInit(): void {
    }
  
  /*******************************************************************************
    설  명 : 온라인 시험 검사신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ApplicationModal(tabIndex: any) {
    const modalRef = this.modalService.open(ApplicationModalComponent, options);

    modalRef.componentInstance.tabIndex = tabIndex;

    modalRef.result.then((result) => {
      if( result ) {
      }
    }, (reason) => {
    });
  }        
}
    