<app-sub-header [menu]="menu"></app-sub-header>

<div class="articles-page contents-page">
  <div class="inner-wrap">
    <div class="edit-date">
      制定 : 83. 11. 15<br/>
      改正 : 86. 09. 22<br/>
      改正 : 95. 03. 15<br/>
      改正 : 99. 02. 25<br/>
      改正 : 02. 02. 27<br/>
      改正 : 05. 04. 12<br/>
      改正 : 06. 03. 30<br/>
      改正 : 07. 04. 13<br/>
      改正 : 08. 03. 05<br/>
    </div>

    <div class="articles">
      <div class="chapter">
        <div class="h1">第 1 章 總 則</div>
        <div class="chapter-txt">
          <div class="h2">第 1 條(名稱)</div>  
          <div class="txt">本會는 韓國에너지機器産業振興會(以下 “振興會”라 한다.)라 칭하고, 영문으로는 KOREA ENERGY APPLIANCES INDUSTRY ASSOCIATION (KEAA)"로 표기한다.</div>
          <div class="h2">第 2 條(目的)</div>  
          <div class="txt">本會는 國家의 에너지機器 發展 施策에 協力하여 에너지機器産業에 對한 健全한 發展을 促進함으로써 에너지機器의 實用化와 普及을 促進하 고, 에너지機器産業의 發展基盤 造成에 關한 事業 및 關聯技術의 標準化에 關한 業務 等을 效率的으로 遂行하고, 會員 相互間의 福利增進을 圖謀함을 目的으로 한다.</div>
          <div class="h2">第 3 條(事務所)</div>  
          <div class="txt">本會는 主된 事務所를 首都圈 地域에 두고 必要에 따라 分事 務所를 둘 수 있다.</div>
          <div class="h2">第 4 條(公告)</div>  
          <div class="txt">本會의 公告事項은 서울特別市內에서 發刊하는 日刊新聞 2個紙 에 揭載한다. 但, 必要하다고 認定할 때에는 公告內容을 書面으로 會員에 게 通告할 수 있다.</div>
        </div>
      </div>  

      <div class="chapter">
        <div class="h1">第 2 章 事 業</div>
        <div class="chapter-txt">
          <div class="h2">第 5 條(事業)</div>  
          <div class="txt">本會는 第 2 條의 目的을 達成하기 爲하여 다음 各號의 事業을 한다.
            <div class="txt1">
              1. 에너지機器의 品質 및 技術 向上 等 國際競爭力 提高를 爲한 技術基 盤造成 및 標準化 事業<br/>
              2. 에너지機器産業의 發展方向에 關한 調査?硏究事業<br/>            
              3. 에너지機器의 安全性 確保를 爲한 試驗?檢査 및 啓蒙 指導에 關한 事業<br/>            
              4. KS表示認證 業體協議會 運營에 關한 事業<br/>            
              5. 政府 및 關係機關으로부터 委託받은 事業<br/>            
              6. 에너지機器 産業에 對한 國內外 資料調査 硏究 및 刊行物 發刊에 關 한 事業<br/>            
              7. 會員 相互間의 諸般 利益 增進에 關한 事業<br/>            
              8. 에너지機器産業 合理化 計劃의 推進을 爲한 事業<br/>            
              9. 産業發展法上 事業者 團體로서 그 役割을 다하기 爲한 事業<br/>            
              10. 에너지機器産業 發展을 爲한 人力養成<br/>            
              11. 政府 및 國內外 關聯機關, 團體로부터 認證 또는 相互 提携된 認定 事業<br/>            
              12. 其他 必要한 附帶事業            
            </div>
          </div>
        </div>
      </div>    
      
      <div class="chapter">
        <div class="h1">第 3 章 會 員</div>
        <div class="chapter-txt">
          <div class="h2">第 6 條(會員의 範圍)</div>  
          <div class="txt1">
            ① 本會의 會員은 正會員과 準會員으로 構成한다.<br/>
            ② 正會員은 에너지機器와 關聯部品 製造 事業者로 한다.<br/>
            ③ 準會員은 에너지機器 開發과 關聯있는 事業者나 本會의 目的에 贊同하 는 團體 및 硏究機關으로 한다.
          </div>
          <div class="h2">第 7 條(會員의 加入)</div>  
          <div class="txt1">
            ① 前條의 資格을 保有한 者가 本會에 入會하고자 할 때에는 加入申請書를 提出하여야 한다.<br/>
            ② 前項의 申請이 있으면 前條의 規程에 依한 資格基準에 따라 加入시키 고, 次期理事會에 報告한다. 但, 第12條 第1項 各 號에 依하여 除名된 者가 再 加入申請을 할 때에는 理事會에서 加入與否를 定한다.
          </div>
          <div class="h2">第 8 條(入會金)</div>  
          <div class="txt">本會의 新入會員은 入會金을 納付하여야 한다.</div>
          <div class="h2">第 9 條(會費)</div>  
          <div class="txt1">
            ① 會員은 本會의 經費를 充當하기 爲하여 會費를 納付하여야 한다.<br/>
            ② 第8條 및 前1項의 入會金 및 會費의 負擔金 等 必要한 事項은 理事會에서 定한다.
          </div>
          <div class="h2">第 10 條(遵守事項)</div>  
          <div class="txt">本會의 會員은 本會의 定款 및 決議事項을 遵守하여야 한다.</div>          
          <div class="h2">第 11 條(脫退)</div>  
          <div class="txt">本會 會員은 다음 各號의 事由가 있을때에는 脫退한다.          
            <div class="txt1">
              1. 脫退書 提出<br/>
              2. 會員의 資格喪失<br/>
              3. 破 産<br/>
              4. 禁治産<br/>
              5. 除 名
            </div>
          </div>
          <div class="h2">第 12 條(除名)</div>  
          <div class="txt1">
            ① 本會 會員中 다음 各號에 該當될때에는 會長은 理事會의 決議를 받아 이를 制裁 또는 除名할 수 있다.
            <div class="txt1 pt10 pb20">
              1. 本會의 名譽를 毁損한 行爲를 하였을때<br/>
              2. 本會의 事業目的에 違背되는 行爲를 하였을때<br/>
              3. 1年以上 會費를 納付하지 아니하였을때<br/>
              4. 其他 本會에 對한 義務를 履行하지 아니하였을때
            </div>
          </div>  
          <div class="txt1">
            ② 本會 會員이 脫退할 때에는 이미 納付한 入會金, 會費 및 手數料 等을 一切 返還하지 아니한다. 其他 財産에 對한 持分도 이와 같다. 但, 脫退 또는 除名시 先納한 會費中 到來되지 않은 期間의 會費는 返還한다.
          </div>          
        </div>
      </div>  

      <div class="chapter">
        <div class="h1">第 4 章 任 員</div>
        <div class="chapter-txt">
          <div class="h2">第 13 條(任員의 定數)</div>  
          <div class="txt">本會에는 다음의 任員을 둔다.
            <div class="txt1">
              1. 會長 1名, 副會長(常勤副會長 包含) 2名以內로 한다.<br/>
              2. 非常勤理事 5名 乃至 10名, 監事 2名 以內로 한다.<br/>
              3. 常任理事는 常勤副會長 1名으로 한다.
            </div>
          </div>
          <div class="h2">第 14 條(任員의 缺格事項)</div>  
          <div class="txt">다음 各號에 該當하는 者는 本會의 任員이 될 수 없다.
            <div class="txt1">
              1. 未成年者, 限定治産者 및 禁治産者<br/>
              2. 破産宣告를 받은 者<br/>
              3. 禁錮以上의 刑을 받고 그 執行이 終了되거나 또는 執行을 받지 아니하기로 確定된 後 3年이 經過하지 아니한 者
            </div>
          </div>
          <div class="h2">第 14 條의 1(常勤任員의 兼職 禁止)</div>  
          <div class="txt">常任理事는 그 職務外의 營利를 目的으로 하는 業務에 從事하지 못하며 理事會의 承認없이 다른 職務를 兼할 수 없 다.</div>
          <div class="h2">第 15 條(任員의 選任)</div>  
          <div class="txt1">
            ① 理事 및 監事는 總會에서 正會員中에서 選任한다.<br/>
            ② 그 會員이 法人일 때에는 그 法人의 代表者를 選任한다.<br/>
            ③ 會長 및 副會長은 總會에서 理事中에서 選任한다.<br/>
            ④ 常任理事는 會長의 推薦에 依하여 理事會에서 選任한다. 但, 會員이 아닌 者라야 한다.
          </div>
          <div class="h2">第 16 條(任期)</div>  
          <div class="txt1">
            ① 任員의 任期는 3年으로 하며 連任할 수 있다. 但, 會長은 單任으로 하되 第17條 第2項에 依한 任期는 單任에 算入하지 아니한다.<br/>
            ② 任員中 會員社의 代表者가 更迭될 때에는 그 後任者가 退任者의 殘餘 任期를 承繼한다.
          </div>          
          <div class="h2">第 17 條(補選)</div>  
          <div class="txt">
            ① 會長, 副會長 및 常任理事의 闕位時에는 理事會에서 補闕選擧를 한다.<br/>
            ② 補闕選擧에 依하여 選任된 任員의 任期는 退任者의 殘餘期間으로 한다.<br/>
            ③ 增員으로 選任되어 就任한 任員의 任期는 다음 總會에서 任員 選出時 까지로 한다.<br/>
            ④ 任員의 任期가 滿了되었을 때에는 다음 定期總會에서 任員選出時까지 그 任期가 延長된 것으로 본다.
          </div>
          <div class="h2">第 18 條(職務)</div>  
          <div class="txt1">
            ① 會長은 本會를 代表하며 會務를 總括한다.<br/>
            ② 副會長은 會長을 補佐하고 會長 有故時에는 理事會에서 指名한 副會長 이 그 職務를 代行한다.<br/>
            ③ 常任理事는 會長을 補佐하고 會長의 命에 依하여 日常 業務를 掌理하 며, 會長, 副會長이 同時 有故時에는 그 職務를 代行한다.<br/>
            ④ 監事의 職務는 다음의 것으로 한다.
            <div class="txt1 pt10">
              1. 1號 및 2號의 監査結果 不正 또는 不當한 점이 있음을 發見한 때에는 理事 會·總會에 그 是正을 要求하고 主務官廳에 報告하는 일<br/>
              2. 3號의 是正要求 및 報告를 하기 위하여 필요한 때에는 總會·理事會의 召集을 要求하는 일<br/>
              3. 法人의 財産狀況과 業務에 관하여 理事會 및 總會 또는 代表者에게 意見을 陳述하는 일
            </div>
          </div>  
          <div class="h2">第 19 條(理事會의 構成)</div>  
          <div class="txt1">
            ① 理事會는 理事로 構成하며 理事會의 議長은 本會 會長이 된다.<br/>
            ② 理事會는 本會 運營上 主要한 事項을 議決한다.<br/>
            ③ 監事는 理事會에 出席하여 發言할 수 있으나 議決權은 없다.
          </div>
          <div class="h2">第 20 條(報酬)</div>  
          <div class="txt">
            任員은 名譽職으로 하고 無報酬로 한다.<br/>
            但, 常任理事는 有給으로 하며 給與에 關한 事項은 理事會에서 定한다.
          </div>
          <div class="h2">第 21 條(顧問)</div>  
          <div class="txt">會長은 本會 運營上 主要 事項에 關한 諮問을 얻기 爲하여 理事會의 同議를 받아 顧問을 推戴할 수 있다.</div>
        </div>
      </div>        

      <div class="chapter">
        <div class="h1">第 5 章 會 議</div>
        <div class="chapter-txt">
          <div class="h2">第 22 條(會議의 種類)</div>  
          <div class="txt1">
            ① 本會에는 總會, 理事會 및 業種別 委員會를 두며 必要에 따라 業務 委員會를 둘 수 있다.<br/>
            ② 前 第1項의 各會 運營에 關한 規約 및 規程은 理事會에서 定한다.
          </div>
          <div class="h2">第 23 條(總會)</div>  
          <div class="txt1">
            ① 總會는 定期總會와 臨時總會로 區分한다.<br/>
            ② 定期總會는 每 年初 會長이 이를 召集한다.<br/>
            ③ 臨時總會는 다음 各號의 境遇에 會長이 召集한다.
            <div class="txt1 pt10">
              1. 會長이 必要하다고 認定하여 理事會의 議決을 얻었을때<br/>
              2. 理事會의 決議로서 要求할때<br/>
              3. 本會 正會員 3分의 1 以上의 請求로서 召集理由와 附議事項을 提示하였을때
            </div>
          </div>
          <div class="h2">第 24 條(總會의 議決事項)</div>  
          <div class="txt">總會는 다음 事項을 議決한다.
            <div class="txt1">
              1. 定款 變更에 關한 事項<br/>
              2. 會長 및 任員의 選擧<br/>
              3. 事業計劃 豫算 및 決算에 關한 事項<br/>
              4. 理事會에서 提案하는 主要한 事項<br/>
              5. 會長이 必要하다고 認定하는 事項<br/>
              6. 解散 및 이에 關聯되는 事項
            </div>   
          </div>     
          <div class="h2">第 25 條(理事會의 議決事項)</div>  
          <div class="txt">理事會는 다음 事項을 議決한다.
            <div class="txt1">
              1. 會員의 再加入, 制裁 및 除名에 關한 事項<br/>
              2. 會費 分賦에 關한 事項<br/>          
              3. 諸 規程의 制定 및 改廢<br/>
              4. 總會 附議事項의 審議<br/>
              5. 年度 事業計劃 및 收支豫算에 關한 事項<br/>          
              6. 豫算決算에 關한 事項<br/>          
              7. 常任理事 選出에 關한 事項<br/>
              8. 財産의 取得 處分 및 缺損에 關한 事項<br/>
              9. 資産運營에 關한 事項<br/>
              10. 總會에서 委任받은 事項<br/>
              11. 第 20 條에 規程된 事項<br/>
              12. 其他 本會 運營上 會長이 必要하다고 認定하는 主要한 事項
            </div>
          </div>
          <div class="h2">第 26 條(召集)</div>  
          <div class="txt1">
            ① 總會 및 理事會는 會長이 召集한다.<br/>  
            ② 總會 및 理事會의 召集은 日時, 場所 및 附議事項을 明示하여 開會 7日前까지 會員에게 通知하여야 한다. 但, 緊急할때에는 例外로 한다.          
          </div>          
          <div class="h2">第 27 條(議長)</div>  
          <div class="txt1">
            ① 總會 및 理事會의 議長은 會長이 되며 會長 有故時에는 非常勤 副會長이 代行한다.<br/>
            ② 會長, 副會長이 同時有故 또는 職務遂行이 不可能할 경우에는 常任理事가 代行한다.        </div>
          <div class="h2">第 28 條(議決權)</div>  
          <div class="txt1">
            ① 總會에 있어서 各 會員의 議決權은 平等하다.<br/>
            但, 準會員은 總會에 參席하여 發言할 수 있으나 議決權은 없다.<br/>
            ② 總會 및 理事會는 會員이 直接 參席함을 原則으로 한다.<br/>
            但, 本人이 參席할 수 없을때에는 當該 會員社의 任員에게 委任 代理參席케 할 수 있다.<br/>
            ③ 總會 및 理事會 議決事項中 法律爭訟, 任員選任 및 解任 等 協會와의 利害關係가 相反되는 者에 對하여는 議決權이 없다.
          </div>  
          <div class="h2">第 29 條(議決)</div>  
          <div class="txt1">
            ① 總會는 在籍 正會員 過半數의 出席으로 成立되며 出席 正 會員 過半數의 贊成으로 議決한다. 但, 可否 同數인 때에는 議長이 이를 決 定한다.<br/>
            ② 理事會는 在籍理事 過半數의 出席으로 成立되며 出席理事 過半數의 贊成으로 議決한다.<br/>
            但, 可否 同數인 때에는 議長이 이를 決定한다.<br/>
            ③ 會長은 부득이한 事由로 理事會를 召集할 수 없거나, 輕微한 事項은 書 面議決을 附議할 수 있다. 이 境遇 議決 定足數는 前 2項의 規定을 準用한 다.<br/>
            ④ 理事會 附議案件 中 會員 除名에 關한 事項은 在籍理事 3分의 2 以上 出席과 出席理事 3分의 2以上의 贊成으로 議決한다.<br/>
            ⑤ 理事會 附議案件中 定款變更에 關한 事項은 在籍理事 3分의 2以上의 贊成으로 議決한다.<br/>
          </div>
          <div class="h2">第 30 條(議事錄)</div>  
          <div class="txt">總會 및 理事會의 議事錄에는 다음 事項을 記載하여 議長 및 出席理事 2人 以上이 署名 捺印하여야 한다.
            <div class="txt1">
              1. 開會 日時 및 場所<br/>
              2. 總 會員數 및 出席會員數 ( 理事會는 出席理事 記名 )<br/>
              3. 議事의 要領<br/>
              4. 議決事項 및 贊反數<br/>
            </div>     
          </div>     
        </div>
      </div>    
      
      <div class="chapter">
        <div class="h1">第 6 章 事務局 및 附設機關</div>
        <div class="chapter-txt">
          <div class="h2">第 31 條(事務局의 設置)</div>  
          <div class="txt">本會의 業務를 效率的으로 運營하기 爲하여 事務局 을 둔다.</div>
          <div class="h2">第31條의1(附設機關)</div>  
          <div class="txt">本會에는 附設機關으로 에너지機器試驗院을 둔다.</div>
          <div class="h2">第 32 條(職制 및 職員)</div>  
          <div class="txt1">
            ① 職制 및 職員의 定數는 會長이 理事會의 決義을 거쳐 定한다.<br/>
            ② 會長은 職員을 任免한다.
          </div>
        </div>
      </div>  
      
      <div class="chapter">
        <div class="h1">第 7 章 財政 및 會計</div>
        <div class="chapter-txt">
          <div class="h2">第 33 條(會計年度)</div>  
          <div class="txt1">
            ① 本會의 事業 및 會計年度는 每年 1月 1日 부터 同年 12月 31日까지로 한다.<br/>
            ② 法人의 事業計劃 및 歲入歲出 豫算은 每會計年度 開始 後 2月以內에 樹立?編成하고, 當該年度의 事業實績書 및 收支決算書는 每會計年度 終了 後 2月以內에 作成하여야 한다.
          </div>
          <div class="h2">第 34 條(財産)</div>  
          <div class="txt1">
            ① 本會의 財産은 入會金, 會費 및 其他 諸 收入金으로 한다.<br/>
            ② 다음의 財産을 基本 財産으로 한다.
            <div class="txt1 pt10 pb10">
              1. 入會金 및 入會金으로 取得한 財産 및 財産權 一切<br/>
              2. 本會 基金 財産으로 取得한 所有物<br/>        
              3. 其他 理事會에서 基本 財産으로 編入키로 決議된 財産
            </div>
            ③ 本會의 經費는 會費 및 其他 諸 收入金으로 充當한다.
          </div>
          <div class="h2">第 35 條(積立金)</div>  
          <div class="txt1">
            ① 本會는 每 事業年度에 있어서 第34條의 諸 收入金으로부 터 經費 및 諸 損失金을 控除한 剩餘金의 3分의 1 以上을 積立하여야 한다.<br/>
            ② 第 1 項의 積立金은 다음 各號의 境遇에 使用한다.<br/>
            <div class="txt1 pt10 pb10">
              1. 損失金을 補塡할 때<br/>
              2. 共同 施設費에 充當할 때<br/>
              3. 任職員의 退職金 및 退職慰勞金을 充當할 때<br/>但, 理事會의 決議를 얻어야 使用할 수 있다.
            </div>            
          </div>
        </div>
      </div>      

      <div class="chapter">
        <div class="h1">第 8 章 其 他</div>
        <div class="chapter-txt">
          <div class="h2">第 36 條(定期總會書類)</div>  
          <div class="txt">會長은 每 會計年度末 다음 各號의 書類를 作成하여 監査를 받은 後 定期總會에 提出하여 그 承認을 받아야 한다.
            <div class="txt1">
              1. 事業報告書<br/>
              2. 收支決算書<br/>
              3. 財産目錄<br/>
              4. 貸借對照表<br/>
              5. 過不足金의 處理案<br/>
              6. 年度 豫算編成案
            </div>
          </div>
          <div class="h2">第 37 條(書類의 保存)</div>  
          <div class="txt">本會는 民法 第55條에 規定된 것외에 다음 各號의 書 類를 保存하여야 한다.
            <div class="txt1">
              1. 定款<br/>
              2. 總會 또는 理事會 會議錄<br/>
              3. 收入·支出에 關한 帳簿 및 證憑書類<br/>
              4. 資産臺帳<br/>
              5. 諸規程의 制定 및 改廢<br/>
              6. 任職員의 名簿와 履歷書<br/>
              7. 事業計劃 및 實績<br/>
              8. 主務官廳 및 關係機關과의 往復書類
            </div>
          </div>
          <div class="h2">第 38 條(解散)</div>  
          <div class="txt1">
            ① 本會는 民法 第 77 條의 事由가 發生하였을때 解散한다.<br/>
            ② 本會의 解散에 關한 事項은 總會의 決議로서 在籍 正會員 3分의 2以上 이 出席하여 出席 正會員 3分의 2 以上의 贊成이 있어야 한다.
          </div>
          <div class="h2">第 39 條(淸算)</div>  
          <div class="txt1">
            ① 本會가 解散할 때에는 淸算人을 選定한다. 淸算人은 總會 에서 會員中에서 選出한다.<br/>
            ② 淸算人은 財産의 現況을 調査하여 財産目錄 貸借對照表 및 收支決算書를 作成하여 總會의 承認을 얻어야 한다.<br/>
            ③ 本會 解散時 殘餘財産이 있을때에는 總會의 決議에 따라 處理한다.
          </div>        
        </div>
      </div>   
      
      <div class="chapter">
        <div class="h1">附 則</div>
        <div class="chapter-txt">
          <div class="txt pt30">本 定款은 主務部長官이 承認한 날로부터 施行한다.</div>
        </div>
      </div>   
      
      <div class="chapter">
        <div class="h1">附 則(86. 9. 22)</div>
        <div class="chapter-txt">
          <div class="h2">第 1 條(準據法)</div>  
          <div class="txt">本 定款에 規定되지 아니한 事項은 工業發展法에 依한다.</div>
          <div class="h2">第 2 條(施行日)</div>  
          <div class="txt">本 定款은 主務部長官이 承認한 날로부터 施行한다.</div>
          <div class="h2">第 3 條(經過措置)</div>  
          <div class="txt1">
            ① 本 協會는 從前 定款 施行當時의 社團法人 韓國에너지器機協會와 同一性을 維持하는 法人임을 確認한다.<br/>
            ② 社團法人 韓國에너지器機協會에 屬하였던 모든 權利와 義務는 本 協會에 屬하는 것으로 確認한다. 다만, 任員의 任期는 從前 定款에 따라 滿了되는 時期까지로 한다.<br/>
            ③ 이 定款 施行時 從前의 定款에 依하여 施行된 其他 事項은 이 定款에 依한 것으로 본다.
          </div>
        </div>
      </div> 
      
      <div class="chapter">
        <div class="h1">附 則(95. 3. 15)</div>
        <div class="chapter-txt">
          <div class="txt pt30">本 定款은 主務部長官이 承認한 날로부터 施行한다.</div>
        </div>
      </div>  
      
      <div class="chapter">
        <div class="h1">附 則(99. 2. 25)</div>
        <div class="chapter-txt">
          <div class="txt pt30">本 定款은 主務部長官이 承認한 날로부터 施行한다.</div>
        </div>
      </div>  

      <div class="chapter">
        <div class="h1">附 則(02. 2. 27)</div>
        <div class="chapter-txt">
          <div class="txt pt30">本 定款은 主務部長官이 承認한 날로부터 施行한다.</div>
        </div>
      </div>  
      
      <div class="chapter">
        <div class="h1">附 則(05. 4. 12)</div>
        <div class="chapter-txt">
          <div class="txt pt30">本 定款은 主務部長官이 承認한 날로부터 施行한다.</div>
        </div>
      </div>  
      
      <div class="chapter">
        <div class="h1">附 則(06. 3. 30)</div>
        <div class="chapter-txt">
          <div class="txt pt30">本 定款은 主務部長官이 承認한 날로부터 施行한다.</div>
        </div>
      </div>      

      <div class="chapter">
        <div class="h1">附 則(07. 4. 13)</div>
        <div class="chapter-txt">
          <div class="txt pt30">本 定款은 主務部長官이 承認한 날로부터 施行한다.</div>
        </div>
      </div>    

      <div class="chapter pb70">
        <div class="h1">附 則(08. 3. 5)</div>
        <div class="chapter-txt">
          <div class="h2">第 1 條(施行日)</div>  
          <div class="txt">이 定款은 主務部長官이 承認한 날(‘08. 3. 14)로부터 施行한다.</div>
          <div class="h2">第 2 條(經過措置)</div>  
          <div class="txt pb70">이 定款 施行當時 從前의 定款에 依한 韓國가스石油機器協會는 이 定款에 依한 韓國에너지機器産業振興會로 보며, 本會는 韓國가스石油機器協會의 財産, 豫算, 任員, 職員, 權利, 義務, 諸規程, 政府 및 關聯機關으로부터 委任?委託?指定?認定된 事務 等 一切를 包括 承繼한다.</div>
        </div>
      </div>    
    </div>
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>