/*******************************************************************************
  설  명 : 아이디 찾기
  작성자 : 
  작성일 : 
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CommonService } from '@app/service/common.service';
import { UtilService } from '@app/service/util.service';

import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';
import { HMemberService } from '@home/service/member.service';

@Component({
  selector: 'app-find-id',
  templateUrl: './find-id.component.html',
  styleUrls: ['./find-id.component.scss']
})
export class FindIdComponent extends O2OZCMS implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public isSelectOn: boolean = false; //selectbox on
  public isSelectTxt = '직접입력'; // selectbox txt  
  public form: FormGroup;
  public formErrors = {};

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    router: Router,
    commonService: CommonService,
    activatedRoute: ActivatedRoute,
    toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private memberService: HMemberService,

  ) {
    // 상위 클래스에 router 전달
    super(router, commonService, activatedRoute, toastrService);

    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      userName: [ '', [Validators.required, Validators.pattern('^[가-힣a-zA-Z]*$')] ],
      email: [ '', [Validators.required, Validators.pattern('^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$')] ],
      email1: [ '', [] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {

  }
  
  /*******************************************************************************
    설  명 : SELECT 선택 처리
  *******************************************************************************/
  selectChange( event: any ) {
    this.isSelectTxt = event.target.innerText;
    let tmp = this.form.controls.email.value;
    let tmp1 = '';
    
    tmp1 = tmp.split('@');

    if ( this.isSelectTxt == '직접입력' ) {
      this.form.patchValue({
        email: tmp1[0]
      });
    } else {
      this.form.patchValue({
        email: tmp1[0] + this.isSelectTxt
      });
    }
  }

  /*******************************************************************************
    설  명 : 아이디 찾기
  *******************************************************************************/
  setFindId() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if ( this.form.valid ) {
      this.memberService.setFindId( this.form ).then( response => {
        if ( response.ResultCode == true ) {
          this.toastrService.success('아이디 찾기를 성공하였습니다.');

          this.router.navigate(['/member/findid/result'],
          {
            queryParams: {memNo: response.data.memNo},
            queryParamsHandling: '', // remove to replace all query params by provided
          });
        } else {
          this.toastrService.error('아이디 찾기를 실패하였습니다.');
        }
      });
    } else {
      this.toastrService.error('필수 입력값을 확인하시기 바랍니다.', '아이디 찾기');

    }
  }
}
  