<app-sub-header [menu]="menu"></app-sub-header>

<div class="online-page contents-page">
  <div class="inner-wrap">
    <ul class="page-tab clearfix">
      <li><a routerLink="/online/accreditedTest">공인시험검사신청</a></li>
      <li><a routerLink="/online/SafetyConfirmationTest">안전확인시험검사신청</a></li>
      <li><a routerLink="/online/StandardDevelopment">표준개발협력제안</a></li>
      <li class="active"><a routerLink="/online/KeaaJoin">진흥회 가입신청</a></li>
    </ul>

    <div class="online1-page">
      <div class="page-title">진흥회 가입신청</div>
      <div class="page-title-bar"></div>
      <div class="guide-txt">
        <span class="blue">온라인 신청 서비스 준비 중입니다.</span><br class="mobile-only"> 서비스 시작 시 까지는 아래 양식을 다운로드 하셔서<br class="mobile-only"> 작성 후 팩스로 접수하여 주십시요. <br class="mobile-only">(팩스 : 031-480 - 2986)
      </div>

      <ul class="application clearfix">
        <li>
          <div>진흥회 가입신청</div>
          <div>
            <a class="cp" (click)="keaaJoin()">온라인신청</a>
            <a href="/assets/download/form1.hwp">양식다운로드</a>
          </div>
        </li> 
      </ul> 

      <div class="section">
        <div class="title">회원가입시 제출서류</div>
        <ul class="txt-area">
          <li>
            <p class="txt"><span></span><span>진흥회 가입신청서 1부(소정양식,hwp)</span></p>
            <p class="txt"><span></span><span>사업자등록증 1부</span></p>
            <p class="txt"><span></span><span>생산제품 소개책자 1부</span></p>
            <p class="txt red">※ 가입문의는 진흥회 기획실(031-480-2981)로 연락주시기 바랍니다.</p>
          </li>
        </ul>
      </div>
  
      <div class="section">
        <div class="title">회원가입 절차</div>
        <ul class="step-ul">
          <li><div>회원가입대상가능여부 사전확인 후<br class="mobile-only"> 회원가입 신청서 등 제출<br/>(입회금 200만원 및 연회비 100만원)</div></li>
          <li><div>회원가입 신청서 및<br class="mobile-only"> 업체현황 자료를 진흥회에서 검토</div></li>
          <li><div>진흥회 회원가입 승인 (회원증발급)</div></li>
          <li><div>차기 이사회 개최시 회원가입 보고</div></li>
        </ul>
      </div>   
    </div>  
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>

