<app-header [menuList$]="menuList$" [memberInfo$]="memberInfo$"></app-header>

<app-sub-header [currMenu]="currMenu$" [memberInfo]="memberInfo$"></app-sub-header>

<div class="findidpw-page contents-page">
  
  <div class="inner-wrap clearfix">
    <form [formGroup]="form">
      <div class="find-area">
        <div class="find-title">비밀번호 재설정</div>
        <input type="password" placeholder="새 비밀번호" formControlName="password" [ngClass]="{formErrors:formErrors['password']}" />
        <input type="password" placeholder="새 비밀번호 재입력" formControlName="passwordConfirm" [ngClass]="{formErrors:formErrors['passwordConfirm']}" pattern="{{ this.form.get('password').value }}" />

        <a class="find-btn" (click)="setChangePassword()">확인</a>
      </div>
    </form>
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>
