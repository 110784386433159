import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

import { HMemberService } from '@app/home/service/member.service';
import { HInspectionService } from '@app/home/service/inspection.service';
import { UtilService } from '@app/service/util.service';

@Component({
  selector: 'app-application-modal',
  templateUrl: './application-modal.component.html',
  styleUrls: ['./application-modal.component.scss'],
})
export class ApplicationModalComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public tabIndex = 1;

  public form: FormGroup;
  public formErrors = {};

  public com_gbn: String;

  private initCompany = {
    IAT_code: [ '1000', [] ],
    company_seq: [ '', [] ],
    com_kor: [ '', [Validators.maxLength(100)] ],
    name_kor: [ '', [Validators.maxLength(50)] ],
    com_number: [ '', [Validators.pattern('^[0-9-]+$'), Validators.minLength(10), Validators.maxLength(12)] ],
    zipcode: [ '', [Validators.maxLength(5)] ],
    com_address: [ '', [Validators.maxLength(200)] ],
    addressDetail: [ '', [] ],
    com_tel: [ '', [Validators.maxLength(20)] ],
    com_fax: [ '', [Validators.maxLength(20)] ],
    job: [ '', [Validators.maxLength(100)] ],
    job1: [ '', [Validators.maxLength(100)] ],
    com_gbn: [ '', [] ],
    application_date: [ '', [Validators.required, Validators.maxLength(100)] ],
    applicant_name: [ '', [Validators.required, Validators.maxLength(50)] ],
    applicant_hp: [ '', [Validators.required, Validators.maxLength(30)] ],
    applicant_email: [ '', [Validators.required, Validators.maxLength(100)] ],
  }

  private tab1Init = {
    tab1_EIG_code: [ '', [Validators.required] ],
    tab1_report_purpose: [ '', [Validators.required] ],
    tab1_sample_disposition: [ '', [Validators.required] ],
    tab1_conformity_statement: [ '', [Validators.required] ],
    tab1_item_gbn_code: [ '', [Validators.required] ],
    tab1_item_seq: [ '', [Validators.required] ],
    tab1_item_code: [ '', [Validators.required] ],
    tab1_IRG_code: [ '', [] ],
    tab1_IRG_NAME: [ '', [] ],
    tab1_IRG_name: [ '', [] ],
    tab1_IRG_code_gas: [ '', [] ],
    tab1_model_name: [ '', [Validators.required, Validators.maxLength(250)] ],
    tab1_sample_qty: [ '', [Validators.pattern('^[0-9]+$')] ],
    tab1_sample_spec: [ '', [Validators.maxLength(50)] ],
    tab1_apply_item_type: [ '', [Validators.required] ],
    tab1_apply_standard: [ '', [Validators.maxLength(20)] ],
    tab1_file: [ '', [] ],
    tab1_files: [[], []],
    tab1_request_memo: [ '', [Validators.required] ],
    tab1_agree: [ '', [Validators.required] ],
  };

  private tab2Init = {
    tab2_application_sdate: [ '', [] ],
    tab2_application_edate: [ '', [] ],
    tab2_lab_seq: [ '', [Validators.required] ],
    tab2_use_date: [ '', [] ],
    tab2_LUG_code: [ '', [] ],
    tab2_use_purpose: [ '', [] ],
    tab2_request_memo: [ '', [Validators.required] ],
    tab2_agree: [ '', [Validators.required] ],
  };

  private tab3Init = {
    tab3_sample_disposition: [ '', [Validators.required] ],
    tab3_model_name: [ '', [Validators.required, Validators.maxLength(250)] ],
    tab3_standard_model_name: [ '', [Validators.required, Validators.maxLength(50)] ],
    tab3_apply_model_name: [ '', [Validators.required, Validators.maxLength(50)] ],
    tab3_kind_grade: [ '', [Validators.maxLength(50)] ],
    tab3_apply_reason: [ '', [Validators.maxLength(150)] ],
    tab3_file: [ '', [] ],
    tab3_files: [[], []],
    tab3_request_memo: [ '', [Validators.required] ],
    tab3_agree: [ '', [Validators.required] ],

    tab3_application_kind1: [ false, [] ],
    tab3_application_kind2: [ false, [] ],
    tab3_application_kind3: [ false, [] ],
    tab3_application_kind4: [ false, [] ],
    tab3_radio: [ '', [] ],
  };

  private tab4Init = {
    tab4_item_gbn_code: [ '', [Validators.required] ],
    tab4_item_seq: [ '', [Validators.required] ],
    tab4_item_code: [ '', [Validators.required] ],
    tab4_IRG_code: [ '', [] ],
    tab4_IRG_NAME: [ '', [] ],
    tab4_IRG_name: [ '', [] ],
    tab4_IRG_code_gas: [ '', [] ],
    tab4_model_name: [ '', [Validators.maxLength(250)] ],
    tab4_standard_model_name: [ '', [Validators.maxLength(50)] ],
    tab4_apply_model_name: [ '', [Validators.maxLength(50)] ],
    tab4_kind_grade: [ '', [Validators.maxLength(50)] ],
    tab4_apply_reason: [ '', [Validators.maxLength(150)] ],
    tab4_apply_standard: [ '', [Validators.maxLength(20)] ],
    tab4_file: [ '', [] ],
    tab4_files: [[], []],
    tab4_request_memo: [ '', [Validators.required] ],
    tab4_agree: [ '', [Validators.required] ],
    tab4_radio: [ '', [] ],
  };

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private utilService: UtilService,
    private hMemberService: HMemberService,
    private hInspectionService: HInspectionService
  ) {
    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    let tmp = {
      ...this.initCompany,
      ...this.tab1Init,
      ...this.tab2Init,
      ...this.tab3Init,
      ...this.tab4Init
    }

    this.form = this.formBuilder.group(tmp);

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.buildForm();

    this.getCompanyInfo();
  }

  /*******************************************************************************
    설  명 : 회사 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getCompanyInfo(): void {
    this.hMemberService.getCompanyInfoLogin().then((response) => {
      if( response.ResultCode && response.data && response.data.length > 0 ) {
        this.form.patchValue(response.data[0]);
        this.form.patchValue({
          com_address: response.data[0].address,
          com_tel: response.data[0].tel,
          com_fax: response.data[0].fax,
          applicant_name: response.data[0].username,
          applicant_hp: response.data[0].mobile,
          applicant_email: response.data[0].email,
        });

        this.com_gbn = response.data[0].com_gbn;

      }

      this.form.patchValue({'application_date': moment().format('YYYY-MM-DD')});
    });
  }

  /*******************************************************************************
    설  명 : 공통코드 저장
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public submit() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    console.log(this.formErrors);

    if(this.form.valid) {
      if( this.tabIndex === 1 ) {
        if( this.form.value.tab1_files.length < 1 ) {
          this.toastrService.error('구비서류 - 사업자 등록증, 기타 설명 문서를 등록해 주시기 바랍니다.');
        }
      } else if( this.tabIndex === 3 ) {
        if( this.form.value.tab3_files.length < 1 ) {
          this.toastrService.error('구비서류 - 사업자 등록증, 기타 설명 문서를 등록해 주시기 바랍니다.');
        }
      } else if( this.tabIndex === 4 ) {
        if( this.form.value.tab4_files.length < 1 ) {
          this.toastrService.error('구비서류 - 사업자 등록증, 기타 설명 문서를 등록해 주시기 바랍니다.');
        }
      }

      let params = {};
      for(let key in this.form.value) {
        if( key.substr(0, 5) === 'tab' + this.tabIndex + '_' ) {
          let key1 = key.substr((key.length - 5) * -1);
          params[key1] = this.form.value[key];
        } else {
          params[key] = this.form.value[key];
        }
      }

      if( this.tabIndex === 2 ) {
        this.hInspectionService.setApplicationInspectionLab(params).then((response) => {
          if( response.ResultCode ) {
            this.toastrService.success('정상적으로 등록되었습니다.', '시험검사신청');
            this.activeModal.close();

          } else {
            this.toastrService.error( response.ResultMessage, '시험검사신청');
          }
        }, (error) => {
          this.toastrService.error(error, '시험검사신청');
        })
      } else {
        this.hInspectionService.setApplicationInspection(params).then((response) => {
          if( response.ResultCode ) {
            this.toastrService.success('정상적으로 등록되었습니다.', '시험검사신청');
            this.activeModal.close();

          } else {
            this.toastrService.error( response.ResultMessage, '시험검사신청');
          }
        }, (error) => {
          this.toastrService.error(error, '시험검사신청');
        });
      }
    }
  }

  /*******************************************************************************
    설  명 : 탭 변경 시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  tabChange(index) {
    this.tabIndex = (index + 1);

    let tmp = {
      ...this.initCompany,
      ...this.tab1Init,
      ...this.tab2Init,
      ...this.tab3Init,
      ...this.tab4Init
    }

    let tabKey = 'tab' + this.tabIndex + '_';

    for(let key in this.form.value ) {
      let item = this.form.get(key);

      // 현재 탭 required 추가
      if( key?.substr(0, 5) === tabKey ) {
        // required
        item.setValidators(tmp[key][1]);
        item.updateValueAndValidity();

      // 다른 탭의 required 제거
      } else if( key?.substr(0, 3) === 'tab' ) {
        let validator = [];

        for(let i = 0; i < tmp[key][1].length; i++ ) {
          if( tmp[key][1][i]?.name === 'required' ) {
            console.log(key, i, 'required');
          } else {
            validator.push(tmp[key][1][i]);
          }
        }

        item.setValidators(validator);
        item.updateValueAndValidity();
      }
    }

    this.form.patchValue({
      IAT_code:
        this.tabIndex == 1 ? '1000'
        : this.tabIndex == 2 ? '2000'
        : this.tabIndex == 3 ? '3000'
        : '4000'
    })

//    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);
  }

}
