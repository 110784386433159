<div class="section" *ngIf="!isboard">
  <table class="list-table">
    <colgroup>
      <col class="col1">
      <col/>
    </colgroup>
    <thead>
    <tr>
      <th colspan="2">IOS/TC 161</th>
    </tr>
    </thead>    
    <tbody>
      <tr>
        <th>SCOPE</th>
        <td>주거, 상업 및 산업 시설의 가스 및 오일을 사용하는 난방용 제어 및 안전장치에 대한 표준화 활동을 함.</td>
      </tr>
      <tr>
        <th>ABOUT</th>
        <td>SO TC 161은 1974년에 설립된 기술위원회로서 회장국은 독일이며, 현재 35개국(P-멤버 : 11, O-멤버 : 24)이 참여하고 있으며 간사기관은 독일(DIN)이다.</td>
      </tr>
      <tr>
        <th>P 멤버</th>
        <td>(14개국) 오스트레일리아(SA), 벨기에(NBN), 캐나다(SCC), 중국(SAC), 덴마크(DS), 프랑스(AFNOR), 독일(DIN), 이태리(UNI), 일본(JISC), 한국(KATS), 네덜란드(NEN), 스페인(AEN
          OR), 영국(BSI), 미국(ANSI)</td>
      </tr>
      <tr>
        <th>O 멤버</th>
        <td>(22개국) 오스트리아(ASI), 보스니아 헤르체고비나(ISBIH), 체코(UNMZ), 이집트(EOS), 핀란드(SFS), 그리스(NQIS ELOT), 홍콩(ITCHKSAR), 헝가리(MSZT), 인도(BIS), 인도네시아
          (BSN), 이란(ISIRI), 파키스탄(PSQCA), 폴란드(PKN), 루마니아(ASRO), 러시아(GOST R), 사우디아라비아(SASO), 세르비아(ISS), 스위스(SNV), 트리니다드 토바고(TTBS), 튀니지
          (INNORPI), 터키(TSE), 우크라이나(DSTU)</td>
      </tr>
      <tr>
        <th>표준화 활동</th>
        <td><button (click)="isboard = true">바로가기</button></td>
      </tr>
    </tbody>
  </table>

  <table class="list-table">
    <colgroup>
      <col class="col1">
      <col/>
    </colgroup>
    <thead>
    <tr>
      <th colspan="2">IOS/TC 291</th>
    </tr>
    </thead>    
    <tbody>
      <tr>
        <th>SCOPE</th>
        <td>가정용 조리기기 분야의 용어, 분류, 설치 및 성능 특성, 시험방법 및 표시에 대한 표준화 활동을 함.</td>
      </tr>
      <tr>
        <th>ABOUT</th>
        <td>2014년에 설립된 기술위원회로서 회장국은 독일이며, 현재 28개국 (P-멤버：18, O-멤버：10)이 참여하고 있으며이며 간사기관은 독일(DIN)이다.
          WG 1 : 안전, WG 2 : 효율, WG 3 : 성능 으로 이루어진 기술위원회이다</td>
      </tr>
      <tr>
        <th>P 멤버</th>
        <td>(24개국) 아르헨티나(IRAM), 호주(SA), 캐나다(SCC), 중국(SAC), 이집트(EOS), 프랑스(AFNOR), 독일(DIN), 이라크(COSQC) 이태리(UNI), 일본(JISC), 요르단(JSMO), 케냐(KEBS), 
          한국(KATS), 멕시코(DGN), 네덜란드(NEN), 뉴질랜드(ZNSO), 포르투칼(IPQ), 사우디(SASO), 싱가포르(SSC), 스페인(AENOR), 탄자니아(TBS), 터키(INNORPI), 영국(BSI), 
          미국(ANSI)</td>
      </tr>
      <tr>
        <th>O 멤버</th>
        <td>(11개국) 체코(UNMZ), 에티오피아(ESA), 핀란드(SFS), 가나(GSA), 이스라엘(SII), 말리(AMANORM), 페루(INACAL), 폴란드(PKN), 루마니아(ASRO), 슬로베니아(SIST), 아랍에메
          레이트(ESMA)</td>
      </tr>
      <tr>
        <th>표준화 활동</th>
        <td><button (click)="isboard = true">바로가기</button></td>
      </tr>
    </tbody>
  </table>
  
  <table class="list-table">
    <colgroup>
      <col class="col1">
      <col/>
    </colgroup>
    <thead>
    <tr>
      <th colspan="2">IOS/TC 86</th>
    </tr>
    </thead>    
    <tbody>
      <tr>
        <th>SCOPE</th>
        <td>냉동 및 공조분야에서 환경 보로, 용어, 기계적 안정성, 성능시험 방법 등에 대한 표준화 활동을 함.</td>
      </tr>
      <tr>
        <th>ABOUT</th>
        <td>
          <div class="pb10">1957년에 설립된 기술위원회로서 회장국은 미국이며, 현재 59개국(P-멤버：21, O-멤버：38)이 참여하고 있으며이며 간사기관은 미국(ANSI)이다.</div>
          SC 1 : Safety and environmental requirements for refrigerating systems<br/>
          SC 2 : Terms and definitions<br/>
          SC 3 : Testing and rating of factory-made refrigeration systems<br/>
          SC 4 : Testing and rating of refrigerant compressors<br/>
          SC 6 : Testing and rating of air-conditioners and heat pumps<br/>
          SC 7 : Testing and rating of commercial refrigerated display cabinets<br/>
          SC 8 : Refrigerants and refrigeration on lubricant
        </td>
      </tr>
      <tr>
        <th>P 멤버</th>
        <td>(20개국) 오스트레일리아(SA), 오스트리아(ASI), 벨기에(NBN), 브라질(ABNT), 중국(SAC), 덴마크(DS), 이집트(EOS), 프랑스(AFNOR), 독일(DIN), 인도(BIS), 이란(ISIRI), 이태리
          (UNI), 일본(JISC), 한국(KATS), 네덜란드(NEN), 폴란드(PKN), 러시아(GOST R), 사우디아라비아(SASO), 영국(BSI), 미국(ANSI)</td>
      </tr>
      <tr>
        <th>O 멤버</th>
        <td>(44개국) 아르헨티나(Argentina), 보스니아 헤르체고비나(ISBIH), 불가리아(BDS), 캐나다(SCC), 칠레(INN), 콜롬비아(ICONTEC), 쿠바(NC), 체코(UNMZ), 에티오피아(ESA), 핀란드
          (SFS), 그리스(NQIS ELOT), 홍콩(ITCHKSAR), 헝가리(MSZT), 아이슬란드(IST), 인도네시아(BSN), 이라크(COSQC), 아일랜드(NSAI), 이스라엘(SII), 자메이카(BSJ), 케냐(KEBS), 
          조선민주주의인민공화국(CSK), 멕시코(DGN), 몽골리아(MASM), 뉴질랜드(NZSO), 노르웨이(SN), 오만(Oman), 파키스탄(PSQCA), 필리핀(BPS), 포르투갈(IPQ), 루마니아(ASRO), 
          세르비아(ISS), 슬로바키아(UNMS SR), 슬로베니아(SIST), 스페인(UNE), 스리랑크(SLSI), 스웨덴(SIS), 스위스(SNV), 태국(TISI), 트리니다드 토바고(TTBS), 튀니지(INNORPI), 
          터키(TSE), 우크라이나(DSTU), 베트남(STAMEQ), 잠비아(ZABS)</td>
      </tr>
      <tr>
        <th>표준화 활동</th>
        <td><button (click)="isboard = true">바로가기</button></td>
      </tr>
    </tbody>
  </table>  
</div>      

<app-board-list *ngIf="isboard" [boardInfo]="boardInfo" (back)="isboard=false"></app-board-list>

<div *ngIf="isboard" class="bottom"></div>