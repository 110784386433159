<div class="eatl-business3">
  <div class="page-title">안전확인시험검사기관</div>
  <div class="page-title-bar"></div>

  <div class="guide-txt">
    전기용품 및 생활용품 안전관리법 제 2조 6.<br/>
    안전확인시험기관으로부터 안전확인시험을 받아<br class="mobile-only"> 안전기준에 적합한 제품인것을 확인할 수 있습니다.
  </div>

  <div class="section">
    <div class="title">안전확인대상제품</div>
    <ul class="txt-area">
      <li>
        <p class="h2"><span></span><span>전기용품 및 생활용품 안전관리법 제2조 11.</span></p>
        <p class="txt">안전확인대상 생활용품 : 구조 · 재질 또는 사용 방법 등으로 인하여 소비자의 생명 · 신체에 대한 위해，재산상 피해나 자연환경의 훼손에 대한 우려가 있는 생활용품으로서 산업통상자원부장관이 지정한 기관의 
          제품시험을 통하여 그 위해를 방지할 수 있다고 인정되어 산업통상자원부령으로 정하는 것.</p>
      </li>
      <li>
        <p class="h2"><span></span><span>전기용품 및 생활용품 안전관리법 시행규칙 별표 2</span></p>
        <table class="txt1-table">
          <tbody>
            <tr>
              <th>분야</th>
              <td>안전확인대상 생활용품</td>
            </tr>
            <tr>
              <th>나. 생활</th>
              <td>기름난로</td>
            </tr>            
          </tbody>
        </table>
      </li>
    </ul>
  </div>

  <div class="section">
    <div class="title">안전확인대상제품의 신고</div>
    <ul class="txt-area">
      <li>
        <p class="h2"><span></span><span>전기용품 및 생활용품 안전관리법 제15조 1항</span></p>
        <p class="txt">안전확인대상제품의 제조업자 또는 수입업자는 안전확인대상제품에 대하여 모델 별로 안전확인 시험기관으로부터 산업통상자원부령으로 정하는 바에 따라 안전확인시험을 받아, 해당 안전확인 대상제품이 
          제3항에 따른 안전기준에 적합한 것임을 확인한 후 그 사실을 산업통상자원부장관에게 신고하여야한다.</p>
      </li>
      <li>
        <p class="h2"><span></span><span>전기용품 및 생활용품 안전관리법 제15조 2항</span></p>
        <p class="txt">안전확인대상제품의 제조업자 또는 수입업자는 제1항에 따른 안전확인의 신고(이하 "안전확인신고"라 한다) 사항을 변경하려는 경우에는 산업통상자원부령으로 정하는 바에 따라 산업통상자원부장관에게 변경
          신고를 하여야 한다.  다만, 제품의 안전성과 관련이 없는 것으로서 산업통상자원부령으로 정하는 사항을 변경하는 경우에는 그러하지 아니하다.</p>
      </li>
    </ul>
  </div>  

  <div class="section">
    <div class="title">안전확인절차</div>
    <ul class="step clearfix">
      <li>
        <div class="no">STEP 1</div>
        <div class="txt">
          <div class="pb15">
            시험검사신청<br/><span>(제조업 또는 수입업자)</span>
          </div>
        </div>  
        <div class="arrow"></div>
      </li>
      <li>
        <div class="no">STEP 2</div>
        <div class="txt">
          <div class="pb15">제품검사실시<br/></div>
        </div>  
        <div class="arrow"></div>
      </li>   
      <li>
        <div class="no">STEP 3</div>
        <div class="txt">
          <div class="pb15">검사성적서 발급<br/></div>
        </div>  
        <div class="arrow"></div>
      </li>            
      <li>
        <div class="no">STEP 4</div>
        <div class="txt">
          <div>
            안전확인신고<br/><span>(제조업자, 수입업자)<br/>[별지 제 14호 서식]</span>
          </div>
        </div>  
        <div class="arrow"></div>
      </li>
      <li>
        <div class="no">STEP 5</div>
        <div class="txt">
          <div>
            안전확인<br/>신고확인증 교부<br/><span>(<span class="blue">*안전인증기관</span>)</span>
          </div>
        </div>  
        <div class="arrow"></div>
      </li>            
      <li>
        <div class="no">STEP 6</div>
        <div class="txt">
          <div>
            안전확인 표시<br/><span>(제조업자, 수입업자)<br/>[별표 8]</span>
          </div>
        </div>  
      </li>      
    </ul>
    <ul class="txt-area">
      <li>
        <p class="h2"><span></span><span class="blue">안전인증기관</span></p>
        <p class="txt">안전확인신고업무대행시 안전인증기관인 한국기계전기전자 시험연구원(KTC)으로 신청됩니다.</p>
      </li>
    </ul>      
  </div>  

  <div class="section">
    <div class="title">안전확인 처리기간</div>
    <ul class="txt-area">
      <li>
        <p class="h2"><span></span><span>전기용품 및 생활용품 안전관리법 시행규칙 제25조 3항 (전기용품 및 생활용품 안전관리법 시행규칙 제8조 준용)</span></p>
        <p class="txt">안전인증기관은 안전확인신고서를 제출 받은 날부터 45일 이내에 안전인증의 신청인에게 안전인증여부를 알려야 한다. 다만 해당제품의 특성상 제품의 시험기간이 30일을 초과하는 경우에는 그 초과하는 제품의 
          시험기간만큼 처리기간을 연장할 수 있다. 
          <span class="txt-term">-  기름난로 시험기간 : 25일</span>
        </p>
      </li>
    </ul>
  </div>  

  <div class="section">
    <div class="title">안전확인의 표시기준 및 표시방법</div>
    <ul class="txt-area">
      <li>
        <p class="h2"><span></span><span>전기용품 및 생활용품 안전관리법 제18조, 시행규칙 제35조</span></p>
        <p class="txt">안전확인대상제품의 제조업자 또는 수입업자는 산업통상자원부령으로 정하는 바에 따라 안전확인대상 제품 또는 포장에 안전확인표시 등으로 하여야한다.</p>
      </li>
      <li class="txt2">
        <p class="h2">[별표 8] 안전확인신고 표시방법<br class="mobile-only">(시행규칙 제35조 제1항 관련)</p>
        <div class="box clearfix">
          <div class="left-area">
            <div class="h3"><span class="blue">가.</span> 도안</div>
            <div class="img"></div>
          </div>
          <div class="right-area">
            <div class="h3"><span class="blue">나.</span> 표시요령</div>
            <ul class="clearfix">
              <li><span></span><span>안전확인신고를 표시하는 도안크기는 안전관리대상 제품의 크기에 따라 조정하되 가로, 세로 배율은 가목과 같다.</span></li>
              <li><span></span><span>안전확인신고의 표시방법은 해당 제품 또는 포장에 쉽게 알아볼 수 있도록 표시하고, 떨어지지 않도록 붙이거나 인쇄 또는 각인 등의 방법으로 표시하여야 한다.</span></li>
              <li><span></span><span>안전확인신고의 표시방법 및 안전기준에 관한 사항, 국가기술표준원장이 정하는 사항은 한글로 표기하여야 한다.</span></li>
              <li><span></span><span>안전확인신고의 도안 색상은 검은색을 원칙으로 하고 보색을 할 수 있다.</span></li>
              <li><span></span><span>제품의 표면에 안전확인표시를 붙이는 것이 곤란하거나 실수요자가 다량을 구입하여 직접 사용하는 생활용품으로서 시중에 유통될 우려가없는 경우에는 해당생활용품의 최소포장마다 붙일 수 있다.</span></li>
              <li><span></span><span>세부표시사항은 국가기술표준원장이 고시하는 바에 따른다.</span></li>
            </ul>
          </div>
        </div>
      </li>      
    </ul>    
  </div>   
</div>  
