<app-header [menuList$]="menuList$" [memberInfo$]="memberInfo$"></app-header>

<app-sub-header [currMenu]="currMenu$" [memberInfo]="memberInfo$"></app-sub-header>

<div class="findidpw-page contents-page">
  <div class="inner-wrap clearfix">
    <div class="find-area">
      <div class="find-title">회원님의 정보와<br class="mobile-only"/> 일치하는 아이디입니다.</div>
      <div class="id"><span>아이디</span>{{userId}}</div>

      <a routerLink="/member/login" class="find-btn">확인</a>
    </div>
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>
