import { NgModule } from '@angular/core';

/* 공용 컴포넌트 모듈 */
import { DaumAddressComponent } from '@components/daum-address/daum-address.component';
import { SafePipe } from '@app/service/safe.pipe';
import { SitePipe } from '@app/service/menu.pipe';

@NgModule({
  imports: [
  ],
  declarations: [
    DaumAddressComponent,
    SafePipe,
    SitePipe,
  ],
  exports: [
    DaumAddressComponent,
    SafePipe,
    SitePipe,
  ]
})

export class SharedModule {}
