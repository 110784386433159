<app-sub-header [menu]="menu"></app-sub-header>

<div class="greetings-page contents-page">
  <div class="inner-wrap">
    <div class="txt-logo"></div>
    <div class="title">한국에너지기기산업진흥회<br class="mobile-only"> 방문을 환영합니다.</div>
    <div class="vbar"></div>

    <div class="greetings clearfix">
      <div class="pic">
        <img src="/assets/images/ceo.png" alt="ceo사진" />
      </div>
      <div class="txt">
        <p>
          우리 진흥회는 <span>에너지기기 제조업체 공동의 이익증진과 발전을 위하여 설립된 사업자 단체</span>로서 맡은 바 
          소임을 수행하기 위하여 최선의 노력을 경주하고 있습니다.
        </p>  
        <p>
          우리나라를 비록한 세계 각국은 지금 <span>청정연료 공급 확대, 에너지 다원화정책 추진</span> 등의 영향으로  다양한 
          에너지를 이용한 관련제품의 선호도가 높아지면서 에너지기기산업은 미래의 전략사업으로 성장하고 
          있습니다.
        </p>    
        <p>    
          다가오는 지식정보화 시대를 대비하고 <span>에너지기기기업계간의 정보교류, 상호의견 교환</span>과 함께 우리 
          업계종사자 뿐만 아니라 에너지기기 산업에 관심을 갖고 있는 이용자들에게 보다 유용한 정보를 제공하기 
          위하여 진흥회는 홈페이지를 운영하고 있습니다.
        </p>  
        <p>
          아무쪼록 우리진흥회 홈페이지가 관련업무에 폭넓게 활용되기를 바랍니다.
        </p>    
        <p class="thanks">감사합니다.</p>

        <div class="ceo">한국에너지기기산업진흥회 회장</div>
        <div class="sign"><img src="/assets/images/signm.png" alt="sign"/></div>
      </div>
    </div>
  </div>
  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>