import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overseas-markets',
  templateUrl: './overseas-markets.component.html',
  styleUrls: ['./overseas-markets.component.scss']
})
export class OverseasMarketsComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  
  public menu: any = {
    depth1_id: 3,
    depth2_id: 1,
    depth1_nm: '고객서비스',
    depth2_nm: '해외시장 조사자료',
  }; 

  constructor() { }

  ngOnInit(): void {
  }

}
