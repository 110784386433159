<app-sub-header [menu]="menu"></app-sub-header>

<div class="joinguide-page contents-page">
  <div class="inner-wrap">
    <div class="info">
      <div class="txt">
        한국에너지기기산업진흥회는<br/>
        에너지기기산업의 발전을 위해 최선을 다하고 있습니다.
      </div>
      <div class="bar"></div>
      <div class="txt-area">
        <div>
          진흥회는 산업발전법상의 사업자단체로서 에너지기기 산업 발전을 위한<br class="pc-only"> 
          <span>조사ㆍ연구사업 등 업계이익 증진을 위한 기본 업무를 충실히 수행</span>하고 있을 뿐만 아니라 <br class="pc-only">
          정부로부터 공인시험검사기관, KS지정심사기관, 품목별 품질관리단체, 기술인력양성교육기관, <br class="pc-only">
          효율관리ㆍ고효율 기자재시험기관으로 지정받아 <span>회원사 및 관련업체의 기술개발, 품질향상, 경쟁력 제고를 통한</span><br class="pc-only">
          <span>안전하고 편리한 에너지기기를 생산ㆍ공급</span>할 수 있도록 최선의 노력을 경주하고 있습니다.
        </div>
      </div>
    </div>  
    
    <button class="join-btn">진흥회 가입신청</button>  

    <ul class="guide">
      <li>
        <div class="guide-title">회원 가입자격</div>
        <ul class="guide-txt">
          <li>정회원 : 에너지기기 제품 및 관련부품 제조 사업자</li>
          <li>준회원 : 에너지기기 개발과 관련있는 사업자나 본회의 목적에 찬동하는 단체 및 연구기관</li>
        </ul>
      </li>
      <li>
        <div class="guide-title">회원 혜택</div>
        <ul class="guide-txt">
          <li>제품의 품질향상을 위한 철저한 품질지도 및 기술지도</li>
          <li>개발제품에 대한 성능시험</li>
          <li>국내외 신기술 및 각종 정보제공</li>
          <li>회원사 애로사항 협의 및 대정부 건의</li>
          <li>에너지기기 통계자료 제공</li>
          <li>KS제품의 규격 제ㆍ개정건의</li>
          <li>업계간 상호 협력체제 구축 및 정보교류</li>
          <li>기타 회원업체의 이익증진 사업 및 정부시책 소개 등
              <p class="txt1">※ 의뢰시험 및 시험검사시 수수료 할인(15%)</p>
          </li>
        </ul>
      </li>
      <li>
        <div class="guide-title">회원 가입시 제출서류</div>
        <ul class="guide-txt">
          <li>진흥회 가입신청서 1부(소정양식,hwp)</li>
          <li>사업자등록증 1부</li>
          <li>생산제품 소개책자 1부
            <p class="txt1">※가입문의는 진흥회 기획실(031-480-2981)로 연락주시기 바랍니다.</p>
          </li>
        </ul>
      </li>    
      <li>
        <div class="guide-title">회원 가입 절차</div>      
        <ul class="guide-process">
          <li><div>회원가입대상가능여부 사전확인 후<br class="mobile-only"> 회원가입 신청서 등 제출<br/>(입회금 200만원 및 연회비 100만원)</div></li>
          <li><div>회원가입 신청서 및<br class="mobile-only"> 업체현황 자료를 진흥회에서 검토</div></li>
          <li><div>진흥회 회원가입 승인 (회원증발급)</div></li>
          <li><div>차기 이사회 개최시 회원가입 보고</div></li>
        </ul>                
    </ul>
  </div>

  <div class="bg1"></div>
  <div class="bg2"></div>
  <div class="bg3"></div>
</div>

<app-footer></app-footer>



