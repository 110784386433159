import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { HAuthService } from '@home/service/auth.service';
import { HMemberService } from '@home/service/member.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

/*******************************************************************************
  설명 : 전역 변수 선언부
*******************************************************************************/
  @Input() sub: boolean = false;
  @Input() menuList$: any;
  @Input() memberInfo$: any;

  public isOpen: boolean = false;
  public isMobileOn: boolean = false;
  public isFullpage: boolean = false;
  public memberInfo: any = {};
  public isLogin$: Observable<boolean>;
  public isLogin: boolean;
  
  public menu_on = '';
  public menuList: any = [];
  
  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: HAuthService,
    private toastrService: ToastrService,
    private memberService: HMemberService,
  ) {
    this.isLogin$ = this.authService.getIsHomeLogin;

    this.isLogin$.subscribe( login => {
      this.isLogin = login;
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.isOpen = false;

    this.menuList$.subscribe( (menuList: any) => {
      if (menuList.length > 0) {
        const mainMenu = menuList[0];

        try {
          const getMenuList = function getMenu(list, menu) {
            if (list.children === undefined) {
              for (const element of list) {
                if (element.mainmenu_view_yn === '1') {
                  menu.push(element);
                  continue;
                } else {
                  getMenu(element, menu);
                }
              }
            }
          };

          getMenuList(mainMenu, this.menuList);
        } catch (e) {
          console.log(e);
          return false;
        }
      }
    });

  }

  /*******************************************************************************
    설  명 : 메뉴펼치기
    입력값 : 
    리턴값 : 
  *******************************************************************************/
  expandMenu() {
    if ( window.innerWidth > 1710 ) {
      this.isOpen = true;
    } else {
      this.isOpen = false;    
    }
  }
  
  /*******************************************************************************
    설  명 : 메뉴닫기
    입력값 : 
    리턴값 : 
  *******************************************************************************/
  closeMenu() {
    this.isOpen = false;
  } 

  /*******************************************************************************
    설  명 : 모바일메뉴
    입력값 :
    리턴값 :
  *******************************************************************************/
  totMenu() {
    this.isOpen = false;
    this.isMobileOn = false;
    this.isFullpage = false;
    if ( window.innerWidth <= 1710 ) {
      this.isOpen = false;
      this.isMobileOn = true;
    } else {
      this.isFullpage = true;
    } 
  }
  
  /******************************************************************************* 
    설  명 : 메뉴명 클릭 시
    입력값 : event = 이벤트, href = 주소
    리턴값 : true / false
  *******************************************************************************/
  goLink( event, menu, menu_on ) {
    // 모바일 메뉴처리
    if (event.view.innerWidth <= 1675 && menu_on > -1) {
      if (this.menu_on == menu_on) {
        this.menu_on = '0';
      } else {
        this.menu_on = menu_on;
      }
      return;
    }

    this.closeMenu1();
    let href = menu[0].link_url;
    let boardId = menu[0].board_id;
    let menuIndex = menu[0].seq;

    if( href === '' ) {
      let result = false;
      const link = function getURL(item) {
        if (item.link_url === '' && item.board_id === '') {
          for (const element of item.children) {
            getURL(element);
            if (result) {
              return false;
            }
          }
        } else {
          href = item.link_url;
          boardId = item.board_id;
          menuIndex = item.seq;
          result = true;
        }
      };

      link(menu[0]);
    } else {
      if( menu[0].link_open_yn == 'C' )
        this.router.navigate([ href ]);
      else {
        if( href.indexOf('http') > -1 ) {
          window.open("about:blank").location.href = href;
        } else {
          window.open("about:blank").location.href = location.origin + href;
        }
      }
    }

    if ( event.view.innerWidth > 1675 || menu_on < 0 ) {
      if ( boardId == false ) {
        this.router.navigate([ href ], { queryParams: { menu_seq: menuIndex }});
      } else {
        this.router.navigate([ (href !== '' ? href : '/board/list') ], { queryParams: { board_id: boardId } });
      }
    }
  }

  /******************************************************************************* 
    설  명 : 서브메뉴명 클릭 시 메뉴 닫기 (모바일메뉴) 
    입력값 : 
    리턴값 : 
  *******************************************************************************/
  closeMenu1() {
    this.isMobileOn = false;  
    this.isFullpage = false;  
  }

  /******************************************************************************* 
    설  명 : 로그아웃
    입력값 : 
    리턴값 : 
  *******************************************************************************/
  logout() {
    this.memberService.setLogout().then( async response => {
      if( response.ResultCode == true ) {
        this.toastrService.success(response.ResultMessage, '회원 로그아웃');

        this.authService.logout();

        this.router.navigate(
          ['/'],
          {
            queryParams: {},
            relativeTo: this.activatedRoute,
            queryParamsHandling: '', // remove to replace all query params by provided
          }
        );

      } else {
        this.toastrService.error(response.ResultMessage, '회원 로그아웃');
      }
    })
    .catch(response => {
    });
  }
}
