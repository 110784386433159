import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business43',
  templateUrl: './business43.component.html',
  styleUrls: ['./business43.component.scss']
})
export class Business43Component implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  public boardInfo: any = {
    board_id: 'complain'
  };

  public isboard : boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
