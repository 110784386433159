<app-header [menuList$]="menuList$" [memberInfo$]="memberInfo$"></app-header>

<app-sub-header [currMenu]="currMenu$" [memberInfo]="memberInfo$"></app-sub-header>

<div class="board-page contents-page">

  <!-- 정부시책의 경우 -->
  <ng-container *ngIf="groupTab != '' && boardInfo.board_id != 'customer_service_request' && boardInfo.board_id != 'customer_service_support'">
    <ul [ngClass]="boardInfo.board_id == 'standards_development' ? 'tab1' : 'tab'" clearfix>
      <ng-container *ngFor="let item of groupTab;index as i;">
        <li [ngClass]="{active: params.group_id == item}" (click)="setTab(item, i)">{{item}}</li>
      </ng-container>
    </ul>

    <div class="policy-tab">
      <div class="page-title">{{tabName}}</div>
      <div class="page-title-bar"></div>
    </div>
  </ng-container>
  
  <ng-container *ngIf="boardInfo.board_id != 'qna' && boardInfo.board_id != 'customer_service_request' && boardInfo.board_id != 'customer_service_support'">
    <div class="inner-wrap">
      <app-board-list [boardInfo]="boardInfo" [data]="data" [params]="params" [totalCount]="totalCount"></app-board-list>
    </div>
    <div class="bg1"></div>
    <div class="bg2"></div>
    <div class="bg3"></div>
  </ng-container>
  
  <!-- 고객상담실의 경우 -->
  <ng-container *ngIf="boardInfo.board_id == 'qna' || boardInfo.board_id == 'customer_service_request' || boardInfo.board_id == 'customer_service_support'">
    <ul class="tab1 clearfix">
      <li [ngClass]="{active: qnaTabIndex == '1'}" (click)="setQnaTab('묻고 답하기', '1')">묻고 답하기</li>
      <li [ngClass]="{active: qnaTabIndex == '2'}" (click)="setQnaTab('건의제도', '2')">건의제도</li>
      <li [ngClass]="{active: qnaTabIndex == '3'}" (click)="setQnaTab('회원사애로지원', '3')">회원사애로지원</li>
    </ul>
    
    <div class="policy-tab">
      <div class="page-title">{{tabQnaName}}</div>
      <div class="page-title-bar"></div>
    </div>

    <div class="inner-wrap">
      <app-board-list *ngIf="qnaTabIndex == '1'" [boardInfo]="boardInfo" [data]="data" [params]="params" [totalCount]="totalCount"></app-board-list>
    </div>
    <div class="bg1"></div>
    <div class="bg2"></div>
    <div class="bg3"></div>
  </ng-container>
  
</div>

<app-footer></app-footer>

