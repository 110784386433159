import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '@app/service/auth.service';
import { HMemberService } from '@home/service/member.service';
import { CommonService } from '@app/service/common.service';
import { Globals } from '@app/service/globals.service';
import { BoardService } from '@app/home/service/board.service';

import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent extends O2OZCMS implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  public isSelectOnA: boolean = false; //selectbox on
  public isSelectTxtA = 'BOARD.SEARCH_GROUP1'; // selectbox txt

  public isSelectRow: boolean = false; //selectbox on

  public params: any = {
    no: '',
    row: '',
    board_id: '',
    group_id: '',
    searchGroup: '',
    searchText: '',
    type: '',   // 리스트, 내용보기, 쓰기, 수정 : list, view, write, modify
    view_type: '', //  리스트, 앨범, 갤러리,
  };

  public boardInfo: any = {};
  public memberInfo: any = {};
  public data: any = [];
  public category: any = [];
  public groupTab: any = [];
  public linecnt: any = [];

  public totalCount: any = 0;

  tabName = ''; // tab순번  
  qnaTabIndex = '1'; // tab순번 - 고객상담실의 경우  
  tabQnaName = '묻고 답하기'; // tab순번 - 고객상담실의 경우

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    router: Router,
    commonService: CommonService,
    activatedRoute: ActivatedRoute,
    toastrService: ToastrService,
    private globals: Globals,
    private boardService: BoardService,
  ) {
    // 상위 클래스에 router 전달
    super(router, commonService, activatedRoute, toastrService);
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.board_id = ( typeof params.board_id == 'undefined' || params.board_id == '' ) ? '' : params.board_id;
      this.params.group_id = ( typeof params.group_id == 'undefined' || params.group_id == '' ) ? '' : params.group_id;
      this.params.type = ( typeof params.type == 'undefined' || params.type == '' ) ? 'list' : params.type;
      this.params.row = ( typeof params.row == 'undefined' || params.row == '' ) ? '' : params.row;
      this.params.no = ( typeof params.no == 'undefined' || params.no == '' ) ? '1' : params.no;
      this.params.group = ( typeof params.group == 'undefined' || params.group == '' ) ? '' : params.group;
      this.params.text = ( typeof params.text == 'undefined' || params.text == '' ) ? '' : params.text;
      this.params.view_type = ( typeof params.view_type == 'undefined' || params.view_type == '' ) ? '' : params.view_type;
      this.params.language = ( typeof params.language == 'undefined' || params.language == '' ) ? 'ko' : params.language;

      if( this.params.board_id !== '' ) {
        this.getBoardInfo();
      }

      this.memberInfo$.subscribe( data => {
        if ( typeof data['memNo'] == 'undefined' )  {
          return false;
        } else {
          this.memberInfo = data;
        }
      });

    });
  }

  /*******************************************************************************
    설  명 : restful get, post params 삭제
  *******************************************************************************/
  deleteParams() {
    delete this.params.program;
    delete this.params.service;
    delete this.params.version;
    delete this.params.action;
    delete this.params.token;
  }
  
  /*******************************************************************************
    설  명 : 뷰 처리 완료 후
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngAfterViewInit() {
    // indicator 표시 숨김
    setTimeout (() => {
      this.globals.indicator.next(true);
    });
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardInfo() {
    this.groupTab = [];

    this.boardService.getBoardInfoId( this.params.board_id ).then( response => {
      if( response.ResultCode ) {
        this.boardInfo = response.data;

        if( this.boardInfo.lvt_group == '1' ) {
          this.groupTab = this.boardInfo.group.split('|');

          if( this.params.group_id == '' ) {
            this.tabName = this.groupTab[0];
            this.params.group_id = this.tabName;
          }
        }

        if( this.params.view_type == '' ) {
          switch( this.boardInfo.lvt_default ) {
            case 'L':
              this.params.view_type = 'list';
              break;
            case 'A':
              this.params.view_type = 'album';
              break;
            case 'G':
              this.params.view_type = 'gallery';
              break;
            default:
              this.params.view_type = 'list';
          }
        }

        if( this.params.view_type == 'list' ) this.linecnt = this.boardInfo.linecnt_list.split('/');
        else if( this.params.view_type == 'album' ) this.linecnt = this.boardInfo.linecnt_album.split('/');
        else if( this.params.view_type == 'gallery' ) this.linecnt = this.boardInfo.linecnt_gallery.split('/');
  
        if( this.params.row == '' ) this.params.row = this.linecnt[0];

        // 게시글 리스트 가져오기
        this.getBoardList();

      } else {
        this.globals.indicator.next(false);

        this.toastrService.error( "존재하지 않는 게시판입니다.", "게시판 에러" );

        this.router.navigate( ['/main'] );
        return false;
      }
    });
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardList() {
    this.boardService.getBoardList( this.params ).then( response => {
      if( response.ResultCode ) {
        this.data = response.data;
        this.totalCount = response.total;
      } else {
        this.data = [];
        this.totalCount = 0;
      }

      this.globals.indicator.next(false);
      this.deleteParams();
    });
  }

  /*******************************************************************************
    설  명 : 글 쓰기 권한
    입력값 : 없음
    리턴값 : true / false
  *******************************************************************************/
  authWriteCheck(): boolean {
    if( this.boardInfo.auth_write <= parseInt(this.memberInfo.level) ) {
      return true;
    } else {
      return false;
    }
  }
  
  /*******************************************************************************
    설  명 : 검색 조건 클릭
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  selectSearchGroup( group ) {
    if( group == '' )
      this.isSelectTxtA = 'BOARD.SEARCH_GROUP1';
    else if( group == 'subject' )
      this.isSelectTxtA = 'BOARD.SEARCH_GROUP2';
    else if( group == 'contents' )
      this.isSelectTxtA = 'BOARD.SEARCH_GROUP3';
    else if( group == 'writer' )
      this.isSelectTxtA = 'BOARD.SEARCH_GROUP4';

    this.params.searchGroup = group;
  }

  /*******************************************************************************
    설  명 : 검색 조건 - 라인 수 변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  // selectParams( field, value ) {
  //   this.params[ field ] = value;

  //   this.getBoardList();
  // }
  
  /*******************************************************************************
    설  명 : 검색 조건 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  // initSearch() {
  //   this.params.searchGroup = '';
  //   this.params.searchText = '';
  //   this.isSelectTxtA = 'BOARD.SEARCH_GROUP1';

  //   this.getBoardList();
  // }

  /*******************************************************************************
    설  명 : 엔터 입력 시 검색
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  // searchList( event ) {
  //   if( event.key == 'Enter' ) {
  //     this.getBoardList();
  //   }
  // }

  /*******************************************************************************
    설  명 : 글 저장/수정
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  write() {
    this.params.type = 'write';

    this.router.navigate(
    ['/board/write'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }
  
  /*******************************************************************************
    설  명 : 게시판 리스트 타입 변경
    입력값 : type = L, A, C, G
    리턴값 : 없음
  *******************************************************************************/
  viewChange( value ) {
    this.params.view_type = value;

    this.router.navigate(
    ['/board/list'],
    {
      relativeTo: this.activatedRoute,
      queryParams: this.params,
      queryParamsHandling: '', // remove to replace all query params by provided
    });
  }
  
  /*******************************************************************************
    설  명 : 서브메뉴 링크
    입력값 : item = 메뉴 array
    리턴값 : 없음
  *******************************************************************************/
  goLink( menu ) {
    let href = menu.link_url;
    let boardId = menu.board_id;
    let menuIndex = menu.seq;

    if (href === '') {
      let result = false;
      const link = function getURL(item) {
        if (item.link_url === '' && item.board_id === '') {
          for (const element of item.children) {
            getURL(element);
            if (result) {
              return false;
            }
          }
        } else {
          href = item.link_url;
          boardId = item.board_id;
          menuIndex = item.seq;
          result = true;
        }
      };
      link(menu);
    }

    if ( boardId === '' ) {
      this.router.navigate([ href ], { queryParams: { menu_seq: menuIndex }});
    } else {
      this.router.navigate([ (href !== '' ? href : '/board/list') ], { queryParams: { board_id: boardId } });
    }
  }

  /*******************************************************************************
    설  명 : 탭클릭 이벤트
    입력값 : 
    리턴값 : 없음
  *******************************************************************************/
  setTab(item, i) {
    this.params.searchText = '';
    this.tabName = item;
    this.params.group_id = this.tabName;

    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = "reload";

    // this.router.navigate(
    // ['/board/list'],
    // {
      
    //   relativeTo: this.activatedRoute,
    //   queryParams: this.params,
    //   queryParamsHandling: 'merge', // remove to replace all query params by provided
    // });
    this.getBoardList();
  }

  setQnaTab(item, i) {
    this.qnaTabIndex = i;
    this.tabQnaName = item;

    if ( i == '2') {
      this.params.board_id = 'customer_service_request';
      this.router.navigate([ ('/board/write') ], { queryParams: { board_id: this.params.board_id} });
    } else if ( i == '3' ) {
      this.params.board_id = 'customer_service_support';
      this.router.navigate([ ('/board/write') ], { queryParams: { board_id: this.params.board_id} });
    } else {
      this.params.board_id = 'qna';
      this.params.group_id = '';

      // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      // this.router.onSameUrlNavigation = "reload";
  
      // this.router.navigate(
      // ['/board/list'],
      // {
      //   relativeTo: this.activatedRoute,
      //   queryParams: this.params,
      //   queryParamsHandling: 'merge', // remove to replace all query params by provided
      // });
      this.getBoardList();
    }
  }

}
