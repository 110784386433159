/*******************************************************************************
  설  명 : 비밀번호 찾기 결과
  작성자 : 
  작성일 : 
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '@app/service/common.service';
import { UtilService } from '@app/service/util.service';
import { HMemberService } from '@home/service/member.service';

import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';

@Component({
  selector: 'app-find-pw-result',
  templateUrl: './find-pw-result.component.html',
  styleUrls: ['./find-pw-result.component.scss']
})
export class FindPwResultComponent extends O2OZCMS implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public form: FormGroup;
  public formErrors = {};

  params = {
    memNo: ''
  }

  /*******************************************************************************
    설  명 : 생성자
  *******************************************************************************/
  constructor(
    router: Router,
    commonService: CommonService,
    activatedRoute: ActivatedRoute,
    toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private memberService: HMemberService,
  ) {
    // 상위 클래스에 router 전달
    super(router, commonService, activatedRoute, toastrService);

    this.buildForm();
  }

  /*******************************************************************************
    설  명 : 빌드폼 생성
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  buildForm(): void {
    this.form = this.formBuilder.group({
      memNo: [ '', [Validators.required] ],
      password: [ '', [Validators.required, Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9\d].{5,19}$')] ],
      passwordConfirm: [ '', [Validators.required] ],
    });

    this.form.valueChanges.subscribe(data => {
      this.utilService.updateFormErrors( this.form, this.formErrors );
    });
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
  *******************************************************************************/
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.params.memNo = ( typeof params.memNo == 'undefined' || params.memNo == '' ) ? 'notice' : params.memNo;

      this.form.patchValue({memNo: this.params.memNo});
    });
  }

  /*******************************************************************************
    설  명 : 새 비밀번호 설정
  *******************************************************************************/
  setChangePassword() {
    this.utilService.makeFormDirtyAndUpdateErrors(this.form, this.formErrors);

    if ( this.form.valid ) {
      this.memberService.setChangePassword( this.form ).then( response => {
        if ( response.ResultCode == true ) {
          this.toastrService.success(response.ResultMessage);

          this.router.navigate(['/member/login']);
        } else {
          this.toastrService.error(response.ResultMessage);
        }
      });
    } else {
      this.toastrService.error('필수 입력값을 확인하시기 바랍니다.', '비밀번호 설정');
    }
  }

}
