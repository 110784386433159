import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eatl-business2',
  templateUrl: './eatl-business2.component.html',
  styleUrls: ['./eatl-business2.component.scss']
})
export class EatlBusiness2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
