/*******************************************************************************
  설  명 : 마이페이지
  작성자 : 
  작성일 : 
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { config } from '@app/service/config.service';
import { CommonService } from '@app/service/common.service';
import { HMemberService } from '@app/home/service/member.service';
import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';

@Component({
  selector: 'app-mypage',
  templateUrl: './mypage.component.html',
  styleUrls: ['./mypage.component.scss']
})
  export class MypageComponent extends O2OZCMS implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  public menu: any = {
    depth1_id: 6,
    depth2_id: 5,
    depth1_nm: '회원',
    depth2_nm: '마이페이지',
  }; 

  tabIndex = '1'; // tab순번
  approvalYN = 'N';  // 기업승인여부

  constructor(
    router: Router,
    commonService: CommonService,
    activatedRoute: ActivatedRoute,
    toastrService: ToastrService,
    private memberService: HMemberService,
  ) {
    super(router, commonService, activatedRoute, toastrService);
  }

  ngOnInit(): void {
    this.getMemberInfo();
  }

  /*******************************************************************************
    설  명 : 사용자 기업승인여부 가져오기
  *******************************************************************************/
  getMemberInfo() {
    this.memberService.getMemberInfo().then(response => {
      if( response['ResultCode'] == true ) {
        if (response.data.company_approval == '1')
          this.approvalYN = 'Y'
        else
          this.approvalYN = 'N';
      }
    })
  }
}
