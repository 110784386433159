<div class="modal-header">
  <div class="modal-title">보안</div>
  <button class="close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <div class="pw-input">
    <div class="txt">비밀번호를 입력해주세요.</div>  
    <input type="password" (change)="setPw($event)">
    <button class="confirm-btn" (click)="confirmPw()">닫기</button>
  </div>
  
</div>
