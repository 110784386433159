import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OnlineApplyService } from '@app/admin/service/online.apply.service';
import { ToastrService } from 'ngx-toastr';
import { FileUploader, FileItem, FileLikeObject } from 'ng2-file-upload';
import { config } from '@app/service/config.service';

const URL = config.apiFileUploadUrl;

@Component({
  selector: 'app-report-check-modal',
  templateUrl: './report-check-modal.component.html',
  styleUrls: ['./report-check-modal.component.scss']
})
export class ReportCheckModalComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/  
  public tabIndex = 1;

  initialValue = ''
  applyInfo = {
    company: this.initialValue,
    depart: this.initialValue,
    name: this.initialValue,
    hp: this.initialValue,
    email_id: this.initialValue,
    email_domain: this.initialValue,
    file_path: this.initialValue,
    file_name: this.initialValue,
  }
  errorMessage = {
    company: '소속',
    depart: '부서',
    name: '성명',
    hp: '연락처',
    email_id: '이메일',
    email_domain: '이메일',
    file_path: '파일',
    file_name: '파일'
  }

  public baseURL = config.baseUrl;
  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'file' });
  public uploadProgress: any = 0;

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  constructor(
    public activeModal: NgbActiveModal,
    public onlineApplyService: OnlineApplyService,
    public toastrService: ToastrService,
  ) { }

  /*******************************************************************************
    설  명 : 파일 변경시 처리
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  handleUploadFileChanged(event) {
    this.uploader.clearQueue();

    const files: File[] = event.target.files;
    const filteredFiles: File[] = [];
    for (const f of files) {
      filteredFiles.push(f);
    }

    const options = null;
    const filters = null;
    this.uploader.addToQueue(filteredFiles, options, filters);

    this.uploader.uploadAll();
  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  ngOnInit(): void {

    // 업로드 허용 파일 설정
    const filetype: string[] = ['pdf'];

    // 업로더 생성
    this.uploader = new FileUploader({
      url: URL,
      itemAlias: 'file',
      maxFileSize: 5 * (1024 * 1024), // 최대 업로드 허용 5MB 용량
      allowedFileType: filetype // 허용 업로드 파일 타입
    });

    // 파일업로드 설정
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
      this.uploadProgress = '0%';
    };

    // 업로드시 프로그레스바 처리
    this.uploader.onProgressItem = (fileItem: FileItem, progress: any) => {
      this.uploadProgress = progress + '%';
    };

    // 업로드 용량 초과시 처리
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject, filter: any, options: any) => {
      if ( filter.name == 'fileSize' ) {
        this.toastrService.error( '파일 업로드 용량(50MB)을 초과하였습니다.', '파일 업로드');
      } else if ( filter.name == 'fileType' ) {
        this.toastrService.error( '허용되는 업로드 파일 타입이 아닙니다.', '파일 업로드');
      }
    };

    // 파일업로드 완료시 처리
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      response = $.parseJSON( response );

      if ( response.result ) {
        this.toastrService.success( response.message, '');
        this.applyInfo.file_name = response.origin_filename
        this.applyInfo.file_path = this.baseURL + response.url

      } else {
        this.toastrService.error( response.message, '');
      }
    };
  }

  setEmailDomain($event) {
    this.applyInfo.email_domain = $event.target.value
  }

  submit() {
    for(const key in this.applyInfo) {
      if(!this.applyInfo[key]) {
        this.toastrService.warning(`${this.errorMessage[key]} 을(를) 입력하세요.`)
        return
      }
    }
    const data = {
      ...this.applyInfo,
      email: `${
        this.applyInfo.email_id
      }@${
        this.applyInfo.email_domain
      }`,
    }

    this.onlineApplyService.postReportCheck(data).then(res => {
      if(res.ResultCode) {
        this.toastrService.success('정상적으로 신청하였습니다.')
        this.activeModal.dismiss()
      } else this.toastrService.error('신청이 정상적으로 이루어지지 않았습니다.')
    })
  }

}
