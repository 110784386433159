/*******************************************************************************
  설  명 : 메인화면
  작성자 : 송영석
  작성일 : 2020-05-18
*******************************************************************************/
import { Component, OnInit, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector'
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { CommonService } from '@app/service/common.service';
import { BoardService } from '@app/home/service/board.service';
import { Globals } from '@app/service/globals.service';

import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ReportCheckModalComponent } from '../online-application/report-check-modal/report-check-modal.component';

const optionsLG: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  centered: true,
  windowClass:'modal-certification'
};

const optionsXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xl',
  centered: true,
  windowClass:'modal-certification'
};

const optionsXXL: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'xxl',
  centered: true,
  windowClass:'modal-certification'
};

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent extends O2OZCMS implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  slideConfig_main = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    dots:true,
    dotsClass: 'main_custom_paging',
    customPaging: function (slider: any, i: any) {
      return  '<span>' + (i + 1) + '</span>' + '/' + slider.slideCount;   
    },      
    autoplay: true,
    autoplaySpeed : 5000, 
    speed:1000,
    infinite:true,
    pauseOnHover:false,
    centerPadding: "0px",
  };

  slideConfig_business = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    dots:false,
    autoplay: true,
    autoplaySpeed : 5000, 
    centerMode: true,
    speed:1000,
    infinite:true,
    variableWidth:true,
    pauseOnHover:true,
    centerPadding: "0px",
    // cssEase: 'step-start'
  };

  isProgressInit : boolean = false; // main slide 진행바 초기화
  isPlay : boolean = true; //메인이미지 play 상태

  beforeSlide_business: boolean = false; 
  
  @ViewChild(SlickCarouselComponent, { static: false }) slickModal?: SlickCarouselComponent;

  public params: any = {
    no: '1',
    row: '3',
    totalCount: '',
    board_id: 'notice',
    group_id: '',
    searchGroup: '',
    searchText: '',
    type: '',   // 리스트, 내용보기, 쓰기, 수정 : list, view, write, modify
    view_type: '', //  리스트, 앨범, 갤러리,
  };
  public data: any = [];
  public boardInfo: any = {};
  public linecnt: any = [];
  public popupList: any = [];

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    router: Router,
    commonService: CommonService,
    activatedRoute: ActivatedRoute,
    toastrService: ToastrService,
    private boardService: BoardService,
    private globals: Globals,
    private modalService: NgbModal,
    private cookieService: CookieService,
    private deviceDetectorService: DeviceDetectorService,
  ) {
    // 상위 클래스에 router 전달
    super(router, commonService, activatedRoute, toastrService);

  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ngOnInit(): void {
    this.getBoardInfo();
    this.getBoardPopupList();
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardInfo() {
    this.boardService.getBoardInfoId( this.params.board_id ).then( response => {
      if( response.ResultCode ) {
        this.boardInfo = response.data;

        if( this.params.view_type == '' ) {
          switch( this.boardInfo.lvt_default ) {
            case 'L':
              this.params.view_type = 'list';
              break;
            case 'A':
              this.params.view_type = 'album';
              break;
            case 'G':
              this.params.view_type = 'gallery';
              break;
            default:
              this.params.view_type = 'list';
          }
        }

        if( this.params.view_type == 'list' ) this.linecnt = this.boardInfo.linecnt_list.split('/');
        else if( this.params.view_type == 'album' ) this.linecnt = this.boardInfo.linecnt_album.split('/');
        else if( this.params.view_type == 'gallery' ) this.linecnt = this.boardInfo.linecnt_gallery.split('/');
  
        if( this.params.row == '' ) this.params.row = this.linecnt[0];

        // 게시글 리스트 가져오기
        if ( this.boardInfo.board_id != 'customer_service' ) {
          this.getBoardList();
        }

      } else {
        this.globals.indicator.next(false);

        this.toastrService.error( "존재하지 않는 게시판입니다.", "게시판 에러" );

        this.router.navigate( ['/main'] );
        return false;
      }
    });
  }

  /*******************************************************************************
    설  명 : 팝업 리스트 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardPopupList() {
    this.boardService.getPopupList( {board_id: 'popup', limit: '0'} ).then( response => {
      if ( response.ResultCode ) {
        if ( response.data.length > 0 ) {
          response.data.forEach( row => {

            if (this.dateDiff(row.notice_sdate, row.notice_edate)) {
              return true;
            }

            if ( this.getPopupToday(row.board_seq) ) {
              if ( row.layer_popup == true ) {
                row.is_popup_preview = true;
                this.popupList.push(row);
              } else {
                const url = 'https://www.eaa.or.kr/api/popup/popup.php?board_id=' + row.board_id + '&board_seq=' + row.board_seq;
                window.open(url, '', 'resizable=no, toolbar=no, scrollbars=auto, menubar=no, directories=no, location=no, width='+row.popup_width+', height='+(parseInt(row.popup_height, 10) + 108)+', left='+row.screen_x+', top=' + row.screen_y );
              }
            }
          });
        }
      }
    });
  }
  
  /*******************************************************************************
    설  명 : 날짜 비교
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  dateDiff(sdate, edate) {
    sdate = sdate.split('-').join('');
    edate = edate.split('-').join('');
    const today = moment().format('YYYY') + moment().format('MM') + moment().format('DD');

    if (sdate > today || edate < today) {
      return true;
    }
  }
  
  /*******************************************************************************
    설  명 : 팝업 오늘 더 이상 보지 않기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getPopupToday( boardSeq ) {
    if ( this.cookieService.get( 'popup' + boardSeq ) == 'hidden' ) {
      return false;
    } else {
      return true;
    }
  }

  /*******************************************************************************
    설  명 : 팝업 오늘 더 이상 보지 않기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  setPopupToday( row ) {
    this.cookieService.set( 'popup' + row.board_seq, 'hidden', 1 );

    row.is_popup_preview = false;
  }

  /*******************************************************************************
    설  명 : 게시판 정보 가져오기
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  getBoardList() {
    this.boardService.getBoardList( this.params ).then( response => {
      if( response.ResultCode ) {
        this.data = response.data;
        
        // 최신글 판별
        this.data.map( row => {
          row.new_check_date = row.new_check_date * 1;
        });

        // 글 내용 태그 제거
        this.data.map ( row => {
          row.contents = row.contents.replace(/(<([^>]+)>)/ig,"")
        });

        this.params.totalCount = response.total;
      } else {
        this.data = [];
        this.params.totalCount = 0;
      }

      this.globals.indicator.next(false);
    });
  }

  /*******************************************************************************
    설  명 : main slider pause / play
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  slidePausePlay() {
    if (this.isPlay) {
      this.slickModal?.slickPause();
      this.isProgressInit = true;
    } else {
      this.slickModal?.slickPlay();
      this.isProgressInit = false;
    }
    this.isPlay = !this.isPlay;
  }
  
  beforeChange(e: any) {
    this.isProgressInit = true;
  }  
  afterChange(e: any) {
    this.isProgressInit = false;
  }  
  
  beforeChange_business(e: any) {
    this.beforeSlide_business = true;
  }  
  afterChange_business(e: any) {
    this.beforeSlide_business = false;
  }    

  
  /*******************************************************************************
    설  명 : 성적서진위확인신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  authenticityModal() {
    const modalRef = this.modalService.open(ReportCheckModalComponent, optionsXL);
    modalRef.result.then((result) => {
      if( result ) {
      }
    }, (reason) => {
    });
  }

  /*******************************************************************************
    설  명 : 모바일에서 레이어 팝업 표시할 때 위치값 변경
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  changePopupLayerStyle(row: any, element: any) {
    if( this.deviceDetectorService.isDesktop() ) return row[ element ];
    else return 0;
  }
}
