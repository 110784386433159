<div class="eatl-business4">
  <div class="page-title">표준개발협력기관</div>
  <div class="page-title-bar"></div>

  <div class="guide-txt">
    ISO의 설립 목적은 ISO정관(Statute) 제2조에<br class="mobile-only"> 명기된 바와 같이 상품 및 서비스의 국제적 교환을<br class="mobile-only"> 촉진하고, 지적, 과학적, 기술적,<br class="pc-only"> 
    경제적 활동 분야<br class="mobile-only">에서의 협력 증진을 위하여 세계의 표준화 및 관련 활동의<br class="mobile-only"> 발전을 촉진시키는데 있다. 이러한 목적 달성을 위하여,<br class="mobile-only"> ISO는 다음과 같은 업무를 수행할 수 있다.
  </div>

  <ul class="box clearfix">
    <li><span></span><span>표준 및 관련 활동의 세계적인 조화를 촉진시키기 위한 조치를 취한다.</span></li>
    <li><span></span><span>국제표준을 개발, 발간하며, 이 표준들이 세계적으로 사용되도록 조치를 취한다.</span></li>
    <li><span></span><span>회원기관 및 기술위원회의 작업에 관한 정보의 교환을 주선한다.</span></li>
    <li><span></span><span>관련 문제에 관심을 갖는 다른 국제기구와 협력하고, 특히 이들이 요청하는 경우 표준화 사업에 관한 연구를 통하여 타 국제기구와 협력한다.</span></li>
    <li><span></span><span>표준화 사업에 관한 연구를 통하여 타 국제기구와 협력한다.</span></li>
  </ul>  

  <ul class="tab clearfix">
    <li [ngClass]="{active: tabIndex1 == '1'}" (click)="tabIndex1 = '1'">COSD</li>
    <li [ngClass]="{active: tabIndex1 == '2'}" (click)="tabIndex1 = '2'">국제표준화활동</li>
    <li [ngClass]="{active: tabIndex1 == '3'}" (click)="tabIndex1 = '3'">정책대응</li>
    <li><a href="https://www.standard.go.kr/KSCI/standardIntro/standardPrvntc.do?menuId=920&topMenuId=502&upperMenuId=516s" target="_blank">표준화활동 KS고시</a></li>
  </ul>

  <app-business41 *ngIf="tabIndex1 == '1'"></app-business41>
  <app-business42 *ngIf="tabIndex1 == '2'"></app-business42>
  <app-business43 *ngIf="tabIndex1 == '3'"></app-business43>
  
