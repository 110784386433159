import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ApplicationModalComponent } from '../application-modal/application-modal.component';
import { ComplainModalComponent } from './complain-modal/complain-modal.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg', 
  centered: true,
  windowClass:'modal-1100'
};

@Component({
  selector: 'app-accredited-test',
  templateUrl: './accredited-test.component.html',
  styleUrls: ['./accredited-test.component.scss']
})
export class AccreditedTestComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  public menu: any = {
    depth1_id: 2,
    depth2_id: 1,
    depth1_nm: '온라인 신청',
    depth2_nm: '공인시험검사신청',
  };   

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  constructor(
    private modalService: NgbModal,
  ) { }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 온라인 시험 검사신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ApplicationModal(tabIndex: any) {
    const modalRef = this.modalService.open(ApplicationModalComponent, options);

    modalRef.componentInstance.tabIndex = tabIndex;

    modalRef.result.then((result) => {
      if( result ) {
      }
    }, (reason) => {
    });
  }    

  /*******************************************************************************
    설  명 : 불만처리신청
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  ComplainModal() {
    const modalRef = this.modalService.open(ComplainModalComponent, options);

    modalRef.result.then((result) => {
      if( result ) {
      }
    }, (reason) => {
    });
  }    

}
  