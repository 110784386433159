import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-join-guide',
  templateUrl: './join-guide.component.html',
  styleUrls: ['./join-guide.component.scss']
})
export class JoinGuideComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  
  public menu: any = {
    depth1_id: 4,
    depth2_id: 1,
    depth1_nm: '회원사',
    depth2_nm: '진흥회 가입안내',
  }; 
  
  constructor() { }

  ngOnInit(): void {
  }

}
