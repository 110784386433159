/*******************************************************************************
  설  명 : 성상분석DATA관리
  작성일 : 2022-02-15
  작성자 : 김종현
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { O2OZCMS } from '@app/components/o2oz-cms/o2oz-cms.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '@app/service/common.service';
import { StatisticsService } from '@home/service/statistics.service';
import * as moment from 'moment';


@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent extends O2OZCMS implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  
  public menu: any = {
    depth1_id: 3,
    depth2_id: 3,
    depth1_nm: '고객서비스',
    depth2_nm: '성상분석 DATA',
  }; 

  isOpenYY : boolean = false;
  isOpenMM : boolean = false;
  public selectedYY = moment().format('YYYY');
  public yearList = [];
  public dataList = [];
  selectedMM = moment().format('MM');
  public sdate = '';
  public edate = '';

  constructor(
    router: Router,
    commonService: CommonService,
    activatedRoute: ActivatedRoute,
    toastrService: ToastrService,
    public statisticsService: StatisticsService
  ) { 
    super(router, commonService, activatedRoute, toastrService);
  }

  ngOnInit(): void {
    this.getYear();
    this.getData();
  }

  /*******************************************************************************
    설명 : 연도 가져오기
  *******************************************************************************/
  getYear() {
    this.statisticsService.getYear().then(response => {
      if ( response.ResultCode ) {
        this.yearList = response.data;
        this.selectedYY = response.data[0].years;
      }
    });
  }

  /*******************************************************************************
    설명 : 데이터 가져오기
  *******************************************************************************/
  getData() {
    this.statisticsService.getData(this.selectedYY, this.selectedMM).then(response => {
      if ( response.ResultCode ) {
        this.dataList = response.data;
        this.sdate = response.data[0].analysis_date;
        this.edate = response.data[response.data.length - 1 ].analysis_date;
        if ( response.data.length == 0 ) {
          this.toastrService.error('데이터가 존재하지 않습니다.');
        }
      }
    });
  }


}
