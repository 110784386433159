<div class="board-list">
  <!-- <div class="board-header clearfix">
    <div *ngIf="boardInfo.board_id=='standard'" class="subtitle">표준화 활동 - <span class="bold">ISO/TC 161</span> (총 5건)</div>
    <div *ngIf="boardInfo.board_id=='complain'" class="subtitle">민원접수 및 기업애로사항 (총 5건)</div>
    <div *ngIf="boardInfo.board_id !=='standard' && boardInfo.board_id !=='complain'" class="rowcnt">총 <span>{{totalCount | number}}</span>건</div>
    <div class="search-area">
      <div class="select mr10">
        <div class="select-title" (click)="isSelectOn=!isSelectOn">{{isSelectTxt}}</div>
        <ul *ngIf="isSelectOn" (click)="isSelectOn=!isSelectOn">
          <li (click)="selectSearchGroup('')">전체</li>
          <li (click)="selectSearchGroup('subject')">제목</li>
          <li (click)="selectSearchGroup('contents')">내용</li>
        </ul>
      </div>
      <input type="text" placeholder="검색어를 입력해주세요" class="input-txt mr10" [(ngModel)]="params.searchText" (keypress)="searchList($event)" />
      <button class="search-btn" (click)="getBoardList()">검색</button>
    </div>
  </div> -->

  <table class="list-table" class={{boardInfo.board_id}}>
    <colgroup>
      <col class="col1">
      <col class="col2"/>
      <col class="col3" *ngIf="boardInfo.board_id=='standard'">
      <col class="col4" *ngIf="boardInfo.board_id=='qna'">
      <col class="col5" *ngIf="boardInfo.board_id=='qna'">
      <col class="col6">
      <col class="col7" *ngIf="boardInfo.board_id!=='qna'">
    </colgroup>
    <thead>
    <tr>
      <th>번호</th>
      <th>제목</th>
      <th *ngIf="boardInfo.board_id=='standards_development' || boardInfo.board_id=='policy'">파일</th>
      <th *ngIf="boardInfo.board_id=='qna'">상태</th>
      <th *ngIf="boardInfo.board_id=='qna'">작성자</th>
      <th>등록일</th>
      <th *ngIf="boardInfo.board_id!=='qna'">조회</th>
    </tr>
    </thead>    
    <tbody>
      <tr *ngFor="let item of data; index as i" [ngClass]="{important: item.is_notice===true}" (click)="goView(item)">
        <td *ngIf="item.is_notice == '1'">중요</td>
        <td *ngIf="item.is_notice == '0'">{{item.board_seq}}</td>
        <td class="cp">
          {{item.subject}}<span *ngIf="boardInfo.board_id=='qna' && item.is_pw == '1'" class="lock"></span>
          <p *ngIf="boardInfo.board_id=='qna'" class="mobile-only"><span>{{item.write_date.substr(0, 10)}}</span><span>{{item.writer_name}}</span></p>
        </td>
        <td *ngIf="boardInfo.board_id=='standards_development' || boardInfo.board_id=='policy'" class="file">
          <!-- <a href="{{item.upload_file}}"></a> -->
          <a [href]="baseURL + '/api/file/file_download.php?seq=' + item.seq" target="_blank">{{item.origin}}</a>
        </td>
        <td *ngIf="boardInfo.board_id=='qna'">{{item.status == '0' ? '확인중' : '확인완료' }}</td>
        <td *ngIf="boardInfo.board_id=='qna'">{{item.writer_name}}</td>
        <td>{{item.write_date.substr(0, 10)}}</td>
        <td *ngIf="boardInfo.board_id!=='qna'">{{item.hit}}</td>
      </tr>
    </tbody>
  </table>

  <div class="clearfix">
    <button *ngIf="boardInfo.board_id=='qna' || boardInfo.board_id=='member_news' || boardInfo.board_id=='member_movements'" class="write-btn" (click)="write()">글쓰기</button>   	

    <div class="pagination-area">
      <ngb-pagination *ngIf="totalCount > 0"
        [(page)]="params.no"
        [pageSize]="params.row"
        [maxSize]="maxSize"
        [collectionSize]="totalCount"
        [boundaryLinks]="true"
        rotate="true"
        (pageChange)="loadPage($event)"
        >
        <ng-template ngbPaginationFirst>&lt;&lt;</ng-template>
        <ng-template ngbPaginationLast>&gt;&gt;</ng-template>
        <ng-template ngbPaginationPrevious>&lt;</ng-template>
        <ng-template ngbPaginationNext>&gt;</ng-template>
      </ngb-pagination>
    </div>  
    <button *ngIf="boardInfo.board_id=='standard' || boardInfo.board_id=='complain'" class="back-btn" (click)="back.emit()">뒤로가기</button>   	
  </div>
</div>
