/*******************************************************************************
  설  명 : 표준개발협력제안
  작성자 : 
  작성일 : 
*******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { StandardDevelopmentApplication1Component } from './standard-development-application1/standard-development-application1.component';
import { StandardDevelopmentApplication2Component } from './standard-development-application2/standard-development-application2.component';

const options: NgbModalOptions = {
  backdrop: 'static',
  keyboard: false,
  size: 'lg', 
  centered: true,
  windowClass:'modal-900'
};

@Component({
  selector: 'app-standard-development',
  templateUrl: './standard-development.component.html',
  styleUrls: ['./standard-development.component.scss']
})
export class StandardDevelopmentComponent implements OnInit {

  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/

  public menu: any = {
    depth1_id: 2,
    depth2_id: 3,
    depth1_nm: '온라인 신청',
    depth2_nm: '표준개발협력제안',
  };   

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  constructor(
    private modalService: NgbModal,
  ) { }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/  
  ngOnInit(): void {
  }

  /*******************************************************************************
    설  명 : 표준개발협력제안
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  application1() {
    const modalRef = this.modalService.open(StandardDevelopmentApplication1Component, options);

    modalRef.result.then((result) => {
      if( result ) {
      }
    }, (reason) => {
    });
  }  

  /*******************************************************************************
    설  명 : 표준개정검토 요청서
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
    application2() {
    const modalRef = this.modalService.open(StandardDevelopmentApplication2Component, options);

    modalRef.result.then((result) => {
      if( result ) {
      }
    }, (reason) => {
    });
  }     
}
    